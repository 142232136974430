import React from 'react'
import {Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import RXRButtonLoader from './RXRButtonLoader'
import PropTypes from 'prop-types'
import {Buttons} from '../assets/styles'
import {useSelector} from 'react-redux'

const mobileOverride = {
  height: 24,
  lineHeight: '24px',
}

const styles = {
  defaultRoot: Buttons.primaryButton,
  defaultDisabled: Buttons.primaryButtonDisabled,

  largeRoot: Buttons.roundedPrimaryButton,
  largeDisabled: Buttons.roundedPrimaryButtonDisabled,

  secondaryRoot: Buttons.secondaryButton,
  secondaryDisabled: Buttons.secondaryButtonDisabled,

  destructiveRoot: Buttons.deleteButton,
  destructiveDisabled: Buttons.deleteButtonDisabled,

  textRoot: Buttons.primaryTextButton,

  textSecondaryRoot: Buttons.secondaryTextButton,
}
// TODO: this not working for some reason. Some strange stuff happening where the hover prop &:hover is being removed...
//  disabling for now.
// // apply our conditional mobile styles to all classes
// Object.keys(styles).forEach(key => {
//   const thisStyle = styles[key]
//   styles[key] = props => (props.isMobile ? {...thisStyle, ...mobileOverride} : thisStyle)
// })

const useStyles = makeStyles(styles)

function getRootClassFromProps(props, classes) {
  let type = props.type || RXRButton.TYPE_DEFAULT

  switch (type) {
    case RXRButton.TYPE_PRIMARY:
    case RXRButton.TYPE_DEFAULT:
      return !props.disabled ? classes.defaultRoot : classes.defaultDisabled

    case RXRButton.TYPE_LARGE:
      return !props.disabled ? classes.largeRoot : classes.largeDisabled

    case RXRButton.TYPE_SECONDARY:
      return !props.disabled ? classes.secondaryRoot : classes.secondaryDisabled

    case RXRButton.TYPE_DESTRUCTIVE:
      return !props.disabled ? classes.destructiveRoot : classes.destructiveDisabled

    case RXRButton.TYPE_TEXT:
      return classes.textRoot

    case RXRButton.TYPE_TEXT_SECONDARY:
      return classes.textSecondaryRoot
  }
}

// TODO: this shouldn't be a named export if it's also a default export
export function RXRButton(props) {
  // const isMobile = useSelector(state => state.App.isMobile) || false
  const classes = useStyles()
  const [width, setWidth] = React.useState(0)
  const [height, setHeight] = React.useState(0)
  const ref = React.useRef(null)

  React.useEffect(() => {
    if (ref.current && ref.current.getBoundingClientRect().width) {
      setWidth(ref.current.getBoundingClientRect().width)
    }
    if (ref.current && ref.current.getBoundingClientRect().height) {
      setHeight(ref.current.getBoundingClientRect().height)
    }
  }, [props.children])

  return (
    <Button
      classes={{
        root: getRootClassFromProps(props, classes),
      }}
      ref={ref}
      style={
        width && height && props.isLoading === true
          ? {
              width: `${width}px`,
              height: `${height}px`,
            }
          : {}
      }
      type={props.htmlType}
      disabled={props.isLoading || props.disabled}
      onClick={props.onClick}
      className={props.className}
    >
      {props.isLoading ? <RXRButtonLoader imgWidth={width * 0.8} imgHeight={height * 0.8} /> : props.children}
    </Button>
  )
}

RXRButton.TYPE_DEFAULT = 'default'
RXRButton.TYPE_PRIMARY = RXRButton.TYPE_DEFAULT
RXRButton.TYPE_LARGE = 'large'
RXRButton.TYPE_SECONDARY = 'secondary'
RXRButton.TYPE_DESTRUCTIVE = 'destructive'
RXRButton.TYPE_TEXT = 'text'
RXRButton.TYPE_TEXT_SECONDARY = 'text-secondary'

RXRButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.any,
  disabled: PropTypes.bool,
  htmlType: PropTypes.string,
}

export default RXRButton
