import * as Sentry from '@sentry/react'

class Pollable {
  /**
   * @param {string} name
   * @param {number} timeoutMs
   * @param {function} queryFunc
   */
  constructor(name, timeoutMs, queryFunc) {
    this.name = name
    this.query = queryFunc
    this.timeout = timeoutMs

    this.poll = this.poll.bind(this)
  }

  /**
   * @param {function} q
   */
  updateQueryFunction(q) {
    this.query = q
  }

  /**
   * @param {boolean?} skipInitial
   */
  startPolling(skipInitial) {
    this.stopPolling()
    if (!skipInitial) {
      // we want to start with a poll
      this.poll().then(() => {})
    }

    this._interval = setInterval(this.poll, this.timeout)
  }

  stopPolling() {
    clearInterval(this._interval)
    delete this._interval
  }

  /**
   * @return {boolean}
   */
  isPolling() {
    return !!this._interval
  }

  /**
   * @param {function} callback
   */
  onPoll(callback) {
    this._onSuccess = callback
  }

  async poll() {
    if (this._lock) {
      console.log(`Poll "${this.name}" still locked, ${Date.now() - this._lock}ms`)
      return
    }
    this._lock = Date.now()

    try {
      let retVal = await this.query()
      if (typeof this._onSuccess === 'function') {
        this._onSuccess(retVal)
      }
    } catch (err) {
      console.error(err)
      Sentry.withScope(scope => {
        scope.setTag('Pollable', this.name)
        Sentry.captureException(err)
      })
    }

    delete this._lock
  }
}

export default Pollable
