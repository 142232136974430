import React, {useState, useEffect} from 'react'
import useFormChanged from '../../hooks/useFormChanged'
import * as Constant from '../../ResidentProfiles/ResidentConstant'
import SlimMultiSelectInput from '../../SlimMultiSelectInput'
import {Grid} from '@material-ui/core'
import ProfileImage from '../../ProfileImage'
import TextInput from '../../TextInput'
import {makeStyles} from '@material-ui/core/styles'
import {spaceMedium, spaceSmall} from '../../../assets/styles/spacing'
import MultilineTextInput from '../../MultilineTextInput'
import useSharedStyles from '../useSharedStyles'
import {useDispatch, useSelector} from 'react-redux'
import SaveOrEditFormCTAs from '../FormCTAs/SaveOrEditFormCTAs'
import FileHelper from '../../../lib/FileHelper'
import {setUpdateResident} from '../../../actions/buildingsActions'
import {isOnboardingTaskStateCompleted} from '../../../Utils/onboardingUtils'
import {ONBOARDING_STATE_COMPLETED} from '../../../constants/ModelConstants'
import {FormComponentPropTypes} from '../onboardingTypes'

// ----------------------------------------------------------------------
// A single form for a single resident's personal preferences

const EMPTY_FORM = {
  goesBy: '',
  picture: null,
  giftPreferences: [],
  alcoholPreferences: [],
  foodPreferences: [],
  dietaryRestrictions: '',
}

function PersonalPreferences(props) {
  const sharedClasses = useSharedStyles()
  const classes = useStyles()
  const {setForm, form, formChanged, resetInitialForm} = useFormChanged(EMPTY_FORM)
  const [isEditMode, setIsEditMode] = useState(false)
  const resident = useSelector(state => state.Residents.residentsLookup[props.submission.residentId])
  const dispatch = useDispatch()

  useEffect(() => {
    if (!resident) {
      resetInitialForm(EMPTY_FORM)
      return
    }

    resetInitialForm({...(resident.userProfileObj || EMPTY_FORM), picture: resident.profileImage})
  }, [resident])

  const handleSave = async () => {
    // pull the profile image out of the form (we don't want to save it on the profile object)
    const newProfileImage = form.picture
    delete form.picture

    const input = {
      id: resident.id,
      userProfile: JSON.stringify(form),
      profileImage: FileHelper.formatS3ObjectForInput(newProfileImage),
    }

    await setUpdateResident(dispatch, input)

    // the state goes to Completed when it's saved
    await props.onUpdateSubmission({...props.submission, state: ONBOARDING_STATE_COMPLETED})

    resetInitialForm(form)
    setIsEditMode(false)
  }

  if (!resident) {
    return null
  }

  const canEditFields = isEditMode || !isOnboardingTaskStateCompleted(props.submission.state)

  return (
    <React.Fragment>
      <div className={sharedClasses.bodyContainer}>
        <p style={{marginBottom: spaceSmall}}>Fill fields below. Attach a profile photo</p>

        <div>
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} className={classes.nameAndImageContainer}>
              <ProfileImage
                className={classes.profileImage}
                colorId={props.submission.residentId}
                displayName={resident.displayName}
                s3Object={form.picture}
                onUploaded={canEditFields ? s3Obj => setForm({picture: s3Obj}) : undefined}
              />
              <TextInput
                label="Goes by"
                value={form.goesBy}
                onChange={v => setForm({goesBy: v})}
                isRequired={true}
                isDisabled={!canEditFields}
              />
            </Grid>
          </Grid>

          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <SlimMultiSelectInput
                label={'Gift preferences'}
                valueLabel={(form.giftPreferences || []).map(g => Constant.GIFT_PREFERENCES_LABELS[g]).join(', ')}
                options={Constant.GIFT_PREFERENCES_OPTIONS}
                onChange={vals => setForm({giftPreferences: vals})}
                value={form.giftPreferences}
                isDisabled={!canEditFields}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SlimMultiSelectInput
                label={'Alcohol preferences'}
                valueLabel={(form.alcoholPreferences || []).map(a => Constant.ALCOHOL_PREFERENCES_LABELS[a]).join(', ')}
                options={Constant.ALCOHOL_PREFERENCES_OPTIONS}
                onChange={vals => setForm({alcoholPreferences: vals})}
                value={form.alcoholPreferences}
                isDisabled={!canEditFields}
              />
            </Grid>
          </Grid>

          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <SlimMultiSelectInput
                label={'Food preferences'}
                valueLabel={(form.foodPreferences || []).map(f => Constant.FOOD_PREFERENCES_LABELS[f]).join(', ')}
                options={Constant.FOOD_PREFERENCES_OPTIONS}
                onChange={vals => setForm({foodPreferences: vals})}
                value={form.foodPreferences}
                isDisabled={!canEditFields}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MultilineTextInput
                label="Dietary restrictions"
                placeholder=""
                rows={3}
                value={form.dietaryRestrictions}
                onChange={val => setForm({dietaryRestrictions: val})}
                isDisabled={!canEditFields}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      {canEditFields ? (
        <SaveOrEditFormCTAs onSave={handleSave} onCancel={props.onCancel} hasChanges={formChanged} />
      ) : (
        <SaveOrEditFormCTAs onSave={() => setIsEditMode(true)} isEdit={true} />
      )}
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  nameAndImageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  profileImage: {
    width: '100px',
    height: '100px',
    marginRight: spaceMedium,
    flexShrink: 0,
  },
}))

PersonalPreferences.propTypes = FormComponentPropTypes

export default PersonalPreferences
