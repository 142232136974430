import React, {useState} from 'react'
import {Grid, makeStyles} from '@material-ui/core'
import RXRIcon from '../RXRIcon'
import {rxrBlackColor, rxrTealColor} from '../../assets/styles/color'
import PropTypes from 'prop-types'
import {spaceExtraLarge, spaceExtraSmall, spaceLarge, spaceMedium, spaceSmall} from '../../assets/styles/spacing'
import {lightFontWeight, fontSizeMedium, mediumFontWeight, fontSizeExtraLarge, fontSizeMediumLarge} from '../../assets/styles/typography'
import {useSelector} from 'react-redux'
import {pluralizeWord} from '../../Utils/StringFormatter'
import RXRButton from '../RXRButton'
import EmptyStateIconAndMessage from '../EmptyStateIconAndMessage'
import {getOnboardingResidentsLookup} from '../../reducers/selectors'
import moment from 'moment'
import {Typography} from '@material-ui/core'
import OnboardingResidentRowContainer from './OnboardingResidentRowContainer'

function OnboardingSummary(props) {
  const classes = useStyles()
  const [isExpanded, setIsExpanded] = useState(false)
  const onboardingResidentsLookup = useSelector(state => getOnboardingResidentsLookup(state))
  let moveInsThisWeek = []
  let otherMoveIns = []
  if (onboardingResidentsLookup && Array.isArray(Object.entries(onboardingResidentsLookup))) {
    Object.entries(onboardingResidentsLookup).forEach(([id, resident]) => {
      let residentMoveInDate = resident.moveInDate
      if (moment(residentMoveInDate).isBefore(moment().add(7, 'days'))) {
        moveInsThisWeek.push(resident)
      } else {
        otherMoveIns.push(resident)
      }
    })
  }

  const hasMoveIns = moveInsThisWeek.length + otherMoveIns.length > 0

  return (
    <React.Fragment>
      <div className={`${classes.container} ${props.className}`}>
        <Typography className={classes.title}>Onboarding</Typography>
        {hasMoveIns && (
          <RXRButton type={RXRButton.TYPE_TEXT} onClick={() => setIsExpanded(!isExpanded)} className={classes.expandToggle}>
            {isExpanded ? 'collapse' : 'expand'}
          </RXRButton>
        )}
        <Grid container>
          {!hasMoveIns && <EmptyStateIconAndMessage message={'No moveins to show'} icon={RXRIcon.NUDGE} iconHeight={50} iconWidth={50} />}
          {!hasMoveIns ? null : !isExpanded ? (
            <div className={classes.summaryContainer}>
              <Grid item xs={12} md={2} lg={4} className={classes.onboardingSummaryContainer}>
                <div className={classes.moveInsCount}>{moveInsThisWeek.length}</div>
                <div className={classes.moveInsLabel}>{pluralizeWord('Move-in', moveInsThisWeek.length)} this week</div>
              </Grid>
              <Grid item xs={12} md={2} lg={4} className={classes.onboardingSummaryContainer}>
                <div className={classes.moveInsCount}>{otherMoveIns.length}</div>
                <div className={classes.moveInsLabel}>Other {pluralizeWord('move-in', otherMoveIns.length)}</div>
              </Grid>
            </div>
          ) : (
            <div className={classes.outterDetailsContainer}>
              <div className={classes.detailsContainer}>
                <Grid item xs={12} className={classes.typeExpandedContainer}>
                  <div className={classes.expandedTypeTitle}>
                    <RXRIcon
                      icon={RXRIcon.ARROW_LONG_RIGHT}
                      color={rxrTealColor}
                      size={RXRIcon.SIZE_EXTRA_LARGE}
                      className={classes.arrowIcon}
                    />
                    {moveInsThisWeek.length} {pluralizeWord('Move-in', moveInsThisWeek.length)} this week
                  </div>
                  {moveInsThisWeek.map(moveIn => {
                    return (
                      <OnboardingResidentRowContainer key={moveIn.id} resident={moveIn}>
                        {moveIn.displayName}
                      </OnboardingResidentRowContainer>
                    )
                  })}
                </Grid>
              </div>
              <div className={classes.detailsContainer}>
                <Grid item xs={12} className={classes.typeExpandedContainer}>
                  <div className={classes.expandedTypeTitle}>
                    <RXRIcon
                      icon={RXRIcon.ARROW_LONG_RIGHT}
                      color={rxrTealColor}
                      size={RXRIcon.SIZE_EXTRA_LARGE}
                      className={classes.arrowIcon}
                    />
                    {otherMoveIns.length} Other {pluralizeWord('move-in', otherMoveIns.length)}
                  </div>
                  {otherMoveIns.map(moveIn => {
                    return (
                      <OnboardingResidentRowContainer key={moveIn.id} resident={moveIn}>
                        {moveIn.displayName}
                      </OnboardingResidentRowContainer>
                    )
                  })}
                </Grid>
              </div>
            </div>
          )}
        </Grid>
      </div>
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    width: '100%',
    paddingTop: `${spaceExtraLarge}px !important`,
  },

  title: {
    position: 'absolute',
    top: spaceMedium,
    left: spaceMedium,
    fontStyle: 'normal',
    fontSize: fontSizeMediumLarge,
    lineHeight: '17px',
    letterSpacing: '1.3px',
    textTransform: 'uppercase',
  },

  expandToggle: {
    position: 'absolute',
    top: 0,
    right: spaceExtraSmall,
  },

  expandedTypeTitle: {
    marginBottom: spaceSmall,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '29px',
    fontStyle: 'normal',
    fontWeight: mediumFontWeight,
    fontSize: fontSizeExtraLarge,
    lineHeight: '29px',
    color: rxrTealColor,

    '& span': {
      marginLeft: spaceMedium,
      fontSize: 26,
      color: rxrBlackColor,
    },
  },

  typeExpandedContainer: {
    marginBottom: spaceLarge,
    width: '100%',
  },

  moveInsCount: {
    color: rxrTealColor,
    marginRight: spaceExtraSmall,
    fontSize: 48,
  },
  onboardingSummaryContainer: {
    width: '100%',
    paddingRight: spaceExtraLarge,
    alignItems: 'center',
  },
  onboardingDetailsContainer: {
    width: '100%',
    paddingRight: spaceExtraLarge,
    alignItems: 'center',
  },

  moveInsLabel: {},
  summaryContainer: {display: 'flex', width: '100%'},
  outterDetailsContainer: {width: '100%'},
  detailsContainer: {width: '100%', marginTop: spaceSmall},
  arrowIcon: {paddingRight: spaceSmall},
}))

OnboardingSummary.propTypes = {
  className: PropTypes.any,
}

export default OnboardingSummary
