import ActionTypes from './types'
import {listNudgesByBuilding, listLoggedNudgesActionByBuilding, deleteBuildingNudge, createBuildingAction} from '../lib/queries'

/**
 * @param {function} dispatch
 * @param {{
 *   buildingId: string,
 *   staffId: string,
 *   residentId: string,
 *   cost: number,
 *   reason: string,
 *   notes: string,
 *   actions: Array,
 *   actionTakenAt: Date
 * }} logActionInput
 * @return {Promise<*>}
 */
export const logBuildingNudgeAction = async (dispatch, logActionInput) => {
  let response = await createBuildingAction(logActionInput)
  dispatch({
    type: ActionTypes.LOG_BUILDING_NUDGE_ACTION,
    payload: response,
  })
  await loadNudgesAndActionsForBuilding(dispatch, logActionInput.buildingId)
  return response
}

export async function loadNudgesAndActionsForBuilding(dispatch, buildingId) {
  return Promise.all([listNudgesByBuilding(buildingId), listLoggedNudgesActionByBuilding(buildingId)])
    .then(results => {
      dispatch({
        type: ActionTypes.ASSISTANT_LOAD_NUDGES,
        buildingNudges: results[0].filter(f => f.buildingActions.items.length < 1),
        loggedNudgesActions: results[1],
      })
    })
    .catch(err => {
      console.error(err)
    })
}

/**
 * @param {function} dispatch
 * @param {String} buildingNudgeId
 * @returns {Promise<*>}
 */
export async function deleteNudge(dispatch, buildingNudgeId) {
  let retVal = await deleteBuildingNudge(buildingNudgeId)

  dispatch({
    type: ActionTypes.ASSISTANT_DELETE_NUDGE,
    payload: buildingNudgeId,
  })
  return retVal
}
