import React from 'react'
import ReadOnlyTask from '../ReadOnlyTask'
import {FormComponentPropTypes} from '../onboardingTypes'

function BuildingRules(props) {
  return <ReadOnlyTask {...props} />
}

BuildingRules.propTypes = FormComponentPropTypes

export default BuildingRules
