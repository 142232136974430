import React, {useState} from 'react'
import {Dialog, DialogActions, DialogTitle, IconButton} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import {Colors} from '../../assets/styles'
import NamePicker from './NamePicker'
import {RXRButton} from '../RXRButton'
import RXRIcon from '../RXRIcon'
import {iconButton} from '../../assets/styles/buttons'

const Constants = {
  BTN_SUBMIT: 'Submit',
  MESSAGE: 'Which tenant is picking up this package from the front desk?',
}
const useStyles = makeStyles(theme => ({
  dialog: {
    maxWidth: '286px',
    '& .MuiDialogActions-root': {
      padding: '0px 8px 16px 16px',
      justifyContent: 'flex-start',
    },
  },
  closeButton: {
    ...iconButton,
    position: 'absolute',
    right: 12,
    top: 12,
    color: Colors.rxrDarkGreyColor,
    cursor: 'pointer',
  },
  cursorHand: {
    cursor: 'pointer',
  },
  messageText: {
    fontSize: 16,
    lineHeight: '18.75px',
    color: Colors.rxrBlackColor,
    marginTop: 20,
  },
}))

function WhoPickedUpModal(props) {
  const [name, setName] = useState(null)

  const classes = useStyles()

  let handleClose = () => {
    setName(null)
    props.handleClose()
  }

  let handleSubmit = () => {
    props.handleSubmit(name)
  }

  return (
    <React.Fragment>
      <Dialog open={props.isOpen} onClose={handleClose} classes={{paper: classes.dialog}}>
        <DialogTitle>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <RXRIcon icon={RXRIcon.CLOSE} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.messageText}>{Constants.MESSAGE}</DialogContentText>
          <NamePicker selectedPackageUnit={props.selectedPackageUnit} name={name} setName={setName} />
        </DialogContent>
        <DialogActions>
          <RXRButton variant="contained" disabled={!name} onClick={handleSubmit}>
            {Constants.BTN_SUBMIT}
          </RXRButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default WhoPickedUpModal
