import React from 'react'
import ReadOnlyTask from '../ReadOnlyTask'
import {FormComponentPropTypes} from '../onboardingTypes'

function FurnitureRental(props) {
  return <ReadOnlyTask {...props} />
}

FurnitureRental.propTypes = FormComponentPropTypes

export default FurnitureRental
