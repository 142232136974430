import React from 'react'
import ReadOnlyTask from '../ReadOnlyTask'
import {FormComponentPropTypes} from '../onboardingTypes'

function MeetYourPropertyStaff(props) {
  return <ReadOnlyTask {...props} />
}

MeetYourPropertyStaff.propTypes = FormComponentPropTypes

export default MeetYourPropertyStaff
