import RXRIcon from '../RXRIcon'

export const FREQUENCY_ONCE = 'once'
export const FREQUENCY_WEEKLY = 'weekly'
export const FREQUENCY_WEEKLY_2 = 'weekly_2'
export const FREQUENCY_WEEKLY_3 = 'weekly_3'
export const FREQUENCY_WEEKLY_4 = 'weekly_4'

export const FrequencyLabels = {
  [FREQUENCY_ONCE]: 'One-time only',
  [FREQUENCY_WEEKLY]: 'Weekly',
  [FREQUENCY_WEEKLY_2]: 'Every 2 weeks',
  [FREQUENCY_WEEKLY_3]: 'Every 3 weeks',
  [FREQUENCY_WEEKLY_4]: 'Every 4 weeks',
}

export const VendorServiceTypeCleaning = 'Cleaning'
export const VendorServiceTypeDogwalking = 'DogWalking'
export const VendorServiceTypeMassage = 'Massage'
export const VendorServiceTypePersonalTraining = 'PersonalTraining'

export const VendorServiceTypeLabels = {
  [VendorServiceTypeCleaning]: 'Cleaning',
  [VendorServiceTypeDogwalking]: 'Dog Walking',
  [VendorServiceTypeMassage]: 'Spa Services',
  [VendorServiceTypePersonalTraining]: 'Personal Training',
}

/**
 * @param {string?} type
 * @return {string}
 */
export function getVendorServiceTypeIcon(type) {
  switch (type) {
    case VendorServiceTypeCleaning:
      return RXRIcon.CLEANING

    case VendorServiceTypeDogwalking:
      return RXRIcon.DOG_WALKING

    case VendorServiceTypeMassage:
      return RXRIcon.MASSAGE

    case VendorServiceTypePersonalTraining:
      return RXRIcon.PERSONAL_TRAINING

    default:
      return RXRIcon.SERVICES
  }
}
