import React from 'react'
import {connect} from 'react-redux'
import {
  SCREEN_RESIDENT_PROFILE,
  SCREEN_SEARCH,
  SCREEN_TIMELINE,
  SCREEN_EDIT_RESIDENT_PROFILE,
  SCREEN_GUEST_REGISTRATION,
  SCREEN_INCOMING_DELIVERY,
  SCREEN_OUTGOING_DELIVERY,
  SCREEN_MAINTENANCE,
  SCREEN_AMENITY_RESERVATION,
  SCREEN_PAYMENT,
  SCREEN_CLEANING,
  SCREEN_DOG_WALKING,
  SCREEN_MASSAGE,
  SCREEN_PERSONAL_TRAINING,
  SCREEN_BUILDING_ACTION,
} from './KYRConstants'
import Search from './Screens/Search'
import ResidentProfileComponent from './Screens/ResidentProfileComponent'
import EditResidentProfile from './EditResidentProfile'
import TimelineComponent from './Screens/TimelineComponent'
import GuestRegistration from './Screens/GuestRegistrationComponent'
import IncomingDeliveryComponent from './Screens/IncomingDeliveryComponent'
import OutgoingDeliveryComponent from './Screens/OutgoingDeliveryComponent'
import MaintenanceComponent from './Screens/MaintenanceComponent'
import AmenityReservationComponent from './Screens/AmenityReservationComponent'
import PaymentComponent from './Screens/PaymentComponent'
import KYRCleaningComponent from './Screens/VendorAppointments/KYRCleaningComponent'
import KYRDogWalkingComponent from './Screens/VendorAppointments/KYRDogWalkingComponent'
import KYRMassageComponent from './Screens/VendorAppointments/KYRMassageComponent'
import KYRPersonalTrainingComponent from './Screens/VendorAppointments/KYRPersonalTrainingComponent'
import RXOAssistantComponentKYR from './Screens/RXOAssistantComponentKYR'

const SCREEN_COMPONENT_MAP = {
  [SCREEN_SEARCH]: Search,
  [SCREEN_RESIDENT_PROFILE]: ResidentProfileComponent,
  [SCREEN_TIMELINE]: TimelineComponent,
  [SCREEN_GUEST_REGISTRATION]: GuestRegistration,
  [SCREEN_INCOMING_DELIVERY]: IncomingDeliveryComponent,
  [SCREEN_OUTGOING_DELIVERY]: OutgoingDeliveryComponent,
  [SCREEN_EDIT_RESIDENT_PROFILE]: EditResidentProfile,
  [SCREEN_MAINTENANCE]: MaintenanceComponent,
  [SCREEN_AMENITY_RESERVATION]: AmenityReservationComponent,
  [SCREEN_PAYMENT]: PaymentComponent,
  [SCREEN_CLEANING]: KYRCleaningComponent,
  [SCREEN_DOG_WALKING]: KYRDogWalkingComponent,
  [SCREEN_MASSAGE]: KYRMassageComponent,
  [SCREEN_PERSONAL_TRAINING]: KYRPersonalTrainingComponent,
  [SCREEN_BUILDING_ACTION]: RXOAssistantComponentKYR,
}

// create nav component
class KYRNavigator extends React.Component {
  render() {
    let currentPageVisit = this.props.current
    let CurrentScreenComponent = SCREEN_COMPONENT_MAP[currentPageVisit.name]

    if (!CurrentScreenComponent) {
      throw new Error('Unknown screen for route ' + currentPageVisit.name)
    }

    return (
      <div>
        <CurrentScreenComponent
          key={currentPageVisit.id}
          navParams={currentPageVisit.params}
          navState={currentPageVisit.frozenState}
          _visitRecord={currentPageVisit}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  current: state.KYRWidget.current,
  history: state.KYRWidget.history,
})

export default connect(mapStateToProps)(KYRNavigator)
