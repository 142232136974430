import React, {useState, useEffect, Fragment} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Colors} from '../../assets/styles'
import {connect, useDispatch, useSelector} from 'react-redux'
import Routes from '../../constants/RouteConstants'
import {useLocation, withRouter, matchPath} from 'react-router-dom'
import PageHeader from '../PageHeader'
import {Grid, Typography} from '@material-ui/core'
import {AntTab, AntTabs} from '../AntTabs'
import ClearableInput from '../ClearableInput'
import OutgoingPackagesTable from './OutgoingPackagesTable'
import moment from 'moment'
import OutgoingPickedUpPackagesTable from './OutgoingPickedUpPackagesTable'
import OutgoingDeliveriesForm from './OutgoingDeliveriesForm'
import Constant from './DeliveryConstants'
import DiscardChangesDialog from '../DiscardChangesDialog'
import EmptyStateIconAndMessage from '../EmptyStateIconAndMessage'
import {rxrBlueColor} from '../../assets/styles/color'
import DateRangeInput from '../DateRangeInput'
import Loader from '../Loader'
import {unMarkedPickedUp} from '../../actions/deliveryActions'
import UnMarkDeliveryIcon from '../../assets/images/Icon/UnMarkDeliveryIcon.svg'
import useFormChanged, {NonEmptyValidator} from '../hooks/useFormChanged'
import {RXRButton} from '../RXRButton'
import {
  deleteOutgoingDelivery,
  unDeleteOutgoingDelivery,
  updateOutgoingDelivery,
  getOutgoingDeliveriesForBuilding,
  CreateNewOutgoingDelivery,
} from '../../lib/queries'
import {PageContainer} from '../../assets/styles/layout'
import LoadingStateIconAndMessage from '../LoadingStateIconAndMessage'
import SidePanel from '../SidePanel'
import useBuildingDataRefresh from '../hooks/useBuildingDataRefresh'
import RXRIcon from '../RXRIcon'
import {loadBuildingStaffForBuilding} from '../../actions/staffActions'
import useResidentLookup from '../hooks/useResidentLookup'
import {selectAuthedUserId} from '../../reducers/selectors'
import {CORE_FEATURE_PACKAGE_DELIVERY} from '../../constants/ModelConstants'

const useStyles = makeStyles(theme => ({
  gridstyling: {
    paddingTop: 15,
  },
  cursorHand: {
    cursor: 'pointer',
  },
  dateFilterContainer: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',

    '& .MuiInput-underline:before': {
      display: 'block',
    },
  },
  textAuthDate: {
    width: '193px',
    borderBottom: '0px',
    textAlign: 'center',
  },

  placeSearch: {
    width: '418px',
    borderBottom: '0px',
  },
  selectDeliveryCloseIcon: {
    textAlign: 'right',
  },
  peopleIconContainer: {
    fontSize: 80,
    lineHeight: 0,
    color: rxrBlueColor,
  },
  notificatinHintHeading: {
    fontSize: 12,
    color: Colors.rxrRedColor,
    paddingBottom: 10,
  },
}))

const TAB_OUTGOING_PACKAGES = 0
const TAB_PICKEDUP_PACKAGES = 1

const EMPTY_FORM_STATE = {
  id: null,
  carrier: null,
  packageType: null,
  residentId: null,
  unitId: null,
  loggedOn: null,
  shippedAt: null,
  trackingNumber: null,
  notes: null,
  staffId: null,
}

function OutgoingViewDeliveriesPage(props) {
  const classes = useStyles()

  const [tab, setTab] = useState(TAB_OUTGOING_PACKAGES)
  const [filterTerm, setFilterTerm] = useState('')
  const [filterDateStart, setFilterDateStart] = useState(null)
  const [filterDateEnd, setFilterDateEnd] = useState(null)
  const [outgoingPackages, setOutgoingPackages] = useState([])
  const [pickedUpPackages, setPickedUpPackages] = useState([])
  const [isLoadingAllPackages, setIsLoadingAllPackages] = useState(true)
  const [deleteDiscardDialog, setDeleteDiscardDialog] = React.useState(false)
  const [unmarkPackageDialog, setUnmarkPackageDialog] = React.useState(false)
  const [discardIcon, setDiscardIcon] = React.useState(null)
  const [discardTitle, setDiscardTitle] = React.useState(null)
  const [discardMessage, setDiscardMessage] = React.useState(null)
  const [discardButton, setDiscardButton] = React.useState(null)
  const [unDeletePackageDialog, setUnDeletePackageDialog] = React.useState(false)
  const [shouldReloadData, setShouldReloadData] = React.useState(false)
  const {getResident} = useResidentLookup()

  const {activeBuildingId} = useBuildingDataRefresh(settingOutgoingPickedUpPackages)

  const recentlyPickedDeliveryIds = useSelector(state => state.Delivery.recentlyPickedDeliveryIds)
  const coreFeatures = useSelector(state => state.GroupPermissions.coreFeatures)
  const authedUserId = useSelector(selectAuthedUserId)
  const staffLookup = useSelector(state => state.Staff.staffLookup)

  const currentLocation = useLocation()
  const dispatch = useDispatch()

  let focusedDeliveryId
  let focusedDeliveryObject = {}
  let isCreateMode = false
  let viewSingleMatch = matchPath(currentLocation.pathname, {path: Routes.DELIVERIES_OUT_VIEW_SINGLE})
  let createMatch = matchPath(currentLocation.pathname, {path: Routes.DELIVERIES_OUT_CREATE})

  if (createMatch && createMatch.isExact) {
    isCreateMode = true
  } else if (viewSingleMatch && viewSingleMatch.isExact) {
    focusedDeliveryId = viewSingleMatch.params.deliveryId
    focusedDeliveryObject = outgoingPackages.concat(pickedUpPackages).find(p => p.id === focusedDeliveryId) || {}
  }

  const {form, setForm, formChanged, resetInitialForm, invalidItems, validateForm} = useFormChanged(
    EMPTY_FORM_STATE,
    useFormChanged.PropLevelValidation({
      carrier: NonEmptyValidator,
      packageType: NonEmptyValidator,
      residentId: NonEmptyValidator,
      unitId: NonEmptyValidator,
    }),
  )

  async function settingOutgoingPickedUpPackages() {
    const packageItems = await fetchDeliveryPackages(activeBuildingId)
    const [outgoingPackages, pickedUpPackages] = packageItems.reduce(
      (retVal, delivery) => {
        if (delivery.shippedAt || delivery.isDeleted) {
          retVal[1].push(delivery)
        } else {
          retVal[0].push(delivery)
        }
        return retVal
      },
      [[], []],
    )
    setIsLoadingAllPackages(false)
    setOutgoingPackages(outgoingPackages)
    setPickedUpPackages(pickedUpPackages)
  }

  useEffect(() => {
    if (shouldReloadData || recentlyPickedDeliveryIds.length > 0) {
      unMarkedPickedUp(dispatch)
      settingOutgoingPickedUpPackages().then()
    }
  }, [tab])

  //Redirecting to Messages page if packageDelivery is disabled
  useEffect(() => {
    !coreFeatures[CORE_FEATURE_PACKAGE_DELIVERY] && props.history.push(Routes.MESSAGES)
  }, [coreFeatures, activeBuildingId])

  // Load the staffLookup on activeBuildingId changes
  useEffect(() => {
    loadBuildingStaffForBuilding(dispatch, activeBuildingId).then()
  }, [activeBuildingId])

  const itemsFiltered = getFilteredItems(
    tab === TAB_OUTGOING_PACKAGES ? outgoingPackages : pickedUpPackages,
    filterDateStart,
    filterDateEnd,
    filterTerm,
    tab,
    getResident,
  )

  // --------------------------------------------------------------------------------------------
  // Delivery Editing stuff

  const [isLoadingDelivery, setIsLoadingDelivery] = useState(false)

  // USES THE VIEW_SINGLE / CREATE LOAD PATTERN
  useEffect(() => {
    if ((!focusedDeliveryId && form.id) || (!focusedDeliveryId && !isCreateMode)) {
      // reset the form
      resetInitialForm(EMPTY_FORM_STATE)
    } else if (focusedDeliveryId && form.id !== focusedDeliveryId) {
      const outGoingDelivery = outgoingPackages.find(g => g.id === focusedDeliveryId)
      const pickedUpDelivery = pickedUpPackages.find(g => g.id === focusedDeliveryId)

      const delivery = outGoingDelivery || pickedUpDelivery
      if (delivery) {
        if (outGoingDelivery) {
          setTab(TAB_OUTGOING_PACKAGES)
        } else {
          setTab(TAB_PICKEDUP_PACKAGES)
        }

        let initialForm = {
          id: delivery.id,
          carrier: delivery.carrier,
          packageType: delivery.packageType,
          residentId: delivery.residentId,
          unitId: delivery.unitId,
          loggedOn: delivery.createdAt ? moment(delivery.createdAt) : null,
          shippedAt: delivery.shippedAt ? moment(delivery.shippedAt) : null,
          trackingNumber: delivery.trackingNumber,
          notes: delivery.notes,
          staffId: delivery.staffId ? delivery.staffId : null,
        }
        resetInitialForm(initialForm, () => setIsLoadingDelivery(false))
      } else if (!isLoadingDelivery) {
        setIsLoadingDelivery(true)
      }
    }
  }, [outgoingPackages, pickedUpPackages, focusedDeliveryId, isCreateMode, isLoadingDelivery])

  const hideSlidingPanel = () => {
    props.history.push(Routes.DELIVERIES_OUT)
  }

  const discardDialog = () => {
    if (deleteDiscardDialog) {
      deleteDeliveryRecord(form.id, true)
    } else if (unmarkPackageDialog) {
      updateDeliveryRecord(true)
    } else if (unDeletePackageDialog) {
      deleteDeliveryRecord(focusedDeliveryId, false)
    }
    setDeleteDiscardDialog(false)
    setUnmarkPackageDialog(false)
    setUnDeletePackageDialog(false)
  }

  /**
   * @param {*} input
   * @returns {Promise<*>}
   */
  async function handleCreateNewOutgoingDelivery(input) {
    return await CreateNewOutgoingDelivery(input)
  }

  const createNewPackage = logAnother => {
    if (!validateForm()) {
      return
    }

    const input = {
      buildingId: activeBuildingId,
      carrier: form.carrier,
      packageType: form.packageType,
      residentId: form.residentId,
      unitId: form.unitId,
      trackingNumber: form.trackingNumber,
      notes: form.notes,
      staffId: authedUserId,
    }

    try {
      handleCreateNewOutgoingDelivery(input).then(async result => {
        const deliveryOutgoingPackage = result
        if (logAnother) {
          resetInitialForm({carrier: input.carrier, packageType: null, residentId: null})
        } else {
          resetInitialForm(EMPTY_FORM_STATE, hideSlidingPanel)
        }
        /// reload to reflect these changes to the table
        settingOutgoingPickedUpPackages().then()
        return deliveryOutgoingPackage
      })
    } catch (er) {
      console.error(er)
      window.alert('Sorry, we could not process this request. Please re-enter the package information.')
    }
  }

  /**
   * @returns {Promise<void>}
   */
  const updateDeliveryRecord = async unMarkedDelivered => {
    try {
      let deliveryForm = {...form}
      if (unMarkedDelivered) {
        deliveryForm = {...form, shippedAt: null}
      }
      await pushUpdateDeliveryRecord(deliveryForm)

      // reload to reflect these changes to the table
      settingOutgoingPickedUpPackages().then()

      resetInitialForm(EMPTY_FORM_STATE, hideSlidingPanel)
    } catch (er) {
      console.error(er)
      // TODO: Better error handling
      window.alert(er.message)
    }
  }

  const deleteDeliveryRecord = async (deliveryId, isDeleteDelivery) => {
    try {
      // we're going to mark isDeleted only on the object in memory after a successful save
      // this is a little hacky, but we don't want to reload the data until we need to
      if (isDeleteDelivery) {
        await deleteOutgoingDelivery(deliveryId)
        let found = outgoingPackages.find(d => d.id === deliveryId)
        found.isDeleted = true
        setOutgoingPackages([...outgoingPackages])
      } else {
        await unDeleteOutgoingDelivery(deliveryId)
        let found = pickedUpPackages.find(d => d.id === deliveryId)
        found.isDeleted = true
        setPickedUpPackages([...pickedUpPackages])
      }

      setShouldReloadData(true)
      resetInitialForm(EMPTY_FORM_STATE, hideSlidingPanel)
    } catch (er) {
      console.error(er)
      // TODO: Better error handling
      window.alert(er.message)
    }
  }

  // --------------------------------------------------------------------------------------------

  let visibleTableComponent

  if (isLoadingAllPackages) {
    visibleTableComponent = <LoadingStateIconAndMessage message={'Loading outgoing deliveries...'} />
  } else if (tab === TAB_OUTGOING_PACKAGES) {
    if (outgoingPackages.length === 0) {
      visibleTableComponent = <EmptyStateIconAndMessage message={Constant.NO_OUTGOING_PACKAGE_MESSAGE} icon={RXRIcon.DELIVERIES} />
    } else {
      visibleTableComponent = <OutgoingPackagesTable outgoingPackages={itemsFiltered} filterTerm={filterTerm} />
    }
  } else {
    if (pickedUpPackages.length === 0) {
      visibleTableComponent = <EmptyStateIconAndMessage message={Constant.NO_PACKAGE_PICKEDUP_MESSAGE} icon={RXRIcon.DELIVERIES} />
    } else {
      visibleTableComponent = <OutgoingPickedUpPackagesTable pickedUpPackages={itemsFiltered} filterTerm={filterTerm} />
    }
  }

  if (isLoadingDelivery) {
    return <Loader />
  }

  return (
    <div style={PageContainer}>
      <PageHeader
        title={Constant.TITLE}
        rightControlComponent={
          <RXRButton type={RXRButton.TYPE_LARGE} onClick={() => props.history.push(Routes.DELIVERIES_OUT_CREATE)}>
            {Constant.ADD_BUTTON}
          </RXRButton>
        }
      >
        <Grid container spacing={3}>
          <Grid item lg={6} md={6}>
            <div className={classes.placeSearch}>
              <ClearableInput value={filterTerm} onChange={setFilterTerm} placeholder={Constant.ENTER_SEARCH_KEYWORDS_OUTGOING_CONSTANT} />
            </div>
          </Grid>

          <Grid item lg={6} md={6}>
            <div className={classes.dateFilterContainer}>
              {/* <DateInput onChange={setFilterDate} value={filterDate} /> */}
              <DateRangeInput
                onChange={(start, end) => {
                  setFilterDateStart(start)
                  setFilterDateEnd(end)
                }}
                startDate={filterDateStart}
                endDate={filterDateEnd}
              />
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item lg={6} md={6}>
            <AntTabs value={tab} onChange={(e, t) => setTab(t)}>
              <AntTab label={Constant.OUTGOING_PACKAGES} />
              <AntTab label={Constant.PICKEDUP_PACKAGES} />
            </AntTabs>
          </Grid>
        </Grid>
      </PageHeader>

      <DiscardChangesDialog
        isOpen={deleteDiscardDialog || unmarkPackageDialog || unDeletePackageDialog}
        discardIcon={discardIcon}
        discardTitle={discardTitle}
        discardMessage={discardMessage}
        discardButton={discardButton}
        onDiscard={discardDialog}
        onCancel={() => {
          setDeleteDiscardDialog(false)
          setUnmarkPackageDialog(false)
          setUnDeletePackageDialog(false)
        }}
      />

      <DiscardChangesDialog hasChanges={formChanged} />

      <div style={{height: '100%'}}>{visibleTableComponent}</div>

      <SidePanel
        isOpen={!!focusedDeliveryId || isCreateMode}
        onClose={hideSlidingPanel}
        title={focusedDeliveryObject.isDeleted ? 'Deleted package details' : isCreateMode ? 'New outgoing package' : 'Package details'}
      >
        <OutgoingDeliveriesForm {...form} updateForm={setForm} invalidItems={invalidItems} staffLookup={staffLookup} />
        {isCreateMode ? (
          <Grid container spacing={2} alignItems="center" className={classes.gridstyling}>
            <Grid item>
              <RXRButton onClick={() => createNewPackage(true)}>{Constant.BTN_SAVE_LOG_ANOTHER}</RXRButton>
            </Grid>
            <Grid item>
              <RXRButton type={RXRButton.TYPE_SECONDARY} onClick={() => createNewPackage(false)}>
                {Constant.BTN_SAVE}
              </RXRButton>
            </Grid>
            <Grid item className={classes.cursorHand}>
              <RXRButton type={RXRButton.TYPE_TEXT} onClick={hideSlidingPanel}>
                {Constant.BTN_CANCEL}
              </RXRButton>
            </Grid>
          </Grid>
        ) : tab === TAB_OUTGOING_PACKAGES ? (
          <Grid container spacing={2} alignItems="center" className={classes.gridstyling}>
            <Grid item>
              <RXRButton disabled={!formChanged} onClick={() => updateDeliveryRecord(false)}>
                {Constant.BTN_SAVE}
              </RXRButton>
            </Grid>
            <Grid item>
              <RXRButton
                type={RXRButton.TYPE_DESTRUCTIVE}
                onClick={() => {
                  setDeleteDiscardDialog(true)
                  setDiscardIcon(null)
                  setDiscardTitle(Constant.DELETE_PACKAGE_DISCARD_TITLE)
                  setDiscardMessage(Constant.DELETE_PACKAGE_DISCARD_MESSAGE)
                  setDiscardButton(null)
                }}
              >
                {Constant.BTN_DELETE_PACKAGE}
              </RXRButton>
            </Grid>
            <Grid item className={classes.cursorHand}>
              <Typography className={classes.cancel} onClick={hideSlidingPanel}>
                {Constant.BTN_CANCEL}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} alignItems="center" className={classes.gridstyling}>
            <Grid item>
              {focusedDeliveryObject.isDeleted ? (
                <React.Fragment>
                  <Typography className={classes.notificatinHintHeading}>{Constant.UNDELETE_NOTIFICATION_TEXT}</Typography>
                  <RXRButton
                    onClick={() => {
                      setUnDeletePackageDialog(true)
                      setDiscardIcon(<img alt="UnMarkDelivery icon" src={UnMarkDeliveryIcon} />)
                      setDiscardTitle(Constant.UNDELETE_PACKAGE_DISCARD_TITLE)
                      setDiscardMessage(Constant.UNDELETE_PACKAGE_DISCARD_MESSAGE)
                      setDiscardButton('Yes')
                    }}
                  >
                    {Constant.BTN_UNDELETE_DELIVERED}
                  </RXRButton>
                </React.Fragment>
              ) : (
                <RXRButton
                  onClick={() => {
                    setUnmarkPackageDialog(true)
                    setDiscardIcon(<img alt="UnMarkDelivery icon" src={UnMarkDeliveryIcon} />)
                    setDiscardTitle(Constant.UNMARK_PACKAGE_PICKEDUP_DISCARD_TITLE)
                    setDiscardMessage(Constant.UNMARK_PACKAGE_PICKEDUP_DISCARD_MESSAGE)
                    setDiscardButton('Yes')
                  }}
                >
                  {Constant.BTN_UNMARK_PICKEDUP}
                </RXRButton>
              )}
            </Grid>
          </Grid>
        )}
      </SidePanel>
    </div>
  )
}

// ------------------------------------------------------------------------------------------------------------
// GraphQL Queries & Mutations
// ------------------------------------------------------------------------------------------------------------

async function fetchDeliveryPackages(buildingId, typeOfPackages) {
  if (!buildingId) {
    return []
  }

  try {
    return await getOutgoingDeliveriesForBuilding(
      buildingId,
      typeof typeOfPackages === 'string' ? typeOfPackages === 'pickedUpPackages' : undefined,
    )
  } catch (e) {
    if (e && Array.isArray(e.errors)) {
      throw new Error(e.errors[0].message)
    }
    throw e
  }
}

/**
 * @param {*} form
 * @returns {Promise<*>}
 */
async function pushUpdateDeliveryRecord(form) {
  return await updateOutgoingDelivery({
    id: form.id,
    residentId: form.residentId,
    unitId: form.unitId,
    carrier: form.carrier,
    packageType: form.packageType,
    trackingNumber: form.trackingNumber,
    priority: form.priority,
    createdAt: form.loggedOn,
    shippedAt: form.shippedAt,
    notes: form.notes,
  })
}

const UNIT_KEYWORD = 'unit:'
/**
 * @param {Array<*>} items
 * @param {Date|null} filterDateStart
 * @param {Date|null} filterDateEnd
 * @param {string|null} filterTerm
 * @param {number} tab
 * @param {function} getResident
 */
function getFilteredItems(items, filterDateStart, filterDateEnd, filterTerm, tab, getResident) {
  const formattedStartDate = filterDateStart ? filterDateStart.toISOString() : null
  const formattedEndDate = filterDateEnd ? filterDateEnd.toISOString() : null
  const formattedTerm = filterTerm.toLowerCase()
  const dateProp = tab === Constant.TAB_INCOMING_PACKAGES ? 'createdAt' : 'deliveredAt'
  let unitFilter = null

  if (formattedTerm.indexOf(UNIT_KEYWORD) === 0) {
    unitFilter = formattedTerm.substring(UNIT_KEYWORD.length)
  }

  // then we filter the incomingPackages array according to the search terms
  return items.filter(g => {
    const resident = getResident(g.residentId)
    // filter by: resident name, unit number, package type, carrier and tracking number
    return (
      (!formattedStartDate || g[dateProp] >= formattedStartDate) &&
      (!formattedEndDate || g[dateProp] <= formattedEndDate) &&
      (unitFilter
        ? resident.occupancy.unit.number.toLowerCase().includes(unitFilter)
        : g.packageType.toLowerCase().includes(formattedTerm) ||
          (g.carrier && g.carrier.toLowerCase().includes(formattedTerm)) ||
          resident.displayName.toLowerCase().includes(formattedTerm) ||
          resident.occupancy.unit.number.toLowerCase().includes(formattedTerm))
    )
  })
}

export default withRouter(OutgoingViewDeliveriesPage)
