import {rxrDarkGreyColor, rxrGreenColor, rxrLightGreyColor, rxrMonotoneLightGreyColor} from '../../assets/styles/color'
import {withStyles} from '@material-ui/core/styles'
import {Button, makeStyles, Menu} from '@material-ui/core'
import PropTypes from 'prop-types'
import RXRIcon from '../RXRIcon'
import {spaceExtraExtraSmall, spaceSmall, spaceMedium} from '../../assets/styles/spacing'
import React, {useRef, useState} from 'react'
import {heavyFontWeight} from '../../assets/styles/typography'
import {useSelector} from 'react-redux'

const shared = {
  textTransform: 'none',
  fontSize: '12px',
  lineHeight: '14px',
  '&:focus': {
    outline: '0px auto transparent',
  },
}

const active = {
  ...shared,
  color: rxrGreenColor,
  backgroundColor: 'rgba(0, 154, 2, 0.2)',
  '&:hover': {
    backgroundColor: 'rgba(0, 154, 2, 0.2)',
  },
}

const inactive = {
  ...shared,
  color: rxrDarkGreyColor,
  backgroundColor: rxrLightGreyColor,
  '&:hover': {
    backgroundColor: rxrLightGreyColor,
  },
}

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: heavyFontWeight,
    borderBottom: `1px solid ${rxrMonotoneLightGreyColor}`,
    padding: spaceSmall,
  },
  panelInner: {
    padding: spaceSmall,
  },
}))

function SettingButton(props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles()
  const containerRef = useRef()
  const [showPanel, setShowPanel] = useState(false)

  // this builds the component we render with appropriate styling
  const Comp = withStyles(theme => ({
    root: props.isActive ? active : inactive,
  }))(Button)

  const handleClick = () => {
    if (typeof props.onOpen === 'function') {
      props.onOpen()
    } else {
      setShowPanel(true)
    }
  }

  const handleClose = () => {
    if (typeof props.onClose === 'function') {
      props.onClose()
    } else {
      setShowPanel(false)
    }
  }

  return (
    <div ref={containerRef} style={props.style}>
      <Comp variant="contained" onClick={handleClick}>
        <div>
          <RXRIcon style={{marginRight: spaceExtraExtraSmall}} icon={props.icon} size={RXRIcon.SIZE_SMALL} />
          {!isMobile && props.label}
        </div>
      </Comp>

      <Menu open={typeof props.isOpen === 'boolean' ? props.isOpen : !!showPanel} anchorEl={containerRef.current} onClose={handleClose}>
        <div className={classes.title}>{props.title}</div>
        <div className={classes.panelInner}>{props.children}</div>
      </Menu>
    </div>
  )
}

SettingButton.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isOpen: PropTypes.bool, // override to control open/closed status
  onOpen: PropTypes.func, // override to control open/closed status
  onClose: PropTypes.func, // override to control open/closed status
  isActive: PropTypes.bool,
  style: PropTypes.any,
  children: PropTypes.any,
}

export default SettingButton
