import React from 'react'
import useSharedStyles from './useSharedStyles'
import MarkTaskCompletedCTAs from './FormCTAs/MarkTaskCompletedCTAs'
import {ONBOARDING_STATE_COMPLETED} from '../../constants/ModelConstants'
import {FormComponentPropTypes} from './onboardingTypes'

function ReadOnlyTask(props) {
  const classes = useSharedStyles()

  const handleMarkCompleted = () => {
    // update our submission state to completed
    props.onUpdateSubmission({
      ...props.submission,
      state: ONBOARDING_STATE_COMPLETED,
    })
  }

  return (
    <React.Fragment>
      <div className={classes.bodyContainer}>
        <div>This is a read-only requirement and doesn't need staff approval</div>
      </div>
      {!props.submission || props.submission.state !== ONBOARDING_STATE_COMPLETED ? (
        <MarkTaskCompletedCTAs onMarkCompleted={handleMarkCompleted} />
      ) : null}
    </React.Fragment>
  )
}

ReadOnlyTask.propTypes = FormComponentPropTypes

export default ReadOnlyTask
