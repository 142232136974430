import ActionTypes from './types'
import {updateIncomingDelivery, updateOutgoingDelivery} from '../lib/queries'

/**
 * @param {function} dispatch
 * @param {string} deliveryId
 * @param {string} staffId
 * @param {string} recipientName
 * @param {string} staffName
 * @returns {Promise<*>}
 */

export const markedDelivered = async (dispatch, deliveryId, staffId, recipientName, staffName) => {
  dispatch({
    type: ActionTypes.DELIVERY_MARK_RECENT_DELIVERED_IN,
    payload: {id: deliveryId, deliveredTo: recipientName ? 'person' : 'unit'},
  })
  let retVal

  try {
    retVal = await updateIncomingDelivery({
      id: deliveryId,
      receivedAt: new Date(),
      deliveredDetails: {
        deliveredTo: recipientName ? 'person' : 'unit',
        staffId: staffId,
        //If a recipientName is passed,it means package is picked up by person and we set recipientName in DB
        //If recipientName is not passed,it means package is delivered to unit,in this case we set staffName as recipientName in DB so that
        //with same code we can show recipientName or staffName without having to fetch staffName if package is delivered to unit
        recipientName: recipientName ? recipientName : staffName ? staffName : null,
      },
    })
  } catch (err) {
    // unmark it immediately
    dispatch({
      type: ActionTypes.DELIVERY_UNMARK_RECENT_DELIVERED_IN,
      payload: deliveryId,
    })
    throw err
  }

  return retVal
}

/**
 * @param {function} dispatch
 * @param {Array<string>} deliveryIds
 * @param {string} staffId
 * @param {string} recipientName
 * @param {string} staffName
 * @returns {Promise<*>}
 */

export const markDelivered_Multiple = async (dispatch, deliveryIds, staffId, recipientName, staffName) => {
  const deliveries = deliveryIds.map(id => ({id: id, deliveredTo: recipientName ? 'person' : 'unit'}))

  dispatch({
    type: ActionTypes.DELIVERY_MARK_RECENT_DELIVERED_IN_MULTIPLE,
    payload: deliveries,
  })

  let returnArray = []
  for (const idx in deliveryIds) {
    const delivery = deliveries[idx]
    const deliveryId = delivery.id
    try {
      const updatedIncomingDelivery = await updateIncomingDelivery({
        id: deliveryId,
        receivedAt: new Date(),
        deliveredDetails: {
          deliveredTo: recipientName ? 'person' : 'unit',
          staffId: staffId,
          //If a recipientName is passed,it means package is picked up by person and we set recipientName in DB
          //If recipientName is not passed,it means package is delivered to unit,in this case we set staffName as recipientName in DB so that
          //with same code we can show recipientName or staffName without having to fetch staffName if package is delivered to unit
          recipientName: recipientName ? recipientName : staffName ? staffName : null,
        },
      })
      returnArray.push(updatedIncomingDelivery)
    } catch (err) {
      // unmark it immediately
      dispatch({
        type: ActionTypes.DELIVERY_UNMARK_RECENT_DELIVERED,
        payload: deliveryId,
      })
      throw err
    }
  }

  return returnArray
}

export const unMarkedDelivered = dispatch => {
  dispatch({
    type: ActionTypes.DELIVERY_UNMARK_RECENT_DELIVERED_IN,
    payload: null, // unmark them all
  })
}

/**
 * @param {function} dispatch
 * @param {string} deliveryId
 * @returns {Promise<*>}
 */

export const markedPickedUp = async (dispatch, deliveryId) => {
  dispatch({
    type: ActionTypes.DELIVERY_MARK_RECENT_PICKED_UP,
    payload: deliveryId,
  })

  let retVal
  try {
    retVal = await updateOutgoingDelivery({
      id: deliveryId,
      shippedAt: new Date(),
    })
  } catch (err) {
    // unmark it immediately
    dispatch({
      type: ActionTypes.DELIVERY_UNMARK_RECENT_PICKED_UP,
      payload: deliveryId,
    })
    throw err
  }

  return retVal
}

/**
 * @param {function} dispatch
 * @param {Array<string>} deliveryIds
 * @returns {Promise<*>}
 */

export const markPickedUp_Multiple = async (dispatch, deliveryIds) => {
  dispatch({
    type: ActionTypes.DELIVERY_MARK_RECENT_PICKED_UP_MULTIPLE,
    payload: deliveryIds,
  })

  let returnArray = []
  for (const idx in deliveryIds) {
    const deliveryId = deliveryIds[idx]
    try {
      const updatedOutgoingDelivery = await updateOutgoingDelivery({
        id: deliveryId,
        shippedAt: new Date(),
      })
      returnArray.push(updatedOutgoingDelivery)
    } catch (err) {
      // unmark it immediately
      dispatch({
        type: ActionTypes.DELIVERY_UNMARK_RECENT_PICKED_UP,
        payload: deliveryId,
      })
      throw err
    }
  }

  return returnArray
}

export const unMarkedPickedUp = dispatch => {
  dispatch({
    type: ActionTypes.DELIVERY_UNMARK_RECENT_PICKED_UP,
    payload: null, // unmark them all
  })
}
