import {Colors} from './index'

// Message chat border style
export const messageInputBorder = {
  border: '1.5px solid',
  borderColor: Colors.rxrGreyColor,
}

// Form field border style
export const textfieldInputBorder = {
  border: '1px solid',
  borderColor: Colors.rxrBlueColor,
}

export const textfieldInputBorderDisabled = {
  border: '1px solid',
  borderColor: Colors.rxrMediumLightGreyColor,
}

export const textfieldInputBorderError = {
  border: '1px solid',
  borderColor: Colors.rxrRedColor,
}

// Form field border style
export const textfieldInputBorderBlue = {
  border: '1px solid',
  borderColor: Colors.rxrMidBlue,
}
export const textfieldInputBorderTransparent = {
  border: '0px solid',
  borderColor: 'transparent',
}

export const borderRadiusMedium = '15px'
