export default {
  _RESET_APP_STATE: '_RESET_APP_STATE',
  AUTH_SET_AUTHED_USER: 'AUTH_SET_AUTHED_USER',

  APP_SET_NAV_PANEL_OPEN: 'APP_SET_NAV_PANEL_OPEN',
  APP_SET_MUTE_SOUNDS: 'APP_SET_MUTE_SOUNDS',
  APP_SET_HIDE_POPUPS: 'APP_SET_HIDE_POPUPS',

  MESSAGES_UPDATE_FILTER_ACTION: 'MESSAGES_UPDATE_FILTER_ACTION',
  MESSAGES_SYNC_CONVERSATIONS: 'MESSAGES_SYNC_CONVERSATIONS',
  MESSAGES_CREATED_NEW_MESSAGE: 'MESSAGES_CREATED_NEW_MESSAGE',
  MESSAGES_SET_CONVERSATION_STATUS: 'MESSAGES_SET_CONVERSATION_STATUS',
  MESSAGES_SET_DRAFT_MESSAGE: 'MESSAGES_SET_DRAFT_MESSAGE',

  BUILDINGS_UPDATE_ALL: 'BUILDINGS_UPDATE_ALL',
  BUILDINGS_SET_VIEWING: 'BUILDINGS_SET_VIEWING',
  BUILDINGS_SET_BUILDING_OOO: 'BUILDINGS_SET_BUILDING_OOO',

  APPOINTMENTS_UPDATE_ALL: 'APPOINTMENTS_UPDATE_ALL',
  APPOINTMENTS_MARK_AS_PAID: 'APPOINTMENTS_MARK_AS_PAID',
  APPOINTMENTS_UPDATE_SINGLE: 'APPOINTMENTS_UPDATE_SINGLE',
  APPOINTMENTS_REMOVE_SINGLE: 'APPOINTMENTS_REMOVE_SINGLE',

  SERVICES_UPDATE_ALL: 'SERVICES_UPDATE_ALL',

  GUEST_AUTH_MARK_RECENT_CHECK_IN: 'GUEST_AUTH_MARK_RECENT_CHECK_IN',
  GUEST_AUTH_UNMARK_RECENT_CHECK_IN: 'GUEST_AUTH_UNMARK_RECENT_CHECK_IN',

  STAFF_SET_AUTHED_USER: 'STAFF_SET_AUTHED_USER',
  STAFF_UPDATE_STAFF: 'STAFF_UPDATE_STAFF',

  STAFF_SET_STAFF_LOOKUP: 'STAFF_SET_STAFF_LOOKUP',
  GROUP_PERMISSIONS_SET: 'GROUP_PERMISSIONS_SET',

  DELIVERY_MARK_RECENT_DELIVERED_IN: 'DELIVERY_MARK_RECENT_DELIVERED_IN',
  DELIVERY_MARK_RECENT_DELIVERED_IN_MULTIPLE: 'DELIVERY_MARK_RECENT_DELIVERED_IN_MULTIPLE',
  DELIVERY_UNMARK_RECENT_DELIVERED_IN: 'DELIVERY_UNMARK_RECENT_DELIVERED_IN',
  DELIVERY_MARK_RECENT_PICKED_UP: 'DELIVERY_MARK_RECENT_PICKED_UP',
  DELIVERY_MARK_RECENT_PICKED_UP_MULTIPLE: 'DELIVERY_MARK_RECENT_PICKED_UP_MULTIPLE',
  DELIVERY_UNMARK_RECENT_PICKED_UP: 'DELIVERY_UNMARK_RECENT_PICKED_UP',
  SET_DISABLED_FEATURES: 'SET_DISABLED_FEATURES',

  AMENITIES_LOAD_ALL: 'AMENITIES_LOAD_ALL',
  AMENITIES_LOAD_RESERVATIONS: 'AMENITIES_LOAD_RESERVATIONS',
  AMENITIES_UPDATE_SINGLE: 'AMENITIES_UPDATE_SINGLE',
  AMENITY_RESERVATIONS_CREATE_NEW: 'AMENITY_RESERVATIONS_CREATE_NEW',
  AMENITY_RESERVATIONS_UPDATE_EXISTING: 'AMENITY_RESERVATIONS_UPDATE_EXISTING',

  UNITS_UPDATE_ALL: 'UNITS_UPDATE_ALL',
  RESIDENTS_UPDATE_ALL: 'RESIDENTS_UPDATE_ALL',
  RESIDENT_UPDATE_SINGLE: 'RESIDENT_UPDATE_SINGLE',

  ASSISTANT_LOAD_NUDGES: 'ASSISTANT_LOAD_NUDGES',
  ASSISTANT_DELETE_NUDGE: 'ASSISTANT_DELETE_NUDGE',
  LOG_BUILDING_NUDGE_ACTION: 'LOG_BUILDING_NUDGE_ACTION',

  KYR_WIDGET_NAVIGATE_BACK: 'KYR_WIDGET_NAVIGATE_BACK',
  KYR_WIDGET_NAVIGATE_TO_SCREEN: 'KYR_WIDGET_NAVIGATE_TO_SCREEN',
  KYR_SET_IS_WIDGET_OPEN: 'KYR_SET_IS_WIDGET_OPEN',

  OCCUPANCIES_UPDATE_ALL: 'OCCUPANCIES_UPDATE_ALL',
  OCCUPANCY_UPDATE: 'OCCUPANCY_UPDATE',

  OCCUPANCY_ONBOARDING_TASK_UPDATE: 'OCCUPANCY_ONBOARDING_TASK_UPDATE',

  FEEDBACK_NEW_FEEDBACK: 'FEEDBACK_NEW_FEEDBACK',
  FEEDBACK_SKIP_FORECAST: 'FEEDBACK_SKIP_FORECAST',
}
