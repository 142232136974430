import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {Grid} from '@material-ui/core'
import ProfileImage from '../../ProfileImage'
import useFormChanged, {DateObjectValidator, MuxValidator, NonEmptyStringValidator, NumberValidator} from '../../hooks/useFormChanged'
import TextInput from '../../TextInput'
import {makeStyles} from '@material-ui/core/styles'
import NumberInput from '../../NumberInput'
import SelectInput from '../../SelectInput'
import DateInput from '../../DateInput'
import MultilineTextInput from '../../MultilineTextInput'
import {spaceMedium} from '../../../assets/styles/spacing'
import {v4 as uuid} from 'uuid'
import RXRButton from '../../RXRButton'
import {createPetProfile, deletePetProfile, updatePetProfile} from '../../../lib/queries'
import FileHelper from '../../../lib/FileHelper'
import ConfirmDeleteDialog from '../../ConfirmDeleteDialog'

const EMPTY_FORM = {
  displayName: '',
  species: '',
  breed: '',
  weight: null,
  image: null,
  birthday: null,
  notes: '',
}

function PetForm(props) {
  const idRef = useRef(uuid())
  const classes = useStyles()
  const [isSaving, setIsSaving] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const {form, formChanged, setForm, invalidItems, validateForm, resetInitialForm} = useFormChanged(
    EMPTY_FORM,
    useFormChanged.PropLevelValidation({
      displayName: NonEmptyStringValidator,
      species: NonEmptyStringValidator,
      // breed: NonEmptyStringValidator,
      // weight: NumberValidator,
      // birthday: DateObjectValidator,
    }),
  )
  useEffect(() => {
    if (props.pet) {
      resetInitialForm(props.pet)
    } else {
      resetInitialForm(EMPTY_FORM)
    }
  }, [props.pet])

  const handleDeletePet = async isConfirmed => {
    if (isSaving) {
      return
    }

    try {
      const removedPetData = await deletePetProfile(form.id)

      if (typeof props.onRemoved === 'function') {
        props.onRemoved(removedPetData)
      }
    } catch (err) {
      window.alert(err.message)
      console.error(err)
    }

    setIsSaving(false)
  }

  const handleSavePet = async () => {
    if (!validateForm() || isSaving) {
      return
    }

    setIsSaving(true)

    const inputData = {...form, image: FileHelper.formatS3ObjectForInput(form.image), occupancyId: props.occupancyId}

    try {
      let newPetData
      if (form.id) {
        newPetData = await updatePetProfile(inputData)
      } else {
        newPetData = await createPetProfile(inputData)

        // after we create, we reset the form because we assume we're going to create another
        // and the one we just created is now in the list
        resetInitialForm(EMPTY_FORM)
      }

      if (typeof props.onSaved === 'function') {
        // pass true in second prop if this was newly created
        props.onSaved(newPetData, !form.id)
      }
    } catch (err) {
      window.alert(err.message)
      console.error(err)
    }

    setIsSaving(false)
  }

  return (
    <div className={props.className}>
      <Grid container spacing={6} alignItems="center">
        <Grid item xs={12} className={classes.nameAndImageContainer}>
          <ProfileImage
            className={classes.profileImage}
            colorId={form.id || idRef.current}
            displayName={form.displayName || 'new pet'}
            s3Object={form.image}
            onUploaded={s3Obj => setForm({image: s3Obj})}
          />
          <TextInput
            label="Name"
            placeholder="New pet"
            value={form.displayName}
            onChange={v => setForm({displayName: v})}
            isRequired={true}
            error={invalidItems.includes('displayName')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <SelectInput
            label="Species"
            onChange={v => setForm({species: v})}
            options={[{label: 'Dog', value: 'Dog'}, {label: 'Cat', value: 'Cat'}]}
            value={form.species}
            isRequired={true}
            error={invalidItems.includes('species')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Breed"
            value={form.breed}
            onChange={v => setForm({breed: v})}
            // isRequired={true}
            error={invalidItems.includes('breed')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <NumberInput
            label="Weight"
            value={form.weight}
            onChange={v => setForm({weight: v})}
            suffix={'lbs'}
            // isRequired={true}
            error={invalidItems.includes('weight')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput
            label="Birthday"
            value={form.birthday}
            onChange={d => setForm({birthday: d})}
            // isRequired={true}
            error={invalidItems.includes('birthday')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}></Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <RXRButton isLoading={isSaving} disabled={!formChanged} onClick={handleSavePet}>
            {form.id ? 'Save' : 'Create'} {form.displayName || 'pet'}
          </RXRButton>
          {form.id && (
            <RXRButton
              type={RXRButton.TYPE_DESTRUCTIVE}
              isLoading={isSaving}
              onClick={() => setConfirmDelete(true)}
              className={classes.deleteButton}
            >
              Delete {form.displayName}
            </RXRButton>
          )}
        </Grid>
      </Grid>

      <ConfirmDeleteDialog
        isOpen={!!confirmDelete}
        prompt={'Are you sure you want to delete this pet profile?'}
        onConfirmDelete={handleDeletePet}
        onCancel={() => {
          setConfirmDelete(null)
        }}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  profileImage: {
    width: '100px',
    height: '100px',
    marginRight: spaceMedium,
    flexShrink: 0,
  },
  nameAndImageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  deleteButton: {
    marginLeft: spaceMedium,
  },
}))

PetForm.propTypes = {
  pet: PropTypes.any,
  occupancyId: PropTypes.string.isRequired,
  onSaved: PropTypes.func,
  onRemoved: PropTypes.func,
  className: PropTypes.string,
}

export default PetForm
