import {OCCUPANCY_STATUS_FUTURE, selectResidentsByUnit, selectResidentsByOccupancyId} from '../Utils/residentUtils'
import {formatResidentObject} from './residentsReducer'
import moment from 'moment'
import {doesResidentNeedForecasting} from '../components/ResidentSentiment/residentSentimentUtilities'
import {isOnboardingTaskPendingApproval} from '../Utils/onboardingUtils'
import {onboardingElevatorAmenityFilter} from '../Utils/amenityUtils'

/**
 * @param {*} state
 * @return {string|null}
 */
export function selectAuthedUserId(state) {
  return state.Auth.authedUser ? state.Auth.authedUser.attributes.sub : null
}

/**
 * @param {*} state
 * @param {string} residentId
 * @returns {*}
 */
export function selectUnitByResident(state, residentId) {
  let resident = state.Residents.residentsLookup[residentId]
  if (!resident) {
    return null
  }
  return state.Units.unitsLookup[resident.occupancy.unit.id]
}

/**
 * @param {*} state
 * @param {string} occupancyId
 * @returns {*}
 */
export function selectUnitByOccupancy(state, occupancyId) {
  let occupancy = state.Occupancies.occupanciesLookup[occupancyId]
  if (!occupancy) {
    return null
  }
  return state.Units.unitsLookup[occupancy.occupancyUnitId]
}

/**
 * NOTE: You should be using `deepEquals` when using this selector
 * @param {*} state
 * @param {string} occupancyId
 * @returns {{id: string, residents: Array<*>, unit: *}}
 */
export function getOccupancyWithResidentAndUnitData(state, occupancyId) {
  const occupancy = state.Occupancies.occupanciesLookup[occupancyId]
  return {
    ...occupancy,
    residents: selectResidentsByOccupancyId(state.Residents.residentsLookup, occupancyId),
    unit: selectUnitByOccupancy(state, occupancyId),
  }
}

/**
 * @param {*} state
 * @param {string} unitId
 * @param {string} permissionName
 * @returns {*}
 */
export function selectPTEForOccupancy(state, unitId, permissionName) {
  let permissionNameValues = ['maintenancePermission', 'cleaningPermission', 'deliveryPermission', 'thirdPartyPermission']
  let residentsLookup = state.Residents.residentsLookup
  let resident = selectResidentsByUnit(residentsLookup, unitId)

  if (!permissionNameValues.includes(permissionName)) {
    return false
  }

  return resident.some(r => {
    return r.userProfile && !!JSON.parse(r.userProfile)[permissionName]
  })
}

/**
 * @param {*} state
 * @param {string} actionId
 * @returns {*}
 */
export function selectLoggedActionFromNudgeActions(state, actionId) {
  let action = state.Nudges.loggedNudgesActionsLookup[actionId]
  if (!action) {
    return null
  }
  return state.Nudges.loggedNudgesActionsLookup[action.id]
}

/**
 * @param {*} state
 * @returns {*}
 */
export function getOnboardingOccupanciesLookup(state) {
  let occupanciesLookup = state.Occupancies.occupanciesLookup

  if (!occupanciesLookup) {
    return null
  }
  let onboardingOccupanciesLookup = {}
  Object.entries(occupanciesLookup).forEach(([key, value]) => {
    if (value.status === OCCUPANCY_STATUS_FUTURE) {
      onboardingOccupanciesLookup[key] = value
    }
  })
  return onboardingOccupanciesLookup
}

/**
 * @param {*} state
 * @returns {*}
 */
export function checkForOnboardingUnitsRequiringReview(state) {
  const onboardingOccupanciesLookup = getOnboardingOccupanciesLookup(state)

  let hasOnboardingUnitsRequiringReview = Object.values(onboardingOccupanciesLookup).some(occupancy => {
    const hasOnboardingTasks = occupancy.onboardingTasks && Array.isArray(occupancy.onboardingTasks) && occupancy.onboardingTasks.length > 0
    const onboardingTasks = hasOnboardingTasks ? occupancy.onboardingTasks : []
    const numTasksPendingApproval = hasOnboardingTasks
      ? onboardingTasks.reduce((accum, currVal) => {
          if (isOnboardingTaskPendingApproval(currVal)) {
            accum++
          }
          return accum
        }, 0)
      : 0
    return numTasksPendingApproval
  })
  return hasOnboardingUnitsRequiringReview
}

/**
 * @param {*} state
 * @returns {*}
 */
export function getOnboardingResidentsLookup(state) {
  let occupanciesLookup = state.Occupancies.occupanciesLookup

  if (!occupanciesLookup) {
    return null
  }
  let onboardingResidentsLookup = {}

  Object.entries(occupanciesLookup).forEach(([key, value]) => {
    if (value.status === OCCUPANCY_STATUS_FUTURE) {
      const residents = selectResidentsByOccupancyId(state.Residents.residentsLookup, key)
      if (residents.length > 0)
        residents.forEach(resident => {
          // We don't want to include residents that have already moved in
          if (
            moment(resident.moveInDate).isAfter(
              moment()
                .subtract(1, 'days')
                .startOf('day'),
            )
          ) {
            const formattedResidentObject = formatResidentObject(resident)
            formattedResidentObject.onboardingTasks = value.onboardingTasks
            onboardingResidentsLookup[resident.id] = formattedResidentObject
          }
        })
    }
  })
  return onboardingResidentsLookup
}

/**
 * @param {*} state
 * @returns {Array<RichResidentObject>}
 */
export function selectResidentsNeedingForecasting(state) {
  return Object.values(state.Residents.residentsLookup).filter(resident =>
    doesResidentNeedForecasting(resident, state.Feedback.recentlyForecastedResidents),
  )
}

/**
 * @param {*} state
 * @param {string} residentId
 * @return {Date|null}
 */
export function selectLastTimeResidentForecasted(state, residentId) {
  return state.Feedback.recentlyForecastedResidents[residentId] ? new Date(state.Feedback.recentlyForecastedResidents[residentId]) : null
}

/**
 * This might be not best-practice, but whatever.
 * This function can be used as the equality checker for useSelector to determine if the response object has meaningfully changed
 * @param {*} objA
 * @param {*} objB
 */
export function deepEquals(objA, objB) {
  return JSON.stringify(objA) === JSON.stringify(objB)
}

export function selectOnboardingElevatorAmenitiesLookup(state) {
  return Object.values(state.Amenities.amenitiesLookup)
    .filter(onboardingElevatorAmenityFilter)
    .filter(amenity => amenity.isVisible)
    .reduce((accum, currVal) => {
      accum[currVal.id] = currVal
      return accum
    }, {})
}
