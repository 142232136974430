import ActionTypes from './types'
import {
  getAllVendorAppointments,
  chargeResidentForVendorAppointment,
  createNewVendorAppointment,
  getAllVendorServices,
  createNewRecurringVendorAppointment,
  rescheduleExistingVendorAppointment,
  cancelExistingVendorAppointment,
  markVendorNoShowForVendorAppointment,
} from '../lib/queries'

/**
 * @param {function} dispatch
 * @param {string} buildingId
 * @return {Promise<*>}
 */
export async function loadAppointmentsForBuilding(dispatch, buildingId) {
  let response = await getAllVendorAppointments(buildingId)

  dispatch({
    type: ActionTypes.APPOINTMENTS_UPDATE_ALL,
    payload: response,
  })

  return response
}

/**
 * @param {function} dispatch
 * @param {string} vendorAppointmentId
 * @return {Promise<*>}
 */
export async function chargeForAppointment(dispatch, vendorAppointmentId) {
  let payment = await chargeResidentForVendorAppointment(vendorAppointmentId)

  dispatch({
    type: ActionTypes.APPOINTMENTS_MARK_AS_PAID,
    vendorAppointmentId: vendorAppointmentId,
    paymentId: payment.id,
  })

  return payment
}

/**
 * @param {function} dispatch
 * @param {string} vendorAppointmentId
 * @return {Promise<*>}
 */
export async function markVendorNoShow(dispatch, vendorAppointmentId) {
  let updatedVendorAppointment = await markVendorNoShowForVendorAppointment(vendorAppointmentId)

  dispatch({
    type: ActionTypes.APPOINTMENTS_UPDATE_SINGLE,
    payload: updatedVendorAppointment,
  })
}

/**
 * @param {function} dispatch
 * @param {string} residentId
 * @param {string} serviceId
 * @param {Date} startAt
 * @param {Date} endAt
 * @param {string} paymentMethodId
 * @param {string?} notes
 * @param {Array<{label: string, cost: number}>?} addOns
 * @param {Array<VendorServiceDiscount>?} discounts
 * @param {string?} recurringFrequency
 * @param {Array<String>?} metaPetProfileIds
 * @returns {Promise<*>}
 */
export async function createNewAppointmentForResident(
  dispatch,
  residentId,
  serviceId,
  startAt,
  endAt,
  paymentMethodId,
  notes,
  addOns,
  discounts,
  recurringFrequency,
  metaPetProfileIds,
) {
  let newAppointment = await createNewVendorAppointment(residentId, serviceId, startAt, endAt, paymentMethodId, notes, addOns, discounts)

  if (recurringFrequency) {
    try {
      await createNewRecurringVendorAppointment(
        serviceId,
        residentId,
        newAppointment.buildingId,
        newAppointment.unitId,
        recurringFrequency,
        startAt,
        notes,
        paymentMethodId,
        newAppointment.id,
        addOns,
        metaPetProfileIds,
      )
    } catch (err) {
      // we're just going to swallow this -- can always create the recurring reference one-off later
      console.error(err)
    }
  }

  dispatch({
    type: ActionTypes.APPOINTMENTS_UPDATE_SINGLE,
    payload: newAppointment,
  })

  return newAppointment
}

/**
 * @param {function} dispatch
 * @param {string} appointmentId
 * @param {Date?} startAt
 * @param {Date?} endAt
 * @param {string?} paymentMethodId
 * @param {string?} notes
 * @param {Array<{label: string, cost: number}>} addOns
 * @param {Array<VendorServiceDiscount>?} discounts
 * @return {Promise<*>}
 */
export async function updateVendorAppointment(dispatch, appointmentId, startAt, endAt, paymentMethodId, notes, addOns, discounts) {
  let retVal = await rescheduleExistingVendorAppointment(appointmentId, startAt, endAt, paymentMethodId, notes, addOns, discounts)

  dispatch({
    type: ActionTypes.APPOINTMENTS_UPDATE_SINGLE,
    payload: retVal,
  })

  return retVal
}

/**
 * @param {function} dispatch
 * @param {string} appointmentId
 * @return {Promise<*>}
 */
export async function cancelVendorAppointment(dispatch, appointmentId) {
  let cancelledAppointment = await cancelExistingVendorAppointment(appointmentId)

  dispatch({
    type: ActionTypes.APPOINTMENTS_UPDATE_SINGLE,
    payload: cancelledAppointment,
  })

  return cancelledAppointment
}

export async function loadVendorServicesForBuilding(dispatch, buildingId) {
  let response = await getAllVendorServices(buildingId)

  dispatch({
    type: ActionTypes.SERVICES_UPDATE_ALL,
    payload: response,
  })

  return response
}
