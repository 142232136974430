import React from 'react'
import PropTypes from 'prop-types'
import {Grid, makeStyles} from '@material-ui/core'
import {rxrBlueColor, rxrSecondaryBlueGrayColor} from '../../assets/styles/color'

const useStyles = makeStyles(theme => ({
  infoTitle: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: '400',
    fontStyle: 'normal',
    color: rxrSecondaryBlueGrayColor,
  },
  infoData: {
    fontSize: 16,
    lineHeight: '16px',
    fontWeight: '400',
    fontStyle: 'normal',
    color: rxrBlueColor,
    paddingTop: '4px',
  },
}))

/**
 * Common function for rendeing data point.
 * @param {*} title
 * @param {*} data
 * @returns {string}
 */
function RenderInfo({title, data}) {
  const classes = useStyles()
  return (
    <Grid container direction="column" style={{paddingBottom: '24px'}}>
      <Grid item>
        <div className={classes.infoTitle}>{title}</div>
      </Grid>
      <Grid item>
        <div className={classes.infoData}>{data ? data : '--'}</div>
      </Grid>
    </Grid>
  )
}

RenderInfo.prototype = {
  title: PropTypes.string,
  data: PropTypes.string,
}
export default RenderInfo
