import {rxrDarkGreyColor} from '../../assets/styles/color'

/**
 * @param {Number} change
 * @returns {{color: String, sign: String}}}
 */
export function getChangeStyle(change) {
  let color
  let sign = ''
  if (change > 0) {
    color = 'green'
    sign = '+'
  } else if (change < 0) {
    color = 'red'
  } else {
    color = rxrDarkGreyColor
  }
  return {color, sign}
}

/**
 * @param {string|number} score
 * @return {*|string}
 */
export function valueToFixed(score) {
  return score === 'N/A' || !score || typeof score !== 'number' ? score : score.toFixed(2)
}
