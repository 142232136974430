import React from 'react'
import PropTypes from 'prop-types'
import VendorAppointment from './VendorAppointment'

function Cleaning(props) {
  return (
    <VendorAppointment
      vendorAppointmentId={props.vendorAppointmentId}
      frequencyLabel={'One-time cleaning'}
      label={'Cleaning type'}
      timeframe={'Cleaning timeframe'}
    />
  )
}

Cleaning.propTypes = {
  vendorAppointmentId: PropTypes.string.isRequired,
}

export default Cleaning
