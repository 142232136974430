import ActionTypes from '../actions/types'

const initialState = {
  recentlyCheckedInGuestIds: [],
}

// --------------------------------------------------------------------------------
// State mutation functions:

function addRecentlyCheckedInGuestId(state, guestId) {
  return {...state, recentlyCheckedInGuestIds: [...state.recentlyCheckedInGuestIds, guestId]}
}

function removeRecentlyCheckedInGuestId(state, guestId) {
  return {...state, recentlyCheckedInGuestIds: state.recentlyCheckedInGuestIds.filter(id => id !== guestId)}
}

// --------------------------------------------------------------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GUEST_AUTH_MARK_RECENT_CHECK_IN:
      return addRecentlyCheckedInGuestId(state, action.payload)

    case ActionTypes.GUEST_AUTH_UNMARK_RECENT_CHECK_IN:
      return removeRecentlyCheckedInGuestId(state, action.payload)

    default:
      /* pass through */
      return state
  }
}
