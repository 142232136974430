import ActionTypes from '../actions/types'

const initialState = {
  authedStaffModel: undefined,
  staffLookup: {},
}

// --------------------------------------------------------------------------------
// State mutation functions:

function updateAuthedStaffModel(state, staffModel) {
  return {
    ...state,
    authedStaffModel: staffModel,
  }
}

function updateStaffModel(state, staffModel) {
  let newStaffLookup = {...state.staffLookup}

  newStaffLookup[staffModel.id] = {
    ...staffModel,
    isDisabled: state.staffLookup[staffModel.id].isDisabled,
  }

  return {
    ...state,
    authedStaffModel: staffModel,
    staffLookup: newStaffLookup,
  }
}

function setStaffLookup(state, buildingStaffList) {
  let newStaffLookup = buildingStaffList.reduce((obj, bS) => {
    obj[bS.staffId] = {
      ...bS.staff,
      isDisabled: bS.isDisabled,
    }
    return obj
  }, {})
  return {...state, staffLookup: {...newStaffLookup}}
}

// --------------------------------------------------------------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.STAFF_SET_AUTHED_USER:
      return updateAuthedStaffModel(state, action.payload)

    case ActionTypes.STAFF_UPDATE_STAFF:
      return updateStaffModel(state, action.payload)

    case ActionTypes.STAFF_SET_STAFF_LOOKUP:
      return setStaffLookup(state, action.payload)

    default:
      /* pass through */
      return state
  }
}
