export default {
  ANNOUNCEMENTS: '/bulletin/announcements',
  ANNOUNCEMENTS_VIEW_SINGLE: '/bulletin/announcements/:announcementId',
  ANNOUNCEMENTS_CREATE: '/bulletin/announcements/create',

  BULLETIN: '/bulletin', // Not *really* a route, but useful in the nav panel
  EVENTS: '/bulletin/events',
  EVENTS_CREATE: '/bulletin/events/create',
  EVENTS_VIEW_SINGLE: '/bulletin/events/view/:eventId',

  ACCOUNT_PREFERENCES: '/account-preferences',
  COMMAND_CENTER: '/command-center',
  COMMAND_CENTER_LOG_ACTION_FROM_NUDGE: '/command-center/action/create/:buildingNudgeId',

  SERVICES: '/services', // not actually used
  CLEANING: '/services/cleaning',
  CLEANING_VIEW_SINGLE: '/services/cleaning/:vendorAppointmentId',
  CLEANING_CREATE: '/services/cleaning/create',
  DOGWALKING: '/services/dogwalking',
  DOGWALKING_VIEW_SINGLE: '/services/dogwalking/:vendorAppointmentId',
  DOGWALKING_CREATE: '/services/dogwalking/create',
  MASSAGE: '/services/massage',
  MASSAGE_VIEW_SINGLE: '/services/massage/:vendorAppointmentId',
  MASSAGE_CREATE: '/services/massage/create',
  PERSONALTRAINING: '/services/personal-training',
  PERSONALTRAINING_VIEW_SINGLE: '/services/personal-training/:vendorAppointmentId',
  PERSONALTRAINING_CREATE: '/services/personal-training/create',

  LOGIN: '/login',

  MESSAGES: '/message',
  MESSAGES_VIEW_SINGLE: '/message/:conversationId',
  MESSAGES_VIEW_SINGLE_JUMP_TO_MESSAGE: '/message/:conversationId/:messageId',

  RESIDENT_INSIGHTS: '/resident', // Not *really* a route, but useful in the nav panel
  PROFILE: '/resident/profile',
  PROFILE_VIEW_SINGLE: '/resident/profile/:residentId',
  PROFILE_EDIT: '/resident/profile/:residentId/edit',
  PROFILE_VIEW_SINGLE_TIMELINE: '/resident/profile/:residentId/timeline',
  PROFILE_VIEW_SINGLE_PETS: '/resident/profile/:residentId/pets',
  PROFILE_VIEW_RENT_BREAKDOWN: '/resident/profile/:residentId/rent/',
  PROFILE_VIEW_DISCOUNTS_BREAKDOWN: '/resident/profile/:residentId/discounts/',
  PROFILE_VIEW_DISCOUNTS_CREATE: '/resident/profile/:residentId/discounts/create',
  // this is a generic path used to match all timeline click through routes on the resident profile
  _MATCH_PROFILE_TIMELINE_GENERIC: '/resident/profile/:residentId/timeline/*/:primaryRecordId',
  // For these timeline routes primaryRecordId is required.
  PROFILE_TIMELINE_CLEANING: '/resident/profile/:residentId/timeline/cleaning/:primaryRecordId',
  PROFILE_TIMELINE_DOG_WALKING: '/resident/profile/:residentId/timeline/dog-walking/:primaryRecordId',
  PROFILE_TIMELINE_MASSAGE: '/resident/profile/:residentId/timeline/massage/:primaryRecordId',
  PROFILE_TIMELINE_PERSONAL_TRAINING: '/resident/profile/:residentId/timeline/personal-training/:primaryRecordId',
  PROFILE_TIMELINE_AMENITY_RESERVATION: '/resident/profile/:residentId/timeline/amenityreservation/:primaryRecordId',
  PROFILE_TIMELINE_GUEST_REGISTRATION: '/resident/profile/:residentId/timeline/guest/:primaryRecordId',
  PROFILE_TIMELINE_INCOMING_DELIVERY: '/resident/profile/:residentId/timeline/incomingdelivery/:primaryRecordId',
  PROFILE_TIMELINE_OUTGOING_DELIVERY: '/resident/profile/:residentId/timeline/outgoingdelivery/:primaryRecordId',
  PROFILE_TIMELINE_MAINTENANCE: '/resident/profile/:residentId/timeline/maintenance/:primaryRecordId',
  PROFILE_TIMELINE_PAYMENT: '/resident/profile/:residentId/timeline/payment/:primaryRecordId',
  PROFILE_TIMELINE_BUILDING_ACTION: '/resident/profile/:residentId/timeline/loggedaction/:primaryRecordId',

  STAFF_PROFILE: '/staff/profile',

  GUESTAUTHORIZATION: '/guests',
  GUESTAUTHORIZATION_CREATE: '/guests/create',
  GUESTAUTHORIZATION_VIEW_SINGLE: '/guests/view/:guestId',

  DELIVERIES: '/deliveries', // Not *really* a route, but useful in the nav panel
  DELIVERIES_IN: '/deliveries/incoming',
  DELIVERIES_IN_CREATE: '/deliveries/incoming/create',
  DELIVERIES_IN_VIEW_SINGLE: '/deliveries/incoming/view/:deliveryId',

  DELIVERIES_OUT: '/deliveries/outgoing',
  DELIVERIES_OUT_CREATE: '/deliveries/outgoing/create',
  DELIVERIES_OUT_VIEW_SINGLE: '/deliveries/outgoing/view/:deliveryId',

  PASSWORD_RESET: '/reset-password',
  PASSWORD_CREATE: '/create-password',
  PASSWORD_FORGOT: '/forgot-password',

  AMENITIES: '/amenities', // Not *really* a route, but useful in the nav panel
  AMENITIES_CALENDAR_VIEW: '/amenities/calendarview',
  AMENITIES_CALENDAR_VIEW_INDIVIDUAL: '/amenities/calendarview/individual',
  AMENITIES_CALENDAR_VIEW_INDIVIDUAL_AMENITY: '/amenities/calendarview/individual/:amenityId',
  ELEVATOR_CALENDAR_VIEW: '/amenities/elevator-calendar-view',
  ELEVATOR_CALENDAR_VIEW_INDIVIDUAL: '/amenities/elevator-calendar-view/individual',
  ELEVATOR_CALENDAR_VIEW_INDIVIDUAL_AMENITY: '/amenities/elevator-calendar-view/individual/:amenityId',
  AMENITIES_RESERVATIONS: '/amenities/reservations',
  AMENITIES_RESERVATIONS_VIEW_SINGLE: '/amenities/reservations/:reservationId',
  AMENITIES_RESERVATIONS_CREATE: '/amenities/reservations/create',
  AMENITIES_RESERVATIONS_CALENDAR_VIEW_CREATE: '/amenities/calendarview/create',
  ELEVATOR_RESERVATIONS_CREATE: '/amenities/elevator-calendar-view/create',
  AMENITIES_MANAGER: '/amenities/manager',
  AMENITIES_CREATE: '/amenities/create',
  AMENITIES_EDIT: '/amenities/:amenityId',

  SETTINGS: '/settings', // Not *really* a route, but useful in the nav panel
  SETTINGS_DASHBOARD_USERS: '/settings/staff',
  SETTINGS_DASHBOARD_USERS_CREATE: '/settings/staff/create',
  SETTINGS_DASHBOARD_USERS_VIEW_SINGLE: '/settings/staff/:userId',
  SETTINGS_PARTNERS: '/settings/partners',
  SETTINGS_PARTNERS_REORDER: '/settings/partners/reorder',
  SETTINGS_PARTNERS_CREATE: '/settings/partners/view/create',
  SETTINGS_PARTNERS_VIEW_SINGLE: '/settings/partners/view/:partnerId',
  SETTINGS_PROMOTIONS: '/settings/promotions',
  SETTINGS_PROMOTIONS_CREATE: '/settings/promotions/create',
  SETTINGS_PROMOTIONS_VIEW_SINGLE: '/settings/promotions/:promotionId',

  SERVICE_REQUESTS: '/maintenance/',
  SERVICE_REQUESTS_CREATE: '/maintenance/create',
  SERVICE_REQUESTS_VIEW: '/maintenance/:serviceRequestId',

  RXO_ASSISTANT: '/resident/assistant',
  RXO_ASSISTANT_LOG_NEW_ACTION: '/resident/assistant/action/create',
  RXO_ASSISTANT_LOG_ACTION_FROM_NUDGE: '/resident/assistant/action/create/:buildingNudgeId',
  RXO_ASSISTANT_VIEW_LOG_ACTION: '/resident/assistant/action/:loggedActionId',

  ONBOARDING: '/onboarding',
  ONBOARDING_VIEW_OCCUPANCY_DETAILS: '/onboarding/:occupancyId',
  ONBOARDING_TASK_DETAILS: '/onboarding/:occupancyId/task/:taskId',
  ONBOARDING_TASK_SUBMISSION_DETAILS: '/onboarding/:occupancyId/task/:taskId/submission/:residentId',

  RESIDENT_SENTIMENT: '/resident/resident-sentiment',

  ROOT: '/',

  /**
   * Example usage:
   * route: '/foo/:bar/bizz/:bop'
   * params: {bar: 5, bop: 10}
   * output: '/foo/5/bizz/10'
   *
   * @param {string} route
   * @param {*} params
   * @returns {*}
   */
  constructPath: (route, params) => {
    let retVal = route
    if (typeof params === 'object') {
      Object.entries(params).forEach(tuple => {
        const paramName = ':' + tuple[0]

        // make sure this route is expecting this parameter
        if (!route.includes(paramName)) {
          throw new Error(`Encountered invalid route param '${paramName}'`)
        }

        // swap the param name for the discrete value
        retVal = retVal.replace(paramName, tuple[1])
      })
    }

    // make sure we aren't missing any params
    if (retVal.indexOf(':') !== -1) {
      throw new Error(`Invalid path construction for route '${route}' --> '${retVal}'`)
    }

    return retVal
  },
}
