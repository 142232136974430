import React from 'react'
import TextField from '@material-ui/core/TextField'
import {KeyboardDatePicker, KeyboardDateTimePicker} from '@material-ui/pickers'
import {withStyles} from '@material-ui/styles'
import {Colors, TextFieldBorders} from './index'

const INPUT_STYLES = {
  root: {
    '& label': {
      color: Colors.rxrBlueColor,
      padding: '2px 4px',
    },
    '& label.Mui-focused': {
      color: Colors.rxrBlueColor,
    },
    '& label.Mui-disabled': {
      color: Colors.rxrDarkGreyColor,
    },
    '& label.Mui-error': {
      color: Colors.rxrRedColor,
    },
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
      backgroundColor: Colors.rxrWhiteColor,
      '& fieldset': {
        ...TextFieldBorders.textfieldInputBorder,
      },
      '&:hover fieldset': {
        ...TextFieldBorders.textfieldInputBorder,
      },
      '&.Mui-disabled fieldset': {
        ...TextFieldBorders.textfieldInputBorderDisabled,
      },
      '&.Mui-focused fieldset': {
        ...TextFieldBorders.textfieldInputBorder,
      },
      '&.Mui-error fieldset': {
        ...TextFieldBorders.textfieldInputBorderError,
      },
      '& .MuiInputAdornment-root': {
        '& .MuiIconButton-root': {
          '&:focus': {
            outline: '0px auto transparent',
          },
        },
      },
    },
    '& .MuiInputBase-root': {
      ...Colors.rxrBlue,
      '&.Mui-disabled': {
        backgroundColor: Colors.rxrLightGreyColor,
        ...Colors.darkGrey,
      },
      '&.Mui-error': {
        ...Colors.red,
      },
    },
    '& .MuiInput-underline:after': {
      display: 'none',
    },
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiFormHelperText-root': {
      ...Colors.rxrBlue,
      '&.Mui-disabled': {
        ...Colors.darkGrey,
      },
      '&.Mui-error': {
        ...Colors.red,
      },
    },
  },
}

export const FormTextField = withStyles(INPUT_STYLES)(TextField)

export const FormDateField = withStyles(INPUT_STYLES)(KeyboardDatePicker)

export const FormDateTimeField = withStyles(INPUT_STYLES)(KeyboardDateTimePicker)
