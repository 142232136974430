import React from 'react'
import {Colors} from '../../assets/styles'
import PropTypes from 'prop-types'
import GenericTag from './GenericTag'
import {useSelector} from 'react-redux'
import useResidentLookup from '../hooks/useResidentLookup'

function ResidentStatusTag(props) {
  const activeBuilding = useSelector(state => state.Buildings.buildingsLookup[state.Buildings.activeBuildingId])
  const hasOnboarding = activeBuilding && !!activeBuilding.onboardingSchema

  const {getResident} = useResidentLookup()
  const resident = getResident(props.residentId)

  // currently we only show resident status for future residents
  if (!resident || resident.occupancy.status !== 'Future') {
    return null
  }

  const statusText = hasOnboarding ? 'Onboarding' : 'Future'
  const bgColor = hasOnboarding ? Colors.rxrOrangeColor : Colors.purple
  return <GenericTag text={statusText} variant={props.variant} background={bgColor} className={props.className} />
}

ResidentStatusTag.propTypes = {
  residentId: PropTypes.string.isRequired,
  variant: GenericTag.propTypes.variant,
  className: PropTypes.string,
}

export default ResidentStatusTag
