/*
While we have to support 2 different data sources, I wanted to centralize the agnosticism logic
Once we move to support only 1 schema, we can remove/refactor a lot of this into actions and queries files
 */

import gql from 'graphql-tag'
import {
  ChatParticipantFragment,
  ChatMessageFragment,
  ChatRoomFragment,
  ConversationFragment,
  MessageFragment,
} from '../../lib/modelFragments'
import QueryHelper from '../../lib/QueryHelper'
import {
  getResidentUsersFromChatRoom,
  chatMessageToGenericChatMessage,
  chatRoomToGenericChatRoom,
  messageToGenericChatMessage,
  conversationToGenericChatRoom,
} from '../../Utils/chatUtils'
import FileHelper from '../../lib/FileHelper'
import {sendNewMessage} from '../../actions/messagesActions'
import * as queries from '../../lib/queries'
import {CONVERSATION_CLOSED, CONVERSATION_IN_PROGRESS, CONVERSATION_OPEN} from '../../constants/ModelConstants'
import ActionTypes from '../../actions/types'

const BATCH_SIZE = 20

const ListMessagesByConversationQuery = gql`
  ${MessageFragment}
  query ListMessagesByConversation($conversationId: ID, $limit: Int, $nextToken: String) {
    listMessagesByConversation(messageConversationId: $conversationId, limit: $limit, nextToken: $nextToken, sortDirection: DESC) {
      items {
        ...MessageFragment
      }
      nextToken
    }
  }
`

const ListChatMessagesByChatRoomQuery = gql`
  ${ChatMessageFragment}
  query ListChatMessagesByChatRoom($chatRoomId: ID!, $limit: Int, $nextToken: String) {
    getChatRoom(id: $chatRoomId) {
      id
      chatMessages(limit: $limit, nextToken: $nextToken, sortDirection: DESC) {
        items {
          ...ChatMessageFragment
        }
        nextToken
      }
    }
  }
`

const SendChatMessage = gql`
  ${ChatMessageFragment}
  mutation SendNewChatMessage($input: SendChatMessageInput!) {
    sendNewChatMessage(input: $input) {
      ...ChatMessageFragment
    }
  }
`

const CreateMessage = gql`
  ${MessageFragment}
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      ...MessageFragment
    }
  }
`

const UpdateChatRoom = gql`
  ${ChatRoomFragment}
  mutation UpdateChatRoom($input: UpdateChatRoomInput!) {
    updateChatRoom(input: $input) {
      ...ChatRoomFragment
    }
  }
`
const UpdateConversation = gql`
  ${ConversationFragment}
  mutation UpdateConversation($input: UpdateConversationInput!) {
    updateConversation(input: $input) {
      ...ConversationFragment
    }
  }
`

const UpdateChatMessage = gql`
  ${ChatMessageFragment}
  mutation UpdateChatMessage($input: UpdateChatMessageInput!) {
    updateChatMessage(input: $input) {
      ...ChatMessageFragment
    }
  }
`
const UpdateMessage = gql`
  ${MessageFragment}
  mutation UpdateMessage($input: UpdateMessageInput!) {
    updateMessage(input: $input) {
      ...MessageFragment
    }
  }
`

const UpdateChatParticipant = gql`
  ${ChatParticipantFragment}
  mutation UpdateChatParticipant($input: UpdateChatParticipantInput!) {
    updateChatParticipant(input: $input) {
      ...ChatParticipantFragment
    }
  }
`

const chatRoomMessagesExtractor = r => r.chatMessages

/**
 * @param {string} conversationId
 * @param {boolean?} isOnboardingChat
 * @return {Promise<Array<*>>}
 */
export async function loadMessagesByConversation(conversationId, isOnboardingChat) {
  if (isOnboardingChat) {
    return await QueryHelper.Instance().queryAll(
      ListChatMessagesByChatRoomQuery,
      {
        chatRoomId: conversationId,
      },
      BATCH_SIZE,
      undefined,
      chatRoomMessagesExtractor,
    )
  } else {
    const retVal = await QueryHelper.Instance().queryAll(
      ListMessagesByConversationQuery,
      {
        conversationId: conversationId,
      },
      BATCH_SIZE,
    )
    // exclude events
    return retVal.filter(m => m.type === 'CONVERSATION')
  }
}

/**
 * @param {string} conversationId
 * @param {boolean?} isOnboardingChat
 * @return {QueryIterator}
 */
export function iterateMessagesByConversation(conversationId, isOnboardingChat) {
  if (isOnboardingChat) {
    return QueryHelper.Instance().iterativeQuery(
      ListChatMessagesByChatRoomQuery,
      {
        chatRoomId: conversationId,
        limit: BATCH_SIZE,
      },
      chatRoomMessagesExtractor,
    )
  } else {
    const iterator = QueryHelper.Instance().iterativeQuery(ListMessagesByConversationQuery, {
      conversationId: conversationId,
      limit: BATCH_SIZE,
    })

    // exclude events
    const old = iterator.next
    iterator.next = async function() {
      const retVal = await old()
      return retVal.filter(m => m.type === 'CONVERSATION')
    }.bind(iterator)

    return iterator
  }
}

/**
 * @param {GenericChatRoom} chatRoom
 * @param {GenericChatUser} chatUser
 * @param {string} messageBody
 * @returns {Promise<GenericChatMessage>}
 */
export async function createTextMessage(chatRoom, chatUser, messageBody) {
  return {
    chatRoomId: chatRoom.id,
    chatUserId: chatUser.id,
    chatUser: chatUser,
    content: messageBody,
    hasAttachments: false,
    attachments: null,
  }
}

/**
 * @param {GenericChatRoom} chatRoom
 * @param {GenericChatUser} chatUser
 * @param {LocalS3Object} s3Object
 * @returns {Promise<GenericChatMessage>}
 */
export async function createImageMessage(chatRoom, chatUser, s3Object) {
  const formattedObj = FileHelper.formatS3ObjectForInput(s3Object)
  return {
    chatRoomId: chatRoom.id,
    chatUserId: chatUser.id,
    chatUser: chatUser,
    content: null,
    hasAttachments: true,
    attachments: [formattedObj],
  }
}

/**
 * @param {function} dispatch
 * @param {GenericChatRoom} chatRoom
 * @param {GenericChatMessage} chatMessage
 * @return {Promise<GenericChatMessage>}
 */
export async function saveMessage(dispatch, chatRoom, chatMessage) {
  let savedMessage
  if (chatRoom.isOnboardingChat) {
    const resp = await QueryHelper.Instance().executeMutation(SendChatMessage, {
      input: {
        chatRoomId: chatRoom.id,
        content: chatMessage.hasAttachments ? JSON.stringify(chatMessage.attachments) : chatMessage.content,
        isAttachments: chatMessage.hasAttachments,
      },
    })
    // the ChatMessaging lambda handles updating the chat room's last message
    savedMessage = chatMessageToGenericChatMessage(resp)
  } else {
    const resp = await QueryHelper.Instance().executeMutation(CreateMessage, {
      input: {
        messageConversationId: chatRoom.id,
        messageStaffId: chatMessage.chatUser.id,
        recipient: getResidentUsersFromChatRoom(chatRoom)[0].userId,
        body: chatMessage.hasAttachments ? 'IMAGE' : chatMessage.content,
        image: chatMessage.hasAttachments ? chatMessage.attachments[0] : null,
        type: 'CONVERSATION',
      },
    })
    // update our latest message referenced
    await QueryHelper.Instance().executeMutation(UpdateConversation, {
      input: {
        id: chatRoom.id,
        conversationLastMessageId: resp.id,
        lastMessageAt: new Date(),
      },
    })
    savedMessage = messageToGenericChatMessage(resp)
  }

  // add our chat user back in
  savedMessage.chatUser = chatMessage.chatUser
  await sendNewMessage(dispatch, savedMessage)
  return savedMessage
}

/**
 * @param {function} dispatch
 * @param {GenericChatRoom} chatRoom
 * @param {GenericChatUser} chatUser
 * @param {string} newStatus
 * @return {Promise<GenericChatRoom>}
 */
export async function updateChatRoomStatus(dispatch, chatRoom, chatUser, newStatus) {
  if (![CONVERSATION_OPEN, CONVERSATION_IN_PROGRESS, CONVERSATION_CLOSED].includes(newStatus)) {
    console.error(`Invalid conversation status: ${newStatus}`)
    return chatRoom
  }

  let updatedChatRoom
  if (chatRoom.isOnboardingChat) {
    const resp = await QueryHelper.Instance().executeMutation(UpdateChatRoom, {
      input: {
        id: chatRoom.id,
        status: newStatus,
      },
    })
    updatedChatRoom = chatRoomToGenericChatRoom(resp)
  } else {
    const resp = await QueryHelper.Instance().executeMutation(UpdateConversation, {
      input: {
        id: chatRoom.id,
        status: newStatus,
      },
    })
    await queries.createMessage({
      messageConversationId: chatRoom.id,
      body: newStatus,
      messageStaffId: chatUser.userId,
      recipient: getResidentUsersFromChatRoom(chatRoom)[0].userId,
      type: 'EVENT',
    })
    updatedChatRoom = conversationToGenericChatRoom(resp)
  }

  // update redux
  dispatch({
    type: ActionTypes.MESSAGES_SET_CONVERSATION_STATUS,
    conversationId: chatRoom.id,
    status: newStatus,
  })

  return updatedChatRoom
}

/**
 * @param {GenericChatMessage} chatMessage
 * @param {string} newContent
 * @return {Promise<GenericChatMessage>}
 */
export async function updateChatMessage(chatMessage, newContent) {
  if (chatMessage.hasAttachments) {
    // TODO: Maybe we could allow this???? Just complicated. Disabling for now
    throw new Error('Cannot edit attachment image')
  }
  if (chatMessage.__originalModel === 'ChatMessage') {
    await QueryHelper.Instance().executeMutation(UpdateChatMessage, {
      input: {
        id: chatMessage.id,
        content: newContent,
      },
    })
  } else {
    await QueryHelper.Instance().executeMutation(UpdateMessage, {
      input: {
        id: chatMessage.id,
        body: newContent,
      },
    })
  }
  chatMessage.content = newContent
  return chatMessage
}

/**
 * @param {GenericChatRoom} chatRoom
 * @param {GenericChatUser} chatUser
 * @return {Promise<void>}
 */
export async function markLastSeenAt(chatRoom, chatUser) {
  if (chatRoom.isOnboardingChat) {
    // if the id matches the userId, this is an indication that this user is not a real participant in the chat yet
    // so we can't actually update their lastSeenAt. Once they send their first message, their chatParticipant will get created
    // and these values will not longer match.
    if (chatUser.id === chatUser.userId) {
      return
    }
    await QueryHelper.Instance().executeMutation(UpdateChatParticipant, {
      input: {
        id: chatUser.id,
        lastSeenAt: new Date(),
      },
    })
  } else {
    await QueryHelper.Instance().executeMutation(UpdateConversation, {
      input: {
        id: chatRoom.id,
        staffLastReadAt: new Date(),
      },
    })
  }
}
