import {useSelector} from 'react-redux'

function useStaffLookup() {
  const staffLookup = useSelector(state => state.Staff.staffLookup)

  /**
   * @param {string} staffId
   * @return {*|{displayName: string, buildings: Array, messages: Array}}
   */
  const getStaff = staffId => {
    let staff = staffLookup[staffId]

    // return an empty object if not found
    return staff
      ? staff
      : {
          displayName: '',
          buildings: [],
          messages: [],
        }
  }

  return {
    getStaff,
  }
}

export default useStaffLookup
