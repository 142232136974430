import React from 'react'
import {makeStyles} from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import {rxrWhiteColor, rxrMidBlue, shadowSoft} from '../../assets/styles/color'

import PropTypes from 'prop-types'
import RXRIcon from '../RXRIcon'

const useStyles = makeStyles({
  root: {
    backgroundColor: rxrMidBlue,
    color: rxrWhiteColor,
    width: '53px',
    height: '53px',
    '&:hover': {
      backgroundColor: '#95C6DB',
      boxShadow: shadowSoft,
      '@media (hover: none)': {
        boxShadow: shadowSoft,
      },
    },
    '&:focus': {
      outline: '0px auto transparent',
    },
    boxShadow: shadowSoft,
    '&:focus-visible': {
      boxShadow: shadowSoft,
    },
    '&:active': {
      boxShadow: shadowSoft,
    },
    '&:disabled': {
      boxShadow: shadowSoft,
    },
  },
})

const FloatingBubbleButton = ({onBubbleClick, isOpen}) => {
  const classes = useStyles()

  return (
    <Fab className={classes.root} onClick={() => onBubbleClick()}>
      {!isOpen ? <RXRIcon icon={RXRIcon.PLUS} color={rxrWhiteColor} /> : <RXRIcon icon={RXRIcon.ARROW_DOWN} color={rxrWhiteColor} />}
    </Fab>
  )
}

FloatingBubbleButton.propTypes = {
  onBubbleClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
}

export default FloatingBubbleButton
