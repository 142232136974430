import React, {useState, useEffect} from 'react'
import {Grid} from '@material-ui/core'
import SelectInput from '../../SelectInput'
import {getMonthSelectorOptions, renderFloatWithTwoDecimalsIfValIsNotNaN} from '../residentSentimentUtilities'
import MetricDataAPI from '../../../lib/MetricData/MetricDataAPI'
import {useSelector} from 'react-redux'
import TextInput from '../../TextInput'
import DetailItemsList from './DetailItemsList'
import LoadingStateIconAndMessage from '../../LoadingStateIconAndMessage'
import EmptyStateIconAndMessage from '../../EmptyStateIconAndMessage'
import RXRIcon from '../../RXRIcon'
import {Typography, Spacing} from '../../../assets/styles'
import {makeStyles} from '@material-ui/core/styles'

const dateOptions = getMonthSelectorOptions()

function SentimentDetails(props) {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  /** @type {FeedbackDetailsPayload} data */
  const [data, setData] = useState({})
  const [asOfDate, setAsOfDate] = useState(dateOptions[0].value)
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const buildingsLookup = useSelector(state => state.Buildings.buildingsLookup)
  const activeBuildingYardiId = buildingsLookup[activeBuildingId]?.yardiId
  const [maxDetailsHeight, setMaxDetailsHeight] = useState('auto')

  useEffect(() => {
    setMaxDetailsHeight('auto')
    setIsLoading(true)
    MetricDataAPI.fetchSentimentDetails(activeBuildingYardiId, asOfDate)
      .then(r => {
        setData(r)
      })
      .catch(setError)
      .finally(() => {
        setIsLoading(false)
      })
  }, [activeBuildingId, asOfDate])

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item sm={12} md={4}>
          <SelectInput onChange={setAsOfDate} value={asOfDate} label={''} options={dateOptions} />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextInput value={`Number of messages: ${data.total}`} isReadOnly={true} />
        </Grid>

        <Grid item xs={6} md={4}>
          <TextInput value={`Sentiment: ${renderFloatWithTwoDecimalsIfValIsNotNaN(data?.average)}`} isReadOnly={true} />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{marginTop: Spacing.spaceMedium}}>
        {isLoading ? (
          <Grid item sm={12}>
            <LoadingStateIconAndMessage message={'Loading details'} />
          </Grid>
        ) : error ? (
          <Grid item sm={12}>
            <EmptyStateIconAndMessage message={error} icon={RXRIcon.ERROR} />
          </Grid>
        ) : (!data.top || data.top.length === 0) && (!data.bottom || data.bottom.length === 0) ? (
          <Grid item sm={12}>
            <EmptyStateIconAndMessage message={'No details to show'} icon={RXRIcon.ELLIPSIS} />
          </Grid>
        ) : (
          <React.Fragment>
            <Grid item sm={12} md={6}>
              <h3 className={classes.subTitle}>Top 10 positive messages</h3>
              <DetailItemsList
                items={data.top}
                style={{height: maxDetailsHeight}}
                ref={r => {
                  if (r) {
                    setMaxDetailsHeight(h => Math.max(r.getBoundingClientRect().height, parseInt(h) || 0) + 'px')
                  }
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <h3 className={classes.subTitle}>Top 10 negative messages</h3>
              <DetailItemsList
                items={data.bottom}
                style={{height: maxDetailsHeight}}
                ref={r => {
                  if (r) {
                    setMaxDetailsHeight(h => Math.max(r.getBoundingClientRect().height, parseInt(h) || 0) + 'px')
                  }
                }}
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  subTitle: {
    ...Typography.totalText,
  },
}))

export default SentimentDetails
