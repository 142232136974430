import React, {useState, useEffect} from 'react'
import {Grid} from '@material-ui/core'
import SelectInput from '../../SelectInput'
import {getMonthSelectorOptions} from '../residentSentimentUtilities'
import MetricDataAPI from '../../../lib/MetricData/MetricDataAPI'
import {useSelector} from 'react-redux'
import TextInput from '../../TextInput'
import DetailItemsList from './DetailItemsList'
import LoadingStateIconAndMessage from '../../LoadingStateIconAndMessage'
import EmptyStateIconAndMessage from '../../EmptyStateIconAndMessage'
import RXRIcon from '../../RXRIcon'
import {valueToFixed} from '../utils'

const dateOptions = getMonthSelectorOptions()

function NPSDetails(props) {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  /** @type {FeedbackDetailsPayload} data */
  const [data, setData] = useState({})
  const [asOfDate, setAsOfDate] = useState(dateOptions[0].value)
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const buildingsLookup = useSelector(state => state.Buildings.buildingsLookup)
  const activeBuildingYardiId = buildingsLookup[activeBuildingId]?.yardiId

  useEffect(() => {
    setIsLoading(true)
    MetricDataAPI.fetchNPSScoreDetails(activeBuildingYardiId, asOfDate)
      .then(r => {
        setData(r)
      })
      .catch(setError)
      .finally(() => {
        setIsLoading(false)
      })
  }, [activeBuildingId, asOfDate])

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item sm={12} md={4}>
          <SelectInput onChange={setAsOfDate} value={asOfDate} label={''} options={dateOptions} />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextInput value={`Number of responses: ${data.total}`} isReadOnly={true} />
        </Grid>

        <Grid item xs={6} md={4}>
          <TextInput value={`Average score: ${valueToFixed(data.average)} out of 10`} isReadOnly={true} />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          {isLoading ? (
            <LoadingStateIconAndMessage message={'Loading details'} />
          ) : error ? (
            <EmptyStateIconAndMessage message={error} icon={RXRIcon.ERROR} />
          ) : !data.items || data.items === 0 ? (
            <EmptyStateIconAndMessage message={'No details to show'} icon={RXRIcon.ELLIPSIS} />
          ) : (
            <DetailItemsList noDescriptionText={'Resident has not left a comment.'} items={data.items} />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default NPSDetails
