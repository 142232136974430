import React from 'react'
import useSharedStyles from '../useSharedStyles'
import PropTypes from 'prop-types'
import {ONBOARDING_STATE_COMPLETED, ONBOARDING_STATE_IN_PROGRESS} from '../../../constants/ModelConstants'
import {MOVING_METHOD_NO_MOVE_NEEDED, MOVING_METHOD_SELF_MOVE, MOVING_INFO_OPTIONS} from '../../../constants/OnboardingConstants'
import {makeStyles} from '@material-ui/core/styles'
import {rxrBlueColor} from '../../../assets/styles/color'
import SyncedFiles from '../SyncedFiles'
import {spaceMedium} from '../../../assets/styles/spacing'
import SelectInput from '../../SelectInput'
import RejectOrAcceptWithFileAttacher from '../FormCTAs/RejectOrAcceptWithFileAttacher'
import {getSyncedAndNotSyncedFilesFromSubmission} from '../../../Utils/onboardingUtils'

function MovingInfo_SelfMoveNoWaiver(props) {
  const sharedClasses = useSharedStyles()
  const classes = useStyles()

  const {syncedAttachments, notSyncedAttachments} = getSyncedAndNotSyncedFilesFromSubmission(props.submission)

  const handleSyncedAttachments = newSyncedAttachmentsArray => {
    props.onUpdateSubmission({
      ...props.submission,
      attachments: [...notSyncedAttachments, ...newSyncedAttachmentsArray],
    })
  }

  const handleSelectMovingMethod = option => {
    if (option === MOVING_METHOD_NO_MOVE_NEEDED || option === MOVING_METHOD_SELF_MOVE) {
      props.onUpdateSubmission({
        ...props.submission,
        movingMethod: option,
        state: ONBOARDING_STATE_COMPLETED,
      })
    } else {
      props.onUpdateSubmission({
        ...props.submission,
        movingMethod: option,
        state: ONBOARDING_STATE_IN_PROGRESS,
      })
    }
  }

  const determineCTAsBasedOnMovingMethod = movingMethod => {
    if (!movingMethod || movingMethod === MOVING_METHOD_SELF_MOVE || movingMethod === MOVING_METHOD_NO_MOVE_NEEDED) {
      return null
    }

    return <RejectOrAcceptWithFileAttacher submission={props.submission} onUpdateSubmission={props.onUpdateSubmission} />
  }

  return (
    <React.Fragment>
      <div className={sharedClasses.bodyContainer}>
        <div>Please check that all details are correct before confirming the requirement as accepted.</div>
        <div className={classes.paymentMethodContainer}>
          <SelectInput
            onChange={handleSelectMovingMethod}
            label={'Moving method'}
            isRequired={true}
            options={MOVING_INFO_OPTIONS}
            value={props.submission.movingMethod}
          />
        </div>
        <div className={classes.syncedAttachmentsContainer}>
          <SyncedFiles value={syncedAttachments} onChange={handleSyncedAttachments} />
        </div>
      </div>
      {determineCTAsBasedOnMovingMethod(props.submission.movingMethod)}
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  linkOut: {
    color: rxrBlueColor,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  paymentMethodContainer: {
    marginTop: spaceMedium,
  },
  syncedAttachmentsContainer: {
    marginTop: spaceMedium,
  },
}))

MovingInfo_SelfMoveNoWaiver.propTypes = {
  onUpdateSubmission: PropTypes.func.isRequired,
  submission: PropTypes.object,
}

export default MovingInfo_SelfMoveNoWaiver
