import pluralize from 'pluralize'

/**
 * @param {string} str
 * @returns {string}
 */
export function capitalizeFirstLetter(str) {
  if (!str || typeof str !== 'string') {
    return ''
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

/**
 * @param {string} singularStr
 * @param {number} count
 * @param {boolean?} includeCount
 * @returns {*}
 */
export function pluralizeWord(singularStr, count, includeCount) {
  return pluralize(singularStr, count, includeCount)
}

/**
 * This function will take an ISO phone number and convert it to a more human-friendly format
 * @param {string} p
 * @returns {string}
 */
export function formatPhoneNumber(p) {
  if (!p) {
    return p
  }

  if (p.length === 12 && p[0] === '+') {
    p = p.substr(2)
  }

  if (p.length !== 10) {
    return p
  }

  return `(${p.substr(0, 3)}) ${p.substr(3, 3)} - ${p.substr(6)}`
}
