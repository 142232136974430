import React, {useEffect, forwardRef} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import {Colors, Spacing, Typography} from '../../../assets/styles'
import {ellipsis, lightFontWeight} from '../../../assets/styles/typography'
import moment from 'moment'
import {constructClassString} from '../../../Utils/objectUtil'

const DEFAULT_NO_DESCRIPTION_TEXT = 'No description provided'

const DetailItemsList = forwardRef(function DetailItemsList(props, ref) {
  const classes = useStyles()

  return (
    <div className={classes.container} ref={ref} style={props.style}>
      {props.items.map((item, itemIdx) => {
        return (
          <div className={classes.item} key={item.label + itemIdx}>
            <div className={classes.value} style={{backgroundColor: props.color || Colors.magentaLight}}>
              {item.value}
              {item.denominator ? <span>/{item.denominator}</span> : ''}
            </div>
            <div className={classes.details}>
              <div>
                <h4 className={classes.label}>
                  {item.label} {item.secondaryLabel && <span className={classes.secondaryLabel}>{item.secondaryLabel}</span>}
                </h4>
              </div>
              <p className={constructClassString(classes.description, {empty: !item.description})}>
                {item.description || (props.noDescriptionText || DEFAULT_NO_DESCRIPTION_TEXT)}
              </p>
              <div className={classes.createdAt}>{moment(item.createdAt).format('M/D/YYYY')}</div>
            </div>
          </div>
        )
      })}
      {props.items.length === 0 && (
        <div style={{padding: Spacing.spaceMedium}}>
          <p>No items to show</p>
        </div>
      )}
    </div>
  )
})

const VALUE_CIRCLE_SIZE = 36

const useStyles = makeStyles(theme => ({
  container: {
    border: `1px solid ${Colors.rxrGreyColor}`,
    backgroundColor: Colors.rxrSidePanelBackgroundCoor,
    borderRadius: 4,
    maxHeight: 400,
    overflowY: 'scroll',
  },

  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: Spacing.spaceMedium,
  },

  value: {
    flexShrink: 0,
    textAlign: 'center',
    height: VALUE_CIRCLE_SIZE,
    width: VALUE_CIRCLE_SIZE,
    borderRadius: '50%',
    lineHeight: `${VALUE_CIRCLE_SIZE}px`,
    fontSize: Typography.fontSizeMedium,
    fontWeight: Typography.heavyFontWeight,

    '& span': {
      fontSize: Typography.fontSizeExtraSmall,
      fontWeight: Typography.lightFontWeight,
      color: Colors.blackOverlay50,
    },
  },

  details: {
    // the size of the
    width: `calc(100% - ${VALUE_CIRCLE_SIZE}px)`,
    position: 'relative',
    paddingLeft: Spacing.spaceMedium,
  },

  label: {
    fontWeight: 'bold',
    marginBottom: Spacing.spaceExtraExtraSmall,
  },

  secondaryLabel: {
    color: Colors.rxrMediumGreyColor,
    marginLeft: Spacing.spaceSmall,
    fontWeight: lightFontWeight,
  },

  description: {
    ...ellipsis,

    '&.empty': {
      color: Colors.rxrMediumGreyColor,
    },
  },

  createdAt: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}))

export default DetailItemsList

DetailItemsList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      description: PropTypes.string,
      createdAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
      denominator: PropTypes.number,
    }).isRequired,
  ),
  noDescriptionText: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
}
