import ActionTypes from './types'

/**
 * @param {function} dispatch
 */
export function openNavPanel(dispatch) {
  dispatch({
    type: ActionTypes.APP_SET_NAV_PANEL_OPEN,
    payload: true,
  })
}

/**
 * @param {function} dispatch
 */
export function closeNavPanel(dispatch) {
  dispatch({
    type: ActionTypes.APP_SET_NAV_PANEL_OPEN,
    payload: false,
  })
}

/**
 * @param {function} dispatch
 * @param {boolean} currentStatus
 */
export function toggleNavPanelOpenStatus(dispatch, currentStatus) {
  return currentStatus ? closeNavPanel(dispatch) : openNavPanel(dispatch)
}

/**
 * @param {function} dispatch
 * @param {boolean} isMuted
 */
export function setMuteSounds(dispatch, isMuted) {
  dispatch({
    type: ActionTypes.APP_SET_MUTE_SOUNDS,
    payload: isMuted,
  })
}

/**
 * @param {function} dispatch
 * @param {boolean} isHideen
 */
export function setHidePopups(dispatch, isHideen) {
  dispatch({
    type: ActionTypes.APP_SET_HIDE_POPUPS,
    payload: isHideen,
  })
}
