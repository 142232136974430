import React, {useState, useEffect} from 'react'
import {PageContainer} from '../../assets/styles/layout'
import PageHeader from '../PageHeader'
import EmptyStateIconAndMessage from '../EmptyStateIconAndMessage'
import {Grid} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {useHistory, useLocation, matchPath} from 'react-router-dom'
import {AntTab, AntTabs} from '../AntTabs'
import RXRIcon from '../RXRIcon'
import {deepEquals, getOccupancyWithResidentAndUnitData, getOnboardingOccupanciesLookup} from '../../reducers/selectors'
import {useDispatch, useSelector} from 'react-redux'
import OnboardingTable from './OnboardingTable'
import {loadAmenitiesForBuilding} from '../../actions/amenitiesActions'

import Routes from '../../constants/RouteConstants'
import SidePanel from '../SidePanel'
import RXRButton from '../RXRButton'
import CompactAmenityReservationEditor from '../Amenities/CompactAmenityReservationEditor'
import OnboardingDetails from './OnboardingDetails'
import GenericTaskComponent from './GenericTaskComponent'
import {convertOccupancyToString} from '../../Utils/onboardingUtils'
import useResidentLookup from '../hooks/useResidentLookup'
import {ONBOARDING_AMENITY_KEY_PICKUP_LABEL, ONBOARDING_IMPORTANCE_OPTIONAL} from '../../constants/ModelConstants'
import {pluralizeWord} from '../../Utils/StringFormatter'
import OnboardingWhitelabel from './OnboardingWhitelabel'
import ElevatorsCalendarView from './ElevatorsCalendarView'
import KeyPickupCalendarView from './KeyPickupCalendarView'
import Loader from '../Loader'

const useStyles = makeStyles(theme => ({
  tabComponentContainer: {
    height: '100%',
  },
  onboardingSidePanelContainer: {
    flex: 1,
  },
}))

const TAB_OVERVIEW = 0
const TAB_ELEVATOR = 1
const TAB_KEY_PICKUP = 2

export const onboardingElevatorAmenityFilter = a => a.isOnboardingOnly && a.label !== ONBOARDING_AMENITY_KEY_PICKUP_LABEL
export const onboardingKeyPickupAmenityFilter = a => a.isOnboardingOnly && a.label === ONBOARDING_AMENITY_KEY_PICKUP_LABEL

function Onboarding(props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const history = useHistory()
  const currentLocation = useLocation()
  const [tab, setTab] = useState(TAB_OVERVIEW)
  const [isLoadingAmenityData, setIsLoadingAmenityData] = useState(true)
  const [createElevatorReservation, setCreateElevatorReservation] = useState(false)
  const [createKeyPickupReservation, setCreateKeyPickupReservation] = useState(false)
  const {getResident} = useResidentLookup()
  const amenitiesLookup = useSelector(state => state.Amenities.amenitiesLookup)
  const onboardingElevatorAmenityArray = Object.values(amenitiesLookup).filter(onboardingElevatorAmenityFilter)
  const onboardingKeyPickupAmenityArray = Object.values(amenitiesLookup).filter(onboardingKeyPickupAmenityFilter)

  let focusedOccupancyId = null
  let viewOccupancymatch = matchPath(currentLocation.pathname, {path: Routes.ONBOARDING_VIEW_OCCUPANCY_DETAILS})
  if (viewOccupancymatch) {
    focusedOccupancyId = viewOccupancymatch.params.occupancyId
  }

  let focusedTaskId = null
  let viewTaskMatch = matchPath(currentLocation.pathname, {path: Routes.ONBOARDING_TASK_DETAILS})
  if (viewTaskMatch) {
    focusedTaskId = viewTaskMatch.params.taskId
  }

  let focusedSubmissionResidentId = null
  const viewSubmissionMatch = matchPath(currentLocation.pathname, {path: Routes.ONBOARDING_TASK_SUBMISSION_DETAILS})
  if (viewSubmissionMatch) {
    focusedSubmissionResidentId = viewSubmissionMatch.params.residentId
  }

  const onboardingOccupanciesFormatted = useSelector(
    state => Object.keys(getOnboardingOccupanciesLookup(state)).map(oId => getOccupancyWithResidentAndUnitData(state, oId)),
    // using deepEquals here because our map function will always return a new array and the elements themselves are composites of different redux stores
    deepEquals,
  )

  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)

  useEffect(() => {
    loadAmenitiesForBuilding(dispatch, activeBuildingId).then(() => setIsLoadingAmenityData(false))
  }, [])

  const hasOnboardingOccupancies = onboardingOccupanciesFormatted.length > 0

  let occupancy
  let task
  if (focusedOccupancyId && hasOnboardingOccupancies) {
    occupancy = onboardingOccupanciesFormatted.find(o => o.id === focusedOccupancyId)
    task = occupancy && occupancy.onboardingTasks ? occupancy.onboardingTasks.find(element => element.id === focusedTaskId) : null
  }

  let tabComponent
  if (tab === TAB_OVERVIEW) {
    if (!hasOnboardingOccupancies) {
      tabComponent = <EmptyStateIconAndMessage message={'There are no onboarding units to show'} icon={RXRIcon.ONBOARDING} />
    } else {
      tabComponent = <OnboardingTable />
    }
  } else if (tab === TAB_ELEVATOR) {
    tabComponent = <ElevatorsCalendarView />
  } else {
    tabComponent = <KeyPickupCalendarView />
  }

  const breadcrumbPaths = [
    {path: '', label: 'Onboarding'},
    {
      path: Routes.constructPath(Routes.ONBOARDING_VIEW_OCCUPANCY_DETAILS, {occupancyId: focusedOccupancyId}),
      label: convertOccupancyToString(occupancy, true),
    },
  ]

  if (task) {
    breadcrumbPaths.push({
      path: Routes.constructPath(Routes.ONBOARDING_TASK_DETAILS, {occupancyId: focusedOccupancyId, taskId: task.id}),
      label: task.label,
    })

    if (focusedSubmissionResidentId && task.supportsMultipleSubmissions) {
      breadcrumbPaths.push({
        path: Routes.constructPath(Routes.ONBOARDING_TASK_SUBMISSION_DETAILS, {
          occupancyId: focusedOccupancyId,
          taskId: task.id,
          residentId: focusedSubmissionResidentId,
        }),
        label: getResident(focusedSubmissionResidentId).displayName,
      })
    }
  }

  const hasKeyPickupAmenity = onboardingKeyPickupAmenityArray.length > 0

  return (
    <OnboardingWhitelabel>
      <div style={PageContainer}>
        <PageHeader
          title={'Onboarding'}
          rightControlComponent={
            tab === TAB_ELEVATOR ? (
              <RXRButton onClick={() => setCreateElevatorReservation(true)}>Create reservation</RXRButton>
            ) : tab === TAB_KEY_PICKUP ? (
              <RXRButton onClick={() => setCreateKeyPickupReservation(true)}>Create reservation</RXRButton>
            ) : (
              undefined
            )
          }
        >
          <Grid container>
            <Grid item lg={6} md={6}>
              <AntTabs value={tab} onChange={(e, t) => setTab(t)}>
                <AntTab label={'Overview'} />
                <AntTab label={pluralizeWord('Elevator', onboardingElevatorAmenityArray.length)} />
                {hasKeyPickupAmenity && <AntTab label={'Key pick-up'} />}
              </AntTabs>
            </Grid>
          </Grid>
        </PageHeader>

        {/* Main page content */}
        {isLoadingAmenityData ? <Loader /> : <div className={classes.tabComponentContainer}>{tabComponent}</div>}
        {/* End main page content */}

        <SidePanel
          isOpen={!!focusedOccupancyId}
          onClose={() => history.push(Routes.ONBOARDING)}
          title={
            task ? (task.importance === ONBOARDING_IMPORTANCE_OPTIONAL ? `Optional step: ${task.label}` : task.label) : 'Onboarding details'
          }
          breadcrumbs={breadcrumbPaths}
        >
          <div className={classes.onboardingSidePanelContainer}>{focusedTaskId ? <GenericTaskComponent /> : <OnboardingDetails />}</div>
        </SidePanel>
        <SidePanel
          isOpen={createElevatorReservation}
          title={'Create Elevator Reservation'}
          onClose={() => setCreateElevatorReservation(false)}
        >
          <CompactAmenityReservationEditor
            onComplete={() => setCreateElevatorReservation(false)}
            amenitiesFilter={onboardingElevatorAmenityFilter}
          />
        </SidePanel>
        {hasKeyPickupAmenity && (
          <SidePanel
            isOpen={createKeyPickupReservation}
            title={'Create Key Pickup Reservation'}
            onClose={() => setCreateKeyPickupReservation(false)}
          >
            <CompactAmenityReservationEditor
              onComplete={() => setCreateKeyPickupReservation(false)}
              amenitiesFilter={onboardingKeyPickupAmenityFilter}
              amenityId={onboardingKeyPickupAmenityArray[0]?.id}
            />
          </SidePanel>
        )}
      </div>
    </OnboardingWhitelabel>
  )
}

export default Onboarding
