import ActionTypes from './types'
import {isGuestDatePermanent} from '../Utils/dateTimeUtil'
import {CreateGuestVisit} from '../lib/queries'
// The amount of time we wait before unmarking a recent checkin - 5 seconds
const CHECK_IN_THROTTLE_DELAY_MS = 5000

/**
 * @param {function} dispatch
 * @param {GuestFragment} guest
 * @param {ResidentFragment} resident
 * @returns {Promise<*>}
 */
export const checkInGuest = async (dispatch, guest, resident) => {
  dispatch({
    type: ActionTypes.GUEST_AUTH_MARK_RECENT_CHECK_IN,
    payload: guest.id,
  })
  let input = {
    isPermanent: isGuestDatePermanent(guest.registrationExpiry),
    guestName: `${guest.firstName} ${guest.lastName}`,
    residentName: resident.displayName,
    unitNumber: resident.occupancy.unit.number,
    buildingId: resident.occupancy.unit.unitBuildingId,
    guestId: guest.id,
  }
  let retVal
  try {
    retVal = await CreateGuestVisit(input)
    setTimeout(() => {
      dispatch({
        type: ActionTypes.GUEST_AUTH_UNMARK_RECENT_CHECK_IN,
        payload: guest.id,
      })
    }, CHECK_IN_THROTTLE_DELAY_MS)
  } catch (err) {
    // unmark it immediately
    dispatch({
      type: ActionTypes.GUEST_AUTH_UNMARK_RECENT_CHECK_IN,
      payload: guest.id,
    })
    throw err
  }

  return retVal
}
