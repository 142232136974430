import {IconButton, makeStyles} from '@material-ui/core'
import React from 'react'
import {useDispatch} from 'react-redux'
import {navigateBack} from '../../actions/kyrWidgetActions'
import RXRIcon from '../RXRIcon'
import {Colors} from '../../assets/styles'
import {iconButton} from '../../assets/styles/buttons'

const useStyles = makeStyles(theme => ({
  icon: {
    ...iconButton,
    '&:focus': {
      outline: 'none',
    },
  },
}))

function KYRWidgetBackButton(props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  return (
    <IconButton className={classes.icon} onClick={() => navigateBack(dispatch)}>
      <RXRIcon icon={RXRIcon.ARROW_LEFT} color={Colors.rxrBlueColor} />
    </IconButton>
  )
}

export default KYRWidgetBackButton
