import ActionTypes from '../actions/types'
import {CACHE_NanPanelOpenStatus, CACHE_MuteSounds, CACHE_HidePopups} from '../constants/AppConstants'

const isNavPanelStartingOpen =
  typeof localStorage[CACHE_NanPanelOpenStatus] === 'string' ? !!parseInt(localStorage[CACHE_NanPanelOpenStatus]) : true

// This is a hacky first pass at supporting Mobile views
const isMobile = window.innerWidth < 900

const navOpenWidth = isMobile ? 0 : 250
const navClosedWidth = isMobile ? 0 : 72

const initialState = {
  isNavPanelOpen: isMobile ? false : isNavPanelStartingOpen,
  navPanelWidth: isNavPanelStartingOpen ? navOpenWidth : navClosedWidth,
  muteSounds: typeof localStorage[CACHE_MuteSounds] === 'string' ? !!parseInt(localStorage[CACHE_MuteSounds]) : false,
  hidePopups: typeof localStorage[CACHE_HidePopups] === 'string' ? !!parseInt(localStorage[CACHE_HidePopups]) : false,
  isMobile: isMobile,
}

// --------------------------------------------------------------------------------
// State mutation functions:

function setNavPanelOpenState(state, isOpen) {
  localStorage[CACHE_NanPanelOpenStatus] = isOpen ? 1 : 0
  return {...state, isNavPanelOpen: isOpen, navPanelWidth: isOpen ? navOpenWidth : navClosedWidth}
}

function setMuteSounds(state, isMuted) {
  localStorage[CACHE_MuteSounds] = isMuted ? 1 : 0
  return {...state, muteSounds: isMuted}
}

function setHidePopups(state, isHidden) {
  localStorage[CACHE_HidePopups] = isHidden ? 1 : 0
  return {...state, hidePopups: isHidden}
}

// --------------------------------------------------------------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.APP_SET_NAV_PANEL_OPEN:
      return setNavPanelOpenState(state, action.payload)

    case ActionTypes.APP_SET_MUTE_SOUNDS:
      return setMuteSounds(state, action.payload)

    case ActionTypes.APP_SET_HIDE_POPUPS:
      return setHidePopups(state, action.payload)

    default:
      /* pass through */
      return state
  }
}
