import {v4 as uuid} from 'uuid'

/**
 * @typedef LocalS3Object
 * @property {string} key
 * @property {string} bucket
 * @property {string} region
 * @property {string?} displayName
 * @property {string?} contentType
 * @property {string?} fileUrl
 */

/**
 * @typedef S3ObjectInput
 * @property {string} key
 * @property {string} bucket
 * @property {string} region
 * @property {string?} displayName
 * @property {string?} contentType
 */

class FileHelper {
  static #instance
  /**
   * @param {*} Storage
   * @param {*} config
   */
  constructor(Storage, config) {
    if (FileHelper.#instance) {
      throw new Error('FileHelper is a singleton. Use FileHelper.getInstance()')
    }

    if (!Storage || !config) {
      throw new Error('Storage and config are required to instantiate FileHelper')
    }

    this.config = config
    this.Storage = Storage
    this.Storage.configure(config)

    FileHelper.#instance = this
  }

  /**
   * @param {*} file
   * @returns {Promise<LocalS3Object>}
   */
  async uploadSingleFileToS3(file) {
    const options = {
      contentType: file.type,
    }

    const result = await this.Storage.put(uuid(), file, options)

    /** @type {* | {key: string}} */
    let fileUrl = await this.getFileUrlFromS3Object(result)
    return {
      key: result.key,
      bucket: this.config.aws_user_files_s3_bucket,
      region: this.config.aws_project_region,
      displayName: file.name,
      contentType: file.type,
      fileUrl: fileUrl,
    }
  }

  /**
   * @param {{key: string, fileUrl?: string}} obj
   * @param {boolean?} force
   * @returns {Promise<string>}
   */
  async getFileUrlFromS3Object(obj, force) {
    if (obj.fileUrl && !force) {
      return obj.fileUrl
    }

    // I don't know why the storage library is always appending public but if they key already has it then we shouldn't
    const key = obj.key.startsWith('public/') ? obj.key.substring(7) : obj.key

    return await this.Storage.get(key)
  }

  /**
   * @param {*} e
   * @param {Array<string>?} acceptedFileTypes
   * @return {Promise<Array<LocalS3Object>>}
   */
  async handleSelectFilesInputEventUploadToS3(e, acceptedFileTypes) {
    const files = Array.from(e.target.files)

    if (Array.isArray(acceptedFileTypes) && acceptedFileTypes.length > 0 && files.some(f => !acceptedFileTypes.includes(f.type))) {
      throw new Error('One or more files of invalid type')
    }

    return Promise.all(files.map(f => this.uploadSingleFileToS3(f)))
  }

  // ------------------------------------------------------------------------
  // Static Methods
  // ------------------------------------------------------------------------

  /**
   * @param {LocalS3Object} obj
   * @returns {S3ObjectInput}
   */
  static formatS3ObjectForInput(obj) {
    return obj
      ? {
          key: obj.key,
          bucket: obj.bucket,
          region: obj.region,
          displayName: obj.displayName,
          contentType: obj.contentType,
          isSynced: obj.isSynced,
        }
      : undefined
  }

  /**
   * @param {*?} Storage
   * @param {*?} config
   * @returns {FileHelper}
   */
  static Instance(Storage, config) {
    if (!FileHelper.#instance) {
      FileHelper.#instance = new FileHelper(Storage, config)
    }
    return FileHelper.#instance
  }

  static IMAGE_FILE_TYPES = ['image/jpg', 'image/jpeg', 'image/bmp', 'image/png', 'image/gif', 'image/tif', 'image/tiff']
  static SVG_FILE_TYPES = ['image/svg+xml']
}

export default FileHelper
