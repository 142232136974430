import React from 'react'
import {makeStyles} from '@material-ui/core'
import {useHistory, matchPath, useLocation} from 'react-router-dom'
import {useSelector, useDispatch, shallowEqual} from 'react-redux'
import {getOnboardingOccupanciesLookup} from '../../reducers/selectors'
import {updateOnboardingTaskAction} from '../../actions/onboardingTaskActions'
import ListSubmissionsForTaskIndex from './ListSubmissionsForTaskIndex'
import Routes from '../../constants/RouteConstants'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}))

function GenericTaskComponent() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentLocation = useLocation()

  const currentLoggedInStaffId = useSelector(state => state.Staff.authedStaffModel.id)

  // using shallowEqual because the resulting object is newly constructed, but contains the original lookup's occupancy object reference
  const onboardingOccupanciesLookup = useSelector(state => getOnboardingOccupanciesLookup(state), shallowEqual)

  let focusedTaskId
  let focusedOccupancyId
  let viewTaskMatch = matchPath(currentLocation.pathname, {path: Routes.ONBOARDING_TASK_DETAILS})
  if (viewTaskMatch) {
    focusedOccupancyId = viewTaskMatch.params.occupancyId
    focusedTaskId = viewTaskMatch.params.taskId
  } else {
    return null
  }

  const occupancy = onboardingOccupanciesLookup[focusedOccupancyId]
  const task = occupancy.onboardingTasks.find(element => element.id === focusedTaskId)

  /**
   * @param {string} nextState
   * @param {*} form
   * @return {Promise<void>}
   */
  const handleTaskUpdate = async (nextState, form) => {
    if (!task || !occupancy) {
      return
    }

    // always update the form
    let updateOnboardingTaskInput = {
      id: task.id,
      form: JSON.stringify(form),
    }

    // if the state has changed, we need to update the state and stateHistory
    if (nextState !== task.state) {
      // set the state and stateHistory props to be updated
      updateOnboardingTaskInput.state = nextState
      updateOnboardingTaskInput.stateHistory = [
        ...task.stateHistory,
        {
          state: nextState,
          previousState: task.state,
          timestamp: new Date(),
          modifiedBy: currentLoggedInStaffId,
        },
      ]
    }

    // at this point the input object is ready to be sent to the updateOnboardingTaskAction, but we need to update the occupancy object in redux as well
    // so we create a new occupancy object with the updated onboardingTasks array
    await updateOnboardingTaskAction(dispatch, updateOnboardingTaskInput)
  }

  return (
    <div className={classes.container}>
      <ListSubmissionsForTaskIndex task={task} onTaskUpdate={handleTaskUpdate} />
    </div>
  )
}

GenericTaskComponent.propTypes = {}

export default GenericTaskComponent
