import ActionTypes from '../actions/types'
import {chatRoomToGenericChatRoom, conversationToGenericChatRoom} from '../Utils/chatUtils'
import {CONVERSATION_CLOSED} from '../constants/ModelConstants'

const initialState = {
  filterString: '',
  unreadCount: 0,
  hasInitialLoad: false, // this gets reset to false if the building changes
  newMessagesFromLastSync: [], // this is used to show new messages cards, the elements are GenericChatMessage objects
  latestResidentMessageTimestamp: undefined, // this is used to play a sound whenever a new message is identified
  conversationsLookup: {}, // the values are GenericChatRoom objects
  draftMessagesByConversationId: {},
}

const DEFAULT_TAB_TILE = document.title

// --------------------------------------------------------------------------------
// State mutation functions:

function updateFilterString(state, newString) {
  return {...state, filterString: newString}
}

/**
 * @param {*} state
 * @param {Array<ConversationFragment>} conversationsData
 * @param {Array<ChatRoomFragment>} chatRoomsData
 * @returns {*}
 */
function setConversations(state, conversationsData, chatRoomsData) {
  const prevLookup = state.conversationsLookup
  const prevMessages = state.newMessagesFromLastSync
  const newMessages = []

  /** @type {Array<GenericChatRoom>} */
  const allChatRooms = conversationsData.map(conversationToGenericChatRoom).concat(chatRoomsData.map(chatRoomToGenericChatRoom))

  let unreadCount = 0
  let latestResidentMessageTimestamp = 0
  const lookup = allChatRooms.reduce((agr, c) => {
    // we want to determine if the last message was sent by a resident
    let lastMessageIsResident = false
    if (c.lastMessage) {
      const lastMessageUser = c.chatUsers.find(u => u.id === c.lastMessage.chatUserId)
      // if we can't find the user, they must be staff (legacy conversation)
      lastMessageIsResident = lastMessageUser && !lastMessageUser.isStaffUser

      // we're treating every conversation as "Unread" IFF the last message was sent by a resident && the status is not CLOSED
      c.hasUnread = !!(lastMessageIsResident && c.status !== CONVERSATION_CLOSED)
    }

    if (c.hasUnread) {
      unreadCount++
    }

    // if this message came after our latest, we record it as the new latest
    if (lastMessageIsResident) {
      // update the timestamp accordingly
      if (new Date(c.lastMessage.creatdAt).getTime() > latestResidentMessageTimestamp) {
        latestResidentMessageTimestamp = c.lastMessage.dateCreated.getTime()
      }

      // if the lastMessage (which is a resident message) is new; i.e., not the lastMessage last time we synced
      if (prevLookup[c.id] && prevLookup[c.id].lastMessage.id !== c.lastMessage.id) {
        newMessages.push(c.lastMessage)
      }
    }
    agr[c.id] = c
    return agr
  }, {})

  // here's where we set our tab title to reflect the unread count
  if (unreadCount > 0) {
    document.title = `${unreadCount} | ${DEFAULT_TAB_TILE}`
  } else {
    document.title = DEFAULT_TAB_TILE
  }

  return {
    ...state,
    hasInitialLoad: true,
    conversationsLookup: lookup,
    unreadCount: unreadCount,
    latestResidentMessageTimestamp: latestResidentMessageTimestamp || undefined, // don't store 0, store undefined
    // only set this array if we know there was a change - we don't want to needlessly trigger an update just because it's a new array
    // we also only do this after the initial load. If we haven't had an initial load then we use the prev array (because it's empty)
    newMessagesFromLastSync: state.hasInitialLoad && (newMessages.length > 0 || prevMessages.length > 0) ? newMessages : prevMessages,
  }
}

/**
 * @param {*} state
 * @param {GenericChatMessage} newMessage
 */
function addNewMessageInfo(state, newMessage) {
  const oldChatRoom = state.conversationsLookup[newMessage.chatRoomId]
  if (!oldChatRoom) {
    // shouldn't happen
    return state
  }
  const newLookup = {
    ...state.conversationsLookup,
    // we set this new message as the last message on the chatRoom object for easy reference
    [newMessage.chatRoomId]: {...oldChatRoom, lastMessageId: newMessage.id, lastMessage: newMessage},
  }
  return {...state, conversationsLookup: newLookup}
}

/**
 * @param {*} state
 * @param {string} conversationId
 * @param {string?} message
 */
function recordDraftMessage(state, conversationId, message) {
  let newDrafts = {...state.draftMessagesByConversationId}
  if (!message) {
    delete newDrafts[conversationId]
  } else {
    newDrafts[conversationId] = message
  }
  return {...state, draftMessagesByConversationId: newDrafts}
}

/**
 * @param {*} state
 * @param {string} conversationId
 * @param {string} newStatus
 */
function updateConversationStatus(state, conversationId, newStatus) {
  return {
    ...state,
    conversationsLookup: {
      ...state.conversationsLookup,
      [conversationId]: {...state.conversationsLookup[conversationId], status: newStatus},
    },
  }
}

/**
 * @return {*}
 */
function handleChangedBuilding() {
  return {
    ...initialState,
  }
}

// --------------------------------------------------------------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.BUILDINGS_SET_VIEWING:
      return handleChangedBuilding()

    case ActionTypes.MESSAGES_UPDATE_FILTER_ACTION:
      return updateFilterString(state, action.payload)

    case ActionTypes.MESSAGES_SYNC_CONVERSATIONS:
      return setConversations(state, action.conversations, action.chatRooms)

    case ActionTypes.MESSAGES_CREATED_NEW_MESSAGE:
      return addNewMessageInfo(state, action.payload)

    case ActionTypes.MESSAGES_SET_CONVERSATION_STATUS:
      return updateConversationStatus(state, action.conversationId, action.status)

    case ActionTypes.MESSAGES_SET_DRAFT_MESSAGE:
      return recordDraftMessage(state, action.conversationId, action.message)

    default:
      /* pass through */
      return state
  }
}
