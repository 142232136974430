import React, {useEffect, useState, useRef} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import {listUnitTagsForBuilding} from '../../lib/queries'
import PrefixComplete from '../PrefixComplete'
import SimpleSpinner from '../SimpleSpinner'
import {rxrBlueColor, rxrRedColor, rxrDarkGreyColor, rxrTeal25to100} from '../../assets/styles/color'
import {spaceExtraExtraSmall, spaceExtraSmall, spaceSmall, spaceMedium} from '../../assets/styles/spacing'
import {TextFieldBorders} from '../../assets/styles'
import RXRIcon from '../RXRIcon'

const ALL_UNITS_PSEUDO_TAG_ID = 'all-units-tag'
const DEFAULT_INPUT_HEIGHT = 21

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },

  selectedTagsContainer: {
    position: 'absolute',
    top: spaceSmall,
    left: spaceSmall,
    display: 'inline-block',
  },

  pillContainer: props => ({
    ...TextFieldBorders.textfieldInputBorder,
    borderColor: props.hasError ? rxrRedColor : rxrBlueColor,
    padding: `${spaceExtraExtraSmall}px ${spaceExtraSmall}px`,
    borderRadius: '2px',
    display: 'inline-block',
    backgroundColor: rxrTeal25to100,
    marginRight: spaceExtraSmall,
    marginBottom: spaceExtraSmall,
    cursor: 'pointer',
  }),

  trashIcon: {
    marginLeft: spaceExtraSmall,
    color: rxrDarkGreyColor,
    verticalAlign: 'middle',
  },

  unitsSummary: {},

  innerInputStyles: props => ({
    '& .MuiInputBase-input': {
      marginLeft: `${props.allPillsWidth || 0}px`,
      height: `${props.allPillsHeight || DEFAULT_INPUT_HEIGHT}px`,
    },
  }),
}))

// ----------------------------------------------------------------------------
// This is a single tag pill component

function Pill(props) {
  const classes = useStyles(props)
  return (
    <div className={classes.pillContainer} onClick={() => props.onTrash()}>
      {props.label}
      <RXRIcon icon={RXRIcon.CLOSE} className={classes.trashIcon} />
    </div>
  )
}
Pill.propTypes = {
  label: PropTypes.string,
  onTrash: PropTypes.func,
}

// ----------------------------------------------------------------------------

function SelectUnitTags(props) {
  const allPillsContainer = useRef(undefined)
  const inputRef = useRef(undefined)
  const [allPillsWidth, setAllPillsWidth] = useState(0)
  const [maxPillsWidth, setMaxPillsWidth] = useState(0)
  const [allPillsHeight, setAllPillsHeight] = useState(0)

  const classes = useStyles({allPillsWidth: allPillsWidth, allPillsHeight: allPillsHeight})
  const [tagsLookup, setTagsLookup] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const unitsLookup = useSelector(state => state.Units.unitsLookup)

  useEffect(() => {
    listUnitTagsForBuilding(activeBuildingId)
      .then(tags => {
        // we created a pseudo tag for each individual unit
        let systemGeneratedTags = Object.values(unitsLookup).reduce(
          (agr, unit) => {
            const id = `unit-${unit.id}`
            agr[id] = {
              id: id,
              label: `Unit ${unit.number}`,
              unitIds: [unit.id],
            }
            return agr
          },
          {
            // and another pseudo tag for ALL UNITS
            [ALL_UNITS_PSEUDO_TAG_ID]: {
              id: ALL_UNITS_PSEUDO_TAG_ID,
              label: 'ALL UNITS',
              unitIds: Object.values(unitsLookup).map(u => u.id),
            },
          },
        )

        setTagsLookup(
          tags.reduce((agr, t) => {
            agr[t.id] = t
            return agr
          }, systemGeneratedTags),
        )
      })
      .finally(() => setIsLoading(false))
  }, [])

  const refreshAllPillsWidth = () => {
    if (!allPillsContainer.current || !inputRef.current) {
      return
    }

    // we don't want the pills to go more than 2/3rds the width, then we'll start breaking to a second line
    const maxPillsWidth = inputRef.current.offsetWidth * 0.66
    setMaxPillsWidth(maxPillsWidth)

    // we do this in a timeout so that our pills have a chance to render and the new width be calculated
    setTimeout(() => {
      const pillsWidth = allPillsContainer.current.offsetWidth
      const pillsHeight = allPillsContainer.current.offsetHeight

      setAllPillsWidth(pillsWidth)
      // logically this should be "- spaceExtraSmall" since that's the marginBottom of the pill,
      // but there's also some extra padding on the input we need to account for, so spaceMedium works better.
      setAllPillsHeight(pillsHeight - spaceMedium)
    }, 300)
  }

  const selectedTags = (props.selectedTagIds || []).filter(tId => !!tagsLookup[tId])
  const handleSelectTag = tag => {
    if (!tag) {
      return
    }
    props.onChange([...selectedTags, tag.id].map(t => tagsLookup[t]))
    refreshAllPillsWidth()
  }

  const handleRemoveTag = tagId => {
    props.onChange(selectedTags.filter(s => s !== tagId).map(t => tagsLookup[t]))
    refreshAllPillsWidth()
  }

  const allTags = Object.values(tagsLookup)
  const unSelectedTags = allTags.filter(t => !selectedTags.includes(t.id))

  return (
    <div className={classes.container}>
      <PrefixComplete
        label={props.label}
        value={''}
        onChange={handleSelectTag}
        isRequired={true}
        options={unSelectedTags}
        isDisabled={allTags.length === selectedTags.length || props.isDisabled}
        clearOnSelect={true}
        getOptionLabel={t => {
          return t.label
        }}
        error={props.hasError}
        textFieldStyle={classes.innerInputStyles}
        ref={inputRef}
      />
      <div className={classes.selectedTagsContainer} style={{maxWidth: `${maxPillsWidth}px`}} ref={allPillsContainer}>
        {isLoading ? (
          <SimpleSpinner />
        ) : (
          selectedTags.map(t => <Pill key={t} label={tagsLookup[t].label} onTrash={() => handleRemoveTag(t)} />)
        )}
      </div>
    </div>
  )
}

SelectUnitTags.propTypes = {
  selectedTagIds: PropTypes.arrayOf(PropTypes.string), // array of tag ID strings
  onChange: PropTypes.func.isRequired, // this will pass an array of UnitTag objects with an array of units associated
  hasError: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
}

export default SelectUnitTags
