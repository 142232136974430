import React, {useState} from 'react'
import {Grid} from '@material-ui/core'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import {spaceMedium} from '../../../assets/styles/spacing'
import SelectInput from '../../SelectInput'
import useFormChanged, {NonEmptyArrayValidator, NonEmptyValidator} from '../../hooks/useFormChanged'
import {PERMANENT_GUEST_EXPIRY_DATE_ISO} from '../../../constants/AppConstants'
import NumberInput from '../../NumberInput'
import MultilineTextInput from '../../MultilineTextInput'
import GenericFilterMenu from '../../GenericFilterMenu'
import RXRButton from '../../RXRButton'
import DiscardChangesDialog from '../../DiscardChangesDialog'
import {useHistory} from 'react-router-dom'
import {createNewVendorCreditForResident} from '../../../lib/queries'
import {useSelector} from 'react-redux'
import Routes from './../../../constants/RouteConstants'
import {rxrRedColor} from '../../../assets/styles/color'
import VendorServiceDiscount from '../../../lib/VendorServiceDiscount'
import {selectAuthedUserId} from '../../../reducers/selectors'

const useStyles = makeStyles(theme => ({
  row: {marginBottom: spaceMedium},
}))

const DISCOUNT_TYPE_OPTIONS = [{label: 'Dollar amount', value: 'Fixed'}, {label: 'Percentage amount', value: 'Percentage'}]
const SERVICE_TYPE_LABELS = {
  Cleaning: 'Cleaning',
  DogWalking: 'Dog walking',
  Massage: 'Massage',
  PersonalTraining: 'Personal training',
}

const EMPTY_FORM_STATE = {
  discountType: 'Fixed',
  amount: 0,
  notes: '',
  vendorServiceTypes: Object.keys(SERVICE_TYPE_LABELS), // start with all selected
}

function CreateNewCreditForm(props) {
  const classes = useStyles()
  const {form, setForm, invalidItems, formChanged, validateForm, resetInitialForm} = useFormChanged(
    EMPTY_FORM_STATE,
    useFormChanged.PropLevelValidation({
      discountType: NonEmptyValidator,
      amount: NonEmptyValidator,
      notes: NonEmptyValidator,
      vendorServiceTypes: NonEmptyArrayValidator,
    }),
  )
  const authedStaffId = useSelector(selectAuthedUserId)
  const [isSaving, setIsSaving] = useState(false)
  const history = useHistory()

  const handleCreateCredit = async () => {
    if (isSaving) {
      return
    }

    if (!validateForm()) {
      return
    }

    const input = {
      residentId: props.residentId,
      discountType: form.discountType,
      amount: form.amount,
      notes: form.notes,
      vendorServiceTypes: form.vendorServiceTypes,
      expiredAt: PERMANENT_GUEST_EXPIRY_DATE_ISO,
      staffId: authedStaffId,
      category: 'PERSONAL_CREDIT',
      title: 'Personal credit',
      description: `You have received a ${VendorServiceDiscount.getDiscountString(form)} discount for your next service booking!`,
    }

    setIsSaving(true)
    try {
      await createNewVendorCreditForResident(input)
      resetInitialForm(EMPTY_FORM_STATE, handleCancel)
    } catch (err) {
      console.error(err)
    }
    setIsSaving(false)
  }
  const handleCancel = () => history.push(Routes.constructPath(Routes.PROFILE_VIEW_SINGLE, {residentId: props.residentId}))

  return (
    <React.Fragment>
      <Grid container spacing={3} className={classes.row}>
        <Grid item xs={12}>
          <GenericFilterMenu
            onChange={types => setForm({vendorServiceTypes: types})}
            filterLabelMap={SERVICE_TYPE_LABELS}
            selectedFilters={form.vendorServiceTypes}
          >
            <div>
              To be used on:{' '}
              {form.vendorServiceTypes.length > 0 ? (
                <span style={{fontWeight: 'bold'}}>{form.vendorServiceTypes.map(k => SERVICE_TYPE_LABELS[k]).join(', ')}</span>
              ) : (
                <span style={{fontWeight: 'bold', color: rxrRedColor}}>Must select at least one service</span>
              )}
            </div>
          </GenericFilterMenu>
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.row}>
        <Grid item xs={6}>
          <SelectInput
            label={'Discount type'}
            isRequired={true}
            onChange={v => setForm({discountType: v})}
            options={DISCOUNT_TYPE_OPTIONS}
            value={form.discountType}
            error={invalidItems.includes('discountType')}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            isRequired={true}
            label={'Amount'}
            min={0}
            max={form.discountType === 'Percentage' ? 100 : undefined}
            showDollarSign={form.discountType === 'Fixed'}
            showPercentSign={form.discountType === 'Percentage'}
            onChange={v => setForm({amount: v})}
            value={form.amount}
            error={invalidItems.includes('amount')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.row}>
        <Grid item xs={12}>
          <MultilineTextInput
            label="Notes"
            placeholder=""
            helperText={`Why are you issuing this credit? (For internal use only)`}
            rows={6}
            rowsMax={6}
            maxLength={500}
            onChange={val => setForm({notes: val})}
            value={form.notes}
            isRequired={true}
            error={invalidItems.includes('notes')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.row}>
        <Grid item>
          <RXRButton onClick={handleCreateCredit} isLoading={isSaving} disabled={form.vendorServiceTypes.length < 1}>
            Issue credit
          </RXRButton>
        </Grid>
        <Grid item>
          <RXRButton type={RXRButton.TYPE_DESTRUCTIVE} onClick={handleCancel}>
            Cancel
          </RXRButton>
        </Grid>
      </Grid>

      <DiscardChangesDialog hasChanges={formChanged} />
    </React.Fragment>
  )
}

CreateNewCreditForm.propTypes = {
  residentId: PropTypes.string.isRequired,
}

export default CreateNewCreditForm
