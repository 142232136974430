import React, {useEffect, useRef} from 'react'
import {useQuill} from 'react-quilljs'
import PropTypes from 'prop-types'
import 'quill/dist/quill.snow.css'
import {makeStyles} from '@material-ui/core/styles'
import {rxrBlueColor, rxrRedColor, rxrWhiteColor} from '../assets/styles/color'
import {spaceExtraSmall} from '../assets/styles/spacing'
import {fontSizeSmall} from '../assets/styles/typography' // Add css for snow theme

const useStyles = makeStyles(theme => {
  return {
    container: props => ({
      position: 'relative',
      paddingTop: spaceExtraSmall,
      backgroundColor: rxrWhiteColor,

      '& .ql-editor': {
        minHeight: '200px',
        maxHeight: '400px',
      },

      '& .ql-toolbar': {
        border: 'none',
        borderBottom: `1px solid ${props.hasError ? rxrRedColor : rxrBlueColor}`,
      },

      '& .ql-container': {
        border: 'none',
      },

      '& strong': {
        fontWeight: 'bold',
      },
      '& em': {
        fontStyle: 'italic',
      },
      '& u': {
        textDecoration: 'underline',
      },
    }),

    editor: {
      position: 'relative',
      zIndex: 2,
    },

    legend: props => ({
      color: props.hasError ? rxrRedColor : rxrBlueColor,
      fontSize: fontSizeSmall,
      width: 'auto',
      '& span': {
        padding: `0px ${spaceExtraSmall}px`,
        userSelect: 'none',
      },
    }),

    fieldset: props => ({
      position: 'absolute',
      border: `1px solid ${props.hasError ? rxrRedColor : rxrBlueColor}`,
      borderRadius: '4px',
      top: 0,
      left: 0,
      width: '100%',
      height: `calc(100% + ${fontSizeSmall / 2}px)`,
      paddingLeft: spaceExtraSmall,
      marginTop: -(fontSizeSmall / 2),
    }),
  }
})

function RichTextInput(props) {
  const classes = useStyles(props)
  const hasInitializedContent = useRef(false)
  const {quill, quillRef} = useQuill({
    modules: {
      toolbar: [[{header: [1, 2, 3, false]}], ['bold', 'italic', 'underline'], ['link', {list: 'ordered'}, {list: 'bullet'}]],
    },
  })

  const label = props.isRequired ? `${props.label} *` : props.label

  // we initialize our value once it becomes available.
  // this is needed in case the component mounts before the value is ready (e.g., when loading an announcement from a deep link)
  useEffect(() => {
    if (quill && !hasInitializedContent.current && props.value) {
      // start off with our initial value
      quill.clipboard.dangerouslyPasteHTML('' + (props.value || ''))
      hasInitializedContent.current = true
    }
  }, [props.value, quill])

  useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta, oldDelta, source) => {
        if (typeof props.onChange === 'function') {
          props.onChange('' + quill.root.innerHTML, '' + quill.getText())
        }
      })
    }
  }, [quill])

  return (
    <div className={classes.container}>
      <div className={classes.editor}>
        <div ref={quillRef} />
      </div>
      <fieldset className={classes.fieldset}>
        <legend className={classes.legend}>
          <span>{label}</span>
        </legend>
      </fieldset>
    </div>
  )
}

RichTextInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  label: PropTypes.string,
  isRequired: PropTypes.bool,

  //TODO:
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
}

export default RichTextInput
