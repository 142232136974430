import React, {useContext, useEffect, useState} from 'react'
import SortableTable, {ColumnConfig} from '../../SortableTable'
import {PartnersContext} from './PartnersContext'
import RXRIcon from '../../RXRIcon'
import {makeStyles} from '@material-ui/core/styles'
import {Buttons, Colors, Spacing} from '../../../assets/styles'
import {constructClassString} from '../../../Utils/objectUtil'
import {rxrGreyColor, rxrMediumGreyColor} from '../../../assets/styles/color'
import useDoOnceTimer from '../../hooks/useDoOnceTimer'
import {setPartnerOrder} from '../../../lib/queries'
import SimpleSpinner from '../../SimpleSpinner'
import DiscardChangesDialog from '../../DiscardChangesDialog'

const DEBOUNCE_TIMEOUT = 2000
const SAVE_TIMER = 'save-timer'

// these columns don't change so we can declare them once outside the render cycle
const columns = [
  new ColumnConfig({
    title: 'App display Order',
    render: (g, c, i) => i + 1,
  }),
  new ColumnConfig({
    title: 'Partner Name',
    render: g => g.displayName,
  }),
]

function EditPartnerOrderTable(props) {
  const classes = useStyles()
  const {partnersLookup, partnerIds, createOrUpdatePartner} = useContext(PartnersContext)
  const [cachedOrder, setCachedOrder] = useState([])
  const [isSaving, setIsSaving] = useState(false)
  const {setTimer, isTimerSet, cancelTimer} = useDoOnceTimer()

  useEffect(() => {
    cancelTimer(SAVE_TIMER)
    setCachedOrder(partnerIds)
  }, [partnerIds])

  const updateCachedOrder = newOrder => {
    setCachedOrder(newOrder)
    setTimer(SAVE_TIMER, () => persistChanges(newOrder), DEBOUNCE_TIMEOUT)
  }

  /**
   * @param {Array<string>} newOrder
   */
  const persistChanges = newOrder => {
    setIsSaving(true)
    const partnersToUpdate = newOrder
      // we store the order from 1 -> n, which is why we add 1 to the index
      .map((id, i) => (partnersLookup[id].order !== i + 1 ? {id: id, order: i + 1} : null))
      .filter(p => p !== null)

    Promise.all(partnersToUpdate.map(p => setPartnerOrder(p.id, p.order)))
      .then(() => {
        partnersToUpdate.forEach(p => {
          createOrUpdatePartner({...partnersLookup[p.id], order: p.order})
        })
      })
      .catch(err => {
        window.alert(err.message)
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  /**
   * @param {string} id
   */
  const handleMoveUp = id => {
    const indexOf = cachedOrder.indexOf(id)
    if (indexOf === 0) {
      return
    }

    const after = cachedOrder.filter(i => i !== id)
    after.splice(indexOf - 1, 0, id)
    updateCachedOrder(after)
  }

  /**
   * @param {string} id
   */
  const handleMoveDown = id => {
    const indexOf = cachedOrder.indexOf(id)
    if (indexOf === cachedOrder.length - 1) {
      return
    }

    const after = cachedOrder.filter(i => i !== id)
    after.splice(indexOf + 1, 0, id)
    updateCachedOrder(after)
  }

  const changeOrderColumn = new ColumnConfig({
    title: 'Change order',
    render: (g, c, i, d) => {
      const canMoveUp = i > 0
      const canMoveDown = i < d.length - 1

      return (
        <React.Fragment>
          <span className={constructClassString(classes.moveButton, {[classes.disabled]: !canMoveUp})} onClick={() => handleMoveUp(g.id)}>
            <RXRIcon icon={RXRIcon.ARROW_UP} />
          </span>
          <span
            className={constructClassString(classes.moveButton, {[classes.disabled]: !canMoveDown})}
            onClick={() => handleMoveDown(g.id)}
          >
            <RXRIcon icon={RXRIcon.ARROW_DOWN} />
          </span>
        </React.Fragment>
      )
    },
  })

  const showSpinner = isSaving || isTimerSet(SAVE_TIMER)

  return (
    <div className={classes.container}>
      {showSpinner && <SimpleSpinner className={classes.spinner} />}
      <SortableTable columns={[...columns, changeOrderColumn]} data={cachedOrder.map(i => partnersLookup[i])} />
      <DiscardChangesDialog hasChanges={showSpinner} onDiscard={() => cancelTimer(SAVE_TIMER)} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },

  spinner: {
    position: 'absolute',
    top: Spacing.spaceSmall,
    right: Spacing.spaceSmall,
    zIndex: 10,
  },

  moveButton: {
    ...Buttons.primaryButton,
    display: 'inline-block',
    height: 32,
    width: 32,
    borderRadius: '50%',
    padding: 0,
    lineHeight: `36px`, // a couple extra pixels looks more centered
    color: Colors.rxrWhiteColor,

    '&:last-child': {
      marginLeft: Spacing.spaceSmall,
    },
  },

  disabled: {
    cursor: 'not-allowed',
    backgroundColor: rxrGreyColor,
    border: 'none !important',
    color: rxrMediumGreyColor,
    '&:hover': {
      backgroundColor: rxrGreyColor,
    },
  },
}))

export default EditPartnerOrderTable
