import moment from 'moment'

/**
 * @returns {Array<SelectOption>}
 */
export function getWeekSelectorOptions() {
  const shortFormat = 'MMM DD'
  const longFormat = 'MMM DD, YYYY'

  const earliestDate = moment().subtract(6, 'months')
  const retVal = []
  let endDate = moment()
  let startDate = endDate
  do {
    startDate = endDate.clone().subtract(1, 'week')
    let labelFormat = startDate.isSame(endDate, 'year') ? shortFormat : longFormat

    retVal.push({
      value: endDate.format('YYYY-MM-DD'),
      // always use long format for end date. Only use long format for start date if it's not the same year as end date
      label: `${startDate.format(labelFormat)} - ${endDate.format(longFormat)}`,
    })

    // move our endDate up to startDate so the loop can restart
    endDate = startDate
  } while (startDate > earliestDate)

  return retVal
}

/**
 * @returns {Array<SelectOption>}
 */
export function getMonthSelectorOptions() {
  const shortFormat = 'MMM DD'
  const longFormat = 'MMM DD, YYYY'

  const numWeeks = 26
  const retVal = []
  let endDate = moment()
  let startDate = moment().subtract(30, 'days')
  for (let i = 0; i < numWeeks; i++) {
    let labelFormat = startDate.isSame(endDate, 'year') ? shortFormat : longFormat
    retVal.push({
      value: endDate.format('YYYY-MM-DD'),
      // always use long format for end date. Only use long format for start date if it's not the same year as end date
      label: `${startDate.format(labelFormat)} - ${endDate.format(longFormat)}`,
    })
    endDate = endDate.subtract(7, 'days')
    startDate = startDate.subtract(7, 'days')
  }

  return retVal
}

// we don't want to ask again for at least 90 days
// this intentionally greater than the 60 days they have to forecast when in range of a forecaster event
const FORECASTER_HIDE_DURATION_MS = 7776000000
const FORECASTER_AVAILABLE_DURATION_DAYS = 60
const FORECASTER_LEASE_END_TRIGGER_MONTHS = 3
const FORECASTER_LEASE_START_TRIGGER_MONTHS = 4
/**
 * @param {RichResidentObject} resident
 * @param {Object<string, number>?} recentlyForecastedResidents
 * @return {boolean}
 */
export function doesResidentNeedForecasting(resident, recentlyForecastedResidents) {
  // if we forecasted this resident recently we return false right off the bat
  if (
    recentlyForecastedResidents &&
    recentlyForecastedResidents[resident.id] &&
    recentlyForecastedResidents[resident.id] > Date.now() - FORECASTER_HIDE_DURATION_MS
  ) {
    return false
  }

  const leaseStartTriggerDate = resident.occupancy.leaseFromDate
    ? moment(resident.occupancy.leaseFromDate)
        .add(FORECASTER_LEASE_START_TRIGGER_MONTHS, 'months')
        .endOf('day')
    : null
  const leaseEndTriggerDate = resident.occupancy.leaseToDate
    ? moment(resident.occupancy.leaseToDate)
        .subtract(FORECASTER_LEASE_END_TRIGGER_MONTHS, 'months')
        .endOf('day')
    : null

  // if we're in the range of the lease start or end trigger date, we return true
  return (
    (leaseStartTriggerDate &&
      moment().isBetween(leaseStartTriggerDate, moment(leaseStartTriggerDate).add(FORECASTER_AVAILABLE_DURATION_DAYS, 'days'))) ||
    (leaseEndTriggerDate &&
      moment().isBetween(leaseEndTriggerDate, moment(leaseEndTriggerDate).add(FORECASTER_AVAILABLE_DURATION_DAYS, 'days')))
  )
}

export const renderNotAvailableIfValIsNaN = val => {
  return isNaN(val) ? 'N/A' : val
}

export const renderFloatWithTwoDecimalsIfValIsNotNaN = val => {
  return isNaN(val) ? 'N/A' : val.toFixed(2)
}
