import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {spaceSmall, spaceExtraSmall} from '../../assets/styles/spacing'
import PropTypes from 'prop-types'
import RXRIcon from '../RXRIcon'
import FileHelper from '../../lib/FileHelper'
import {IconButton} from '@material-ui/core'

function AttachedFile(props) {
  const classes = useStyles(props)

  const handleDownloadClick = e => {
    e.stopPropagation()
    FileHelper.Instance()
      .getFileUrlFromS3Object(props.file)
      .then(url => {
        window.open(url, '_blank')
      })
  }

  return (
    <div className={classes.attachedFile}>
      <IconButton className={classes.CTA} onClick={() => props.handleRemoveAttachment(props.idx)}>
        <RXRIcon icon={RXRIcon.TRASH} />
      </IconButton>
      <span className={classes.attachFileText}>{props.file.displayName || props.file.key}</span>
      <IconButton className={classes.CTA} onClick={handleDownloadClick}>
        <RXRIcon icon={RXRIcon.DOWNLOAD} />
      </IconButton>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  attachedFile: {
    display: 'flex',
    paddingBottom: spaceExtraSmall,
    alignItems: 'center',
  },
  attachFileText: {
    marginLeft: spaceSmall,
    marginRight: spaceSmall,
    display: 'inline-block',
  },
  CTA: {
    borderRadius: '50%',
    height: 36,
    width: 36,
  },
}))

AttachedFile.propTypes = {
  file: PropTypes.object.isRequired, // S3 object
  handleRemoveAttachment: PropTypes.func.isRequired,
  idx: PropTypes.number.isRequired,
}

export default AttachedFile
