import React, {useState, useEffect} from 'react'
import SortableTable, {ColumnConfig} from '../SortableTable'
import PropTypes from 'prop-types'
import moment from 'moment'
import Routes from '../../constants/RouteConstants'
import {makeStyles} from '@material-ui/core/styles'
import {withRouter} from 'react-router-dom'
import {secondaryButton} from '../../assets/styles/buttons'
import {rxrGreenColor, rxrBlackColor, rxrGreyColor} from '../../assets/styles/color'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {markedPickedUp, markPickedUp_Multiple} from '../../actions/deliveryActions'
import Constants from './DeliveryConstants'
import HighlightText from '../HighlightText'
import {RXRButton} from '../RXRButton'
import useResidentLookup from '../hooks/useResidentLookup'
import RXRIcon from '../RXRIcon'
import {Typography} from '@material-ui/core'
import TableMultiSelectToolBar from '../TableMultiSelectToolBar'
import {pluralizeWord} from '../../Utils/StringFormatter'

const useStyles = makeStyles(theme => ({
  lineThrough: {
    textDecoration: 'line-through',
  },
  checkInButtonConfirm: {
    ...secondaryButton,
    borderColor: rxrGreenColor,
    color: rxrGreenColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  multiSelectCopy: {
    color: rxrBlackColor,
  },
  multiSelectCTAs: {
    marginLeft: 'auto',
  },
}))

const OutgoingPackagesTable = function(props) {
  const classes = useStyles()

  const handleClickDelivery = delivery => {
    props.history.push(Routes.constructPath(Routes.DELIVERIES_OUT_VIEW_SINGLE, {deliveryId: delivery.id}))
  }

  const {getResident} = useResidentLookup()

  const NULL_MUTATE = value => (value ? value.toLowerCase() : '0')

  const [multiSelectSelectedArray, setMultiSelectSelectedArray] = useState([])

  const columns = [
    new ColumnConfig({
      title: 'Unit',
      renderPrimitive: g => getResident(g.residentId).occupancy.unit.number,
      render: (g, col) => (
        <HighlightText className={g.isDeleted ? classes.lineThrough : null} search={props.filterTerm}>
          {col.renderPrimitive(g)}
        </HighlightText>
      ),
      comparator: ColumnConfig.residentUnitComparatorGenerator(getResident),
    }),

    new ColumnConfig({
      title: 'Resident',
      renderPrimitive: g => getResident(g.residentId).displayName,
      render: (g, col) => (
        <HighlightText className={g.isDeleted ? classes.lineThrough : null} search={props.filterTerm}>
          {col.renderPrimitive(g)}
        </HighlightText>
      ),
      comparator: ColumnConfig.residentNameComparatorGenerator(getResident),
    }),

    new ColumnConfig({
      title: 'Type',
      renderPrimitive: g => {
        let matching = Constants.PACKAGE_OPTIONS.find(o => o.value === g.packageType)
        return matching ? matching.label : 'Unknown'
      },
      render: (g, col) => (
        <HighlightText className={g.isDeleted ? classes.lineThrough : null} search={props.filterTerm}>
          {col.renderPrimitive(g)}
        </HighlightText>
      ),
      comparator: ColumnConfig.simpleComparatorGenerator('packageType'),
    }),

    new ColumnConfig({
      title: 'Carrier',
      renderPrimitive: g => {
        let matching = Constants.CARRIER_OPTIONS.find(o => o.value === g.carrier)
        return matching ? matching.label : '--'
      },
      render: (g, col) => (
        <HighlightText className={g.isDeleted ? classes.lineThrough : null} search={props.filterTerm}>
          {col.renderPrimitive(g)}
        </HighlightText>
      ),
      comparator: ColumnConfig.simpleComparatorGenerator('carrier', undefined, NULL_MUTATE),
    }),

    new ColumnConfig({
      title: 'Tracking number',
      renderPrimitive: g => g.trackingNumber || '--',
      render: (g, col) => (
        <HighlightText className={g.isDeleted ? classes.lineThrough : null} search={props.filterTerm}>
          {col.renderPrimitive(g)}
        </HighlightText>
      ),
      comparator: ColumnConfig.simpleComparatorGenerator('trackingNumber', undefined, NULL_MUTATE),
    }),

    new ColumnConfig({
      title: 'Logged in on',
      renderPrimitive: g => moment(g.createdAt).format(Constants.PACKAGE_TABLE_DATETIME_FORMAT),
      render: (g, col) => <span className={g.isDeleted ? classes.lineThrough : null}>{col.renderPrimitive(g)}</span>,
      comparator: (a, b) => moment(a.createdAt) - moment(b.createdAt),
      isDefaultSort: true,
      sortingDirection: 'desc',
    }),

    new ColumnConfig({
      title: 'Picked up',
      render: g => {
        let isMarkedPuckedUp = props.recentlyPickedDeliveryIds.includes(g.id)

        return isMarkedPuckedUp ? (
          <div className={classes.checkInButtonConfirm} onClick={e => e.stopPropagation()}>
            <RXRIcon icon={RXRIcon.CHECK} size={RXRIcon.SIZE_LARGE} />
          </div>
        ) : (
          <RXRButton
            disabled={g.isDeleted}
            onClick={e => {
              e.stopPropagation()
              props.dispatchMarkedPickedUp(g.id).then()
            }}
          >
            Picked up
          </RXRButton>
        )
      },
    }),
  ]

  const handleBulkMarkPickedUp = () => {
    props.dispatchMarkPickedUp_Multiple(multiSelectSelectedArray.map(d => d.id)).then(() => setMultiSelectSelectedArray([]))
  }
  const numberOfPackagesSelected = multiSelectSelectedArray.length

  return (
    <React.Fragment>
      <SortableTable
        data={props.outgoingPackages}
        downloadFileName={`Outgoing Packages`}
        columns={columns}
        onClickRow={handleClickDelivery}
        rowHighlightCondition={g =>
          props.recentlyPickedDeliveryIds.includes(g.id) || multiSelectSelectedArray.map(d => d.id).includes(g.id)
        }
        hasMultiSelect={true}
        multiSelectSelectedArray={multiSelectSelectedArray}
        setMultiSelectSelectedArray={setMultiSelectSelectedArray}
      />
      {numberOfPackagesSelected > 0 ? (
        <TableMultiSelectToolBar>
          <Typography className={classes.multiSelectCopy}>{pluralizeWord('Package', numberOfPackagesSelected, true)} Selected</Typography>
          <div className={classes.multiSelectCTAs}>
            <RXRButton onClick={e => handleBulkMarkPickedUp()}>Picked up</RXRButton>
          </div>
        </TableMultiSelectToolBar>
      ) : null}
    </React.Fragment>
  )
}

OutgoingPackagesTable.propTypes = {
  outgoingPackages: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  recentlyPickedDeliveryIds: state.Delivery.recentlyPickedDeliveryIds,
})

const mapDispatchToProps = dispatch => ({
  dispatchMarkedPickedUp: deliveryId => markedPickedUp(dispatch, deliveryId),
  dispatchMarkPickedUp_Multiple: deliveryIds => markPickedUp_Multiple(dispatch, deliveryIds),
})

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(OutgoingPackagesTable)
