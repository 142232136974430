import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {loadPaymentMethodsForResident} from '../../lib/queries'
import SelectInput from '../SelectInput'

function SelectPaymentMethodsForResident(props) {
  const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])

  useEffect(() => {
    if (props.residentId) {
      setIsLoadingPaymentMethods(true)

      loadPaymentMethodsForResident(props.residentId)
        .then(r => {
          setPaymentMethods(r)
          setIsLoadingPaymentMethods(false)
        })
        .catch(err => {
          console.error(err)
          window.alert(err.message)
          setIsLoadingPaymentMethods(false)
        })
    } else {
      setPaymentMethods([])
    }
  }, [props.residentId])

  return (
    <SelectInput
      label={'Payment method'}
      isLoading={isLoadingPaymentMethods}
      disabled={!props.residentId || props.isDisabled}
      isRequired={true}
      value={props.paymentMethodId}
      onChange={val => props.onSelect(val)}
      error={props.hasError}
      options={paymentMethods.map(m => ({value: m.id, label: `${m.nickName} (${m.type})`}))}
    />
  )
}

SelectPaymentMethodsForResident.propTypes = {
  residentId: PropTypes.string,
  onSelect: PropTypes.func,
  paymentMethodId: PropTypes.string,
  isDisabled: PropTypes.bool,
  hasError: PropTypes.bool,
}

export default SelectPaymentMethodsForResident
