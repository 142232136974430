import React from 'react'
import {makeStyles} from '@material-ui/core'
import {spaceSmall} from '../../assets/styles/spacing'
import {rxrGreenColor, rxrOrangeColor, rxrRedColor, rxrWhiteColor} from '../../assets/styles/color'
import PropTypes from 'prop-types'

const chartWidth = 300
const chartHeight = 4
const borderRadius = 3

const useStyles = makeStyles(theme => ({
  npsScoreBreakdownChart: {
    marginTop: spaceSmall,
    position: 'relative',
    width: `${chartWidth}px`,
    backgroundColor: rxrWhiteColor,
    height: `${chartHeight}px`,
    borderRadius: `${borderRadius}px`,
  },

  npsScoreBreakdownOverlay: {
    position: 'absolute',
    display: 'flex',
    height: `${chartHeight}px`,
    width: `${chartWidth}px`,
    top: 0,
    left: 0,
  },

  npsScorePromoters: props => ({
    width: `${chartWidth * props.npsScorePercentagePromoters}px`,
    backgroundColor: rxrGreenColor,
    height: `${chartHeight}px`,
    borderRadius: `${borderRadius}px 0 0 ${borderRadius}px`,
  }),
  npsScorePassives: props => ({
    width: `${chartWidth * props.npsScorePercentagePassives}px`,
    backgroundColor: rxrOrangeColor,
    height: `${chartHeight}px`,
  }),
  npsScoreDetractors: props => ({
    width: `${chartWidth * props.npsScorePercentageDetractors}px`,
    backgroundColor: rxrRedColor,
    height: `${chartHeight}px`,
    borderRadius: `0 ${borderRadius}px ${borderRadius}px 0`,
  }),
}))

function NPSScoreBreakdownChart(props) {
  const npsScorePercentagePromoters = props.npsScorePercentagePromoters
  const npsScorePercentagePassives = props.npsScorePercentagePassives
  const npsScorePercentageDetractors = props.npsScorePercentageDetractors

  const classes = useStyles({
    npsScorePercentagePromoters,
    npsScorePercentagePassives,
    npsScorePercentageDetractors,
  })
  return (
    <div className={classes.npsScoreBreakdownChart}>
      <div className={classes.npsScoreBreakdownOverlay}>
        <div className={classes.npsScorePromoters}></div>
        <div className={classes.npsScorePassives}></div>
        <div className={classes.npsScoreDetractors}></div>
      </div>
    </div>
  )
}

NPSScoreBreakdownChart.propTypes = {
  npsScorePercentagePromoters: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  npsScorePercentagePassives: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  npsScorePercentageDetractors: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
}

export default NPSScoreBreakdownChart
