import React from 'react'
import PropTypes from 'prop-types'
import SortableTable, {ColumnConfig} from '../SortableTable'
import moment from 'moment'
import HighlightText from '../HighlightText'

const TYPE_ONE_TIME = 'One-time guest'
const TYPE_PERMANENT = 'Permanent guest'

const guestNameComparator = ColumnConfig.simpleComparatorGenerator('guestName')

function GuestLogTable(props) {
  const columns = [
    new ColumnConfig({
      title: 'Guest name',
      render: v => <HighlightText search={props.filterTerm}>{v.guestName}</HighlightText>,
      comparator: guestNameComparator,
    }),
    new ColumnConfig({
      title: 'Resident',
      render: v => <HighlightText search={props.filterTerm}>{v.residentName}</HighlightText>,
      comparator: ColumnConfig.simpleComparatorGenerator('residentName', guestNameComparator),
    }),
    new ColumnConfig({
      title: 'Unit',
      render: v => <HighlightText search={props.filterTerm}>{v.unitNumber}</HighlightText>,
      comparator: ColumnConfig.simpleComparatorGenerator('unitNumber', guestNameComparator),
    }),
    new ColumnConfig({
      title: 'Checked in on',
      render: v => moment(v.createdAt).format('MM/DD/YY, h:mma'),
      comparator: ColumnConfig.simpleComparatorGenerator('createdAt', guestNameComparator),
      isDefaultSort: true,
    }),
    new ColumnConfig({
      title: 'Type',
      render: v => (v.isPermanent ? TYPE_PERMANENT : TYPE_ONE_TIME),
      comparator: ColumnConfig.simpleComparatorGenerator('isPermanent'),
    }),
  ]
  
  return <SortableTable data={props.guestVisits} columns={columns} />
}

GuestLogTable.propTypes = {
  guestVisits: PropTypes.array.isRequired,
}

export default GuestLogTable
