import React, {useState, Fragment, useContext} from 'react'
import {Grid} from '@material-ui/core'
import RXRButton from '../../RXRButton'
import {makeStyles} from '@material-ui/core/styles'
import SidePanel from '../../SidePanel'
import useListCreateViewPattern from '../../hooks/useListCreateViewPattern'
import useFormChanged, {
  NonEmptyValidator,
  DateObjectValidator,
  NonEmptyArrayValidator,
  ConstructGreaterThanOtherDateValidator,
} from '../../hooks/useFormChanged'
import PromotionForm from './PromotionForm.js'
import DiscardChangesDialog from '../../DiscardChangesDialog'
import Routes from '../../../constants/RouteConstants'
import {spaceMedium} from '../../../assets/styles/spacing'
import {useHistory} from 'react-router-dom'
import {PromotionsContext} from './PromotionsContext'
import {useSelector} from 'react-redux'
import {Cleaning} from './PromotionConstants.js'

const EMPTY_FORM_STATE = {
  id: null,
  promotionTitle: '',
  vendorServiceTypes: [Cleaning],
  postingDate: null,
  expiryDate: null,
  discountType: null,
  discountAmount: null,
  description: '',
}
const useStyles = makeStyles(theme => ({
  cancelButton: {
    marginLeft: spaceMedium,
  },
}))
function CreateOrEditPromotion(props) {
  const classes = useStyles()
  const {promotionsLookup, createOrEditPromotion} = useContext(PromotionsContext)
  const [isSaving, setIsSaving] = useState(false)
  const history = useHistory()
  const {form, setForm, formChanged, resetInitialForm, invalidItems, validateForm} = useFormChanged(
    EMPTY_FORM_STATE,
    useFormChanged.PropLevelValidation({
      promotionTitle: NonEmptyValidator,
      vendorServiceTypes: NonEmptyArrayValidator,
      postingDate: DateObjectValidator,
      expiryDate: ConstructGreaterThanOtherDateValidator('postingDate'),
      discountType: NonEmptyValidator,
      discountAmount: NonEmptyValidator,
      description: NonEmptyValidator,
    }),
  )

  const resetForm = () => {
    resetInitialForm(EMPTY_FORM_STATE, hideSlidingPanel)
  }

  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)

  const {isCreateMode, focusedItemId} = useListCreateViewPattern(
    Routes.SETTINGS_PROMOTIONS_CREATE,
    Routes.SETTINGS_PROMOTIONS_VIEW_SINGLE,
    'promotionId',
    EMPTY_FORM_STATE,
    form,
    resetInitialForm,
    promotionsLookup,
    promotion => ({
      id: promotion.id,
      promotionTitle: promotion.title,
      vendorServiceTypes: promotion.vendorServiceTypes,
      postingDate: new Date(promotion.startedAt),
      expiryDate: new Date(promotion.expiredAt),
      discountType: promotion.discountType,
      discountAmount: promotion.amount,
      description: promotion.description,
    }),
    isSaving,
  )

  const hideSlidingPanel = () => {
    history.push(Routes.SETTINGS_PROMOTIONS)
  }

  const handleSubmit = async () => {
    // make sure the form is valid
    if (!validateForm()) {
      console.log('invalid Form')
      return
    }
    setIsSaving(true)
    try {
      let input = {
        title: form.promotionTitle,
        buildingId: activeBuildingId,
        vendorServiceTypes: form.vendorServiceTypes,
        startedAt: form.postingDate,
        expiredAt: form.expiryDate,
        discountType: form.discountType,
        amount: form.discountAmount,
        description: form.description,
      }

      if (form.id) {
        input.id = form.id
        await createOrEditPromotion(input)
      } else {
        await createOrEditPromotion(input)
      }
      setIsSaving(false)
      resetForm()
    } catch (er) {
      setIsSaving(false)
      console.error(er)
      alert('Sorry, we could not process this request. Please re-enter the promotion information.')
    }
  }
  return (
    <Fragment>
      <DiscardChangesDialog hasChanges={formChanged} />
      <SidePanel
        isOpen={!!focusedItemId || isCreateMode}
        onClose={hideSlidingPanel}
        title={isCreateMode ? 'Create a new promotion' : 'Edit promotion'}
      >
        <Fragment>
          <PromotionForm {...form} updateForm={setForm} invalidItems={invalidItems} />
          <Grid container spacing={6} style={{marginTop: 20}}>
            <Grid item>
              <RXRButton onClick={handleSubmit} isLoading={isSaving}>
                {isCreateMode ? 'Create Promotion' : 'Save Changes'}
              </RXRButton>
              <RXRButton
                type={RXRButton.TYPE_TEXT}
                className={classes.cancelButton}
                onClick={() => history.push(Routes.SETTINGS_PROMOTIONS)}
              >
                {'Cancel'}
              </RXRButton>
            </Grid>
          </Grid>
        </Fragment>
      </SidePanel>
    </Fragment>
  )
}

export default CreateOrEditPromotion
