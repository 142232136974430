import React, {useEffect, useState, Fragment} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core'
import {KYRLoaderScreen} from '../../assets/styles/layout'
import moment from 'moment'
import Loader from '../Loader'
import KYRDataField from './KYRDataField'
import {getOutgoingDelivery} from '../../lib/queries'
import {capitalizeFirstLetter} from '../../Utils/StringFormatter'
import TimelineEvent from './TimelineEvent'

const useStyles = makeStyles(theme => ({
  loader: {
    ...KYRLoaderScreen,
  },
}))

function OutgoingDelivery(props) {
  const classes = useStyles()
  const [deliveryInfo, setDeliveryInfo] = useState({})
  const [isDeliveryInfoLoading, setDeliveryInfoLoading] = useState(true)

  useEffect(() => {
    getOutgoingDelivery(props.deliveryId)
      .then(res => {
        setDeliveryInfo(res)
        setDeliveryInfoLoading(false)
      })
      .catch(err => console.error(err))
  }, [])

  const shippedAt = deliveryInfo.shippedAt ? `${moment(deliveryInfo.shippedAt).format('MMM Do YYYY, h:mm a')}` : ''
  const status = deliveryInfo.shippedAt ? 'Shipped' : 'Recieved'

  return (
    <Fragment>
      {isDeliveryInfoLoading ? (
        <Loader className={classes.loader} />
      ) : (
        <div>
          <KYRDataField title="Incoming or outgoing" data="Outgoing" />
          <KYRDataField title="Status" data={status} />
          <KYRDataField title="Package type" data={deliveryInfo.packageType && capitalizeFirstLetter(deliveryInfo.packageType)} />
          <KYRDataField title="Tracking number" data={deliveryInfo.trackingNumber} />
          <KYRDataField title="Carrier" data={deliveryInfo.carrier && capitalizeFirstLetter(deliveryInfo.carrier)} />
          <KYRDataField title="Picked up on" data={shippedAt} />
          <KYRDataField title="Notes" data={deliveryInfo.notes} />
          <TimelineEvent primaryRecordId={props.deliveryId} />
        </div>
      )}
    </Fragment>
  )
}

OutgoingDelivery.propTypes = {
  deliveryId: PropTypes.string.isRequired,
}

export default OutgoingDelivery
