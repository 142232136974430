import React, {useState, createContext, useEffect} from 'react'
import Loader from '../Loader'
import {BUILDING_CONFIG_FILE_NAME, S3_URL} from '../../constants/OnboardingConstants'
import {useSelector} from 'react-redux'

export const WhitelabelContext = createContext({})

function OnboardingWhitelabel(props) {
  const [whitelabel, setWhitelabel] = useState(null)
  const activeBuilding = useSelector(state => state.Buildings.buildingsLookup[state.Buildings.activeBuildingId])

  useEffect(() => {
    fetch(`${S3_URL}/${activeBuilding.yardiId}/${BUILDING_CONFIG_FILE_NAME}`)
      .then(response => response.json())
      .then(setWhitelabel)
      .catch(err => {
        console.error(err)
        window.alert(err.message)
      })
  }, [])

  return <WhitelabelContext.Provider value={whitelabel}>{whitelabel ? props.children : <Loader />}</WhitelabelContext.Provider>
}

export default OnboardingWhitelabel
