import React from 'react'
import {RXOTextField} from '../assets/styles'
import PropTypes from 'prop-types'

export default function MultilineTextInput(props) {
  let errorProp
  if (props.error && props.value === '') {
    errorProp = true
  } else {
    errorProp = false
  }

  const onChangeHandler = e => {
    let val = e.target.value
    if (val !== '') {
      setIsError(false)
    }
    if (val === '') {
      setIsError(true)
    }

    if (typeof props.maxLength === 'number') {
      val = val.slice(0, props.maxLength)
    }

    props.onChange(val)
  }

  const [isError, setIsError] = React.useState(false)
  return (
    <RXOTextField.FormTextField
      label={props.label}
      variant={'outlined'}
      required={props.isRequired}
      fullWidth
      placeholder={props.placeholder}
      helperText={
        props.helperText
          ? props.helperText
          : props.maxLength
          ? `${props.value ? props.value.length.toLocaleString() + ' / ' : ''}${props.maxLength.toLocaleString()} character limit`
          : undefined
      }
      onChange={onChangeHandler}
      value={props.value || ''}
      disabled={props.isDisabled}
      error={(isError && props.isRequired) || errorProp}
      multiline
      minRows={props.rows}
      maxRows={props.rowsMax}
      InputLabelProps={{
        shrink: true,
      }}
      className={`${props.className || ''}`}
      onKeyDown={props.onKeyDown}
    />
  )
}
MultilineTextInput.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool, // will append * to label
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  error: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  maxLength: PropTypes.number,
  onKeyDown: PropTypes.func,
}
