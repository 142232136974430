import {combineReducers} from 'redux'
import messagesReducer from './messagesReducer'
import guestAuthReducer from './guestAuthReducer'
import buildingReducer from './buildingsReducer'
import residentsReducer from './residentsReducer'
import occupanciesReducer from './occupanciesReducer'
import unitsReducer from './unitsReducer'
import groupPermissionsReducer from './groupPermissionsReducer'
import staffReducer from './staffReducer'
import authReducer from './authReducer'
import deliveryReducer from './deliveryReducer'
import amenitiesReducer from './amenitiesReducer'
import nudgesReducer from './nudgesReducer'
import feedbackReducer from './feedbackReducer'
import ActionTypes from '../actions/types'
import {
  CACHE_ActiveBuildingId,
  CACHE_NanPanelOpenStatus,
  CACHE_MuteSounds,
  CACHE_HidePopups,
  CACHE_SuperForecaster,
} from '../constants/AppConstants'
import kyrWidgetReducer from './kyrWidgetReducer'
import vendorAppointmentsReducer from './vendorAppointmentsReducer'
import appReducers from './appReducers'

const appReducer = combineReducers({
  /*
  debug: (state, action) => {
    console.log(state, action)
    return state || {}
  },
  */
  Auth: authReducer,
  App: appReducers,
  Amenities: amenitiesReducer,
  Buildings: buildingReducer,
  Appointments: vendorAppointmentsReducer,
  GuestAuth: guestAuthReducer,
  GroupPermissions: groupPermissionsReducer,
  Messages: messagesReducer,
  Residents: residentsReducer,
  Staff: staffReducer,
  Units: unitsReducer,
  Delivery: deliveryReducer,
  Nudges: nudgesReducer,
  KYRWidget: kyrWidgetReducer,
  Occupancies: occupanciesReducer,
  Feedback: feedbackReducer,
})

export default (state, action) => {
  if (action.type === ActionTypes._RESET_APP_STATE) {
    state = action.payload

    // also clear any cached items we have
    delete localStorage[CACHE_ActiveBuildingId]
    delete localStorage[CACHE_NanPanelOpenStatus]
    delete localStorage[CACHE_MuteSounds]
    delete localStorage[CACHE_HidePopups]
    delete localStorage[CACHE_SuperForecaster]
  }
  return appReducer(state, action)
}
