import React from 'react'
import PropTypes from 'prop-types'
import VendorAppointment from './VendorAppointment'

function DogWalking(props) {
  return (
    <VendorAppointment
      vendorAppointmentId={props.vendorAppointmentId}
      frequencyLabel={'One-time dog walking'}
      label={'Dog walking type'}
      timeframe={'Dog walking timeframe'}
    />
  )
}

DogWalking.propTypes = {
  vendorAppointmentId: PropTypes.string.isRequired,
}

export default DogWalking
