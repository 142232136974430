export const Cleaning = 'Cleaning'
export const DogWalking = 'DogWalking'
export const Massage = 'Massage'
export const PersonalTraining = 'PersonalTraining'

export const VENDOR_SERVICE_TYPE_LABELS = {
  [Cleaning]: 'Cleaning',
  [DogWalking]: 'Dog walking',
  [Massage]: 'Massage',
  [PersonalTraining]: 'Personal training',
}

export const Percentage = 'Percentage'
export const Amount = 'Fixed'

export const DISCOUNT_TYPE_LABLES = {
  [Percentage]: 'Percentage',
  [Amount]: 'Amount',
}

export const TAB_ACTIVE = 0
export const TAB_INACTIVE = 1
