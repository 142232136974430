import ActionTypes from '../actions/types'
import {CACHE_ActiveBuildingId} from '../constants/AppConstants'

const initialState = {
  buildingsLookup: {},
  activeBuildingId: undefined,
}

// --------------------------------------------------------------------------------
// State permutation functions:

function reduceBuildingsListToObj(state, buildingsList) {
  let buildingObj = buildingsList.reduce((obj, b) => {
    obj[b.building.id] = b.building
    return obj
  }, {})
  return {...state, buildingsLookup: buildingObj}
}

function setBuildingOOOStatus(state, buildingId, status, message) {
  let newBuildings = {...state.buildingsLookup}
  // clone the object so it forces a re-render
  newBuildings[buildingId] = {...newBuildings[buildingId], outOfOfficeStatus: status, outOfOfficeMessage: message}
  return {...state, buildingsLookup: newBuildings}
}

function setActiveBuildingById(state, buildingId) {
  if (buildingId) {
    localStorage[CACHE_ActiveBuildingId] = buildingId
  } else {
    delete localStorage[CACHE_ActiveBuildingId]
  }
  return {...state, activeBuildingId: buildingId}
}

// --------------------------------------------------------------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.BUILDINGS_UPDATE_ALL:
      return reduceBuildingsListToObj(state, action.payload)

    case ActionTypes.BUILDINGS_SET_VIEWING:
      return setActiveBuildingById(state, action.payload)

    case ActionTypes.BUILDINGS_SET_BUILDING_OOO:
      return setBuildingOOOStatus(state, action.payload.buildingId, action.payload.status, action.payload.message)

    default:
      return state
  }
}
