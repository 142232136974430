import PropTypes from 'prop-types'

/**
 * @typedef OnboardingTaskSubmission
 * @property {string} residentId
 * @property {string} state
 * @property {string} stateModifiedTimestamp
 * @property {string} stateModifiedBy
 * @property {Array<S3Object>?} attachments
 * + other task-submission-specific properties
 */

/**
 * @typedef OnboardingTaskForm
 * @property {Array<OnboardingTaskSubmission>} submissions
 * + other task-specific properties
 */

/**
 * @typedef OnboardingTaskStateHistory
 * @property {string} state
 * @property {string} timestamp
 * @property {string?} previousState
 * @property {string?} modifiedBy
 */

// These are the props that are passed to the specific TaskComponent (see ListSubmissionsForTaskIndex component)
export const FormComponentPropTypes = {
  onUpdateSubmission: PropTypes.func.isRequired,
  submission: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
}
