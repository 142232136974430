import React, {useState} from 'react'
import {spaceExtraSmall, spaceMedium, spaceSmall} from '../../../assets/styles/spacing'
import {makeStyles} from '@material-ui/core'
import moment from 'moment'
import {rxrLightGreyColor, rxrTealColor} from '../../../assets/styles/color'
import {borderRadiusMedium} from '../../../assets/styles/border'
import {NoteCardTitle, heavyFontWeight, lightFontWeight, BodyHeader} from '../../../assets/styles/typography'
import RXRIcon from '../../RXRIcon'
import CreateNewNote from './CreateNewNote'
import NoteCard from './NoteCard'
import {deleteStaffNote, updateStaffNote} from '../../../lib/queries'
import RXRButton from '../../RXRButton'
import PropTypes from 'prop-types'
import ConfirmDeleteDialog from '../../ConfirmDeleteDialog'
import {CRUD_ACTION_DELETE, CRUD_ACTION_UPDATE} from './constants'

function NoteThread(props) {
  const classes = useStyles()
  const [isDraftingReply, setIsDraftingReply] = useState(false)

  const [deleteNoteId, setDeleteNoteId] = useState(undefined)

  const handleDelete = async id => {
    if (deleteNoteId !== id) {
      setDeleteNoteId(id)
      return
    }

    let noteIsParent = props.thread.note.id === id
    try {
      if (noteIsParent) {
        await Promise.all(props.thread.children.map(child => deleteStaffNote(child.id)))
      }
      const deletedStaffNote = await deleteStaffNote(id)
      props.onSave(deletedStaffNote, CRUD_ACTION_DELETE)
    } catch (err) {
      console.error(err)
    }
  }

  const handleNotePin = async (noteId, isPinned) => {
    try {
      const input = {
        id: noteId,
        isPinned: !isPinned,
      }
      const updatedNote = await updateStaffNote(input)
      props.onSave(updatedNote, CRUD_ACTION_UPDATE)
    } catch (e) {
      
    }
  }

  return (
    <div className={classes.noteCardContainer}>
      <NoteCard
        key={props.thread.note.id}
        note={props.thread.note}
        onSave={props.onSave}
        handleDelete={handleDelete}
        handleNotePin={handleNotePin}
        noteIsParent={true}
      />
      <div className={classes.replyContainer}>
        {props.thread.children.length > 0 ? (
          <div className={classes.replyIcon}>
            <RXRIcon icon={RXRIcon.ARROW_TURN_DOWN_RIGHT} color={rxrTealColor} />
          </div>
        ) : null}
        <div className={classes.replies}>
          {props.thread.children
            .sort((x, y) => {
              return (
                moment(x.createdAt)
                  .toDate()
                  .valueOf() -
                moment(y.createdAt)
                  .toDate()
                  .valueOf()
              )
            })
            .map(child => {
              return <NoteCard key={child.id} note={child} onSave={props.onSave} handleDelete={handleDelete} />
            })}
        </div>
      </div>
      <div className={classes.footer}>
        {isDraftingReply ? (
          <React.Fragment>
            <CreateNewNote placeholder={'Type a reply'} staffNoteId={props.thread.note.id} onSave={props.onSave} useCompact={true} />
            <RXRButton type={RXRButton.TYPE_TEXT} onClick={() => setIsDraftingReply(false)}>
              cancel
            </RXRButton>
          </React.Fragment>
        ) : (
          <RXRButton type={RXRButton.TYPE_TEXT} onClick={() => setIsDraftingReply(true)}>
            Reply
          </RXRButton>
        )}
      </div>

      <ConfirmDeleteDialog
        isOpen={!!deleteNoteId}
        prompt={'Are you sure you want to delete this note?'}
        onConfirmDelete={() => handleDelete(deleteNoteId)}
        onCancel={() => {
          setDeleteNoteId(undefined)
        }}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  noteCardContainer: {
    width: '100%',
    paddingLeft: spaceMedium,
    paddingRight: spaceMedium,
    paddingTop: spaceMedium,
    paddingBottom: spaceMedium,
    backgroundColor: rxrLightGreyColor,
    marginBottom: spaceMedium,
    borderRadius: borderRadiusMedium,
  },

  titleContainer: {
    marginBottom: spaceExtraSmall,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  modifyNote: {
    marginLeft: 'auto',
    cursor: 'pointer',
  },

  authorName: {
    ...NoteCardTitle,
    fontWeight: heavyFontWeight,
  },

  dateTime: {
    ...NoteCardTitle,
    fontWeight: lightFontWeight,
  },

  body: {
    ...BodyHeader,
    marginBottom: spaceMedium,
  },

  replyContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },

  replyIcon: {
    width: '10%',
  },

  replies: {
    width: '90%',
  },

  footer: {},

  sendButton: {
    marginLeft: spaceSmall,
    flexGrow: 0,
    zIndex: 2,
    height: '54px',
    width: '80px',
    flexShrink: 0,
  },

  input: {
    width: '100%',
  },

  sendContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    marginBottom: spaceSmall,
  },
}))

NoteThread.propTypes = {
  thread: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default NoteThread
