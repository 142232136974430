import KYRVendorAppointmentComponent from './KYRVendorAppointmentComponent'
import {VENDOR_APPOINTMENT_TYPE_MASSAGE} from '../../KYRConstants'
import AbstractKYRScreen from '../AbstractKYRScreen'
import React from 'react'

export default class KYRMassageComponent extends AbstractKYRScreen {
  render() {
    return <KYRVendorAppointmentComponent type={VENDOR_APPOINTMENT_TYPE_MASSAGE} navParams={this.props.navParams} />
  }
}
