export const CategoryAppliance = 'Appliance'
export const CategoryBuildingExterior = 'Building Exterior'
export const CategoryDoorsLocks = 'Doors and Locks'
export const CategoryElectrical = 'Electrical'
export const CategoryLifeSafetySystems = 'Life Safety Systems'
export const CategoryFlooring = 'Flooring'
export const CategoryHeatingCooling = 'Heating and Cooling'
export const CategoryPlumbingBath = 'Plumbing and Bath'
export const CategoryGeneral = 'General'

export const CATEGORY_LABELS = {
  [CategoryAppliance]: 'Appliance',
  [CategoryBuildingExterior]: 'Building Exterior',
  [CategoryDoorsLocks]: 'Doors, Locks & Windows',
  [CategoryElectrical]: 'Electrical',
  [CategoryLifeSafetySystems]: 'Emergency Systems',
  [CategoryFlooring]: 'Flooring',
  [CategoryHeatingCooling]: 'Heating & Cooling',
  [CategoryPlumbingBath]: 'Plumbing & Bath',
  [CategoryGeneral]: 'Other',
}
