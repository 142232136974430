import React, {useState, useEffect, useRef} from 'react'
import GenericServicesPage from './GenericServicesPage'
import {VendorServiceTypeDogwalking} from './ServicesConstants'
import Routes from '../../constants/RouteConstants'
import SelectPetAddOns from './SelectPetAddOns'
import {createPetProfileVendorAppointment} from '../../lib/queries'

function DogwalkingPage() {
  /**
   * @param {VendorAppointmentFragment} newAppointment
   * @param {*} formData
   */
  const handleCreateNewDogWalk = (newAppointment, formData) => {
    return Promise.all(formData.metaPetProfileIds.map(pId => createPetProfileVendorAppointment(pId, newAppointment.id))).catch()
  }

  return (
    <GenericServicesPage
      serviceType={VendorServiceTypeDogwalking}
      title={'Dog Walking'}
      createNewCTA={'Book dog walking'}
      baseRoute={Routes.DOGWALKING}
      createNewRoute={Routes.DOGWALKING_CREATE}
      viewSingleRoute={Routes.DOGWALKING_VIEW_SINGLE}
      onCreate={handleCreateNewDogWalk}
      AddOnsComponent={SelectPetAddOns}
      extraFormValidation={{
        // must select at least 1 dog. If form has an id, then we know they're modifying an appointment and addOns can't be changed, so always valid
        // we use the `addOns` field since that's what the form will check to pass to its props.AddOnsComponent
        addOns: (addOns, form) => !!form.id || (Array.isArray(form.metaPetProfileIds) && form.metaPetProfileIds.length > 0),
      }}
    />
  )
}

export default DogwalkingPage
