import {Box, Grid, Typography} from '@material-ui/core'
import RxoLogo from '../../assets/images/Icon/RxoLogo.svg'
import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import BuildingImage from '../../assets/images/Icon/BuildingImage.png'
import {Colors, Spacing} from '../../assets/styles'
import PropTypes from 'prop-types'
import SimpleSpinner from '../SimpleSpinner'

function AuthPage(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container item className={classes.mainContainer}>
        <Grid item xs={4} className={classes.backgroundImage}>
          <img src={RxoLogo} className={classes.logo} />
        </Grid>
        <Grid item xs={8} className={classes.formContainer}>
          <Box className={classes.uiContainer}>
            {props.isLoading ? (
              <div style={{textAlign: 'center', width: '100%'}}>
                <SimpleSpinner size={SimpleSpinner.SIZE_LARGE} />
              </div>
            ) : (
              <div className={classes.innerContainer}>{props.children}</div>
            )}
          </Box>
          <Typography className={classes.trademark}>
            RXO and the RXO Home Logo are trademarks of Urban Solutions LLC, an affiliate of RXR Realty LLC.
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContainer: {
    minHeight: '442px',
    maxWidth: '613px',
    borderRadius: '12px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  backgroundImage: {
    backgroundImage: `url(${BuildingImage})`,
    backgroundSize: 'cover',
    textAlign: 'center',
    borderRadius: '12px 0px 0px 12px',
  },
  logo: {
    marginTop: '72px',
  },
  formContainer: {
    backgroundColor: Colors.rxrWhiteColor,
    borderRadius: '0px 12px 12px 0px',
  },
  trademark: {
    fontSize: '7.5px',
    lineHeight: '9px',
    letterSpacing: '-0.06px',
    color: Colors.rxrMediumGreyColor,
    textAlign: 'center',
  },
  uiContainer: {
    paddingTop: Spacing.spaceExtraLarge,
    display: 'flex',
    alignItems: 'center',
    height: `calc(100% - ${Spacing.spaceExtraLarge}px)`,
    marginBottom: Spacing.spaceMedium,
    position: 'relative',
  },
  innerContainer: {
    width: '100%',
    textAlign: 'center',
  },
}))

AuthPage.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
}

export default AuthPage
