import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core'
import {spaceMedium, spaceSmall} from '../../assets/styles/spacing'
import RXRIcon from '../RXRIcon'
import SimpleSpinner from '../SimpleSpinner'
import {rxrBlackColor, rxrDarkGreyColor, rxrTeal25to100} from '../../assets/styles/color'

function MetricCard(props) {
  const classes = useStyles()

  const hasMetric = typeof props.metric === 'number' || typeof props.metric === 'string'
  const hasDelta = typeof props.delta === 'number' || typeof props.delta === 'string'
  const hasRevenue = typeof props.revenue === 'number' || typeof props.revenue === 'string'

  return (
    <div className={classes.cardContainer}>
      <div className={classes.number}>{hasMetric ? props.metric.toLocaleString('en-US') : <SimpleSpinner />}</div>
      <div className={classes.label}>
        {props.label.split('\n').map((str, i) => {
          if (i > 0) {
            return (
              <React.Fragment key={str}>
                <br />
                {str}
              </React.Fragment>
            )
          }
          return str
        })}
      </div>
      <div className={classes.iconAndDelta}>
        <div className={classes.iconContainer}>
          <RXRIcon icon={props.icon} size={RXRIcon.SIZE_DEFAULT} />
        </div>
        {hasDelta && (
          <div className={classes.delta}>
            {<RXRIcon icon={props.delta < 0 ? RXRIcon.ARROW_LONG_DOWN : RXRIcon.ARROW_LONG_UP} size={RXRIcon.SIZE_SMALL} />}
            {'\n'}
            {Math.abs(parseInt(props.delta)).toLocaleString('en-US')}%
          </div>
        )}
      </div>
      {hasRevenue && <div className={classes.revenueContainer}>{`$${parseFloat(props.revenue).toLocaleString('en-US')}`}</div>}
    </div>
  )
}

const circleSize = 36

const useStyles = makeStyles(theme => ({
  cardContainer: {
    width: '100%',
    padding: spaceSmall,
    paddingLeft: spaceMedium,
    paddingRight: spaceMedium,
  },

  number: {
    lineHeight: '1em',
    fontSize: 44,
    fontWeight: 900,
    color: rxrBlackColor,
  },

  label: {
    textTransform: 'uppercase',
    marginTop: spaceSmall,
    marginBottom: spaceSmall,
    color: rxrBlackColor,
  },

  iconAndDelta: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: rxrBlackColor,
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: spaceSmall,
    backgroundColor: rxrTeal25to100,
    borderRadius: '50%',
    textAlign: 'center',
    height: circleSize,
    width: circleSize,
    color: rxrBlackColor,
  },

  icon: {},

  revenueContainer: {
    marginTop: spaceMedium,
    color: rxrDarkGreyColor,
  },
}))

MetricCard.propTypes = {
  metric: PropTypes.any,
  label: PropTypes.string,
  icon: PropTypes.string,
  delta: PropTypes.number,
  revenue: PropTypes.number,
}

export default MetricCard
