/**
 * @typedef AbstractDiscountFragment
 * @property {string} id
 * @property {string} title
 * @property {string} description
 * @property {number} amount
 * @property {string} discountType
 * @property {string|Date} expiredAt
 * @property {string} vendorAppointmentId
 * @property {Array<string>} vendorServiceTypes
 */

class VendorServiceDiscount {
  /**
   * @param {AbstractDiscountFragment} fragment
   * @param {string} type
   */
  constructor(fragment, type) {
    this.id = fragment.id
    this.title = fragment.title
    this.description = fragment.description
    this.amount = fragment.amount
    this.discountType = fragment.discountType
    this.promotionOrCredit = type
    this.expiredAt = new Date(fragment.expiredAt)
    this.vendorServiceTypes = fragment.vendorServiceTypes
    // if redeemed for an appointment...
    this.vendorAppointmentId = fragment.vendorAppointmentId
  }

  /**
   * @returns {string}
   */
  toString() {
    return VendorServiceDiscount.toShortLabel(this)
  }

  /**
   * @returns {{id: string, promotionOrCredit: string}}
   */
  toAppSyncInput() {
    return VendorServiceDiscount.toAppSyncInput(this)
  }

  // -------------------------------------------------------------------------------------
  // Static functions:

  /**
   * @param {AbstractDiscountFragment|VendorServiceDiscount|{discountType: string, amount: number}} obj
   */
  static getDiscountString(obj) {
    return obj.discountType === 'Fixed' ? `$${obj.amount}` : `${obj.amount}%`
  }

  /**
   * @param {AbstractDiscountFragment|VendorServiceDiscount} obj
   * @returns {string}
   */
  static toShortLabel(obj) {
    return `${obj.title} (${VendorServiceDiscount.getDiscountString(obj)})`
  }

  /**
   * @param {AbstractDiscountFragment|VendorServiceDiscount} obj
   * @param {number} basePrice
   * @returns {number}
   */
  static getDollarAmount(obj, basePrice) {
    return Math.max(0, obj.discountType === 'Fixed' ? obj.amount : parseFloat((basePrice * (obj.amount / 100)).toFixed(2)))
  }

  /**
   * @param {Array<VendorServiceDiscount>} discounts
   * @param {number} startingPrice
   * @returns {{
   *   startingPrice: number,
   *   discountContributions: Object<string, number>,
   *   finalCost: number
   * }}
   */
  static applyManyDiscounts(discounts, startingPrice) {
    // Sort the credits such that Fixed credits are first followed by Percentage credits
    discounts.sort((a, b) => {
      // Fixed applies before Percentage
      return a.discountType === b.discountType
        ? a.amount < b.amount
          ? -1
          : a.amount > b.amount
          ? 1
          : 0
        : a.discountType === 'Fixed'
        ? -1
        : 1
    })
    let finalCost = startingPrice
    const discountContributions = {}
    discounts.forEach(d => {
      const discountValue = VendorServiceDiscount.getDollarAmount(d, finalCost)
      discountContributions[d.id] = discountValue
      // prevent going below 0
      finalCost -= Math.min(discountValue, finalCost)
    })

    return {
      startingPrice: startingPrice,
      discountContributions: discountContributions,
      finalCost: finalCost,
    }
  }

  /**
   * @param {Array<VendorServiceDiscount>} discounts
   * @param {string} vendorServiceType
   * @returns {Array<VendorServiceDiscount>}
   */
  static filterDiscountsForServiceType(discounts, vendorServiceType) {
    return discounts.filter(d => Array.isArray(d.vendorServiceTypes) && d.vendorServiceTypes.includes(vendorServiceType))
  }

  /**
   * @param {VendorServiceDiscount} obj
   * @returns {{id: string, promotionOrCredit: string}}
   */
  static toAppSyncInput(obj) {
    return {
      id: obj.id,
      promotionOrCredit: obj.promotionOrCredit,
    }
  }
}

VendorServiceDiscount.TYPE_PROMOTION = 'PROMOTION'
VendorServiceDiscount.TYPE_CREDIT = 'CREDIT'

export default VendorServiceDiscount
