const STATES = {
  STATE_REQUESTED: 'REQUESTED',
  STATE_CONFIRMED: 'CONFIRMED',
  STATE_CONFIRMED_APPROVED: 'CONFIRMED_APPROVED',
  STATE_UNDERWAY: 'UNDERWAY',
  STATE_COMPLETED: 'COMPLETED',
  STATE_CANCELLED: 'CANCELLED',
  STATE_DECLINED: 'DECLINED',
  STATE_TIMEDOUT: 'TIMEDOUT',
}
export default {
  TAB_NEEDS_APPROVAL: 0,
  TAB_UPCOMING_RESERVATION: 1,
  TAB_PAST_RESERVATION: 2,
  PAGE_TITLE: 'Reservations',
  ENTER_SEARCH_KEYWORDS_CONSTANT: 'Search by resident, unit number or amenity...',
  BTN_SAVE_CHECKIN: 'Save and check in',
  BTN_SAVE: 'Save',
  BTN_CANCEL: 'Cancel',
  BTN_ADD: 'Make a reservation',
  BTN_DISCARD_CHANGES: 'Discard changes',
  ...STATES,
  RESERVATION_STATE_LABELS: {
    [STATES.STATE_REQUESTED]: 'Pending approval',
    [STATES.STATE_CONFIRMED]: 'Confirmed',
    [STATES.STATE_CONFIRMED_APPROVED]: 'Approved',
    [STATES.STATE_UNDERWAY]: 'Underway',
    [STATES.STATE_COMPLETED]: 'Completed',
    [STATES.STATE_CANCELLED]: 'Cancelled',
    [STATES.STATE_DECLINED]: 'Declined',
    [STATES.STATE_TIMEDOUT]: 'Timed Out',
  },
  BTN_APPROVE_REQUEST: 'Approve request',
  BTN_SAVE_AND_APPROVE_REQUEST: 'Save and approve request',
  BTN_UPDATE_REQUEST: 'Update request',
  BTN_DENY_REQUEST: 'Deny request',
  BTN_CHAT_RESIDENT: 'Chat resident',
  BTN_VIEW_PROFILE: 'View profile',
  BTN_DELETE: 'Delete',
  RESERVATION_TIME_FORMAT: 'h:mma',
  RESERVATION_DATE_FORMAT: 'MMM DD, YYYY',
  RESERVATION_CALENDAR: 'Reservation Calendar',
  ELEVATOR_RESERVATION_CALENDAR: 'Elevators',
  PAGE_TITLE_AMENITY_MANAGER: 'Amenities Manager',
  BTN_ADD_AMENITY_MANAGER: 'Create new amenity',
  ACTIONS_MENU_EDIT_AMENITY: 'Edit amenity',
  ACTIONS_MENU_VIEW_RESERVATIONS: 'View reservations',
  ACTIONS_MENU_BOOK_FOR_TENANT: 'Book for tenant',
  NO_AMENITY_MESSAGE: 'There are currently no amenity. Select “Create new amenity” to create a new amenity.',

  ALL_FIELDS_REQUIRED: '* All starred fields are required.',

  TITLE_RESTRICTIONS: 'Restrictions',
  TITLE_CLOSURES: 'Block off amenity bookings',
  TITLE_RULES: 'Rules for amenity',
  TITLE_DETAILS: 'Details',

  NEEDS_APPROVAL_OPTIONS: [{value: 'false', label: "Doesn't need approval"}, {value: 'true', label: 'Needs approval'}],

  MINIMUM_RESERVATION_OPTIONS: [
    {value: '15', label: '15 minutes'},
    {value: '30', label: '30 minutes'},
    {value: '60', label: '1 hour'},
    {value: '120', label: '2 hours'},
    {value: '180', label: '3 hours'},
  ],
  daysInAWeek: [...new Array(7)].map((e, i) => i), // just an array from 0 -> 6
}
