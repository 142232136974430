import React, {useState} from 'react'
import {Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Constant, {CATEGORY_USER_TYPE, PERMISSION_TO_LABEL_MAP} from './SettingsConstants'
import {Typography as TypographyStyle, Colors} from './../../assets/styles'
import TextInput from '../TextInput'
import {spaceMedium, spaceSmall} from '../../assets/styles/spacing'
import RXRButton from '../RXRButton'
import SelectInput from '../SelectInput'
import {convertKeyLabelMapIntoSelectOptions} from '../../Utils/objectUtil'
import {resendStaffInvite} from '../../lib/queries'
import {useSelector} from 'react-redux'
import {getPermissionObjectForPermissionGroup} from '../../reducers/groupPermissionsReducer'
import RXRIcon from '../RXRIcon'
import {PERMISSION_GROUP_FORMER_USER, PERMISSION_GROUP_SUPER_ADMIN} from '../../constants/ModelConstants'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  formRow: {
    marginBottom: spaceMedium,
  },
  heading: {
    color: Colors.rxrRedColor,
  },
  permissionsSubTitle: {
    fontSize: TypographyStyle.fontSizeSmall,
    color: Colors.rxrBlueColor,
  },
  inviteButton: {
    height: 55,
    flexShrink: 0,
    marginLeft: spaceMedium,
  },
  inviteRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },

  permissionItem: {
    marginTop: spaceSmall,
  },
  permissionIcon: {
    marginRight: spaceSmall,
  },
  permissionLabel: {
    fontSize: TypographyStyle.fontSizeMediumLarge,
  },
}))

export default function UserForm(props) {
  const [isSendingInvite, setIsSendingInvite] = useState(false)
  const classes = useStyles()
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const isCreateMode = !props.id
  const isEditingASuperAdminOrFormerUser = !isCreateMode && (props.userType === PERMISSION_GROUP_SUPER_ADMIN || props.isDisabled)

  const selectUserTypeOptions =
    // If we are creating a new user or if we are editing a user that is not a super admin or former user, then we should filter out the super admin and former user options
    isCreateMode || (!isCreateMode && props.userType !== PERMISSION_GROUP_SUPER_ADMIN && !props.isDisabled)
      ? convertKeyLabelMapIntoSelectOptions(CATEGORY_USER_TYPE).filter(
          option => option.value !== PERMISSION_GROUP_SUPER_ADMIN && option.value !== PERMISSION_GROUP_FORMER_USER,
        )
      : // If we are editing a user that is a former user, then we should filter out the super admin option
      !isCreateMode && props.isDisabled
      ? convertKeyLabelMapIntoSelectOptions(CATEGORY_USER_TYPE).filter(option => option.value !== PERMISSION_GROUP_SUPER_ADMIN)
      : // If we are editing a user that is a super admin, then we should filter out the former user option
      !isCreateMode && props.userType === PERMISSION_GROUP_SUPER_ADMIN
      ? convertKeyLabelMapIntoSelectOptions(CATEGORY_USER_TYPE).filter(option => option.value !== PERMISSION_GROUP_FORMER_USER)
      : // We should never get here, but just in case, we will filter out the super admin and former user options
        convertKeyLabelMapIntoSelectOptions(CATEGORY_USER_TYPE).filter(
          option => option.value !== PERMISSION_GROUP_SUPER_ADMIN && option.value !== PERMISSION_GROUP_FORMER_USER,
        )

  const emailTextInput = (
    <TextInput
      label="Email Address"
      placeholder=""
      value={props.email}
      maxLength={120}
      onChange={val => props.updateForm({email: val})}
      isRequired={true}
      error={props.invalidItems.includes('email')}
      isDisabled={isEditingASuperAdminOrFormerUser}
    />
  )

  const handleResendInvite = () => {
    setIsSendingInvite(true)
    resendStaffInvite(props.email, props.staffName, activeBuildingId)
      .then(() => {
        window.alert('Invite email has been sent successfully')
      })
      .catch(err => {
        window.alert('Failed to send invite email: ' + err.message)
      })
      .finally(() => {
        setIsSendingInvite(false)
      })
  }

  const permissionsObject = getPermissionObjectForPermissionGroup(props.userType)

  return (
    <div className={classes.root}>
      {isCreateMode && (
        <Grid container spacing={3} className={classes.formRow}>
          <Grid item xs={12}>
            <Typography className={classes.heading}>{Constant.ALL_FIELDS_REQUIRED}</Typography>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={3} className={classes.formRow}>
        <Grid item xs={12}>
          <TextInput
            label="Staff name"
            placeholder=""
            maxLength={120}
            onChange={val => props.updateForm({staffName: val})}
            value={props.staffName}
            isRequired={true}
            isDisabled={isEditingASuperAdminOrFormerUser}
            error={props.invalidItems.includes('staffName')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.formRow}>
        <Grid item xs={12}>
          {!isCreateMode ? (
            <div className={classes.inviteRow}>
              {emailTextInput}
              <RXRButton
                isLoading={isSendingInvite}
                className={classes.inviteButton}
                type={RXRButton.TYPE_SECONDARY}
                onClick={handleResendInvite}
                disabled={isEditingASuperAdminOrFormerUser}
              >
                {'Resend invite'}
              </RXRButton>
            </div>
          ) : (
            emailTextInput
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.formRow}>
        <Grid item xs={6}>
          <SelectInput
            label={'User Type'}
            value={props.isDisabled ? PERMISSION_GROUP_FORMER_USER : props.userType}
            onChange={val => props.updateForm({userType: val})}
            options={selectUserTypeOptions}
            error={props.invalidItems.includes('userType')}
            disabled={isEditingASuperAdminOrFormerUser}
          />
        </Grid>
      </Grid>
      <div className={classes.permissionsSubTitle}>{'Permissions'}</div>
      <Grid container spacing={3} className={classes.formRow}>
        <Grid item xs={12}>
          {Object.entries(PERMISSION_TO_LABEL_MAP).map(([key, label]) => (
            <div key={key} className={classes.permissionItem} style={permissionsObject[key] ? undefined : {textDecoration: 'line-through'}}>
              <RXRIcon
                className={classes.permissionIcon}
                icon={permissionsObject[key] ? RXRIcon.CHECK : RXRIcon.TODO}
                color={Colors.rxrBlueColor}
              />
              <span className={classes.permissionLabel}>{label}</span>
            </div>
          ))}
        </Grid>
      </Grid>
    </div>
  )
}
