import React, {useState, useEffect, Fragment} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {RXRButton} from '../RXRButton'
import {useDispatch, useSelector} from 'react-redux'
import Routes from '../../constants/RouteConstants'
import {withRouter} from 'react-router-dom'
import PageHeader from '../PageHeader'
import {Grid} from '@material-ui/core'
import {AntTab, AntTabs} from '../AntTabs'
import ClearableInput from '../ClearableInput'
import ActiveGuestsTable from './ActiveGuestsTable'
import moment from 'moment'
import GuestLogTable from './GuestLogTable'
import GuestAuthorizationForm from './GuestAuthorizationForm'
import {isToday} from '../../Utils/dateTimeUtil'
import Constant from './GuestAuthorizationConstants'
import DiscardChangesDialog from '../DiscardChangesDialog'
import EmptyStateIconAndMessage from '../EmptyStateIconAndMessage'
import {rxrBlueColor} from '../../assets/styles/color'
import DateRangeInput from '../DateRangeInput'
import Loader from '../Loader'
import useFormChanged, {DateObjectValidator, NonEmptyValidator} from '../hooks/useFormChanged'
import {checkInGuest} from '../../actions/guestAuthActions'

import {fetchGuestVisits, fetchAllGuestPasses, createNewGuest, updateGuest} from '../../lib/queries'
import {PageContainer} from '../../assets/styles/layout'
import SidePanel from '../SidePanel'
import useResidentLookup from '../hooks/useResidentLookup'
import useBuildingDataRefresh from '../hooks/useBuildingDataRefresh'
import useListCreateViewPattern from '../hooks/useListCreateViewPattern'
import RXRIcon from '../RXRIcon'
import {OCCUPANCY_STATUS_PAST} from '../../constants/ModelConstants'

const useStyles = makeStyles(theme => ({
  cursorHand: {
    cursor: 'pointer',
  },
  dateFilterContainer: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',

    '& .MuiInput-underline:before': {
      display: 'block',
    },
  },
  placeSearch: {
    maxWidth: '420px',
    borderBottom: '0px',
  },

  selectGuestCloseIcon: {
    textAlign: 'right',
  },
  peopleIconContainer: {
    fontSize: 80,
    lineHeight: 0,
    color: rxrBlueColor,
  },
}))

const TAB_ACTIVE_PASSES = 0
const TAB_GUEST_LOG = 1

const EMPTY_FORM_STATE = {
  id: null,
  firstName: null,
  lastName: null,
  residentId: null,
  unitId: null,
  guestRegistrationExpiry: null,
  meetingOption: Constant.MEETING_OPTIONS[0].value,
  specialInstructions: null,
}

function ViewGuestAuthorizationsPage(props) {
  const classes = useStyles()
  const [tab, setTab] = useState(TAB_ACTIVE_PASSES)
  const [filterTerm, setFilterTerm] = useState('')
  const [filterDateStart, setFilterDateStart] = useState(null)
  const [filterDateEnd, setFilterDateEnd] = useState(null)
  const [activeGuests, setActiveGuests] = useState([])
  const [guestVisits, setGuestVisits] = useState([])
  const {form, setForm, formChanged, resetInitialForm, invalidItems, validateForm} = useFormChanged(
    EMPTY_FORM_STATE,
    useFormChanged.PropLevelValidation({
      firstName: NonEmptyValidator,
      lastName: NonEmptyValidator,
      residentId: NonEmptyValidator,
      unitId: NonEmptyValidator,
      guestRegistrationExpiry: DateObjectValidator,
      meetingOption: NonEmptyValidator,
    }),
  )
  const {getResident} = useResidentLookup()
  const {activeBuildingId, isLoadingData: isLoadingGuest} = useBuildingDataRefresh(async () => {
    setActiveGuests([])
    setGuestVisits([])
    settingGuestData()
  })

  const recentlyCheckedInGuestIds = useSelector(state => state.GuestAuth.recentlyCheckedInGuestIds)
  const dispatch = useDispatch()

  const today = moment()
    .startOf('day')
    .format()

  function settingGuestData() {
    Promise.all([fetchAllGuestPasses(activeBuildingId), fetchGuestVisits(activeBuildingId)])
      .then(results => {
        setActiveGuests(
          results[0].filter(g => {
            const resident = getResident(g.residentId)
            return resident.occupancy?.status !== OCCUPANCY_STATUS_PAST && resident.statusOverride !== OCCUPANCY_STATUS_PAST
          }),
        )
        setGuestVisits(results[1])
      })
      .catch(err => {
        // TODO: Better error handling
        console.error(err)
      })
  }

  useEffect(() => {
    // after one [or many] checkins occur, we wait for the recently-checked-in-queue to empty and then update our guest visits log
    if (recentlyCheckedInGuestIds.length === 0) {
      fetchGuestVisits(activeBuildingId)
        .then(setGuestVisits)
        .catch(err => console.error(err))
    }
  }, [activeBuildingId, recentlyCheckedInGuestIds])

  let activeGuestsFiltered = []
  let guestVisitsFiltered = []
  const formattedStartDate = filterDateStart ? filterDateStart.toISOString() : null
  const formattedEndDate = filterDateEnd ? filterDateEnd.toISOString() : null
  const formattedTerm = filterTerm.toLowerCase()

  // if we're on the Active Passes tab
  if (tab === TAB_ACTIVE_PASSES) {
    // then we filter the activeGuests array according to the search terms
    activeGuestsFiltered = activeGuests.filter(g => {
      const resident = getResident(g.residentId)
      return (
        g.registrationExpiry >= today &&
        (!formattedStartDate || g.registrationExpiry >= formattedStartDate) &&
        (!formattedEndDate || g.registrationExpiry <= formattedEndDate) &&
        (g.firstName.toLowerCase().includes(formattedTerm) ||
          g.lastName.toLowerCase().includes(formattedTerm) ||
          resident.displayName.toLowerCase().includes(formattedTerm) ||
          resident.occupancy.unit.number.toLowerCase().includes(formattedTerm))
      )
    })
  } else {
    guestVisitsFiltered = guestVisits.filter(v => {
      return (
        (!formattedStartDate || v.createdAt >= formattedStartDate) &&
        (!formattedEndDate || v.createdAt <= formattedEndDate) &&
        (v.guestName.toLowerCase().includes(formattedTerm) ||
          v.residentName.toLowerCase().includes(formattedTerm) ||
          v.unitNumber.toLowerCase().includes(formattedTerm))
      )
    })
  }

  // --------------------------------------------------------------------------------------------
  // Guest Editing stuff

  const {isCreateMode, focusedItemId} = useListCreateViewPattern(
    Routes.GUESTAUTHORIZATION_CREATE,
    Routes.GUESTAUTHORIZATION_VIEW_SINGLE,
    'guestId',
    EMPTY_FORM_STATE,
    form,
    resetInitialForm,
    activeGuests,
    guest => ({
      id: guest.id,
      firstName: guest.firstName,
      lastName: guest.lastName,
      residentId: guest.residentId,
      unitId: guest.unitId,
      guestRegistrationExpiry: moment(guest.registrationExpiry).toDate(),
      specialInstructions: guest.instructions,
      meetingOption: guest.meetingOption,
    }),
    isLoadingGuest,
  )

  const hideSlidingPanel = () => {
    props.history.push(Routes.GUESTAUTHORIZATION)
  }

  /**
   * @param {*} input
   * @returns {Promise<*>}
   */
  async function handleCreateNewGuest(input) {
    return await createNewGuest(input)
  }

  const createGuest = includeCheckin => {
    if (!validateForm()) {
      return
    }

    const input = {
      firstName: form.firstName,
      lastName: form.lastName,
      registrationExpiry: form.guestRegistrationExpiry,
      meetingOption: form.meetingOption,
      instructions: form.specialInstructions,
      residentId: form.residentId,
      unitId: form.unitId,
      buildingId: activeBuildingId,
    }

    try {
      handleCreateNewGuest(input)
        .then(async result => {
          const guest = result
          if (includeCheckin) {
            try {
              await checkInGuest(dispatch, guest, getResident(guest.residentId))
            } catch (err) {
              console.error(err)
              window.alert('Failed to check new guest in, please try again from the Guest Auth page')
            }
          }
          return guest
        })
        .then(() => {
          settingGuestData()
          resetInitialForm(EMPTY_FORM_STATE, hideSlidingPanel)
        })
    } catch (er) {
      console.error(er)
      window.alert('Sorry, we could not process this request. Please re-enter the guest information.')
    }
  }

  /**
   * @param {boolean} includeCheckin
   * @returns {Promise<void>}
   */
  const updateGuestRecord = async includeCheckin => {
    try {
      const guest = await pushUpdateGuestRecord(form)

      if (includeCheckin) {
        await checkInGuest(dispatch, guest, getResident(guest.residentId))
      }

      // reload to reflect these changes to the table
      let newAllGuestsData = await fetchAllGuestPasses(activeBuildingId)
      setActiveGuests(newAllGuestsData)

      resetInitialForm(EMPTY_FORM_STATE, hideSlidingPanel)
    } catch (er) {
      console.error(er)
      // TODO: Better error handling
      window.alert(er.message)
    }
  }

  // --------------------------------------------------------------------------------------------

  let visibleTableComponent

  if (tab === TAB_ACTIVE_PASSES) {
    if (activeGuests.length === 0) {
      visibleTableComponent = (
        <EmptyStateIconAndMessage
          message={'There are currently no active guest passes. Select “Add new guest” to create a new pass.'}
          icon={RXRIcon.GUEST_AUTH}
        />
      )
    } else {
      visibleTableComponent = <ActiveGuestsTable activeGuests={activeGuestsFiltered} filterTerm={filterTerm} />
    }
  } else {
    if (guestVisits.length === 0) {
      visibleTableComponent = (
        <EmptyStateIconAndMessage
          message={'No guests have been checked in. Check in a guest from the Active passes tab.'}
          icon={RXRIcon.GUEST_AUTH}
        />
      )
    } else {
      visibleTableComponent = <GuestLogTable guestVisits={guestVisitsFiltered} filterTerm={filterTerm} />
    }
  }

  if (isLoadingGuest) {
    return <Loader />
  }

  return (
    <div style={PageContainer}>
      <PageHeader
        title={'Guest Authorization'}
        rightControlComponent={
          <RXRButton type={RXRButton.TYPE_LARGE} onClick={() => props.history.push(Routes.GUESTAUTHORIZATION_CREATE)}>
            {Constant.BTN_ADD}
          </RXRButton>
        }
      >
        <Grid container spacing={3}>
          <Grid item lg={6} md={6}>
            <div className={classes.placeSearch}>
              <ClearableInput value={filterTerm} onChange={setFilterTerm} placeholder={'Search by resident, guest, or unit number...'} />
            </div>
          </Grid>

          <Grid item lg={6} md={6}>
            <div className={classes.dateFilterContainer}>
              <DateRangeInput
                onChange={(start, end) => {
                  setFilterDateStart(start)
                  setFilterDateEnd(end)
                }}
                startDate={filterDateStart}
                endDate={filterDateEnd}
              />
            </div>
          </Grid>
        </Grid>

        <AntTabs value={tab} onChange={(e, t) => setTab(t)}>
          <AntTab label="Active passes" />
          <AntTab label="Guest log" />
        </AntTabs>
      </PageHeader>

      <DiscardChangesDialog hasChanges={formChanged} />

      <div style={{height: '100%'}}>{visibleTableComponent}</div>

      <SidePanel isOpen={isCreateMode || !!focusedItemId} onClose={hideSlidingPanel} title={isCreateMode ? 'New guest' : 'Guest'}>
        <GuestAuthorizationForm {...form} updateForm={setForm} invalidItems={invalidItems} />
        <Grid container spacing={2} alignItems="center">
          {isCreateMode ? (
            <Fragment>
              <Grid item>
                <RXRButton disabled={!isToday(form.guestRegistrationExpiry)} onClick={() => createGuest(true)}>
                  {Constant.BTN_SAVE_CHECKIN}
                </RXRButton>
              </Grid>
              <Grid item>
                <RXRButton type={RXRButton.TYPE_SECONDARY} onClick={() => createGuest(false)}>
                  {Constant.BTN_SAVE}
                </RXRButton>
              </Grid>
            </Fragment>
          ) : (
            <Fragment>
              <Grid item>
                <RXRButton disabled={!formChanged || !isToday(form.guestRegistrationExpiry)} onClick={() => updateGuestRecord(true)}>
                  {Constant.BTN_SAVE_CHECKIN}
                </RXRButton>
              </Grid>
              <Grid item>
                <RXRButton type={RXRButton.TYPE_SECONDARY} disabled={!formChanged} onClick={() => updateGuestRecord(false)}>
                  {Constant.BTN_SAVE}
                </RXRButton>
              </Grid>
            </Fragment>
          )}

          <Grid item className={classes.cursorHand}>
            <RXRButton type={RXRButton.TYPE_TEXT} onClick={hideSlidingPanel}>
              {Constant.BTN_CANCEL}
            </RXRButton>
          </Grid>
        </Grid>
      </SidePanel>
    </div>
  )
}

// ------------------------------------------------------------------------------------------------------------
// GraphQL Queries & Mutations
// ------------------------------------------------------------------------------------------------------------

async function pushUpdateGuestRecord(form) {
  let input = {
    id: form.id,
    residentId: form.residentId,
    unitId: form.unitId,
    firstName: form.firstName,
    lastName: form.lastName,
    registrationExpiry: form.guestRegistrationExpiry,
    instructions: form.specialInstructions,
    meetingOption: form.meetingOption,
  }
  return await updateGuest(input)
}

export default withRouter(ViewGuestAuthorizationsPage)
