import {rxrBlueColor} from '../../assets/styles/color'
import {spaceLarge} from '../../assets/styles/spacing'

export const heading = {
  fontSize: '26px',
  letterSpacing: '1.3px',
  color: rxrBlueColor,
  textAlign: 'center',
  textTransform: 'uppercase',
  marginBottom: spaceLarge,
}

export const inputRow = {
  margin: '0 auto',
  marginTop: spaceLarge,
  width: '66%',
}
