import {useSelector} from 'react-redux'

/**
 * @typedef {Object} RichResidentObject
 * @property {string} id
 * @property {string} displayName
 * @property {Object} occupancy
 * @property {Object} occupancy.unit
 * @property {string} occupancy.unit.unitBuildingId
 * @property {string} occupancy.unit.number
 * // optional props
 * @property {string?} moveInDate
 * @property {string?} moveOutDate
 * @property {string?} phoneNumber
 * @property {string?} referenceId
 * @property {string?} secondaryReferenceId
 * @property {string?} statusOverride
 * @property {string?} occupancy.status
 * @property {string?} occupancy.leaseToDate
 * @property {string?} occupancy.leaseFromDate
 */

function useResidentLookup() {
  const residentsLookup = useSelector(state => state.Residents.residentsLookup)

  /**
   * @param {string} residentId
   * @return {RichResidentObject}
   */
  const getResident = residentId => {
    let resident = residentsLookup[residentId]

    // return an empty object if not found
    return resident
      ? resident
      : {
          displayName: '',
          occupancy: {
            unit: {
              unitBuildingId: '',
              number: '',
            },
          },
        }
  }

  return {
    getResident,
  }
}

export default useResidentLookup
