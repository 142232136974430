/*This Custom Hook provides Form Change detection functionality to any Component,the receiving component then can use the formChanged
state supplied by this hook to make buttons disabled or show Discard Changes dialog based on whether changes have been made
or not.

This provides an object with 5 fields -
form -  the form state object
setForm - Method to set form state
formChanged - True if form is changed,false if not
resetInitialForm - Method which takes a form object just before we want to detect changes from,all
                       further change detections for current form will be made by comparing new form sting to
                       this inital string
*/

import {useState, useRef, useEffect} from 'react'

//Method to convert empty String values to null,so that while comparing ,there is a standard
//and false change detections do not occur
function emptyStringToNull(form) {
  for (let [key, value] of Object.entries(form)) {
    form[key] = !value ? null : value
  }
  return form
}

/**
 * TODO could maybe look at shallowEqual to do this?
 * @param {*} obj1
 * @param {*} obj2
 * @return {Array<string>}
 */
function findDifferentProps(obj1, obj2) {
  const allKeys = [...new Set(Object.keys(obj1).concat(Object.keys(obj2)))]

  return allKeys.filter(k => {
    let value1 = obj1[k]
    let value2 = obj2[k]

    return JSON.stringify(value1) !== JSON.stringify(value2)
  })
}

/**
 * @param {*} emptyFormState
 * @param {function?} validationFunction -- this function should be invoked with a form object and return an array of invalid prop names
 * @return {{
      form: *,
      setForm: function,
      formChanged: boolean,
      changedItems: Array<string>,
      validateForm: function,
      invalidItems: Array<string>,
      resetInitialForm: function,
    }}
 */
function useFormChanged(emptyFormState, validationFunction) {
  let initialFormString = useRef(emptyStringToNull({...emptyFormState}))
  const [form, setFormInternal] = useState(emptyFormState)
  const [formChanged, setFormChanged] = useState(false)
  const [changedItems, setChangedItems] = useState([])
  const [invalidItems, setInvalidItems] = useState([])

  const resetInitialForm = (initialForm, callback) => {
    let newForm = {...initialForm}
    initialFormString.current = emptyStringToNull(newForm)
    setInvalidItems([])
    setFormInternal(newForm)

    // this is here so that we give the implementing component time to recognize the formChanged is now false
    // before invoking the callback.
    setTimeout(callback, 50)
  }

  // this allows us to update only a single prop at a time (similar to how setState({...}) works)
  const setForm = newObj => {
    setFormInternal(oldForm => ({...oldForm, ...newObj}))
  }

  /**
   * returns true IFF the entire form is valid
   * @returns {boolean}
   */
  const validateForm = () => {
    if (typeof validationFunction !== 'function') {
      throw new Error('Cannot validate form, missing validation function')
    }

    let invalid = validationFunction(form)

    setInvalidItems(invalid)
    return invalid.length === 0
  }

  useEffect(() => {
    let updatedForm = emptyStringToNull({...form})

    const diffItems = findDifferentProps(updatedForm, initialFormString.current)

    setFormChanged(diffItems.length > 0)
    setChangedItems(diffItems)
  }, [form])

  return {
    form,
    setForm,
    formChanged,
    changedItems,
    validateForm,
    invalidItems,
    resetInitialForm,
  }
}

/**
 * @param {*} validationObject - every key of this object should match a required field on the form, and the value should be a function that returns true IFF the form value is valid for that prop
 * @returns {function(*): [string, unknown][]}
 * @constructor
 */
useFormChanged.PropLevelValidation = function(validationObject) {
  return form => {
    return Object.entries(validationObject)
      .filter(([prop, validator]) => {
        return !validator(form[prop], form, validationObject)
      })
      .map(([key]) => key)
  }
}

export default useFormChanged

// ------------------------------------------------------------------------------------------
// TODO: Maybe these should go into their own files??
// VALIDATORS
// all validators should be invoked with 2 arguments:
//   1. The value they're validating
//   2. The form they belong to (in case validation is relative to other values)
//   3. The validation objects itself (in case validation is relative to other props validation)
// and should return true IFF the data is valid
// ------------------------------------------------------------------------------------------

/**
 * @param {Array<function>} validators
 * @returns {(function(*))|*}
 * @constructor
 */
export function MuxValidator(validators) {
  // every will break as soon as one does not validate, so the order is important
  return (value, form) => validators.every(validator => validator(value, form))
}

// true IFF the value is non empty or 0
export const NonEmptyValidator = value => !!value || value === 0

// true IFF the value is a string at least 1 non whitespace character
export const NonEmptyStringValidator = value => typeof value === 'string' && value.trim().length > 0

export const URLValidator = value =>
  NonEmptyStringValidator(value) &&
  new RegExp(
    '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?',
  ).test(value)

// true IFF the value is a number
export const NumberValidator = value => !isNaN(parseInt(value))

// true IFF the value is an array with at least 1 element
export const NonEmptyArrayValidator = value => Array.isArray(value) && value.length > 0

// true IFF the value is a date object
export const DateObjectValidator = value => value instanceof Date

// true IFF the value is a string that can be parsed into a date object
export const DateStringValidator = value => typeof value === 'string' && !isNaN(Date.parse(value))

// will return a date object validator that compares the value to another form value and returns true IFF this date is greater than that other date
export function ConstructGreaterThanOtherDateValidator(otherPropName) {
  return MuxValidator([DateObjectValidator, (v, form) => v > form[otherPropName]])
}

// true IFF the value is an object with the keys we expect an S3 object to have (key, bucket, region)
export const S3ObjectValidator = value => !!value && typeof value === 'object' && value.key && value.bucket && value.region

// true IFF the value is a date object that is strictly in the future
export const FutureDateValidator = MuxValidator([DateObjectValidator, v => v.getTime() > Date.now()])

// true IFF the value is a number or if the input field is disabled
export function NumberValidatorIfInputFieldIsEnabled(toggleProp) {
  return MuxValidator([(v, form) => NumberValidator(v) || !form[toggleProp]])
}

// true IFF the value is a valid email address
export function EmailValidator(email) {
  return typeof email === 'string' && email.includes('@')
}

export function PhoneNumberValidator(number) {
  if (typeof number !== 'string') {
    return false
  }

  let digitsOnly = number.replace(/\D/g, '')

  switch (digitsOnly.length) {
    case 10:
      digitsOnly = '1' + digitsOnly

    // eslint-disable-next-line no-fallthrough
    case 11:
      // if they had a leading one it's good to go
      // we're not actually going to validate it's correct
      return digitsOnly[0] === '1'

    default:
      return false
  }
}

const ALREADY_CHECKED = 'ALREADY_CHECKED'
// This will return if either itself validates or any prop in the otherProps array validates
export function ConstructValidateDependency(thisPropValidator, otherProps) {
  return (myValue, theForm, theValidationObject) => {
    return (
      // if this prop validates
      !!thisPropValidator(myValue, theForm, theValidationObject) ||
      // or if any of the other props validate
      (Array.isArray(otherProps) &&
        otherProps.some(otherProp => {
          return (
            // if this prop has already been checked, return true -- this should break our infinite loop
            // that might be caused if you included thisProp in otherProps
            theValidationObject[otherProp] !== ALREADY_CHECKED &&
            // otherwise we check if this "otherProp" validates by calling its validator function and passing the same validationObject
            // except with ALREADY_CHECKED. So next time we encounter it, we know not to check it again
            theValidationObject[otherProp](theForm[otherProp], theForm, {
              ...theValidationObject,
              [otherProp]: ALREADY_CHECKED,
            })
          )
        }))
    )
  }
}
