import {useState} from 'react'
import {createTheme} from '@material-ui/core/styles'
import {blue, blueGrey} from '@material-ui/core/colors'

const defaultTheme = {
  typography: {
    fontFamily: ['proxima_nova_regular'],
  },
}

export function useTheme() {
  const [currentTheme, setCurrentTheme] = useState({
    palette: {
      primary: blue,
      secondary: blueGrey,
    },
  })
  const muiTheme = createTheme({
    ...defaultTheme,
    ...currentTheme,
  })
  return [muiTheme, setCurrentTheme]
}
