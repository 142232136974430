import ActionTypes from '../actions/types'
import moment from 'moment'
import {NUDGE_TYPE_BIRTHDAY, NUDGE_TYPE_ISSUE} from '../components/RXOAssistant/AssistantConstants'
const initialState = {
  nudgesLookup: {},
  loggedNudgesActionsLookup: {},
}

// --------------------------------------------------------------------------------
// State mutation functions:

function checkHighPriority(insight) {
  if (NUDGE_TYPE_BIRTHDAY.includes(insight.type) && moment(insight.triggeredAt).isSame(moment().toISOString(), 'day')) {
    // Birthday or anniversary
    return true
  } else if (NUDGE_TYPE_ISSUE.includes(insight.type) && moment().diff(moment(insight.triggeredAt).startOf('day'), 'days') >= 2) {
    // delayed work order
    return true
  }
  return false
}

function setNudgesAndLoggedActionsData(state, nudgesArr, loggedActionArr) {
  let newLookupNudge = nudgesArr.reduce((agr, a) => {
    a.isHighPriority = checkHighPriority(a)
    agr[a.id] = a
    return agr
  }, {})

  let newLookupLoggedAction = loggedActionArr.reduce((agr, a) => {
    agr[a.id] = a
    return agr
  }, {})
  return {...state, nudgesLookup: newLookupNudge, loggedNudgesActionsLookup: newLookupLoggedAction}
}

function deleteBuildingNudgeById(state, buildingNudgeId) {
  let newLookup = {...state.nudgesLookup}
  delete newLookup[buildingNudgeId]
  return {...state, nudgesLookup: newLookup}
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ASSISTANT_LOAD_NUDGES:
      return setNudgesAndLoggedActionsData(state, action.buildingNudges, action.loggedNudgesActions)

    case ActionTypes.ASSISTANT_DELETE_NUDGE:
      return deleteBuildingNudgeById(state, action.payload)

    default:
      /* pass through */
      return state
  }
}
