import React from 'react'
import SortableTable, {ColumnConfig} from '../SortableTable'
import PropTypes from 'prop-types'
import moment from 'moment'
import Routes from '../../constants/RouteConstants'
import {withRouter} from 'react-router-dom'
import Constants from './AmenitiesConstants'
import HighlightText from '../HighlightText'
import useResidentLookup from '../hooks/useResidentLookup'
import useStaffLookup from '../hooks/useStaffLookup'
import {useSelector} from 'react-redux'

const tabToFilenameMap = ['Needs_Approval', 'Upcoming_Reservations', 'Past_Reservations']

const ReservationsTable = function(props) {
  const amenitiesLookup = useSelector(state => state.Amenities.amenitiesLookup)

  const handleClickRow = row => {
    props.history.push(Routes.constructPath(Routes.AMENITIES_RESERVATIONS_VIEW_SINGLE, {reservationId: row.id}))
  }

  const {getResident} = useResidentLookup()
  const {getStaff} = useStaffLookup()

  const columns = [
    new ColumnConfig({
      title: 'Unit',
      renderPrimitive: g => (!g.staffId ? getResident(g.residentId).occupancy.unit.number : ''),
      render: (g, col) =>
        !g.staffId ? (
          <div>
            <HighlightText search={props.filterTerm}>{col.renderPrimitive(g)}</HighlightText>
          </div>
        ) : null,
      comparator: ColumnConfig.residentUnitComparatorGenerator(getResident),
    }),

    new ColumnConfig({
      title: 'Resident',
      renderPrimitive: g => (!g.staffId ? getResident(g.residentId).displayName : ''),
      render: (g, col) => (!g.staffId ? <HighlightText search={props.filterTerm}>{col.renderPrimitive(g)}</HighlightText> : null),
      comparator: ColumnConfig.residentNameComparatorGenerator(getResident),
    }),

    new ColumnConfig({
      title: 'Staff',
      renderPrimitive: g => (g.staffId ? getStaff(g.staffId).displayName : ''),
      render: (g, col) => (g.staffId ? <HighlightText search={props.filterTerm}>{col.renderPrimitive(g)}</HighlightText> : null),
      comparator: ColumnConfig.simpleComparatorGenerator('staffId', undefined, getStaff),
    }),

    new ColumnConfig({
      title: 'Amenity',
      renderPrimitive: g => amenitiesLookup[g.amenityId].label,
      render: g => <HighlightText search={props.filterTerm}>{amenitiesLookup[g.amenityId].label}</HighlightText>,
      comparator: (a, b) => amenitiesLookup[a.amenityId].label.localeCompare(amenitiesLookup[b.amenityId].label),
    }),

    new ColumnConfig({
      title: 'Start time',
      renderPrimitive: g => moment(g.startAt).format(Constants.RESERVATION_TIME_FORMAT),
      comparator: (a, b) => moment(a.startAt) - moment(b.startAt),
      isDefaultSort: true,
      sortingDirection: 'desc',
    }),

    new ColumnConfig({
      title: 'End time',
      renderPrimitive: g => moment(g.endAt).format(Constants.RESERVATION_TIME_FORMAT),
      comparator: (a, b) => moment(a.endAt) - moment(b.endAt),
      sortingDirection: 'desc',
    }),

    new ColumnConfig({
      title: 'Date',
      renderPrimitive: g => moment(g.startAt).format(Constants.RESERVATION_DATE_FORMAT),
      comparator: (a, b) => moment(a.startAt) - moment(b.startAt),
      sortingDirection: 'desc',
    }),

    new ColumnConfig({
      title: 'Guest',
      renderPrimitive: g => (g.partySize > 0 ? g.partySize - 1 : '--'),
      render: (g, col) => <HighlightText search={props.filterTerm}>{col.renderPrimitive(g)}</HighlightText>,
      comparator: (a, b) => {
        return a.partySize === b.partySize
          ? a.partySize < b.partySize
            ? -1
            : a.partySize > b.partySize
            ? 1
            : 0
          : a.partySize - b.partySize
      },
    }),

    new ColumnConfig({
      title: 'Date Created',
      renderPrimitive: g => moment(g.createdAt).format(Constants.RESERVATION_DATE_FORMAT),
      comparator: (a, b) => moment(a.createdAt) - moment(b.createdAt),
      sortingDirection: 'desc',
    }),

    new ColumnConfig({
      title: 'Status',
      renderPrimitive: g => Constants.RESERVATION_STATE_LABELS[g.reservationState] || 'Unknown',
      render: (g, col) => <HighlightText search={props.filterTerm}>{col.renderPrimitive(g)}</HighlightText>,
      comparator: ColumnConfig.simpleComparatorGenerator('reservationState'),
    }),
  ]

  return (
    <SortableTable
      data={props.reservations}
      downloadFileName={`${tabToFilenameMap[props.tab]}`}
      // every row on the Needs Approval tab gets highlighted
      rowHighlightCondition={() => props.tab === Constants.TAB_NEEDS_APPROVAL}
      columns={columns}
      onClickRow={handleClickRow}
    />
  )
}

ReservationsTable.propTypes = {
  reservations: PropTypes.array.isRequired,
  tab: PropTypes.number,
  filterTerm: PropTypes.string,
}

export default withRouter(ReservationsTable)
