import {
  ONBOARDING_STATE_COMPLETED,
  ONBOARDING_STATE_PENDING_APPROVAL,
  ONBOARDING_STATE_CHANGES_REQUESTED,
  ONBOARDING_STATE_LOCKED,
} from '../constants/ModelConstants'

/**
 * @param {string} state
 * @return {boolean}
 */
export const isOnboardingTaskStateCompleted = state => {
  return state.slice(0, 9) === ONBOARDING_STATE_COMPLETED
}

/**
 * @param {string} state
 * @return {boolean}
 */
export const isOnboardingTaskStatePendingApproval = state => {
  return state.slice(-16) === ONBOARDING_STATE_PENDING_APPROVAL
}

/**
 * @param {string} state
 * @return {boolean}
 */
export const isOnboardingTaskStateChangesRequested = state => {
  return state === ONBOARDING_STATE_CHANGES_REQUESTED
}

export const isOnboardingTaskStateLocked = state => {
  return state === ONBOARDING_STATE_LOCKED
}

/** @deprecated */
export const isOnboardingTaskComplete = onboardingTask => {
  return onboardingTask.state.slice(0, 9) === ONBOARDING_STATE_COMPLETED
}

/** @deprecated */
export const isOnboardingTaskPendingApproval = onboardingTask => {
  return onboardingTask.state.slice(-16) === ONBOARDING_STATE_PENDING_APPROVAL
}

/** @deprecated */
export const isOnboardingTaskChangesRequested = onboardingTask => {
  return onboardingTask.state === ONBOARDING_STATE_CHANGES_REQUESTED
}

// This function takes an occupancy object and converts it to a string containing all residents in the occupancy (separated
// by commas), followed by a hyphen, and then the unit number of the occupancy
/**
 * @param {{residents?: Array<*>, unit?: *}} occupancy
 * @param {boolean?} skipNames
 * @return {string}
 */
export const convertOccupancyToString = (occupancy, skipNames) => {
  let occupancyString = ''
  if (occupancy) {
    if (occupancy.residents && !skipNames) {
      for (let i = 0; i < occupancy.residents.length; i++) {
        let resident = occupancy.residents[i]
        if (i === occupancy.residents.length - 1) {
          occupancyString += resident.displayName
        } else {
          occupancyString += resident.displayName + ', '
        }
      }
    }
    if (occupancy.unit) {
      occupancyString += (occupancyString ? ' - ' : '') + 'Unit ' + occupancy.unit.number
    }
  }
  return occupancyString
}

/**
 * @param {{attachments?:Array<{isSynced: boolean}>}} submission
 * @returns {{syncedAttachments: Array<S3Object>, notSyncedAttachments: Array<S3Object>}}
 */
export const getSyncedAndNotSyncedFilesFromSubmission = submission => {
  const attachments = submission.attachments || []
  const syncedAttachments = attachments.filter(attachment => attachment.isSynced)
  const notSyncedAttachments = attachments.filter(attachment => !attachment.isSynced)

  return {
    syncedAttachments,
    notSyncedAttachments,
  }
}
