import LogActionForm from './LogActionForm'
import {Grid} from '@material-ui/core'
import RXRButton from '../RXRButton'
import {BTN_CANCEL, BTN_SAVE} from './AssistantConstants'
import React, {useEffect} from 'react'
import useFormChanged, {DateObjectValidator, NonEmptyArrayValidator, NonEmptyValidator} from '../hooks/useFormChanged'
import PropTypes from 'prop-types'
import DiscardChangesDialog from '../DiscardChangesDialog'
import {makeStyles} from '@material-ui/core/styles'
import SingleBuildingActionInput from './SingleBuildingActionInput'
import moment from 'moment'
import {useSelector, useDispatch} from 'react-redux'
import {logBuildingNudgeAction} from '../../actions/nudgesActions'
import useListCreateViewPattern from '../hooks/useListCreateViewPattern'
import {selectAuthedUserId} from '../../reducers/selectors'

const useStyles = makeStyles(theme => ({
  gridstyling: {
    paddingTop: 10,
  },
}))

const todayDate = moment()
  .startOf('day')
  .toDate()

const EMPTY_FORM_STATE = {
  id: null,
  residentId: null,
  cost: null,
  reason: null,
  notes: null,
  actions: [],
  actionTakenAt: todayDate,
}

function CreateNewBuildingActionForm(props) {
  const classes = useStyles()
  const {form, setForm, formChanged, resetInitialForm, invalidItems, validateForm} = useFormChanged(
    EMPTY_FORM_STATE,
    useFormChanged.PropLevelValidation({
      residentId: NonEmptyValidator,
      reason: NonEmptyValidator,
      actions: NonEmptyArrayValidator,
      actionTakenAt: DateObjectValidator,
    }),
  )

  const dispatch = useDispatch()
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const authedStaffId = useSelector(selectAuthedUserId)
  const nudgesLookup = useSelector(state => state.Nudges.nudgesLookup)

  // USES THE VIEW_SINGLE / CREATE LOAD PATTERN
  useEffect(() => {
    if ((!props.buildingNudgeId && form.id) || (!props.buildingNudgeId && !props.isCreateMode)) {
      resetInitialForm(EMPTY_FORM_STATE)
    } else if (props.buildingNudgeId && props.buildingNudgeId !== form.buildingNudgeId) {
      const nudge = nudgesLookup[props.buildingNudgeId]

      if (nudge) {
        let initialForm = {
          id: nudge.id,
          buildingId: nudge.buildingId,
          buildingNudgeId: nudge.id,
          residentId: nudge.residentId,
          reason: nudge.type,
          notes: null,
          // make sure our "actions" is an array of action objects (not just the action type enum)
          actions: (nudge.actions || []).map(SingleBuildingActionInput.GetObjectFromAction),
          actionTakenAt: new Date(),
        }

        resetInitialForm(initialForm)
      }
    }
  }, [props.buildingNudgeId, props.isCreateMode, nudgesLookup])

  const saveActionHandler = async () => {
    if (!validateForm()) {
      return
    }

    let input = {
      buildingId: activeBuildingId,
      staffId: authedStaffId,
      residentId: form.residentId,
      cost: typeof form.cost === 'number' ? parseFloat(form.cost.toFixed(2)) : null,
      reason: form.reason,
      notes: form.notes,
      actions: form.actions.map(fa => fa.action),
      actionTakenAt: form.actionTakenAt,
      buildingNudgeId: form.buildingNudgeId,
    }

    let loggedAction = await logBuildingNudgeAction(dispatch, input)
    if (loggedAction) {
      resetInitialForm(EMPTY_FORM_STATE, props.onCancel)
    }
  }

  return (
    <React.Fragment>
      <DiscardChangesDialog hasChanges={formChanged} />
      <LogActionForm invalidItems={invalidItems} updateForm={setForm} {...form} />
      <Grid container spacing={2} alignItems="center" className={classes.gridstyling}>
        <Grid item>
          <RXRButton onClick={() => saveActionHandler()}>{BTN_SAVE}</RXRButton>
        </Grid>
        <Grid item>
          <RXRButton type={RXRButton.TYPE_TEXT} onClick={props.onCancel}>
            {BTN_CANCEL}
          </RXRButton>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

CreateNewBuildingActionForm.propTypes = {
  onCancel: PropTypes.func,
  buildingNudgeId: PropTypes.string,
  isCreateMode: PropTypes.bool,
}

export default CreateNewBuildingActionForm
