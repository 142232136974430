import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import AllAmenitiesCalendarView from '../Amenities/AllAmenitiesCalendarView'
import IndividualAmenityCalendarView from '../Amenities/IndividualAmenityCalendarView'
import {useSelector} from 'react-redux'
import ToggleButtons from '../ToggleButtons'
import {spaceLarge} from '../../assets/styles/spacing'
import {onboardingElevatorAmenityFilter} from './Onboarding'
import Routes from '../../constants/RouteConstants'

const TAB_SINGLE = 0
const TAB_ALL = 1

function ElevatorsCalendarView(props) {
  const classes = useStyles()
  const [tab, setTab] = useState(TAB_SINGLE)

  const amenitiesLookup = useSelector(state => state.Amenities.amenitiesLookup)
  const filteredAmenities = Object.values(amenitiesLookup).filter(onboardingElevatorAmenityFilter)

  return (
    <div className={classes.mainContainer}>
      {filteredAmenities.length > 1 && (
        <div className={classes.toggleContainer}>
          <ToggleButtons selected={tab} onChange={setTab} buttonLabels={['Individual', 'All elevators']} inverseColors={true} />
        </div>
      )}
      {tab === TAB_ALL ? (
        <AllAmenitiesCalendarView amenitiesFilter={onboardingElevatorAmenityFilter} />
      ) : (
        <IndividualAmenityCalendarView
          amenitiesFilter={onboardingElevatorAmenityFilter}
          individualAmenityRoute={Routes.ELEVATOR_CALENDAR_VIEW_INDIVIDUAL_AMENITY}
        />
      )}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    position: 'relative',
  },
  toggleContainer: {
    width: '260px',
    position: 'absolute',
    top: spaceLarge,
    right: spaceLarge,
  },
}))

export default ElevatorsCalendarView
