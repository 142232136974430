import ActionTypes from './types'
import {
  getAllAmenitys,
  updateOnlyAmenity,
  listAmenityReservationsByBuilding,
  createNewAmenityReservation,
  updateExistingAmenityReservation,
  cancelReservation,
  approveReservation,
  declineReservation,
} from '../lib/queries'

export const loadAmenitiesForBuilding = async (dispatch, buildingId) => {
  let response = await getAllAmenitys(buildingId)

  dispatch({
    type: ActionTypes.AMENITIES_LOAD_ALL,
    payload: response,
  })

  return response
}

export async function loadAmenityReservationsForBuilding(dispatch, buildingId) {
  let response = await listAmenityReservationsByBuilding(buildingId)

  dispatch({
    type: ActionTypes.AMENITIES_LOAD_RESERVATIONS,
    payload: response,
  })

  return response
}

export const setAmenityIsVisible = async (dispatch, amenityId, isVisible) => {
  let response = await updateOnlyAmenity({id: amenityId, isVisible: isVisible})

  dispatch({
    type: ActionTypes.AMENITIES_UPDATE_SINGLE,
    payload: response,
  })

  return response
}

export async function createAmenityReservation(dispatch, amenityReservationInput) {
  const resp = await createNewAmenityReservation(amenityReservationInput)

  dispatch({
    type: ActionTypes.AMENITY_RESERVATIONS_CREATE_NEW,
    payload: resp,
  })

  return resp
}

export async function updateAmenityReservation(dispatch, amenityReservationInput) {
  const resp = await updateExistingAmenityReservation(amenityReservationInput)

  dispatch({
    type: ActionTypes.AMENITY_RESERVATIONS_UPDATE_EXISTING,
    payload: resp,
  })

  return resp
}

export async function cancelAmenityReservation(dispatch, amenityReservationId) {
  const resp = await cancelReservation(amenityReservationId)

  dispatch({
    type: ActionTypes.AMENITY_RESERVATIONS_UPDATE_EXISTING,
    payload: resp,
  })

  return resp
}

export async function approveAmenityReservation(dispatch, amenityReservationId) {
  const resp = await approveReservation(amenityReservationId)

  dispatch({
    type: ActionTypes.AMENITY_RESERVATIONS_UPDATE_EXISTING,
    payload: resp,
  })

  return resp
}

export async function declineAmenityReservation(dispatch, amenityReservationId) {
  const resp = await declineReservation(amenityReservationId)

  dispatch({
    type: ActionTypes.AMENITY_RESERVATIONS_UPDATE_EXISTING,
    payload: resp,
  })

  return resp
}
