import {useState, useRef} from 'react'
import {useSelector} from 'react-redux'
import FileHelper from '../../lib/FileHelper'

function useImageUploader() {
  const [stagedImage, setStagedImage] = useState(null)
  const authedStaffModel = useSelector(state => state.Staff.authedStaffModel)
  const fileInputRef = useRef(null)

  const handleClick = () => {
    fileInputRef.current.click()
  }

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const userHasMadeChangesToProfileImage =
    stagedImage &&
    (authedStaffModel.profileImage ? JSON.stringify(authedStaffModel.profileImage) : authedStaffModel.profileImage) !==
      JSON.stringify(stagedImage)

  const handleImageChange = event => {
    const file = event.target.files[0]
    if (file) {
      FileHelper.Instance()
        .uploadSingleFileToS3(file)
        .then(resp => {
          setStagedImage(FileHelper.formatS3ObjectForInput(resp))
          clearFileInput()
        })
        .catch(err => {
          console.error('Error uploading image:', err)
          alert('Failed to upload image.')
        })
    }
  }
  const cancelImageUpload = () => {
    setStagedImage(authedStaffModel.profileImage)
    clearFileInput()
  }

  return {
    stagedImage,
    setStagedImage,
    fileInputRef,
    handleClick,
    handleImageChange,
    cancelImageUpload,
    userHasMadeChangesToProfileImage,
  }
}

export default useImageUploader
