import React, {useEffect, useState, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Grid, makeStyles} from '@material-ui/core'
import {rxrBlueColor, rxrSecondaryBlueGrayColor} from '../../assets/styles/color'
import moment from 'moment'
import {capitalizeFirstLetter} from '../../Utils/StringFormatter'
import {getAmenityReservationDetails} from '../../lib/queries'
import Loader from '../Loader'
import TimelineEvent from './TimelineEvent'
import {useSelector} from 'react-redux'

const useStyles = makeStyles(theme => ({
  infoTitle: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: '400',
    fontStyle: 'normal',
    color: rxrSecondaryBlueGrayColor,
  },
  infoData: {
    fontSize: 16,
    lineHeight: '16px',
    fontWeight: '400',
    fontStyle: 'normal',
    color: rxrBlueColor,
    paddingTop: '4px',
  },
  loader: {
    paddingTop: '120px',
    backgroundColor: 'transparent ',
    opacity: 1,
  },
}))

function AmenityReservation(props) {
  const classes = useStyles()

  const amenitiesLookup = useSelector(state => state.Amenities.amenitiesLookup)
  const [amenityReservationInfo, setAmenityReservationInfo] = useState({})
  const [isAmenityReservationInfoLoading, setAmenityReservationInfoLoading] = useState(true)

  /**
   * Common function for rendeing data point.
   * @param {*} title
   * @param {*} data
   * @returns {string}
   */
  const renderInfo = (title, data) => (
    <Grid container direction="column" style={{paddingBottom: '24px'}}>
      <Grid item>
        <div className={classes.infoTitle}>{title}</div>
      </Grid>
      <Grid item>
        <div className={classes.infoData}>{data ? data : '--'}</div>
      </Grid>
    </Grid>
  )

  useEffect(() => {
    getAmenityReservationDetails(props.amenityReservationId)
      .then(res => {
        setAmenityReservationInfo(res)
        setAmenityReservationInfoLoading(false)
      })
      .catch(err => console.error(err))
  }, [])

  const startAt = amenityReservationInfo.startAt ? `${moment(amenityReservationInfo.startAt).format('LT')}` : ''
  const endAt = amenityReservationInfo.endAt ? `${moment(amenityReservationInfo.endAt).format('LT')}` : ''
  const date = amenityReservationInfo.startAt ? `${moment(amenityReservationInfo.startAt).format('ll')}` : ''

  return (
    <Fragment>
      {isAmenityReservationInfoLoading ? (
        <Loader className={classes.loader} />
      ) : (
        <div>
          {renderInfo('Amenity', amenitiesLookup[amenityReservationInfo.amenityId].label)}
          {renderInfo('Status', amenityReservationInfo.reservationState && capitalizeFirstLetter(amenityReservationInfo.reservationState))}
          {renderInfo('Start time', startAt)}
          {renderInfo('End time', endAt)}
          {renderInfo('Date', date)}
          <TimelineEvent primaryRecordId={props.amenityReservationId} />
        </div>
      )}
    </Fragment>
  )
}

AmenityReservation.propTypes = {
  amenityReservationId: PropTypes.string.isRequired,
}

export default AmenityReservation
