import React from 'react'
import {RXOTextField} from '../assets/styles'
import PropTypes from 'prop-types'

export default function TextInput(props) {
  const onChangeHandler = value => {
    if (typeof props.onChange !== 'function') {
      return
    }

    if (typeof props.maxLength === 'number') {
      value = value.substr(0, props.maxLength)
    }

    props.onChange(value)
  }

  return (
    <RXOTextField.FormTextField
      className={props.className}
      label={props.label}
      variant={'outlined'}
      required={props.isRequired}
      fullWidth
      placeholder={props.placeholder}
      helperText={
        props.helperText
          ? props.helperText
          : props.maxLength
          ? `${props.value ? props.value.length.toLocaleString() + ' / ' : ''}${props.maxLength.toLocaleString()} character limit`
          : undefined
      }
      onChange={e => onChangeHandler(e.target.value)}
      value={props.value || ''}
      disabled={props.isDisabled}
      error={props.error}
      readOnly={props.isReadOnly}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={props.inputProps}
      InputProps={props.InputProps}
      type={props.htmlType}
    />
  )
}
TextInput.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool, // will append * to label
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  error: PropTypes.bool,
  htmlType: PropTypes.string,
  className: PropTypes.any,

  // -----------------------------
  // these should almost never be used unless we're trying to integrate with another material component
  inputProps: PropTypes.any,
  InputProps: PropTypes.any,
}
