/**
 * @typedef GenericChatRoom
 * @property {string} id
 * @property {string} status
 * @property {boolean} isOnboardingChat
 * @property {string?} lastMessageId
 * @property {GenericChatMessage?} lastMessage
 * @property {Array<GenericChatUser>} chatUsers
 * @property {boolean} hasUnread
 * @property {string?} __originalModel
 */

/**
 * @typedef GenericChatUser
 * @property {string} id
 * @property {string} userId
 * @property {string} displayName
 * @property {boolean} isStaffUser
 * @property {string} lastSeenAt
 * @property {string?} __originalModel
 */

/**
 * @typedef GenericChatMessage
 * @property {string?} id
 * @property {string} chatRoomId
 * @property {string} chatUserId
 * @property {GenericChatUser} chatUser
 * @property {string} content
 * @property {boolean} hasAttachments
 * @property {Array<S3Object>} attachments
 * @property {string} createdAt
 * @property {string?} __originalModel
 */

// --- OLD CHAT MODEL MAPPING --------------------------------------------------------------------------------

import {CONVERSATION_CLOSED} from '../constants/ModelConstants'

const DEFAULT_USER_NAME = 'Former Staff'

/**
 * @param {ConversationFragment} c
 * @return {GenericChatRoom}
 */
export function conversationToGenericChatRoom(c) {
  return {
    id: c.id,
    status: c.status || CONVERSATION_CLOSED,
    isOnboardingChat: false,
    lastMessageId: c.lastMessage ? c.lastMessage.id : null,
    lastMessage: c.lastMessage ? messageToGenericChatMessage(c.lastMessage) : null,
    chatUsers: [residentToGenericChatUser(c.resident, c)], // only the resident user is a chat user in the old model
    hasUnread: false, // default to false, will determine later based on authed user
    __originalModel: 'Conversation',
  }
}

/**
 * @param {MessageFragment} m
 * @return {GenericChatMessage}
 */
export function messageToGenericChatMessage(m) {
  const isImageAttachment = m.body === 'IMAGE'

  return {
    id: m.id,
    chatRoomId: m.messageConversationId,
    chatUserId: m.messageStaffId || m.recipient, // legacy models the participant id matches the user id
    content: isImageAttachment ? null : m.body,
    attachments: isImageAttachment ? [m.image] : null,
    hasAttachments: isImageAttachment,
    createdAt: m.createdAt,
    __originalModel: 'Message',
  }
}

/**
 * @param {StaffFragment} s
 * @param {ConversationFragment} c
 * @return {GenericChatUser}
 */
export function staffToGenericChatUser(s, c) {
  return {
    // NOTE the id and userId match, this is expected behavior and is used to indicate a pseudo-user (@see ChatContainerQueries -> markLastSeenAt)
    id: s.id,
    userId: s.id, // in legacy cases, the chatUser id and primary user id are the same
    displayName: s.displayName,
    isStaffUser: true,
    lastSeenAt: c.staffLastReadAt,
    __originalModel: 'Staff',
  }
}

/**
 * @param {ResidentFragment} r
 * @param {ConversationFragment} c
 * @return {GenericChatUser}
 */
export function residentToGenericChatUser(r, c) {
  return {
    id: r.id,
    userId: r.id, // in legacy cases, the chatUser id and primary user id are the same
    displayName: r.displayName,
    isStaffUser: false,
    lastSeenAt: c.residentReadAt,
    __originalModel: 'Resident',
  }
}

// --- NEW CHAT MODEL MAPPING --------------------------------------------------------------------------------

/**
 * @param {ChatRoomFragment} c
 * @return {GenericChatRoom}
 */
export function chatRoomToGenericChatRoom(c) {
  return {
    id: c.id,
    status: c.status || CONVERSATION_CLOSED,
    isOnboardingChat: true,
    lastMessageId: c.lastChatMessage ? c.lastChatMessage.id : null,
    lastMessage: c.lastChatMessage ? chatMessageToGenericChatMessage(c.lastChatMessage) : null,
    chatUsers: c.chatParticipants.map(chatParticipantToGenericChatUser),
    hasUnread: false, // default to false, will determine later based on authed user
    __originalModel: 'ChatRoom',
  }
}

/**
 * @param {ChatMessageFragment} c
 * @return {GenericChatMessage}
 */

export function chatMessageToGenericChatMessage(c) {
  return {
    id: c.id,
    chatRoomId: c.chatRoomId,
    chatUserId: c.chatParticipantId,
    content: c.isAttachments ? null : c.content,
    attachments: c.isAttachments ? JSON.parse(c.content) : null,
    hasAttachments: c.isAttachments,
    createdAt: c.createdAt,
    __originalModel: 'ChatMessage',
  }
}
/**
 * @param {ChatParticipantFragment} c
 * @return {GenericChatUser}
 */
export function chatParticipantToGenericChatUser(c) {
  return {
    id: c.id,
    userId: c.participantId,
    displayName: c.displayName,
    isStaffUser: c.isStaffParticipant,
    lastSeenAt: c.lastSeenAt,
    __originalModel: 'ChatParticipant',
  }
}

/**
 * TODO: This function is used to format messages from a GenericChatRoom into GenericChatMessage records. In doing so
 *   this function also sets the .chatUser prop for that ChatMessage, but this isn't ideal since we it's not
 *   intuitive and we'll be processing the same staff user record multiple times for each message...
 *   Would be better if we could add all participants to the ChatRoom.chatUsers array on initial sync.
 *   But this is also difficult currently because:
 *   A) we don't have staffLookup available in the messagesAction and
 *   B) staff is loaded in a separate query which may not have returned and hydrated when we try to load conversations
 *   ... Leaving for now. Once we deprecate the old Conversation schema this will basically handle itself
 *
 * @param {GenericChatRoom} chatRoom
 * @param {Array<*>} messagesArr
 * @param {Object<string, *>} staffLookup
 * @return {Array<GenericChatMessage>}
 */
export function formatChatMessages(chatRoom, messagesArr, staffLookup) {
  // find all the users in this chat and index them by id
  const chatUsersLookup = chatRoom.chatUsers.reduce((agr, u) => {
    agr[u.id] = u
    return agr
  }, {})

  // format the correct object (if it's not already formatted -- using __originalModel to determine if it's already formatted)
  const retVal = chatRoom.isOnboardingChat
    ? messagesArr.map(m => (m.__originalModel ? m : chatMessageToGenericChatMessage(m)))
    : messagesArr.map(m => (m.__originalModel ? m : messageToGenericChatMessage(m)))

  // add the chatUser prop (handy for some of the components code)
  retVal.forEach(m => {
    m.chatUser = chatUsersLookup[m.chatUserId] || _getStaffChatUserOrDefault(staffLookup, m.chatUserId, m.createdAt)
  })

  return retVal
}

/**
 * @param {GenericChatRoom} chatRoom
 * @return {Array<GenericChatUser>}
 */
export function getResidentUsersFromChatRoom(chatRoom) {
  return chatRoom.chatUsers.filter(cU => !cU.isStaffUser)
}

/**
 * @param {GenericChatRoom} chatRoom
 * @param {string} authedUserId
 * @param {Object<string, StaffFragment>} staffLookup
 * @returns {GenericChatUser}
 */
export function getAuthedUserFromChatRoom(chatRoom, authedUserId, staffLookup) {
  if (!chatRoom) {
    return null
  }

  return chatRoom.chatUsers.find(cU => cU.userId === authedUserId) || _getStaffChatUserOrDefault(staffLookup, authedUserId)
}

/**
 * @param {Object<string, StaffFragment>} staffLookup
 * @param {string} staffUserId
 * @param {string?} staffLastReadAt
 * @return {GenericChatUser|{lastSeenAt, isStaffUser: boolean, displayName: string, id, userId}}
 */
function _getStaffChatUserOrDefault(staffLookup, staffUserId, staffLastReadAt) {
  return staffLookup[staffUserId]
    ? staffToGenericChatUser(staffLookup[staffUserId], {staffLastReadAt: staffLastReadAt})
    : {
        // NOTE the id and userId match, this is expected behavior and is used to indicate a pseudo-user (@see ChatContainerQueries -> markLastSeenAt)
        // if the user doesn't exist, then it's probably a former staff user. Use a default displayName
        id: staffUserId,
        userId: staffUserId,
        displayName: DEFAULT_USER_NAME,
        isStaffUser: true,
        lastSeenAt: staffLastReadAt,
      }
}

export const FILTER_NONE = 'none'
export const FILTER_CURRENT = 'current'
export const FILTER_ONBOARDING = 'onboarding'
