import ActionTypes from '../actions/types'

const initialState = {
  recentlyMarkedDeliveryIds: [], // for the incoming deliveries, we don't store an array of IDs, we store an array of objects {id: "...", deliveredTo: "person|unit"}
  recentlyPickedDeliveryIds: [],
}

// --------------------------------------------------------------------------------
// State mutation functions:

// TO DO: this is not actually a list of deliveryIds. It is a list of objects with a key that is an id
function addRecentlyMarkedDeliveryId(state, deliveryId) {
  return {...state, recentlyMarkedDeliveryIds: [...state.recentlyMarkedDeliveryIds, deliveryId]}
}

function addRecentlyMarkedDeliveryIds(state, deliveries) {
  return {...state, recentlyMarkedDeliveryIds: [...state.recentlyMarkedDeliveryIds, ...deliveries]}
}

/**
 * If we pass the deliveryId, we'll remove just that one from the list
 * @param state
 * @param {string?} deliveryId
 */
function removeRecentlyMarkedDeliveryId(state, deliveryId) {
  let newArr = deliveryId ? state.recentlyMarkedDeliveryIds.filter(obj => obj.id !== deliveryId) : []
  return {...state, recentlyMarkedDeliveryIds: newArr}
}

function addRecentlyPickedUpDeliveryId(state, deliveryId) {
  return {...state, recentlyPickedDeliveryIds: [...state.recentlyPickedDeliveryIds, deliveryId]}
}

function addRecentlyPickedUpDeliveryIds(state, deliveryIds) {
  return {...state, recentlyPickedDeliveryIds: [...state.recentlyPickedDeliveryIds, ...deliveryIds]}
}

/**
 * If we pass the deliveryId, we'll remove just that one from the list
 * @param state
 * @param {string?} deliveryId
 */
function removeRecentlyPickedUpDeliveryId(state, deliveryId) {
  let newArr = deliveryId ? state.recentlyMarkedDeliveryIds.filter(id => id !== deliveryId) : []
  return {...state, recentlyPickedDeliveryIds: newArr}
}

// --------------------------------------------------------------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.DELIVERY_MARK_RECENT_DELIVERED_IN:
      return addRecentlyMarkedDeliveryId(state, action.payload)

    case ActionTypes.DELIVERY_MARK_RECENT_DELIVERED_IN_MULTIPLE:
      return addRecentlyMarkedDeliveryIds(state, action.payload)

    case ActionTypes.DELIVERY_UNMARK_RECENT_DELIVERED_IN:
      return removeRecentlyMarkedDeliveryId(state, action.payload)

    case ActionTypes.DELIVERY_MARK_RECENT_PICKED_UP:
      return addRecentlyPickedUpDeliveryId(state, action.payload)

    case ActionTypes.DELIVERY_MARK_RECENT_PICKED_UP_MULTIPLE:
      return addRecentlyPickedUpDeliveryIds(state, action.payload)

    case ActionTypes.DELIVERY_UNMARK_RECENT_PICKED_UP:
      return removeRecentlyPickedUpDeliveryId(state, action.payload)

    default:
      /* pass through */
      return state
  }
}
