import ActionTypes from '../actions/types'

const initialState = {
  occupanciesLookup: {},
}

// --------------------------------------------------------------------------------
// State permutation functions:

function reduceOccupanciesListToObj(state, occupanciesList) {
  let byId = {}
  occupanciesList.forEach(o => {
    formatOccupancyObjectInPlace(o)
    byId[o.id] = o
  })

  return {...state, occupanciesLookup: byId}
}

function updateOccupancyInOccupanciesLookup(state, occupancy) {
  let occupanciesLookup = {...state.occupanciesLookup}
  occupanciesLookup[occupancy.id] = formatOccupancyObjectInPlace(occupancy)

  return {...state, occupanciesLookup}
}

/**
 * @param {*} state
 * @param {*} onboardingTask
 */
function updateOccupancyOnboardingTask(state, onboardingTask) {
  const occu = {...state.occupanciesLookup[onboardingTask.occupancyId]}
  occu.onboardingTasks = occu.onboardingTasks.map(t => (t.id === onboardingTask.id ? onboardingTask : t))
  return {...state, occupanciesLookup: {...state.occupanciesLookup, [occu.id]: formatOccupancyObjectInPlace(occu)}}
}

// --------------------------------------------------------------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.OCCUPANCIES_UPDATE_ALL:
      return reduceOccupanciesListToObj(state, action.payload)

    case ActionTypes.OCCUPANCY_UPDATE:
      return updateOccupancyInOccupanciesLookup(state, action.payload)

    case ActionTypes.OCCUPANCY_ONBOARDING_TASK_UPDATE:
      return updateOccupancyOnboardingTask(state, action.payload)

    default:
      return state
  }
}

/**
 * @param {*} o
 */
function formatOccupancyObjectInPlace(o) {
  if (o.onboardingTasks && Array.isArray(o.onboardingTasks) && o.onboardingTasks.length > 0) {
    o.onboardingTasks.forEach(t => {
      if (t.form && typeof t.form === 'string') {
        t.form = JSON.parse(t.form)
      }
    })
  }

  return o
}
