import React, {useState} from 'react'
import moment from 'moment'
import {Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import {bodyText, H4HeaderBlack, subBodyText, totalText} from '../../../assets/styles/typography'
import {spaceExtraLarge, spaceMedium, spaceSmall} from '../../../assets/styles/spacing'
import {rxrBlackColor, rxrWhiteColor, rxrBlueColor} from '../../../assets/styles/color'
import ChargesTable from './ChargesTable'
import PaymentsTable from './PaymentsTable'
import RXRIcon from '../../RXRIcon'
import RXRButton from '../../RXRButton'
import ConfirmDeleteDialog from '../../ConfirmDeleteDialog'
import {INVOICE_STATUS_PARTIAL, INVOICE_STATUS_PARTIAL_LATE} from '../../../constants/ModelConstants'

const useStyles = makeStyles(theme => ({
  parentContainer: {
    flexGrow: 1,
    marginBottom: 22,
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalText: {
    ...totalText,
  },
  H4HeaderBlack: {
    ...H4HeaderBlack,
  },
  sectionContainer: {
    paddingBottom: spaceExtraLarge,
  },
  bodyText: {...bodyText},
  subBodyText: {...subBodyText, marginTop: spaceSmall},
  balance: {
    fontWeight: 'bold',
    fontSize: 16,
    color: rxrBlackColor,
    fontStyle: 'normal',
  },

  mustUpdateMessage: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: rxrWhiteColor,
    padding: spaceSmall,
    borderRadius: 4,
    marginBottom: spaceMedium,
    border: '1px solid ' + rxrBlueColor,

    '& p': {
      marginLeft: spaceSmall,
    },
  },
}))

export default function RentBreakdown(props) {
  const classes = useStyles()
  const [showRefreshDialog, setShowRefreshDialog] = useState(false)
  const [hasRefreshedInvoice, setHasRefreshedInvoice] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)

  const handleClickRefreshInvoice = () => {
    if (!showRefreshDialog) {
      setShowRefreshDialog(true)
      return
    }
    setIsRefreshing(true)
    setShowRefreshDialog(false)
    props
      .onRefreshInvoice()
      .then(() => {
        setHasRefreshedInvoice(true)
      })
      .finally(() => {
        setIsRefreshing(true)
      })
  }

  const canRefresh =
    !hasRefreshedInvoice &&
    props.activeInvoice &&
    [INVOICE_STATUS_PARTIAL, INVOICE_STATUS_PARTIAL_LATE].includes(props.activeInvoice.status)

  return (
    <div className={classes.parentContainer}>
      <React.Fragment>
        {props.occupancy.mustUpdateLedger ? (
          <div className={classes.mustUpdateMessage}>
            <RXRIcon icon={RXRIcon.INFO} size={RXRIcon.SIZE_LARGE} color={rxrBlueColor} />
            <p>
              The balance shown in the app is correct, but some recent charges may be missing from this ledger.
              <br />
              Please check back later for an up to date list.
            </p>
          </div>
        ) : null}
        <ChargesTable charges={props.occupancy.ledgerItems} />

        {props.activeInvoice ? (
          <React.Fragment>
            <PaymentsTable payments={props.activeInvoice.payments.items} />
            <div className={classes.sectionContainer}>
              <div className={classes.rowContainer}>
                <Typography className={classes.balance}>Balance</Typography>
                <Typography className={classes.balance}>
                  {`$${(props.activeInvoice.total - props.activeInvoice.amountPaid).toFixed(2)}`}
                </Typography>
              </div>
              <div className={classes.rowContainer}>
                <Typography className={classes.bodyText}>
                  Paid ${props.activeInvoice.amountPaid} / ${props.activeInvoice.total}
                </Typography>
                <Typography className={classes.bodyText}>{`Due at ${moment(props.activeInvoice.dueAt).format('MMM D, YYYY')}`}</Typography>
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div>
                <RXRButton disabled={!canRefresh} onClick={handleClickRefreshInvoice} isLoading={isRefreshing}>
                  Re-sync balance
                </RXRButton>

                {!canRefresh && (
                  <Typography className={classes.subBodyText}>
                    Invoices can only be refreshed if they're in a partially paid state
                  </Typography>
                )}
              </div>
            </div>
            <ConfirmDeleteDialog
              isOpen={showRefreshDialog}
              onCancel={() => setShowRefreshDialog(false)}
              onConfirmDelete={handleClickRefreshInvoice}
              prompt={
                "Once you refresh this invoice, it may take a several hours for the balance to update in the RXO Home mobile app. During this time, the resident will not be able to make additional payments. Are you sure you'd like to proceed?"
              }
              deleteButtonText={'Re-sync balance'}
            />
          </React.Fragment>
        ) : (
          <Typography>Resident does not currently have an outstanding balance</Typography>
        )}
      </React.Fragment>
    </div>
  )
}

RentBreakdown.propTypes = {
  activeInvoice: PropTypes.object,
  occupancy: PropTypes.object,
  onRefreshInvoice: PropTypes.func,
}
