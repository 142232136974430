import React, {useState} from 'react'
import {PageContainer} from '../../../assets/styles/layout'
import PageHeader from '../../PageHeader'
import RXRButton from '../../RXRButton'
import Routes from '../../../constants/RouteConstants'
import {Grid} from '@material-ui/core'
import ClearableInput from '../../ClearableInput'
import {AntTab, AntTabs} from '../../AntTabs'
import {makeStyles} from '@material-ui/core/styles'
import {matchPath, useHistory, useLocation} from 'react-router-dom'
import EditPartnerOrderTable from './EditPartnerOrderTable'
import ActivePartnersTable from './ActivePartnersTable'
import PartnersProvider from './PartnersContext'
import CreateOrEditPartner from './CreateOrEditPartner'

const tabToPath = [
  // basically tab 0
  Routes.SETTINGS_PARTNERS,
  // tab 1
  Routes.SETTINGS_PARTNERS_REORDER,
]

const TAB_ACTIVE = 0
const TAB_REORDER = 1

function PartnersPage(props) {
  const classes = useStyles()
  const [searchTerm, setSearchTerm] = useState('')
  const history = useHistory()

  const currentLocation = useLocation()
  const isReorderPage = matchPath(currentLocation.pathname, {path: Routes.SETTINGS_PARTNERS_REORDER})
  const tab = isReorderPage ? TAB_REORDER : TAB_ACTIVE

  let visibleTableComponent
  switch (tab) {
    case TAB_ACTIVE:
      visibleTableComponent = <ActivePartnersTable searchTerm={searchTerm} />
      break
    case TAB_REORDER:
      visibleTableComponent = <EditPartnerOrderTable />
      break
  }

  return (
    <PartnersProvider>
      <div style={PageContainer}>
        <PageHeader
          title={'Partners'}
          rightControlComponent={
            <RXRButton type={RXRButton.TYPE_LARGE} onClick={() => history.push(Routes.SETTINGS_PARTNERS_CREATE)}>
              Create new Partner
            </RXRButton>
          }
        >
          <Grid container>
            <Grid item lg={6} md={6}>
              <div className={classes.placeSearch}>
                <ClearableInput value={searchTerm} onChange={setSearchTerm} placeholder={'Search by partner name'} />
              </div>
              <AntTabs value={tab} onChange={(e, t) => history.push(tabToPath[t])}>
                <AntTab label={'Active'} />
                <AntTab label={'Display order'} />
                {/*<AntTab label={'Inactive'} />*/}
              </AntTabs>
            </Grid>
          </Grid>
        </PageHeader>
        <div style={{height: '100%'}}>{visibleTableComponent}</div>

        <CreateOrEditPartner />
      </div>
    </PartnersProvider>
  )
}

const useStyles = makeStyles(theme => ({
  placeSearch: {
    width: '367px',
  },
}))

export default PartnersPage
