import ActionTypes from './types'
import * as queries from '../lib/queries'
import {setAuthenticatedStaffUser} from './staffActions'
import {setBuildingsAll, setBuildingViewing} from './buildingsActions'
import {CACHE_ActiveBuildingId} from '../constants/AppConstants'
import * as Sentry from '@sentry/react'

/**
 * @param {function} dispatch
 * @param {AuthedUser} authedUser
 */
export const setAuthenticatedUser = async (dispatch, authedUser) => {
  try {
    if (authedUser) {
      let staffModel = await queries.getStaffModel(authedUser.id)

      // if this staff user does not exist or has no buildings, throw an error
      if (!staffModel || staffModel.buildings.items.length === 0) {
        throw new Error('Invalid staff user')
      }

      // mark logged in
      await queries.markStaffUserLoggedIn(staffModel.id, !staffModel.dateClaimed)

      dispatch({
        type: ActionTypes.AUTH_SET_AUTHED_USER,
        payload: authedUser,
      })
      setAuthenticatedStaffUser(dispatch, staffModel)
      setBuildingsAll(dispatch, staffModel.buildings.items)

      // configure sentry context
      Sentry.configureScope(scope => {
        scope.setUser({id: staffModel.id, username: staffModel.displayName})
      })

      const queryParamBuildingId = new URLSearchParams(window.location.search).get('building')
      const cachedBuildingId = localStorage[CACHE_ActiveBuildingId]

      if (queryParamBuildingId && staffModel.buildings.items.some(b => b.building.id === queryParamBuildingId)) {
        // if a building was passed in the query params, use that first
        await setBuildingViewing(dispatch, queryParamBuildingId)
      } else if (cachedBuildingId && staffModel.buildings.items.some(b => b.building.id === cachedBuildingId)) {
        // if no query param was passed, use the building they viewed last session
        await setBuildingViewing(dispatch, cachedBuildingId)
      } else if (staffModel.buildings.items.length > 0) {
        // if no query param was passed and no building was cached, use the first building in their buildings list
        await setBuildingViewing(dispatch, staffModel.buildings.items[0].building.id)
      } else {
        // this shouldn't happen...
        throw new Error('No buildings available')
      }
    } else {
      throw new Error('Not authenticated')
    }
  } catch (err) {
    // remove sentry context
    Sentry.configureScope(scope => {
      scope.setUser({})
    })

    dispatch({
      type: ActionTypes._RESET_APP_STATE,
    })

    throw err
  }
}
