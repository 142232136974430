import React from 'react'
import PropTypes from 'prop-types'
import {ONBOARDING_IMPORTANCE_OPTIONAL, ONBOARDING_STATE_PENDING_APPROVAL} from '../../constants/ModelConstants'
import RXRIcon from '../RXRIcon'
import {rxrBlueColor, rxrGreyColor, rxrOrangeColor} from '../../assets/styles/color'
import {isOnboardingTaskStateCompleted} from '../../Utils/onboardingUtils'

function TaskStateIcon(props) {
  if (props.state === ONBOARDING_STATE_PENDING_APPROVAL) {
    return <RXRIcon icon={RXRIcon.REVIEW_NEEDED} color={rxrOrangeColor} className={props.className} style={props.style} />
  } else if (isOnboardingTaskStateCompleted(props.state)) {
    return <RXRIcon icon={RXRIcon.ONBOARDING} color={rxrBlueColor} className={props.className} style={props.style} />
  } else if (props.importance === ONBOARDING_IMPORTANCE_OPTIONAL) {
    return (
      <RXRIcon
        icon={RXRIcon.OPTIONAL}
        style={{backgroundColor: rxrGreyColor, borderRadius: 10, ...props.style}}
        className={props.className}
      />
    )
  } else {
    return (
      <RXRIcon icon={RXRIcon.TODO} style={{backgroundColor: rxrGreyColor, borderRadius: 10, ...props.style}} className={props.className} />
    )
  }
}

TaskStateIcon.propTypes = {
  state: PropTypes.string.isRequired,
  importance: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default TaskStateIcon
