import React, {useState, useRef} from 'react'
import PropTypes from 'prop-types'
import ResidentSentimentForecaster from './ResidentSentimentForecaster'
import {makeStyles} from '@material-ui/core/styles'
import {Colors, Spacing} from '../../../assets/styles'
import {useDispatch, useSelector} from 'react-redux'
import EmptyStateIconAndMessage from '../../EmptyStateIconAndMessage'
import RXRIcon from '../../RXRIcon'
import {selectAuthedUserId, selectResidentsNeedingForecasting} from '../../../reducers/selectors'
import {skipForecastForResident, createFeedbackForResident} from '../../../actions/feedbackActions'
import {FEEDBACK_TYPE_OVERALL_SENTIMENT, FEEDBACK_TYPE_PROBABILITY_OF_RENEWAL} from '../../../constants/ModelConstants'

function SuperForecaster(props) {
  const classes = useStyles()
  const [forecastIndex, setForecastIndex] = useState(0)
  const [isSaving, setIsSaving] = useState(false)
  const dispatch = useDispatch()
  const residentsToForecast = useRef(useSelector(selectResidentsNeedingForecasting))
  const authedStaffId = useSelector(selectAuthedUserId)
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const toForecastCount = residentsToForecast.current.length

  const handleSubmit = (resId, renewalProbability, sentimentScore) => {
    setIsSaving(true)
    Promise.all([
      createFeedbackForResident(dispatch, activeBuildingId, authedStaffId, resId, FEEDBACK_TYPE_OVERALL_SENTIMENT, sentimentScore),
      createFeedbackForResident(dispatch, activeBuildingId, authedStaffId, resId, FEEDBACK_TYPE_PROBABILITY_OF_RENEWAL, renewalProbability),
    ])
      .then(() => {
        setForecastIndex(c => c + 1)
      })
      .catch(e => console.error(e))
      .finally(() => {
        setIsSaving(false)
      })
  }

  const handleSkip = resId => {
    skipForecastForResident(dispatch, resId).then(() => setForecastIndex(c => c + 1))
  }

  return (
    <div className={classes.superForecaster}>
      <div className={classes.header}>
        <h3>Superforecaster</h3>
        {toForecastCount > 0 && forecastIndex < toForecastCount && (
          <div className={classes.counter}>
            {forecastIndex + 1} / {toForecastCount}
          </div>
        )}
      </div>
      {forecastIndex >= toForecastCount ? (
        <EmptyStateIconAndMessage message={'Feedback complete at this time, thank you.'} icon={RXRIcon.FEEDBACK} />
      ) : (
        <ResidentSentimentForecaster
          residentId={residentsToForecast.current[forecastIndex].id}
          onSubmit={handleSubmit}
          onSkip={handleSkip}
          skipText={'Skip this resident'}
        />
      )}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  superForecaster: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: Spacing.spaceMedium,

    '& h3': {
      textTransform: 'uppercase',
      color: Colors.rxrMediumGreyColor,
    },
  },
  counter: {
    color: Colors.rxrMediumGreyColor,
  },
}))

SuperForecaster.propTypes = {}

export default SuperForecaster
