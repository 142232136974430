import React from 'react'
import moment from 'moment'
import Routes from '../../constants/RouteConstants'
import SortableTable, {ColumnConfig} from '../SortableTable'
import {compose} from 'redux'
import {withRouter} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {CORE_FEATURE_KORMAN} from '../../constants/ModelConstants'

function EventTable(props) {
  const coreFeatures = useSelector(state => state.GroupPermissions.coreFeatures)
  // we only show RSVP to Korman properties for now
  const showRSVP = !coreFeatures[CORE_FEATURE_KORMAN]

  const handleClickEvent = event => {
    props.history.push(Routes.constructPath(Routes.EVENTS_VIEW_SINGLE, {eventId: event.id}))
  }

  const columns = [
    new ColumnConfig({
      title: 'Name',
      render: e => e.eventName,
      comparator: ColumnConfig.simpleComparatorGenerator('eventName'),
    }),

    new ColumnConfig({
      title: 'Event byline',
      render: e => e.previewDescription,
      comparator: ColumnConfig.simpleComparatorGenerator('previewDescription'),
    }),

    new ColumnConfig({
      title: 'Location',
      render: e => e.location,
      comparator: ColumnConfig.simpleComparatorGenerator('location'),
    }),

    new ColumnConfig({
      title: 'Date',
      render: e => moment(e.startTime).format('l'),
      comparator: (a, b) => moment(a.startTime) - moment(b.startTime),
      isDefaultSort: true,
      sortingDirection: 'desc',
    }),

    new ColumnConfig({
      title: 'Start time',
      render: e => moment(e.startTime).format('h:mma'),
    }),
  ]

  if (showRSVP) {
    columns.push(
      new ColumnConfig({
        title: 'RSVP count',
        render: e => (e.rsvpRequired && e.headcount ? e.headcount : '-'),
      }),
      new ColumnConfig({
        title: 'RSVP vs Cap',
        render: e => (e.rsvpRequired && e.capacity ? `${e.headcount ? e.headcount : 0} / ${e.capacity}` : '-'),
      }),
    )
  }

  return <SortableTable downloadFileName={'Events'} data={props.events} columns={columns} onClickRow={handleClickEvent} />
}

export default compose(withRouter)(EventTable)
