import React from 'react'
import PropTypes from 'prop-types'
import {Colors, RXOTextField} from '../assets/styles'
import {makeStyles} from '@material-ui/core/styles'
import SimpleSpinner from './SimpleSpinner'
import RXRIcon from './RXRIcon'

const useStyles = makeStyles(theme => ({
  dateContainer: {},
}))

function DateInput(props) {
  const classes = useStyles()

  const label = props.isRequired ? `${props.label} *` : props.label

  const calendarColor = props.error ? Colors.rxrRedColor : props.disabled ? Colors.rxrDarkGreyColor : Colors.rxrBlueColor

  const onChangeHandler = selectedDate => {
    if (typeof props.onChange === 'function') {
      props.onChange(selectedDate)
    }
  }

  const onMonthChangeHandler = newMonth => {
    if (typeof props.onMonthChange === 'function') {
      props.onMonthChange(newMonth)
    }
  }

  return (
    <RXOTextField.FormDateField
      variant="inline"
      className={`${classes.dateContainer} ${props.className || ''}`}
      format="MM/dd/yyyy"
      label={label}
      minDate={props.min || undefined}
      maxDate={props.max || undefined} // passing null here disables the next month option for some reason so we enforce undefined
      onChange={onChangeHandler}
      onMonthChange={onMonthChangeHandler}
      shouldDisableDate={props.shouldDisableDate}
      // emptyLabel={props.placeholder || 'mm/dd/yyy'}
      helperText={props.helperText}
      value={props.value}
      KeyboardButtonProps={{
        'aria-label': 'Change date',
        ...(props.isReadOnly ? {disabled: true, style: {display: 'none'}} : undefined),
      }}
      keyboardIcon={
        props.isLoading ? <SimpleSpinner /> : <RXRIcon size={RXRIcon.SIZE_LARGE} icon={RXRIcon.CALENDAR} color={calendarColor} />
      }
      inputVariant={typeof label === 'string' ? 'outlined' : 'standard'}
      InputLabelProps={{
        shrink: true,
      }}
      disabled={props.disabled || props.isLoading}
      autoOk={true}
      readOnly={props.isReadOnly}
      defaultValue={props.defaultValue}
      fullWidth
      error={props.error}
    />
  )
}

DateInput.propTypes = {
  onChange: PropTypes.func,
  onMonthChange: PropTypes.func,
  shouldDisableDate: PropTypes.func,
  value: PropTypes.any,
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  // placeholder: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  defaultValue: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default DateInput
