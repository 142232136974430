import React, {useState} from 'react'
import PropTypes from 'prop-types'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import {DateRangePicker} from 'react-dates'
import {makeStyles} from '@material-ui/core/styles'
import moment from 'moment'
import {rxrDarkGreyColor, rxrGreyColor, rxrTealColor, rxrWhiteColor} from '../assets/styles/color'
import {IconButton} from '@material-ui/core'
import RXRIcon from './RXRIcon'
import {iconButton} from '../assets/styles/buttons'

const sharedDayStyles = {
  background: rxrTealColor,
  color: rxrWhiteColor,
  border: '1px solid ' + rxrTealColor,
}

const hoveredDayStyles = {
  ...sharedDayStyles,
  background: '#81cbeb',
}

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    borderBottom: '2px solid ' + rxrGreyColor,
    '& .DateRangePickerInput': {
      border: 0,
    },
    '& .DateInput': {
      backgroundColor: 'transparent',
      width: '100px',
    },
    '& .DateInput_input': {
      fontSize: '14px',
      height: '48px',
      backgroundColor: 'transparent',
    },
    '& .CalendarDay': {lineHeight: '38px'},
    '& .CalendarDay__selected': sharedDayStyles,
    '& .CalendarDay__selected:hover': sharedDayStyles,
    '& .CalendarDay__selected_span': sharedDayStyles,
    '& .CalendarDay__selected_span:hover': hoveredDayStyles,
    '& .CalendarDay__hovered_span:hover': hoveredDayStyles,
    '& .CalendarDay__hovered_span': hoveredDayStyles,
    '& .DayPickerKeyboardShortcuts_buttonReset': {
      display: 'none',
    },
    '& .DateInput_input__focused': {
      borderColor: rxrTealColor,
    },
  },

  icon: {
    ...iconButton,
    height: RXRIcon.BUTTON_SIZE_LARGE,
    width: RXRIcon.BUTTON_SIZE_LARGE,
  },
}))

const FOCUSED_INPUT_START = 'startDate'
// const FOCUSED_INPUT_END = 'endDate'

function DateRangeInput(props) {
  const classes = useStyles()
  const [uuid] = useState(('' + Math.random()).substr(-10))
  const [focusedInput, setFocusedInput] = useState(null)
  const [shouldShowClearButton, setShouldShowClearButton] = useState(props.startDate || props.endDate)

  /**
   * @param {{startDate:moment, endDate: moment}} dates
   */
  const handleDatesChanged = dates => {
    // we place our start date to be 00:00:00 of that date
    let startDate = dates.startDate ? dates.startDate.startOf('day').toDate() : null
    // we place our end date to be 23:59:59 of that date
    let endDate = dates.endDate ? dates.endDate.endOf('day').toDate() : null

    setShouldShowClearButton(startDate || endDate)

    props.onChange(startDate, endDate)
  }

  const minDate = props.minDate ? moment(props.minDate) : null
  const maxDate = props.maxDate ? moment(props.maxDate) : null

  return (
    <div className={classes.container}>
      <DateRangePicker
        startDateId={'start-' + uuid}
        endDateId={'end-' + uuid}
        startDate={props.startDate ? moment(props.startDate) : null}
        endDate={props.endDate ? moment(props.endDate) : null}
        onDatesChange={handleDatesChanged}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        isOutsideRange={date => {
          // minDate prop doesn't seem to be working, going with this solution instead
          return (minDate && date.isBefore(minDate)) || (maxDate && date.isAfter(maxDate))
        }}
        minimumNights={0}
        numberOfMonths={1}
      />

      {shouldShowClearButton ? (
        <IconButton className={classes.icon} onClick={() => handleDatesChanged({})}>
          <RXRIcon icon={RXRIcon.CLOSE} size={RXRIcon.SIZE_LARGE} />
        </IconButton>
      ) : (
        <IconButton className={classes.icon} onClick={() => setFocusedInput(FOCUSED_INPUT_START)}>
          <RXRIcon icon={RXRIcon.CALENDAR} size={RXRIcon.SIZE_LARGE} color={rxrDarkGreyColor} />
        </IconButton>
      )}
    </div>
  )
}

DateRangeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
}

export default DateRangeInput
