import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import {IconButton} from '@material-ui/core'
import TrashAltInput from '../assets/images/Icon/TrashAltInput.svg'
import {v4 as uuid} from 'uuid'
import {spaceMedium, spaceSmall} from '../assets/styles/spacing'

const useStyles = makeStyles(theme => ({
  gridstyling: {
    marginTop: 20,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: spaceMedium,
  },
  iconPlaceholder: {
    width: 52,
    height: 56,
    flexShrink: 0,
    marginLeft: spaceSmall,
  },
}))

function getId(obj) {
  return obj.id || obj.rowId
}

function isObjectComplete(obj) {
  return !Object.values(obj).some(o => o === null || o === '')
}

function GenericManyInput(props) {
  const classes = useStyles()
  const {value} = props
  const [newModel, setNewModel] = useState(props.emptyModel)

  const handleExistingChange = obj => {
    if (!isObjectComplete(obj)) {
      return
    }

    let valueObj = value.find(a => getId(a) === getId(obj))
    Object.keys(props.emptyModel).forEach(key => {
      if (key !== 'rowId') {
        valueObj[key] = obj[key]
      }
    })

    props.onChange([...value])
  }

  const handleNewChange = obj => {
    let newObj = {
      ...obj,
      rowId: uuid(),
    }

    // if all fields are complete
    if (isObjectComplete(newObj)) {
      // we add it to the list
      props.onChange([...value, newObj])
      // and reset our New input object
      setNewModel(props.emptyModel)

      // call on Add if it's present
      if (typeof props.onAdd === 'function') {
        props.onAdd(newObj)
      }
    } else {
      // else, we just update our New input object
      setNewModel(newObj)
    }
  }

  const handleRemove = obj => {
    props.onChange(value.filter(a => getId(a) !== getId(obj)))

    // call on Remove if it's present
    if (typeof props.onRemove === 'function') {
      props.onRemove(obj)
    }
  }

  const SingleInputComponent = props.singleInputComponent

  return (
    <React.Fragment>
      {value.map((field, index) => {
        return (
          <div key={getId(field)} className={classes.inputContainer}>
            <SingleInputComponent
              {...props.singleInputProps}
              isRequired={index === 0}
              value={field}
              onChange={obj => handleExistingChange(obj)}
              error={props.error}
            />
            <IconButton
              style={{flexShrink: 0, marginLeft: spaceSmall}}
              component="span"
              aria-label="remove"
              onClick={() => handleRemove(field)}
            >
              <img src={TrashAltInput} />
            </IconButton>
          </div>
        )
      })}
      <div className={classes.inputContainer}>
        <SingleInputComponent
          {...props.singleInputProps}
          value={newModel}
          isRequired={value.length === 0}
          onChange={obj => handleNewChange(obj)}
          error={props.error}
        />
        <div className={classes.iconPlaceholder} />
      </div>
    </React.Fragment>
  )
}

GenericManyInput.propTypes = {
  onChange: PropTypes.func.isRequired, // returns the array of objects
  value: PropTypes.array.isRequired, // an array of objects
  emptyModel: PropTypes.object.isRequired, // an object that represents an empty state of the object we're modifying
  singleInputComponent: PropTypes.func.isRequired, // a component that represents a single input
  singleInputProps: PropTypes.object,
  onRemove: PropTypes.func, // optional callback to handle Removals separate
  onAdd: PropTypes.func, // optional callback to handle New elements separate
}

export default GenericManyInput
