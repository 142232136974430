import React, {useRef, useEffect, useState} from 'react'
import {select, scaleLinear, scaleBand} from 'd3'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'

function D3Chart(props) {
  const classes = useStyles()
  const container = useRef(null)
  const [minWidth, setMinWidth] = useState(0)

  useEffect(() => {
    let resizeTimeout
    const handleResize = () => {
      clearTimeout(resizeTimeout)
      resizeTimeout = setTimeout(() => {
        const newWidth = determineMinWidth(props.xAxisLabels, props.formatXAxis)
        setMinWidth(newWidth)
        props.onReady(initChart(container.current))
        // to avoid calling this too many times, we set it in a timeout
      }, 300)
    }
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [props.xAxisLabels, props.formatXAxis, props.onReady])

  return (
    <div className={classes.outerContainer}>
      <div className={classes.container} ref={container} id={props.id} style={{minWidth: `${minWidth}px`}} />
    </div>
  )
}

D3Chart.propTypes = {
  onReady: PropTypes.func.isRequired,
  id: PropTypes.string,
  xAxisLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  formatXAxis: PropTypes.func,
}

const useStyles = makeStyles(theme => ({
  container: {
    lineHeight: 0,
    height: '100%',
  },
  outerContainer: {
    overflowX: 'auto',
    overflowY: 'hidden',
    position: 'relative',
    height: '100%',
  },
}))

// -----------------------------------------------------------------------------------
// Helper functions

/**
 * @param {HTMLElement} elem

 */
function initChart(elem) {
  if (!elem) return [undefined, undefined, undefined]

  const width = elem.clientWidth
  const height = elem.clientHeight || 500
  const margin = 60

  // clear the element
  select(elem).html('')

  const canvas = select(elem)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    // this 8 is just to make sure the Y axis labels don't get cut off at the top
    .attr('transform', 'translate(' + margin + ', 8)')

  // the canvas height and width is the area the graph will actually draw.
  // It will not include axis labels so we need to make sure there's enough space
  canvas.height = height - margin
  canvas.width = width - margin * 2

  const x = scaleBand().range([0, canvas.width])
  const y = scaleLinear().range([canvas.height, 0])

  return [canvas, x, y]
}

/**
 * @param {Array<string>} labels
 * @param {function?} formatXAxis
 */
function determineMinWidth(labels, formatXAxis) {
  // Initialize max width of label to be 20 pixels. We will return the maxWidthOfLabel
  // multiplied by the number of labels to determine the minX of the canvas
  let maxWidthOfLabel = 20
  labels.forEach(l => {
    if (formatXAxis) {
      l = formatXAxis(l)
    }
    let widthOfDataXAxisLabel = estimateWidthOfLabelBasedOnNumberOfCharacters(l)
    maxWidthOfLabel = Math.max(widthOfDataXAxisLabel, maxWidthOfLabel)
  })
  return maxWidthOfLabel * labels.length
}

/**
 * @param {String} label
 */
function estimateWidthOfLabelBasedOnNumberOfCharacters(label) {
  let lengthOfLabel = label.length
  let estimatedWidthOfCharacter = 5 // Estimate a character to have a width of 5 pixels
  return lengthOfLabel * estimatedWidthOfCharacter
}

export default D3Chart
