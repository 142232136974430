// TODO: Merge this with GenericFileSelector

import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {rxrMediumLightGreyColor, rxrBlackColor, rxrBlueColor, rxrLightGreyColor, rxrWhiteColor} from '../../assets/styles/color'
import {spaceMedium, spaceSmall} from '../../assets/styles/spacing'
import PropTypes from 'prop-types'
import FileHelper from '../../lib/FileHelper'
import SimpleSpinner from '../SimpleSpinner'
import AttachedFile from './AttachedFile'

function FileAttacher(props) {
  const classes = useStyles(props)
  const [isLoading, setIsLoading] = useState(false)
  const filesArray = Array.isArray(props.value) ? props.value : []

  const handleFileSelect = e => {
    setIsLoading(true)
    FileHelper.Instance()
      .handleSelectFilesInputEventUploadToS3(e)
      .then(uploaded => {
        props.onChange([...filesArray, ...uploaded].map(obj => FileHelper.formatS3ObjectForInput(obj)))
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => setIsLoading(false))
  }

  const handleRemoveAttachment = index => {
    let newFiles = [...filesArray]
    newFiles.splice(index, 1)
    props.onChange(newFiles.map(obj => FileHelper.formatS3ObjectForInput(obj)))
  }

  return (
    <div className={classes.fileAttacher}>
      {filesArray.length > 0 && (
        <div className={classes.attachmentList}>
          {filesArray.map((file, idx) => {
            return (
              <div className={classes.attachedFileContainer} key={`${file.displayName}-${idx}`}>
                <AttachedFile file={file} handleRemoveAttachment={handleRemoveAttachment} idx={idx} />
              </div>
            )
          })}
        </div>
      )}
      {!props.isDisabled && (
        <React.Fragment>
          <input name="file" onChange={handleFileSelect} style={{display: 'none'}} id="upload-file-input" multiple type="file" />
          <label htmlFor="upload-file-input">
            <div className={classes.attachFilesCopy}>
              {isLoading ? <SimpleSpinner /> : <span>Attach {filesArray.length > 0 ? 'more ' : ''}files</span>}
            </div>
          </label>
        </React.Fragment>
      )}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  fileAttacher: {
    width: '100%',
    border: `1px dashed ${rxrMediumLightGreyColor}`,
    borderRadius: 4,
    backgroundColor: rxrWhiteColor,
  },
  attachmentList: {
    marginTop: spaceSmall,
  },
  attachedFileContainer: {
    marginLeft: spaceMedium,
  },
  attachFilesCopy: props => ({
    cursor: 'pointer',
    textDecoration: 'underline',
    width: '100%',
    textAlign: 'center',
    color: (props.value && Array.isArray(props.value) ? props.value : []).length > 0 ? rxrBlueColor : rxrBlackColor,
    backgroundColor: (props.value && Array.isArray(props.value) ? props.value : []).length > 0 ? rxrLightGreyColor : 'transparent',
    padding: spaceSmall,
  }),
}))

FileAttacher.propTypes = {
  value: PropTypes.array, // Array of S3 objects
  onChange: PropTypes.func.isRequired, // callback invoked with the selected files prop
  isDisabled: PropTypes.bool,
}

export default FileAttacher
