import React from 'react'
import moment from 'moment'
import SortableTable, {ColumnConfig} from '../SortableTable'
import Routes from '../../constants/RouteConstants'
import {withRouter} from 'react-router-dom'

function AnnouncementsTable(props) {
  const now = moment()

  const columns = [
    new ColumnConfig({
      title: 'Title',
      render: a => a.headline,
      comparator: ColumnConfig.simpleComparatorGenerator('headline'),
    }),

    new ColumnConfig({
      title: 'Posting Date',
      render: a => moment(a.activeStart).format('l'),
      comparator: (a, b) => moment(a.activeStart) - moment(b.activeStart),
      isDefaultSort: true,
      sortingDirection: 'desc',
    }),

    new ColumnConfig({
      title: 'Expiry date',
      render: a => moment(a.activeEnd).format('l'),
      comparator: (a, b) => moment(a.activeEnd) - moment(b.activeEnd),
    }),

    new ColumnConfig({
      title: 'Status',
      render: a => {
        const start = moment(a.activeStart)
        const end = moment(a.activeEnd)
        const isActive = now >= start && now <= end
        return isActive ? 'Active' : 'Inactive'
      },
    }),
  ]

  return (
    <SortableTable
      data={props.announcements}
      columns={columns}
      downloadFileName={'Announcements'}
      onClickRow={a => props.history.push(Routes.constructPath(Routes.ANNOUNCEMENTS_VIEW_SINGLE, {announcementId: a.id}))}
    />
  )
}

export default withRouter(AnnouncementsTable)
