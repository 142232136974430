import React, {useState} from 'react'
import RXRButton from '../../RXRButton'
import useSharedStyles from '../useSharedStyles'
import PropTypes from 'prop-types'

function MarkTaskCompletedCTAs(props) {
  const classes = useSharedStyles()
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async () => {
    setIsLoading(true)
    try {
      await props.onMarkCompleted()
    } catch (err) {
      window.alert(err.message)
      console.error(err)
    }
    setIsLoading(false)
  }

  return (
    <div className={classes.ctaContainer}>
      <RXRButton onClick={handleClick} className={classes.CTA} isLoading={isLoading}>
        Mark completed
      </RXRButton>
    </div>
  )
}

MarkTaskCompletedCTAs.propTypes = {
  onMarkCompleted: PropTypes.func.isRequired,
}

export default MarkTaskCompletedCTAs
