import React from 'react'
import PropTypes from 'prop-types'
import SingleGeneralAvailabilityInput from './SingleGeneralAvailabilityInput'
import GenericManyInput from '../GenericManyInput'

const initialGeneralAvailabilityModel = {dayOfWeek: '', startTime: null, endTime: null}

function ManyGeneralAvailabilityInput(props) {
  return (
    <GenericManyInput
      onChange={props.onChange}
      value={props.value}
      emptyModel={initialGeneralAvailabilityModel}
      singleInputComponent={SingleGeneralAvailabilityInput}
      singleInputProps={{increment: props.increment}}
      onRemove={props.onRemove}
      error={props.error}
    />
  )
}

ManyGeneralAvailabilityInput.propTypes = {
  onChange: PropTypes.func.isRequired, // returns the array of GeneralAvailability objects
  onRemove: PropTypes.func.isRequired, // how to treat deletions
  value: PropTypes.array.isRequired, // an array of GeneralAvailabilityModal
  increment: PropTypes.number.isRequired, // reservationIncrement (time interval)
}

export default ManyGeneralAvailabilityInput
