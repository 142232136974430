import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {Grid, makeStyles} from '@material-ui/core'
import {rxrBlackColor, rxrBlueColor, rxrMediumGreyColor, rxrTealColor} from '../../assets/styles/color'
import {selectResidentsByUnit} from '../../Utils/residentUtils'
import * as Constant from './ResidentConstant'
import RXRButton from '../RXRButton'
import {residentProfileTabHeading} from '../../assets/styles/typography'
import {spaceLarge, spaceMedium, spaceSmall} from '../../assets/styles/spacing'
import Routes from './../../constants/RouteConstants'
import {matchPath, useHistory, useLocation} from 'react-router-dom'
import RXRIcon from '../RXRIcon'
import {navigationButtonWithArrow} from '../../assets/styles/typography'
import SidePanel from '../SidePanel'
import RentBreakdown from './RentBreakdown/RentBreakdown'
import DiscountsBreakdown from './Discounts/DiscountsBreakdown'
import CreateNewCreditForm from './Discounts/CreateNewCreditForm'
import {closeInvoice, getDiscountsForResident} from '../../lib/queries'
import {formatPhoneNumber, pluralizeWord} from '../../Utils/StringFormatter'
import * as queries from '../../lib/queries'
import ResidentForecasterCard from '../ResidentSentiment/Forecaster/ResidentForecasterCard'
import {
  CORE_FEATURE_CLEANINGS,
  CORE_FEATURE_DOG_WALKING,
  CORE_FEATURE_MASSAGE,
  CORE_FEATURE_PERSONAL_TRAINING,
  CORE_FEATURE_RENT,
} from '../../constants/ModelConstants'

const useStyles = makeStyles(theme => ({
  profileContainer: {
    paddingLeft: '40px',
    width: '100%',
    marginTop: spaceMedium,
  },
  infoTitle: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: rxrMediumGreyColor,
  },
  infoData: {
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: rxrBlackColor,
    paddingTop: spaceSmall,
  },
  ptePreferenceContainer: {
    marginLeft: -spaceMedium,
  },
  rowPadding: {
    paddingBottom: spaceLarge,
    marginLeft: spaceSmall,
  },
  rowPaddingDecreasedPaddingBottom: {
    paddingBottom: spaceMedium,
    marginLeft: '16px',
  },
  tabHeading: {
    ...residentProfileTabHeading,
  },
  editButtonContainer: {
    paddingBottom: spaceSmall,
  },
  viewRentBreakdown: {
    ...navigationButtonWithArrow,
  },
  link: {
    color: rxrTealColor,
    cursor: 'pointer',
    marginRight: spaceSmall,
  },

  forecaster: {
    marginBottom: spaceLarge,
  },
}))

const PACKAGE_MAP = {
  ['inUnit']: 'In unit',
  ['frontDesk']: 'Front desk',
}
function ProfileDetail(props) {
  const classes = useStyles()

  const history = useHistory()
  const currentLocation = useLocation()

  const viewRentBreakdownMatch = matchPath(currentLocation.pathname, {path: Routes.PROFILE_VIEW_RENT_BREAKDOWN})
  const viewDiscountsBreakdownMatch = matchPath(currentLocation.pathname, {path: Routes.PROFILE_VIEW_DISCOUNTS_BREAKDOWN})
  const [isRefreshingInvoice, setIsRefreshingInvoice] = useState(false)
  const [activeInvoice, setActiveInvoice] = useState(undefined)

  const residentsLookup = useSelector(state => state.Residents.residentsLookup)
  const occupancy = useSelector(state => state.Residents.residentsLookup[props.residentId].occupancy)
  const activeBuilding = useSelector(state => state.Buildings.buildingsLookup[state.Buildings.activeBuildingId])
  const resident = useSelector(state => state.Residents.residentsLookup[props.residentId])
  const permissionsObject = useSelector(state => state.GroupPermissions.permissionsObject)
  const coreFeatures = useSelector(state => state.GroupPermissions.coreFeatures)

  const userProfile = resident.userProfile ? JSON.parse(resident.userProfile) : {}
  const [discounts, setDiscounts] = useState([])

  useEffect(() => {
    if (!props.residentId) {
      setDiscounts([])
      return
    }

    const today = new Date()
    getDiscountsForResident(props.residentId, activeBuilding.id).then(all =>
      // only show active discounts
      setDiscounts(all.filter(d => !d.vendorAppointmentId && d.expiredAt > today)),
    )
  }, [props.residentId])

  useEffect(() => {
    queries.getActiveInvoiceByOccupancy(occupancy.id).then(i => {
      setActiveInvoice(i)
    })
  }, [occupancy.id])

  let roommates = []
  if (resident.occupancy && resident.occupancy.unit && resident.occupancy.unit.id) {
    roommates = selectResidentsByUnit(residentsLookup, resident.occupancy.unit.id).filter(r => r.id !== resident.id)
  }

  const handleRefreshInvoice = () => {
    if (isRefreshingInvoice) {
      return
    }
    setIsRefreshingInvoice(false)
    return closeInvoice(activeInvoice.id)
      .then(() => {
        setActiveInvoice(null)
      })
      .catch(err => {
        window.alert('ERROR: ' + err.message)
      })
      .finally(() => {
        setIsRefreshingInvoice(false)
      })
  }

  /**
   * Common function for rendeing data point.
   * @param {*} title
   * @param {*} data
   * @returns {string}
   */
  const renderInfo = (title, data) => (
    <Grid container direction="column">
      <Grid item>
        <div className={classes.infoTitle}>{title}</div>
      </Grid>
      <Grid item>
        <div className={classes.infoData}>{data ? data : '--'}</div>
      </Grid>
    </Grid>
  )

  const renderPTE = () => (
    <Grid container direction="column" style={{paddingBottom: '24px'}}>
      <Grid item>
        <div className={classes.infoTitle}>PTE</div>
      </Grid>
      <Grid item>
        {userProfile.maintenancePermission ||
        userProfile.cleaningPermission ||
        userProfile.deliveryPermission ||
        userProfile.thirdPartyPermission ? (
          <ul className={classes.ptePreferenceContainer}>
            {!!userProfile.maintenancePermission && <li className={classes.infoData}>Maintenance</li>}
            {!!userProfile.cleaningPermission && <li className={classes.infoData}>Cleaning</li>}
            {!!userProfile.deliveryPermission && <li className={classes.infoData}>Package delivery</li>}
            {!!userProfile.thirdPartyPermission && <li className={classes.infoData}>3rd party service providers</li>}
          </ul>
        ) : (
          <div className={classes.infoData}>--</div>
        )}
      </Grid>
    </Grid>
  )

  const handleClickViewRentBreakdown = () => {
    history.push(Routes.constructPath(Routes.PROFILE_VIEW_RENT_BREAKDOWN, {residentId: props.residentId}))
  }

  const handleClickViewDiscountsBreakdown = () => {
    history.push(Routes.constructPath(Routes.PROFILE_VIEW_DISCOUNTS_BREAKDOWN, {residentId: props.residentId}))
  }

  const handleClickCreateDiscount = () => {
    history.push(Routes.constructPath(Routes.PROFILE_VIEW_DISCOUNTS_CREATE, {residentId: props.residentId}))
  }

  const hideSlidingPanel = () => {
    history.push(Routes.constructPath(Routes.PROFILE_VIEW_SINGLE, {residentId: props.residentId}))
  }

  const handleClickEdit = () => {
    history.push(Routes.constructPath(Routes.PROFILE_EDIT, {residentId: props.residentId}))
  }

  let userBirthdayMonth
  let userBirthdayDay
  if (userProfile.birthday) {
    userBirthdayMonth = userProfile.birthday.slice(5, 7)
    userBirthdayDay = userProfile.birthday.slice(8, 10)
  }

  let sidePanelComponent
  let sidePanelTitle
  if (viewRentBreakdownMatch || viewDiscountsBreakdownMatch) {
    if (viewRentBreakdownMatch) {
      sidePanelComponent = <RentBreakdown activeInvoice={activeInvoice} occupancy={occupancy} onRefreshInvoice={handleRefreshInvoice} />
      sidePanelTitle = 'Rent breakdown'
    } else if (viewDiscountsBreakdownMatch.isExact) {
      sidePanelComponent = <DiscountsBreakdown residentId={props.residentId} />
      sidePanelTitle = 'Credit details'
    } else {
      sidePanelComponent = <CreateNewCreditForm residentId={props.residentId} />
      sidePanelTitle = 'Issue credit'
    }
  }

  const hasServices =
    coreFeatures[CORE_FEATURE_CLEANINGS] ||
    coreFeatures[CORE_FEATURE_PERSONAL_TRAINING] ||
    coreFeatures[CORE_FEATURE_MASSAGE] ||
    coreFeatures[CORE_FEATURE_DOG_WALKING]

  return (
    <div className={classes.profileContainer}>
      <Grid container>
        <Grid item md={8} lg={8}>
          <div className={classes.tabHeading}>{Constant.RESIDENT_DETAILS}</div>
          <Grid container className={classes.rowPadding}>
            <Grid item md={6}>
              {renderInfo('Name', resident.displayName)}
            </Grid>
            <Grid item md={6}>
              {renderInfo('Goes by', userProfile.goesBy)}
            </Grid>
          </Grid>

          <Grid container className={classes.rowPadding}>
            <Grid item md={6}>
              {renderInfo('Gender', userProfile.gender ? userProfile.gender : '')}
            </Grid>
            <Grid item md={6}>
              {renderInfo('Birthday', userBirthdayMonth && userBirthdayDay ? `${userBirthdayMonth}/${userBirthdayDay}` : '')}
            </Grid>
          </Grid>

          <Grid container className={classes.rowPadding}>
            <Grid item md={6}>
              {renderInfo('Phone number', formatPhoneNumber(resident.phoneNumber))}
            </Grid>
            <Grid item md={6}>
              {renderInfo('Email', resident.email)}
            </Grid>
          </Grid>

          <ResidentForecasterCard className={classes.forecaster} residentId={props.residentId} alwaysOn={true} />

          <div className={classes.tabHeading}>{Constant.UNIT_DETAILS}</div>
          <Grid container className={classes.rowPadding}>
            <Grid item md={6}>
              {renderInfo('Building', activeBuilding.displayName)}
            </Grid>
            <Grid item md={6}>
              {renderInfo('Unit', resident.occupancy.unit.number)}
            </Grid>
          </Grid>

          <Grid container className={classes.rowPadding}>
            <Grid item>
              {roommates.length > 0 ? (
                <Grid container direction="column">
                  <Grid item>
                    <div className={classes.infoTitle}>Roommate(s)</div>
                  </Grid>
                  <Grid item>
                    <div className={classes.infoData}>
                      {roommates.map((r, i) => (
                        <span
                          className={classes.link}
                          key={r.id}
                          onClick={() => history.push(Routes.constructPath(Routes.PROFILE_VIEW_SINGLE, {residentId: r.id}))}
                        >
                          {r.displayName}
                          {i < roommates.length - 1 ? ',' : ''}
                        </span>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Grid>

          {permissionsObject.sensitiveInformationRead && coreFeatures[CORE_FEATURE_RENT] && (
            <React.Fragment>
              <div className={classes.tabHeading}>{Constant.RENT_DETAILS}</div>
              <Grid container className={activeInvoice ? classes.rowPaddingDecreasedPaddingBottom : classes.rowPadding}>
                <Grid item md={6}>
                  {renderInfo('Balance', activeInvoice ? `$${(activeInvoice.total - activeInvoice.amountPaid).toFixed(2)}` : '')}
                </Grid>
                <Grid item md={6}>
                  {renderInfo('Auto-pay', resident.autoPay.items.some(element => element.active) ? `Auto-pay is on` : `Auto-pay is off`)}
                </Grid>
              </Grid>
              <Grid container className={classes.rowPadding}>
                <Grid item>
                  <RXRButton
                    type={RXRButton.TYPE_TEXT_SECONDARY}
                    className={classes.viewRentBreakdown}
                    onClick={handleClickViewRentBreakdown}
                  >
                    View rent breakdown
                    <RXRIcon icon={RXRIcon.ARROW_RIGHT} color={rxrBlueColor} style={{paddingLeft: spaceSmall}} />
                  </RXRButton>
                </Grid>
              </Grid>
            </React.Fragment>
          )}

          {hasServices && (
            <React.Fragment>
              <div className={classes.tabHeading}>Service discounts</div>
              <Grid container className={classes.rowPadding}>
                <Grid item md={6} xs={12}>
                  {renderInfo('Discounts', `${pluralizeWord('offer', discounts.length, true)} available to resident`)}
                </Grid>
                <Grid item md={3} xs={6}>
                  <RXRButton
                    type={RXRButton.TYPE_TEXT_SECONDARY}
                    className={classes.viewRentBreakdown}
                    onClick={handleClickViewDiscountsBreakdown}
                  >
                    View discounts
                    <RXRIcon icon={RXRIcon.ARROW_RIGHT} color={rxrBlueColor} style={{paddingLeft: spaceSmall}} />
                  </RXRButton>
                </Grid>

                <Grid item md={3} xs={6}>
                  <RXRButton type={RXRButton.TYPE_TEXT_SECONDARY} className={classes.viewRentBreakdown} onClick={handleClickCreateDiscount}>
                    Issue new credit
                    <RXRIcon icon={RXRIcon.ARROW_RIGHT} color={rxrBlueColor} style={{paddingLeft: spaceSmall}} />
                  </RXRButton>
                </Grid>
              </Grid>
            </React.Fragment>
          )}

          <div className={classes.tabHeading}>{Constant.RESIDENT_PREFERENCES}</div>
          <Grid container className={classes.rowPadding}>
            <Grid item md={6}>
              {renderInfo('Packages', PACKAGE_MAP[userProfile.packagePreference])}
            </Grid>
            <Grid item md={6}>
              {renderPTE()}
            </Grid>
          </Grid>

          <Grid container className={classes.rowPadding}>
            <Grid item md={6}>
              {renderInfo('Gift preferences', (userProfile.giftPreferences || []).map(g => Constant.GIFT_PREFERENCES_LABELS[g]).join(', '))}
            </Grid>
            <Grid item md={6}>
              {renderInfo(
                'Alcohol preferences',
                (userProfile.alcoholPreferences || []).map(a => Constant.ALCOHOL_PREFERENCES_LABELS[a]).join(', '),
              )}
            </Grid>
          </Grid>

          <Grid container className={classes.rowPadding}>
            <Grid item md={6}>
              {renderInfo('Food preferences', (userProfile.foodPreferences || []).map(f => Constant.FOOD_PREFERENCES_LABELS[f]).join(', '))}
            </Grid>
            <Grid item md={6}>
              {renderInfo('Dietary restrictions', userProfile.dietaryRestrictions)}
            </Grid>
          </Grid>

          <Grid container className={classes.rowPadding}>
            <Grid item md={6}>
              {renderInfo('Likes', userProfile.likes)}
            </Grid>
            <Grid item md={6}>
              {renderInfo('Dislikes', userProfile.dislikes)}
            </Grid>
          </Grid>

          <Grid container className={classes.rowPadding}>
            <Grid item md={6}>
              {renderInfo('Cleaning preferences', userProfile.cleaningPreferences)}
            </Grid>
            <Grid item md={6}>
              {renderInfo('Notes', userProfile.notes)}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.editButtonContainer}>
        <RXRButton onClick={handleClickEdit}>Edit</RXRButton>
      </div>
      <SidePanel isOpen={!!sidePanelComponent} onClose={hideSlidingPanel} title={sidePanelTitle}>
        {sidePanelComponent}
      </SidePanel>
    </div>
  )
}

ProfileDetail.propTypes = {
  residentId: PropTypes.string.isRequired,
}

export default ProfileDetail
