import React from 'react'
import moment from 'moment'
import {Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import {bodyText, H4HeaderBlack, subBodyText, totalText} from '../../../assets/styles/typography'
import {spaceExtraLarge, spaceSmall} from '../../../assets/styles/spacing'

const useStyles = makeStyles(theme => ({
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: spaceSmall,
    borderTop: '1px solid black',
  },
  totalText: {
    ...totalText,
  },
  H4HeaderBlack: {
    ...H4HeaderBlack,
  },
  sectionContainer: {
    paddingBottom: spaceExtraLarge,
  },
  bodyText: {...bodyText},
  subBodyText: {...subBodyText},
}))

export default function PaymentsTable(props) {
  const classes = useStyles()

  return (
    <div className={classes.sectionContainer}>
      <Typography className={classes.H4HeaderBlack}>Payments</Typography>
      {props.payments && props.payments.length > 0 ? (
        <React.Fragment>
          {props.payments.map(payment => {
            return (
              <div className={classes.payment}>
                <div className={classes.rowContainer}>
                  <Typography className={classes.bodyText}>{payment.paymentMethod.nickName}</Typography>
                  <Typography className={classes.bodyText}>{`$${payment.totalAmount.toFixed(2)}`}</Typography>
                </div>
                <div className={classes.rowContainer}>
                  <Typography className={classes.subBodyText}>{`${moment(payment.timestamp).format('MM/DD/YYYY')}, ${
                    payment.paymentMethod.type
                  }`}</Typography>
                  <Typography className={classes.subBodyText}>
                    {`${payment.status.charAt(0) + payment.status.slice(1).toLowerCase()}`} {payment.failureReason || ''}
                  </Typography>
                </div>
              </div>
            )
          })}
          <div className={classes.totalContainer}>
            <Typography className={classes.totalText}>Total payments</Typography>
            <Typography className={classes.totalText}>
              $
              {`${props.payments
                .reduce((accum, element) => {
                  return accum + element.totalAmount
                }, 0)
                .toFixed(2)}`}
            </Typography>
          </div>
        </React.Fragment>
      ) : (
        <Typography className={classes.bodyText}>Resident has not made any payments</Typography>
      )}
    </div>
  )
}

PaymentsTable.propTypes = {
  payments: PropTypes.array,
}
