import React from 'react'
import SortableTable, {ColumnConfig} from '../SortableTable'
import {useHistory} from 'react-router-dom'
import {getDateFromDateString_IgnoreTime, formatDateMMDDYYYY} from '../../Utils/dateTimeUtil'
import {rxrTealColor, rxrStatusGreenColor, rxrOrangeColor, rxrWhiteColor, rxrRedColor} from '../../assets/styles/color'
import {makeStyles} from '@material-ui/core/styles'
import {spaceSmall} from '../../assets/styles/spacing'
import {fontSizeMedium, fontSizeMediumSmall} from '../../assets/styles/typography'
import ProgressBar from '../ProgressBar'
import RXRButton from '../RXRButton'
import Routes from '../../constants/RouteConstants'
import {isOnboardingTaskComplete, isOnboardingTaskPendingApproval, isOnboardingTaskChangesRequested} from '../../Utils/onboardingUtils'
import {ONBOARDING_IMPORTANCE_CRITICAL, ONBOARDING_IMPORTANCE_REQUIRED} from '../../constants/ModelConstants'
import {shallowEqual, useSelector} from 'react-redux'
import {getOnboardingOccupanciesLookup, getOccupancyWithResidentAndUnitData, deepEquals} from '../../reducers/selectors'

const useStyles = makeStyles(theme => {
  return {
    progressContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    progressBarContainer: {
      width: '60%',
      height: '10px',
    },
    onboardingTasksCopyContainer: {
      marginRight: spaceSmall,
      marginLeft: spaceSmall,
      fontSize: fontSizeMediumSmall,
    },
    reviewButton: {
      width: 'fit-content',
      paddingLeft: spaceSmall,
      paddingRight: spaceSmall,
      height: '30x',
      borderRadius: '15px',
      fontSize: fontSizeMedium,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    viewFullDetails: {
      fontSize: fontSizeMedium,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }
})

const OnboardingTable = function(props) {
  const classes = useStyles()
  // using shallowEqual because the resulting object is newly constructed, but contains the original lookup's occupancy object reference
  const onboardingOccupanciesLookup = useSelector(state => getOnboardingOccupanciesLookup(state), shallowEqual)
  /** @type {Object<string, GenericChatRoom>} */
  const conversationsLookup = useSelector(state => state.Messages.conversationsLookup)
  // the resulting array is contains composite objects so need to use deepEquals
  const data = useSelector(
    state => Object.keys(onboardingOccupanciesLookup).map(oId => getOccupancyWithResidentAndUnitData(state, oId)),
    deepEquals,
  )
  const history = useHistory()

  const handleClickOccupancy = row => {
    history.push(Routes.constructPath(Routes.ONBOARDING_VIEW_OCCUPANCY_DETAILS, {occupancyId: row.id}))
  }

  const columns = [
    new ColumnConfig({
      title: 'Unit',
      renderPrimitive: occupancy => occupancy.unit.number,
      comparator: ColumnConfig.simpleComparatorGenerator('unit.number'),
      isDefaultSort: true,
    }),

    new ColumnConfig({
      title: 'Resident(s)',
      render: occupancy => {
        return (
          <div>
            {occupancy.residents.map(resident => {
              return <div key={resident.id}>{resident.displayName}</div>
            })}
          </div>
        )
      },
      renderPrimitive: occupancy => occupancy.residents.map(resident => resident.displayName).join(', '),
    }),

    new ColumnConfig({
      title: 'Lease start',
      renderPrimitive: occupancy => formatDateMMDDYYYY(getDateFromDateString_IgnoreTime(occupancy.leaseFromDate)),
      comparator: ColumnConfig.simpleComparatorGenerator('leaseFromDate'),
    }),

    new ColumnConfig({
      title: 'Move-ins',
      render: occupancy => {
        return (
          <div>
            {occupancy.residents
              .filter(resident => !!resident.moveInDate)
              .map(resident => {
                return (
                  <div key={resident.id}>{`${resident.displayName}: ${formatDateMMDDYYYY(
                    getDateFromDateString_IgnoreTime(resident.moveInDate),
                  )}`}</div>
                )
              })}
          </div>
        )
      },
      renderPrimitive: occupancy => {
        return occupancy.residents
          .filter(resident => !!resident.moveInDate)
          .map(resident => {
            return `${resident.displayName}: ${formatDateMMDDYYYY(getDateFromDateString_IgnoreTime(resident.moveInDate))}`
          })
          .join(', ')
      },
    }),

    new ColumnConfig({
      title: 'Log in',
      render: occupancy => {
        return (
          <div>
            {occupancy.residents.map(resident => {
              return <div key={resident.id}>{`${resident.displayName}: ${resident.lastLoggedInAt ? 'Yes' : 'No'}`}</div>
            })}
          </div>
        )
      },
      renderPrimitive: occupancy => {
        return occupancy.residents
          .map(resident => {
            return `${resident.displayName}: ${resident.lastLoggedInAt ? 'Yes' : 'No'}`
          })
          .join(', ')
      },
    }),

    new ColumnConfig({
      title: 'Onboarding progress',
      render: occupancy => {
        const hasOnboardingTasks =
          occupancy.onboardingTasks &&
          Array.isArray(occupancy.onboardingTasks) &&
          occupancy.onboardingTasks.filter(
            task => task.importance === ONBOARDING_IMPORTANCE_CRITICAL || task.importance === ONBOARDING_IMPORTANCE_REQUIRED,
          ).length > 0
        const onboardingTasks = hasOnboardingTasks
          ? occupancy.onboardingTasks.filter(
              task => task.importance === ONBOARDING_IMPORTANCE_CRITICAL || task.importance === ONBOARDING_IMPORTANCE_REQUIRED,
            )
          : []
        const totalOnboardingTasks = hasOnboardingTasks ? onboardingTasks.length : 0
        const completedOnboardingTasks = hasOnboardingTasks
          ? onboardingTasks.reduce((accum, currVal) => {
              if (isOnboardingTaskComplete(currVal)) {
                accum++
              }
              return accum
            }, 0)
          : 0
        const numTasksPendingApproval = hasOnboardingTasks
          ? onboardingTasks.reduce((accum, currVal) => {
              if (isOnboardingTaskPendingApproval(currVal)) {
                accum++
              }
              return accum
            }, 0)
          : 0
        const numTasksChangesRequested = hasOnboardingTasks
          ? onboardingTasks.reduce((accum, currVal) => {
              if (isOnboardingTaskChangesRequested(currVal)) {
                accum++
              }
              return accum
            }, 0)
          : 0

        const completedAllTasks = hasOnboardingTasks && completedOnboardingTasks === totalOnboardingTasks
        const dynamicColor =
          numTasksPendingApproval > 0
            ? rxrOrangeColor
            : numTasksChangesRequested > 0
            ? rxrRedColor
            : completedAllTasks
            ? rxrStatusGreenColor
            : rxrTealColor

        return (
          <div>
            {totalOnboardingTasks > 0 ? (
              <div className={classes.progressContainer}>
                <div className={classes.progressBarContainer}>
                  <ProgressBar color={dynamicColor} progress={hasOnboardingTasks ? completedOnboardingTasks / totalOnboardingTasks : 0} />
                </div>
                <div className={classes.onboardingTasksCopyContainer}>
                  <span style={{color: dynamicColor}}>
                    {completedOnboardingTasks}/{totalOnboardingTasks}
                  </span>
                </div>
              </div>
            ) : (
              <div>{'Manual onboarding'}</div>
            )}
          </div>
        )
      },
      renderPrimitive: occupancy => {
        const hasOnboardingTasks =
          occupancy.onboardingTasks &&
          Array.isArray(occupancy.onboardingTasks) &&
          occupancy.onboardingTasks.filter(
            task => task.importance === ONBOARDING_IMPORTANCE_CRITICAL || task.importance === ONBOARDING_IMPORTANCE_REQUIRED,
          ).length > 0
        const onboardingTasks = hasOnboardingTasks
          ? occupancy.onboardingTasks.filter(
              task => task.importance === ONBOARDING_IMPORTANCE_CRITICAL || task.importance === ONBOARDING_IMPORTANCE_REQUIRED,
            )
          : []
        const totalOnboardingTasks = hasOnboardingTasks ? onboardingTasks.length : 0
        const completedOnboardingTasks = hasOnboardingTasks
          ? onboardingTasks.reduce((accum, currVal) => {
              if (isOnboardingTaskComplete(currVal)) {
                accum++
              }
              return accum
            }, 0)
          : 0
        return `${completedOnboardingTasks} completed out of ${totalOnboardingTasks}`
      },
    }),

    new ColumnConfig({
      title: 'Chat',
      render: occupancy => {
        const handleClick = e => {
          const allResidentIds = occupancy.residents.map(r => r.id)
          const foundConversation = Object.values(conversationsLookup)
            .filter(c => c.isOnboardingChat)
            .find(c => {
              const foundOccupancy = c.chatUsers.find(c => allResidentIds.includes(c.userId))
              return !!foundOccupancy
            })

          if (foundConversation) {
            // if we found the conversation, we stop propagation
            e.stopPropagation()
            history.push(Routes.constructPath(Routes.MESSAGES_VIEW_SINGLE, {conversationId: foundConversation.id}))
          } else {
            console.log('No conversation found')
          }
        }
        return (
          <RXRButton type={RXRButton.TYPE_SECONDARY} onClick={handleClick}>
            {'Chat'}
          </RXRButton>
        )
      },
    }),

    new ColumnConfig({
      title: 'Action Needed',
      render: occupancy => {
        const hasOnboardingTasks =
          occupancy.onboardingTasks && Array.isArray(occupancy.onboardingTasks) && occupancy.onboardingTasks.length > 0
        const onboardingTasks = hasOnboardingTasks ? occupancy.onboardingTasks : []
        const totalOnboardingTasks = hasOnboardingTasks ? onboardingTasks.length : 0
        const completedOnboardingTasks = hasOnboardingTasks
          ? onboardingTasks.reduce((accum, currVal) => {
              if (isOnboardingTaskComplete(currVal)) {
                accum++
              }
              return accum
            }, 0)
          : 0
        const numTasksPendingApproval = hasOnboardingTasks
          ? onboardingTasks.reduce((accum, currVal) => {
              if (isOnboardingTaskPendingApproval(currVal)) {
                accum++
              }
              return accum
            }, 0)
          : 0
        const numTasksChangesRequested = hasOnboardingTasks
          ? onboardingTasks.reduce((accum, currVal) => {
              if (isOnboardingTaskChangesRequested(currVal)) {
                accum++
              }
              return accum
            }, 0)
          : 0

        const completedAllTasks = hasOnboardingTasks && completedOnboardingTasks === totalOnboardingTasks
        const dynamicColor =
          numTasksPendingApproval > 0
            ? rxrOrangeColor
            : numTasksChangesRequested > 0
            ? rxrRedColor
            : completedAllTasks
            ? rxrStatusGreenColor
            : rxrTealColor
        const dynamicCopy =
          numTasksPendingApproval > 0
            ? 'Review'
            : numTasksChangesRequested > 0
            ? 'Changes requested'
            : completedAllTasks
            ? 'Ready for check-in'
            : null

        if (numTasksPendingApproval > 0 || numTasksChangesRequested > 0 || completedAllTasks) {
          return (
            <div className={classes.reviewButton} style={{backgroundColor: dynamicColor, color: rxrWhiteColor}}>
              {dynamicCopy}
            </div>
          )
        } else {
          return null
        }
      },
    }),
  ]

  return <SortableTable data={data} columns={columns} downloadFileName={`Onboarding residents`} onClickRow={handleClickOccupancy} />
}

OnboardingTable.propTypes = {}

export default OnboardingTable
