//import React from 'react'
import React, {useState, Fragment} from 'react'
import {Grid} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import {withRouter} from 'react-router-dom'
import EventForm from './EventForm'
import EventTable from './EventTable'
import {listSocialEvents, deleteSocialEvent, createSocialEvent, updateSocialEvent} from '../../lib/queries.js'
import {useSelector} from 'react-redux'
import ConfirmDeleteDialog from '../ConfirmDeleteDialog'
import PageHeader from '../PageHeader'
import Routes from '../../constants/RouteConstants'
import EmptyStateIconAndMessage from '../EmptyStateIconAndMessage'
import Constant from '../Messages/MessageConstant'
import EventConstant from '../Events/EventConstant'
import DiscardChangesDialog from '../DiscardChangesDialog'
import {RXRButton} from '../RXRButton'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {spaceMedium} from '../../assets/styles/spacing'
import {PageContainer} from '../../assets/styles/layout'
import useFormChanged, {
  ConstructGreaterThanOtherDateValidator,
  DateObjectValidator,
  NonEmptyValidator,
  S3ObjectValidator,
  NumberValidatorIfInputFieldIsEnabled,
} from '../hooks/useFormChanged'
import LoadingStateIconAndMessage from '../LoadingStateIconAndMessage'
import SidePanel from '../SidePanel'
import useBuildingDataRefresh from '../hooks/useBuildingDataRefresh'
import useListCreateViewPattern from '../hooks/useListCreateViewPattern'
import RXRIcon from '../RXRIcon'
import FileHelper from '../../lib/FileHelper'

const useStyles = makeStyles(theme => ({
  cancelButton: {
    marginLeft: spaceMedium,
  },
  deleteButton: {
    marginLeft: spaceMedium,
  },
}))

const EMPTY_FORM_STATE = {
  name: '',
  byline: '',
  description: '',
  image: null,
  startDate: null,
  endDate: null,
  location: '',
  rsvpRequired: false,
  capRsvpRequired: false,
  capacity: null,
}

function ViewEventsPage(props) {
  const classes = useStyles()

  const [isSaving, setIsSaving] = useState(false)
  const [deleteDialogEvent, setDeleteDialogEvent] = useState(null)
  const [existingEvents, setExistingEvents] = useState([])
  const {form, setForm, formChanged, resetInitialForm, invalidItems, validateForm} = useFormChanged(
    EMPTY_FORM_STATE,
    useFormChanged.PropLevelValidation({
      name: NonEmptyValidator,
      byline: NonEmptyValidator,
      description: NonEmptyValidator,
      // image is optional, but if it's provided it must be an S3 object
      image: v => !v || S3ObjectValidator(v),
      startDate: DateObjectValidator,
      endDate: ConstructGreaterThanOtherDateValidator('startDate'),
      location: NonEmptyValidator,
      capacity: NumberValidatorIfInputFieldIsEnabled('capRsvpRequired'),
    }),
  )
  const {activeBuildingId, isLoadingData: isLoadingEvents} = useBuildingDataRefresh(loadEventsData)

  const permissionsObject = useSelector(state => state.GroupPermissions.permissionsObject)

  async function loadEventsData() {
    let existingEvents = await listSocialEvents(activeBuildingId)
    let sortedEvents = existingEvents.sort((a, b) => {
      return new Date(a.startTime) - new Date(b.startTime)
    })
    setExistingEvents(sortedEvents)
  }

  const {isCreateMode, focusedItemId} = useListCreateViewPattern(
    Routes.EVENTS_CREATE,
    Routes.EVENTS_VIEW_SINGLE,
    'eventId',
    EMPTY_FORM_STATE,
    form,
    resetInitialForm,
    existingEvents,
    event => ({
      id: event.id,
      description: event.description,
      image: event.image,
      location: event.location,
      name: event.eventName,
      byline: event.previewDescription,
      startDate: new Date(event.startTime),
      endDate: event.endTime ? new Date(event.endTime) : event.endTime,
      rsvpRequired: event.rsvpRequired,
      capRsvpRequired: !!event.capacity,
      capacity: event.capacity,
      allowResidentsToViewRsvps: event.allowResidentsToViewRsvps,
      allowResidentGuestRsvps: event.allowResidentGuestRsvps,
      allowWaitlist: event.allowWaitlist,
    }),
    isLoadingEvents,
  )

  const handleSubmit = async () => {
    // make sure the form is valid
    if (!validateForm()) {
      return
    }

    setIsSaving(true)

    try {
      let input = {
        eventName: form.name,
        location: form.location,
        startTime: form.startDate,
        endTime: form.endDate,
        previewDescription: form.byline,
        description: form.description,
        image: FileHelper.formatS3ObjectForInput(form.image),
        socialEventBuildingId: props.activeBuilding.id,
        rsvpRequired: form.rsvpRequired,
        capacity: form.rsvpRequired ? form.capacity : null,
        allowResidentsToViewRsvps: form.rsvpRequired ? form.allowResidentsToViewRsvps : false,
        allowResidentGuestRsvps: form.rsvpRequired ? form.allowResidentGuestRsvps : false,
        allowWaitlist: form.rsvpRequired ? form.allowWaitlist : false,
      }

      if (form.id) {
        input.id = form.id
        await updateSocialEvent(input)
      } else {
        await createSocialEvent(input)
      }

      await loadEventsData()

      setIsSaving(false)
      resetForm()
    } catch (er) {
      setIsSaving(false)
      console.error(er)
      alert('Sorry, we could not process this request. Please re-enter the event information.')
    }
  }

  const deleteEvent = eventId => {
    if (!deleteDialogEvent || deleteDialogEvent !== eventId) {
      setDeleteDialogEvent(eventId)
      return
    }

    setDeleteDialogEvent(null)
    let itemToRemove = existingEvents.find(e => e.id === eventId)
    setExistingEvents(existingEvents.filter(e => e.id !== eventId))
    deleteSocialEvent(eventId).catch(err => {
      // not likely to happen. It's ok if the experience is a little bit janky
      console.error(err)
      window.alert(`Failed to delete ${itemToRemove.eventName} event, please try again.`)
      // put the event back into our list
      setExistingEvents([...existingEvents, itemToRemove])
      props.history.push(Routes.constructPath(Routes.EVENTS_VIEW_SINGLE, {eventId: eventId}))
    })
    resetForm()
  }

  const resetForm = () => {
    resetInitialForm(EMPTY_FORM_STATE, hideSlidingPanel)
  }

  const hideSlidingPanel = () => {
    props.history.push(Routes.EVENTS)
  }

  return (
    <div style={PageContainer}>
      <PageHeader
        title={'Events'}
        rightControlComponent={
          <RXRButton
            type={RXRButton.TYPE_LARGE}
            disabled={!permissionsObject.announcementsWrite}
            onClick={() => props.history.push(Routes.EVENTS_CREATE)}
          >
            {EventConstant.BTN_ADD}
          </RXRButton>
        }
      />

      <div style={{height: '100%'}}>
        {isLoadingEvents ? (
          <LoadingStateIconAndMessage message={'Loading events...'} />
        ) : existingEvents.length === 0 ? (
          <EmptyStateIconAndMessage message={Constant.MESSAGE_EVENT_CONSTANT} icon={RXRIcon.EVENTS} />
        ) : (
          <EventTable events={existingEvents} />
        )}
      </div>

      <DiscardChangesDialog hasChanges={formChanged} />
      <SidePanel
        isOpen={!!focusedItemId || isCreateMode}
        onClose={hideSlidingPanel}
        title={isCreateMode ? 'New event' : 'View event details'}
      >
        <Fragment>
          <EventForm {...form} updateForm={setForm} invalidItems={invalidItems} />
          <Grid container spacing={6} style={{marginTop: 20}}>
            <Grid item>
              <RXRButton onClick={handleSubmit} isLoading={isSaving}>
                {EventConstant.BTN_POST}
              </RXRButton>
              {focusedItemId && (
                <RXRButton
                  type={RXRButton.TYPE_DESTRUCTIVE}
                  disabled={!permissionsObject.socialEventsWrite}
                  onClick={() => deleteEvent(focusedItemId)}
                  className={classes.deleteButton}
                >
                  {EventConstant.BTN_DELETE}
                </RXRButton>
              )}
              <RXRButton type={RXRButton.TYPE_TEXT} className={classes.cancelButton} onClick={() => props.history.push(Routes.EVENTS)}>
                {EventConstant.BTN_CANCEL}
              </RXRButton>
            </Grid>
          </Grid>
        </Fragment>
      </SidePanel>

      <ConfirmDeleteDialog
        prompt={'Are you sure you want to delete this event?'}
        isOpen={!!deleteDialogEvent}
        onConfirmDelete={() => deleteEvent(deleteDialogEvent)}
        onCancel={() => setDeleteDialogEvent(null)}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  activeBuilding: state.Buildings.buildingsLookup[state.Buildings.activeBuildingId],
})

export default compose(
  withRouter,
  connect(mapStateToProps),
)(ViewEventsPage)
