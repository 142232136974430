import KYRVendorAppointmentComponent from './KYRVendorAppointmentComponent'
import {VENDOR_APPOINTMENT_TYPE_CLEANING} from '../../KYRConstants'
import AbstractKYRScreen from '../AbstractKYRScreen'
import React from 'react'

export default class KYRCleaningComponent extends AbstractKYRScreen {
  render() {
    return <KYRVendorAppointmentComponent type={VENDOR_APPOINTMENT_TYPE_CLEANING} navParams={this.props.navParams} />
  }
}
