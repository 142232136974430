import React from 'react'
import SortableTable, {ColumnConfig} from '../SortableTable'
import HighlightText from '../HighlightText'
import {useHistory} from 'react-router-dom'
import Routes from '../../constants/RouteConstants'
import {CATEGORY_USER_TYPE} from './SettingsConstants'
import {PERMISSION_GROUP_FORMER_USER} from '../../constants/ModelConstants'

const DashboardUsersTable = function(props) {
  const history = useHistory()
  const columns = [
    new ColumnConfig({
      title: 'Staff Name',
      renderPrimitive: g => (g.id ? g.displayName : ''),
      render: (g, col) => (g.id ? <HighlightText search={props.filterTerm}>{col.renderPrimitive(g)}</HighlightText> : null),
      comparator: ColumnConfig.simpleComparatorGenerator('displayName'),
      isDefaultSort: true,
    }),
    new ColumnConfig({
      title: 'User Type',
      renderPrimitive: g =>
        g.isDisabled ? CATEGORY_USER_TYPE[PERMISSION_GROUP_FORMER_USER] : g.permissionGroup ? CATEGORY_USER_TYPE[g.permissionGroup] : 'N/A',
      render: (g, col) => (g.id ? <HighlightText search={props.filterTerm}>{col.renderPrimitive(g)}</HighlightText> : null),
      comparator: ColumnConfig.simpleComparatorGenerator('permissionGroup', undefined, group => CATEGORY_USER_TYPE[group]),
    }),
  ]

  return (
    <SortableTable
      data={props.users}
      columns={columns}
      downloadFileName={`Users`}
      onClickRow={r => history.push(Routes.constructPath(Routes.SETTINGS_DASHBOARD_USERS_VIEW_SINGLE, {userId: r.id}))}
    />
  )
}

export default DashboardUsersTable
