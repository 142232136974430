import React, {useState, useEffect, useRef} from 'react'
import D3Chart from './D3Chart'
import PropTypes from 'prop-types'
import {axisBottom, axisLeft, min, max, select} from 'd3'
import {drawLineToGraph} from './chartUtils'
import {Colors, Typography, Spacing} from '../../assets/styles'

const COLOR_DISABLED = Colors.rxrGreyColor
const OVERLAY_ITEM_STYLE = `
  position: absolute;
  z-index: 10;
  display: none;
  pointer-events: none;
`
const TOOLTIP_STYLES =
  OVERLAY_ITEM_STYLE +
  `
  text-align:left;
  font-size: ${Typography.fontSizeMedium};
  background: ${Colors.rxrWhiteColor};
  color: ${Colors.rxrBlackColor};
  border-radius: 4px;
  padding: ${Spacing.spaceExtraExtraSmall}px ${Spacing.spaceSmall}px;
`
const VERTICAL_LINE_STYLES =
  OVERLAY_ITEM_STYLE +
  `
  width: 0px;
  border-left: 3px dotted ${Colors.rxrMediumGreyColor};
`

function LineGraph(props) {
  const graphID = useRef(
    props.id ||
      Math.random()
        .toString(36)
        .substring(7),
  )
  const canvasRef = useRef(null)
  const dataFormatted = useRef([])
  const xDomainRef = useRef([])
  const yDomainRef = useRef([])
  const [xAxisLabelsFinal, setXAxisLabelsFinal] = useState(props.xAxisLabels)
  const [yAxisLabelsFinal, setYAxisLabelsFinal] = useState(props.yAxisLabels)

  function cleanupChartItems() {
    // these are tool tip objects we create during onReady / chart render
    select(`#tooltip-${graphID.current}`).remove()
    select(`#vertical-line-${graphID.current}`).remove()
  }

  useEffect(() => {
    return cleanupChartItems
  }, [])

  // when any of our props change, we need to re-render the chart
  useEffect(() => {
    reRenderChart()
  }, [props.data, props.xAxisLabels, props.yAxisLabels, props.palette])

  // this function normalizes our data and determines the number of labels we'll need
  // it must be determined before the chart renders and so it terminates by calling reset on the chart
  const reRenderChart = () => {
    const xDomain = new Set()
    dataFormatted.current = props.data.map((arr, lineNumber) =>
      arr.map((d, i) => {
        const retVal =
          typeof d === 'number' || !d
            ? // if it's just numbers or is undefined or null, then the xValue is its location in the array and it's not projected
              {
                yValue: d,
                xValue: i,
                isProjected: false,
                // these props are used by renderToolTip.js \/
                lineColor: props.palette && props.palette[lineNumber] ? props.palette[lineNumber] : '#000',
                label: props.labels && props.labels[lineNumber] ? props.labels[lineNumber] : '',
              }
            : // if it's an object we expect it definitely as a yValue, and merge down
              Object.assign(
                {},
                {
                  yValue: d.yValue,
                  xValue: i,
                  isProjected: false,
                  // these props are used by renderToolTip.js \/
                  lineColor: props.palette && props.palette[lineNumber] ? props.palette[lineNumber] : '#000',
                  label: props.labels && props.labels[lineNumber] ? props.labels[lineNumber] : '',
                },
                d,
              )

        // track our mins and our maxes
        xDomain.add(retVal.xValue)
        return retVal
      }),
    )

    // sort the data so we can control which lines get drawn on top
    // any line that's color DISABLED should be drawn first so non-disabled colors can be drawn on top
    dataFormatted.current.sort((a, b) => {
      if (a.length === 0) {
        return -1
      } else if (b.length === 0) {
        return 1
      }
      const aColor = a[0].lineColor
      const bColor = b[0].lineColor

      return aColor === bColor ? 0 : aColor === COLOR_DISABLED ? -1 : bColor === COLOR_DISABLED ? 1 : 0
    })

    // this is the complete x domain
    xDomainRef.current = [...xDomain].sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
    setXAxisLabelsFinal(
      props.xAxisLabels
        ? typeof props.formatXAxis === 'function'
          ? props.xAxisLabels.map(l => props.formatXAxis(l))
          : props.xAxisLabels
        : xDomainRef.current,
    )

    yDomainRef.current = [
      typeof props.yAxisMin === 'number' ? props.yAxisMin : min(dataFormatted.current, arr => min(arr, o => o.yValue)),
      typeof props.yAxisMax === 'number' ? props.yAxisMax : max(dataFormatted.current, arr => max(arr, o => o.yValue)),
    ]
    setYAxisLabelsFinal(
      props.yAxisLabels
        ? typeof props.formatYAxis === 'function'
          ? props.yAxisLabels.map(l => props.formatYAxis(l))
          : props.yAxisLabels
        : yDomainRef.current,
    )
  }

  const onReady = ([canvas, x, y]) => {
    if (!canvas || !x || !y) return

    cleanupChartItems()
    canvasRef.current = canvas
    // Scale the range of the data in the domains
    x.domain(xDomainRef.current)
    y.domain(yDomainRef.current)

    const tooltip = select('body')
      .append('div')
      .attr('style', TOOLTIP_STYLES)
      .attr('id', `tooltip-${graphID.current}`)
      .html('<div>hi</div>')

    const verticalLine = select('body')
      .append('div')
      .attr('id', `vertical-line-${graphID.current}`)
      .attr('style', VERTICAL_LINE_STYLES)
      .style('height', `${canvas.height}px`)

    // draw all the lines
    dataFormatted.current.forEach((d, i) => {
      // we're now caching the color on the datapoint directly, but functionally identical to:
      // const color = props.palette[i] || '#000'
      const color = d.length > 0 ? d[0].lineColor : '#000'

      // we're going to convert our single line of data points into segments of matching isProjected status
      // for example (suppose A = Actual & P = Projected):
      // a line segment of [A,A,A,P,P,A,A,A,P,P,P]
      // will become [[A,A,A],[P,P],[A,A,A],[P,P,P]]
      /** @type Array<Array<DataPoint>> */
      const lineSegments = d.reduce((agr, dataPoint) => {
        if (!agr[0]) {
          // this is our base case, just start with the first one
          agr.push([dataPoint])
        } else {
          const currentSegment = agr[agr.length - 1]
          // if this dataPoint is the same isProjected status as the last segment, we append id
          if (dataPoint.isProjected === currentSegment[0].isProjected) {
            currentSegment.push(dataPoint)
          } else {
            // this is going to ensure the lines leading into or out of a projected value will always appear projected
            if (dataPoint.isProjected) {
              // if we're switching to now projected, we start a new line segment with this data point + the last one
              agr.push([{...currentSegment[currentSegment.length - 1], isProjected: true}, dataPoint])
            } else {
              // if we're switching to an actual value segment, we push our real value onto the end of the last projected segment
              currentSegment.push({...dataPoint, isProjected: true})
              // and start a new segment
              agr.push([dataPoint])
            }
          }
        }

        return agr
      }, [])

      // finally, we draw each line segment individually
      lineSegments.forEach((segment, j) => {
        if (segment.length === 0) {
          // this shouldn't happen, but just to be safe
          return
        }
        const id = `${i}-${j}`
        // since we know all the segments are the same projected status, we can just grab the first one
        drawLineToGraph(canvas, x, y, id, segment, color, segment[0].isProjected, tooltip, props.onHover, verticalLine)
      })
    })

    // add the x Axis
    canvas
      .append('g')
      .attr('transform', `translate(0, ${canvas.height})`)
      .attr('style', 'font-size:10px')
      .call(axisBottom(x).tickFormat(d => xAxisLabelsFinal[d] || ''))

    // add the y Axis
    canvas
      .append('g')
      .attr('style', 'font-size:10px')
      .call(axisLeft(y).tickValues(yAxisLabelsFinal))

    // add y-axis title. https://stackoverflow.com/questions/11189284/d3-axis-labeling
    canvas
      .append('text')
      .attr('style', 'font-size:10px')
      .attr('text-anchor', 'end')
      .attr('y', 6)
      .attr('dy', '.75em')
      .attr('transform', 'rotate(-90)')
      .text(props.yAxisTitle ? props.yAxisTitle : '')
  }

  return <D3Chart onReady={onReady} xAxisLabels={xAxisLabelsFinal} />
}

/**
 * @typedef LineGraphDataPoint
 * @property {number} xValue
 * @property {number} yValue
 * @property {boolean?} isProjected
 */

/**
 * @typedef {Array<number|LineGraphDataPoint>} SingleLine
 */

/**
 * @callback OnHoverCallback
 * @param {LineGraphDataPoint} dataPoint
 * @param {HTMLElement} tooltip
 * @param {MouseEvent} event
 * @returns {string} // the raw HTML string to be rendered in the tooltip
 */

LineGraph.propTypes = {
  id: PropTypes.string,
  palette: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)).isRequired, // array of SingeLines
  labels: PropTypes.arrayOf(PropTypes.string), // legend labels (basically, labels per SingleLine)
  xAxisLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  formatXAxis: PropTypes.func,
  yAxisLabels: PropTypes.arrayOf(PropTypes.string),
  formatYAxis: PropTypes.func,
  yAxisTitle: PropTypes.string,
  yAxisMin: PropTypes.number,
  yAxisMax: PropTypes.number,
  onHover: PropTypes.func, // OnHoverCallback
}

export default LineGraph

/*
Use like:

import LineGraph from '../Charts/LineGraph'

const labels = [
  '2023-01-01',
  '2023-02-01',
  '2023-03-01',
  '2023-04-01',
  '2023-05-01',
  '2023-06-01',
  '2023-07-01',
  '2023-08-01',
  '2023-09-01',
  '2023-10-01',
  '2023-11-01',
  '2023-12-01',
]

const data = [
  [1, 2, 3, 4, 5, 6, 7],
  [4, 2, 3, 6, 1, 4, 5],
  [3, 1, 3, 5, 7, 4, 2],
  [8, 4, 2, 7, 1, 2, 3],
  [6, 4, 2, 3, 3, 3, 4],
  [1, 8, 5, 2, 5, 3, 7],
]

const onHover = (e, d, tooltip) => {
  console.log(e, d, tooltip)
  return `<div>${d.yValue}</div>`
}

<LineGraph
  xAxisLabels={labels}
  data={data}
  formatXAxis={s => moment(s).format('MMM')}
  palette={['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#00FFFF', '#FF00FF']}
  onHover={onHover}
/>
 */
