import ActionTypes from '../actions/types'

const initialState = {
  // instance of AuthedUser (see /lib/AppAuth -> AuthedUser class)
  authedUser: undefined,
}

// --------------------------------------------------------------------------------
// State mutation functions:

function setAuthedUser(state, authedUser) {
  return {...state, authedUser: authedUser}
}

// --------------------------------------------------------------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.AUTH_SET_AUTHED_USER:
      return setAuthedUser(state, action.payload)

    default:
      /* pass through */
      return state
  }
}
