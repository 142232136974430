import React from 'react'
import PropTypes from 'prop-types'
import SingleClosureInput from './SingleClosureInput'
import GenericManyInput from '../GenericManyInput'

const initialClosureModel = {label: '', startAt: null, endAt: null}

function ManyClosureInput(props) {
  return (
    <GenericManyInput
      onChange={props.onChange}
      value={props.value}
      emptyModel={initialClosureModel}
      singleInputComponent={SingleClosureInput}
      onRemove={props.onRemove}
    />
  )
}

ManyClosureInput.propTypes = {
  onChange: PropTypes.func.isRequired, // returns the array of Closure objects
  onRemove: PropTypes.func.isRequired, // how to treat deletions
  value: PropTypes.array.isRequired, // an array of Closure objects
}

export default ManyClosureInput
