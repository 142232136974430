import React from 'react'
import {Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {Typography as TypographyStyle, Colors} from './../../../assets/styles'
import TextInput from '../../TextInput'
import {spaceMedium} from '../../../assets/styles/spacing'
import SelectInput from '../../SelectInput'
import DateInput from '../../DateInput'
import NumberInput from '../../NumberInput.js'
import MultilineTextInput from '../../MultilineTextInput'
import {convertKeyLabelMapIntoSelectOptions} from '../../../Utils/objectUtil'
import {DISCOUNT_TYPE_LABLES, VENDOR_SERVICE_TYPE_LABELS} from './PromotionConstants.js'
import SlimMultiSelectInput from '../../SlimMultiSelectInput.js'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  formRow: {
    marginBottom: spaceMedium,
  },
  heading: {
    ...TypographyStyle.CaptionHeader,
    lineHeight: '19px',
    color: Colors.rxrRedColor,
  },
}))

const selectDiscountTypeOptions = convertKeyLabelMapIntoSelectOptions(DISCOUNT_TYPE_LABLES)
const VENDOR_SERVICE_OPTIONS = convertKeyLabelMapIntoSelectOptions(VENDOR_SERVICE_TYPE_LABELS)
const rowSize = 8

function PromotionForm(props) {
  const classes = useStyles()
  const isCreateMode = !props.id
  return (
    <div className={classes.root}>
      {isCreateMode && (
        <Grid container spacing={3} className={classes.formRow}>
          <Grid item xs={12}>
            <Typography className={classes.heading}>{'* All starred fields are required.'}</Typography>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={3} className={classes.formRow}>
        <Grid item xs={12}>
          <TextInput
            label="Promotion Title"
            placeholder=""
            maxLength={120}
            onChange={val => props.updateForm({promotionTitle: val})}
            value={props.promotionTitle}
            isRequired={true}
            error={props.invalidItems.includes('promotionTitle')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.formRow}>
        <Grid item xs={12}>
          <SlimMultiSelectInput
            label={'Vendor Service Type'}
            valueLabel={(props.vendorServiceTypes || []).map(g => VENDOR_SERVICE_TYPE_LABELS[g]).join(', ')}
            options={VENDOR_SERVICE_OPTIONS}
            onChange={vals => props.updateForm({vendorServiceTypes: vals})}
            value={props.vendorServiceTypes}
            isRequired={true}
            error={props.invalidItems.includes('vendorServiceTypes')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.formRow}>
        <Grid item xs={6}>
          <DateInput
            label={'Posting date'}
            value={props.postingDate}
            onChange={date => {
              props.updateForm({postingDate: isNaN(date) ? null : date})
            }}
            isRequired={true}
            error={props.invalidItems.includes('postingDate')}
          />
        </Grid>
        <Grid item xs={6}>
          <DateInput
            label={'Expiry date'}
            value={props.expiryDate}
            onChange={date => {
              props.updateForm({expiryDate: isNaN(date) ? null : date})
            }}
            isRequired={true}
            error={props.invalidItems.includes('expiryDate')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.formRow}>
        <Grid item xs={6}>
          <SelectInput
            label={'Discount Type'}
            value={props.discountType}
            onChange={val => props.updateForm({discountType: val})}
            options={selectDiscountTypeOptions}
            isRequired={true}
            error={props.invalidItems.includes('discountType')}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            label="Discount Amount"
            onChange={val => props.updateForm({discountAmount: val})}
            value={props.discountAmount}
            min={0}
            max={props.discountType === 'Percentage' ? 100 : undefined}
            showDollarSign={props.discountType === 'Fixed'}
            showPercentSign={props.discountType === 'Percentage'}
            isRequired={true}
            error={props.invalidItems.includes('discountAmount')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.formRow}>
        <Grid item xs={12}>
          <MultilineTextInput
            label="Description"
            placeholder=""
            helperText={`${5000} character limit.`}
            rows={rowSize}
            rowsMax={rowSize}
            maxLength={5000}
            onChange={val => props.updateForm({description: val})}
            value={props.description}
            isRequired={true}
            error={props.invalidItems.includes('description')}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default PromotionForm
