import React from 'react'
import ReadOnlyTask from '../ReadOnlyTask'
import {FormComponentPropTypes} from '../onboardingTypes'

function UtilitiesSetup(props) {
  return <ReadOnlyTask {...props} />
}

UtilitiesSetup.propTypes = FormComponentPropTypes

export default UtilitiesSetup
