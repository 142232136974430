import React, {useState} from 'react'
import RXRButton from '../../RXRButton'
import useSharedStyles from '../useSharedStyles'
import PropTypes from 'prop-types'

function EditCTA(props) {
  const classes = useSharedStyles()

  const handleClick = async () => {
    try {
      if (props.isEditing) {
        await props.onFinishEditing()
      } else {
        await props.onEdit()
      }
    } catch (err) {
      window.alert(err.message)
      console.error(err)
    }
  }

  return (
    <RXRButton onClick={handleClick} className={classes.CTA} disabled={props.isDisabled}>
      {props.isEditing ? 'Finish editing' : 'Edit'}
    </RXRButton>
  )
}

EditCTA.propTypes = {
  onEdit: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
}

export default EditCTA
