import React from 'react'
import ReadOnlyTask from '../ReadOnlyTask'
import {FormComponentPropTypes} from '../onboardingTypes'

function KeyPickup_ReadOnly(props) {
  return <ReadOnlyTask {...props} />
}

KeyPickup_ReadOnly.propTypes = FormComponentPropTypes

export default KeyPickup_ReadOnly
