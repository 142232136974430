import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import Paper from '@material-ui/core/Paper'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import {useHistory} from 'react-router-dom'
import Routes from '../../constants/RouteConstants'
import {rxrTealColor, rxrBlueColor, rxrWarmBrown, rxrErrorColor, shadowSoft, rxrSuccessColor} from '../../assets/styles/color'
import {
  EVENT_TYPE_TO_FILTER,
  IGNORE_CTA,
  IGNORE_MESSAGE,
  IGNORE_TITLE,
  INSIGHT_CATEGORY_TO_LABEL,
  NUDGE_TYPE_TO_ICON,
} from './AssistantConstants'
import {navigateToScreen, setIsWidgetOpen} from '../../actions/kyrWidgetActions'
import {SCREEN_TIMELINE} from '../KnowYourResident/KYRConstants'
import RXRIcon from '../RXRIcon'
import {spaceSmall} from '../../assets/styles/spacing'
import {iconButton} from '../../assets/styles/buttons'
import {IconButton} from '@material-ui/core'
import PropTypes from 'prop-types'
import {deleteNudge} from '../../actions/nudgesActions'
import DiscardChangesDialog from '../DiscardChangesDialog'
import useResidentLookup from '../hooks/useResidentLookup'

const useStyles = makeStyles(theme => {
  return {
    parentStyle: {
      alignItems: 'center',
      paddingRight: spaceSmall,
    },
    cardContainer: {
      width: '100%',
      minHeight: theme.spacing(9), //72px
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: '22px',
      paddingBottom: '10px',
      marginBottom: '10px',
      borderRadius: '1px',
      '&:focus': {
        outline: '0px auto transparent',
      },
      boxShadow: shadowSoft,
      borderLeft: `10px solid transparent`,
    },
    leftBorderHighPriority: {
      borderLeft: `10px solid`,
      borderLeftColor: rxrErrorColor,
    },
    labelTextStyle: {
      width: '100%',
    },
    divlableStyle: {marginLeft: '10px'},

    categoryTitleStyle: {
      fontSize: '16px',
      lineHeight: '12px',
      letterSpacing: '0.8px',
      color: rxrTealColor,
      cursor: 'pointer',
      marginTop: '25px',
    },
    dateTimeStyle: {
      marginLeft: '12px',
      marginRight: '20px',
      fontSize: '12px',
      color: rxrTealColor,
      letterSpacing: '1.3px',
    },
    textStyle: {
      fontSize: '12px',
      color: rxrTealColor,
    },
    lableStyle: {
      fontSize: '16px',
      color: rxrBlueColor,
      letterSpacing: '1.3px',
    },
    remainingDays: {
      fontSize: '12px',
      color: rxrWarmBrown,
      //minWidth: '30px',
      textTransform: 'uppercase',
      marginLeft: '5px',
      minWidth: '100px',
      letterSpacing: '1.3px',
      textAlign: 'right',
    },

    icon: {
      ...iconButton,
      marginLeft: spaceSmall,
    },

    eventTypeContainer: {
      backgroundColor: 'rgba(46, 156, 202, 0.15)',
      borderRadius: '20px',
      width: '107px',
      height: 26,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px',
      marginLeft: '15px',
      textTransform: 'uppercase',
    },

    eventType: {fontSize: 12, textTransform: 'uppercase', color: '#0C4067', letterSpacing: '1.3px'},
  }
})

const InsightCardComponent = ({insightData, ...props}) => {
  const classes = useStyles()
  const [confirmDelete, setConfirmDelete] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const {getResident} = useResidentLookup()

  const dateTimeObj = new Date(insightData.triggeredAt)
  let isHighPriority = insightData.isHighPriority
  let remainingDays = moment(insightData.expiredAt).diff(new Date(), 'days')
  let daysLeftString = ''

  if (remainingDays > 1) {
    daysLeftString = `${remainingDays} days left`
  } else if (remainingDays > 0) {
    daysLeftString = `${remainingDays} day left`
  }
  const dateTime = `${moment(dateTimeObj)
    .format('MM/DD')
    .toUpperCase()}`

  const handleCheckClick = () => {
    const route =
      typeof props.createRoute === 'string'
        ? props.createRoute
        : Routes.constructPath(Routes.RXO_ASSISTANT_LOG_ACTION_FROM_NUDGE, {buildingNudgeId: insightData.id})
    history.push(route)
  }

  const handleDeleteNudge = async () => {
    if (!confirmDelete) {
      setConfirmDelete(true)
      return
    }

    try {
      await deleteNudge(dispatch, insightData.id)

      setConfirmDelete(false)
    } catch (er) {
      console.error(er)
      setConfirmDelete(false)
      window.alert(er.message)
    }
  }

  const dispatchNavigateToKYRResidentProfile = () => {
    setIsWidgetOpen(dispatch, true)
    navigateToScreen(dispatch, SCREEN_TIMELINE, {residentId: insightData.residentId})
  }

  let nameParts = [insightData.label]
  const residentName = getResident(insightData.residentId).displayName
  const resNameLocation = insightData.label.indexOf(residentName)
  if (resNameLocation >= 0) {
    nameParts = [
      insightData.label.substring(0, resNameLocation),
      residentName,
      insightData.label.substring(resNameLocation + residentName.length),
    ]
  }

  return (
    <Paper className={isHighPriority ? [classes.cardContainer, classes.leftBorderHighPriority].join(' ') : classes.cardContainer}>
      <DiscardChangesDialog
        isOpen={confirmDelete}
        discardIcon={RXRIcon.NOT_ALLOWED}
        discardTitle={IGNORE_TITLE}
        discardMessage={IGNORE_MESSAGE}
        discardButton={IGNORE_CTA}
        onDiscard={handleDeleteNudge}
        onCancel={() => setConfirmDelete(false)}
      />

      <Grid container className={classes.parentStyle} direction="row" wrap="nowrap" spacing={0}>
        <Grid item>
          <span className={classes.dateTimeStyle}>{dateTime}</span>
        </Grid>
        <Grid>
          <RXRIcon icon={NUDGE_TYPE_TO_ICON[insightData.type] || NUDGE_TYPE_TO_ICON.DEFAULT} color={rxrBlueColor} />
        </Grid>

        <Grid item className={classes.labelTextStyle}>
          <div className={classes.divlableStyle}>
            <span className={classes.lableStyle}>{nameParts[0]}</span>
            {nameParts.length > 1 && (
              <span className={classes.categoryTitleStyle} onClick={dispatchNavigateToKYRResidentProfile}>
                {nameParts[1]}
              </span>
            )}
            {nameParts.length > 2 && <span className={classes.lableStyle}>{nameParts[2]}</span>}
          </div>
        </Grid>
        <Grid item>
          <div className={classes.remainingDays}>
            <span>{`${daysLeftString}`}</span>
          </div>
        </Grid>
        <Grid item>
          <div className={classes.eventTypeContainer}>
            <Typography className={classes.eventType}>
              {INSIGHT_CATEGORY_TO_LABEL[EVENT_TYPE_TO_FILTER[insightData.type] || EVENT_TYPE_TO_FILTER.DEFAULT]}
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <IconButton onClick={handleCheckClick} className={classes.icon}>
            <RXRIcon icon={RXRIcon.CHECK} color={rxrSuccessColor} />
          </IconButton>
        </Grid>

        <Grid item>
          <IconButton onClick={handleDeleteNudge} className={classes.icon}>
            <RXRIcon icon={RXRIcon.CLOSE} color={rxrTealColor} />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  )
}

InsightCardComponent.propTypes = {
  insightData: PropTypes.any.isRequired,
  createRoute: PropTypes.string, // optional, will route to RXO_ASSISTANT_LOG_ACTION_FROM_NUDGE if not provided
}

export default InsightCardComponent
