// ------------------------------------------------------------------------------
// BUILDING_NUDGE_TYPE
// These should match the BuildingNudgeType enum types in graphql schema

import RXRIcon from '../RXRIcon'

export const BUILDING_NUDGE_TYPE_RESIDENT_BIRTHDAY = 'RESIDENT_BIRTHDAY'
export const BUILDING_NUDGE_TYPE_PET_BIRTHDAY = 'PET_BIRTHDAY'
export const BUILDING_NUDGE_TYPE_ROMANTIC_ANNIVERSARY = 'ROMANTIC_ANNIVERSARY'
export const BUILDING_NUDGE_TYPE_NEW_BABY = 'NEW_BABY'
export const BUILDING_NUDGE_TYPE_ENGAGEMENT = 'ENGAGEMENT'
export const BUILDING_NUDGE_TYPE_PROMOTION = 'PROMOTION'
export const BUILDING_NUDGE_TYPE_WEDDING = 'WEDDING'
export const BUILDING_NUDGE_TYPE_GRADUATION = 'GRADUATION'
export const BUILDING_NUDGE_TYPE_FAMILY_MEMBER_BIRTHDAY = 'FAMILY_MEMBER_BIRTHDAY'
export const BUILDING_NUDGE_TYPE_MAINTENANCE_DELAY = 'MAINTENANCE_DELAY'
export const BUILDING_NUDGE_TYPE_APP_ISSUE = 'APP_ISSUE'
export const BUILDING_NUDGE_TYPE_PARKING_ISSUE = 'PARKING_ISSUE'
export const BUILDING_NUDGE_TYPE_CLEANING_ISSUE = 'CLEANING_ISSUE'
export const BUILDING_NUDGE_TYPE_PACKAGE_ISSUE = 'PACKAGE_ISSUE'
export const BUILDING_NUDGE_TYPE_NEW_RESIDENT = 'NEW_RESIDENT'
export const BUILDING_NUDGE_TYPE_RESIDENT_BUILDING_ANNIVERSARY = 'RESIDENT_BUILDING_ANNIVERSARY'
export const BUILDING_NUDGE_TYPE_LEASE_ENDING = 'LEASE_ENDING'
export const BUILDING_NUDGE_TYPE_LEASE_RENEWAL = 'LEASE_RENEWAL'
export const BUILDING_NUDGE_TYPE_JUST_BECAUSE = 'JUST_BECAUSE'
export const BUILDING_NUDGE_TYPE_GET_WELL_SOON = 'GET_WELL_SOON'
export const BUILDING_NUDGE_TYPE_THANK_YOU = 'THANK_YOU'
export const BUILDING_NUDGE_TYPE_CONGRATULATIONS = 'CONGRATULATIONS'
export const BUILDING_NUDGE_TYPE_CONDOLENCES = 'CONDOLENCES'
export const BUILDING_NUDGE_TYPE_OTHER = 'OTHER'

export const BUILDING_NUDGE_TYPE_LABELS = {
  [BUILDING_NUDGE_TYPE_RESIDENT_BIRTHDAY]: 'Resident birthday',
  [BUILDING_NUDGE_TYPE_PET_BIRTHDAY]: 'Pet birthday',
  [BUILDING_NUDGE_TYPE_ROMANTIC_ANNIVERSARY]: 'Romantic anniversary',
  [BUILDING_NUDGE_TYPE_NEW_BABY]: 'New baby',
  [BUILDING_NUDGE_TYPE_ENGAGEMENT]: 'Engagement',
  [BUILDING_NUDGE_TYPE_PROMOTION]: 'Promotion',
  [BUILDING_NUDGE_TYPE_WEDDING]: 'Wedding',
  [BUILDING_NUDGE_TYPE_GRADUATION]: 'Graduation',
  [BUILDING_NUDGE_TYPE_FAMILY_MEMBER_BIRTHDAY]: 'Family member birthday',
  [BUILDING_NUDGE_TYPE_MAINTENANCE_DELAY]: 'Maintenance delay',
  [BUILDING_NUDGE_TYPE_APP_ISSUE]: 'App issue',
  [BUILDING_NUDGE_TYPE_PARKING_ISSUE]: 'Parking issue',
  [BUILDING_NUDGE_TYPE_CLEANING_ISSUE]: 'Cleaning issue',
  [BUILDING_NUDGE_TYPE_PACKAGE_ISSUE]: 'Package issue',
  [BUILDING_NUDGE_TYPE_NEW_RESIDENT]: 'New resident',
  [BUILDING_NUDGE_TYPE_RESIDENT_BUILDING_ANNIVERSARY]: 'Resident building anniversary',
  [BUILDING_NUDGE_TYPE_LEASE_ENDING]: 'Lease ending',
  [BUILDING_NUDGE_TYPE_LEASE_RENEWAL]: 'Lease renewal',
  [BUILDING_NUDGE_TYPE_JUST_BECAUSE]: 'Just because',
  [BUILDING_NUDGE_TYPE_GET_WELL_SOON]: 'Get well soon',
  [BUILDING_NUDGE_TYPE_THANK_YOU]: 'Thank you',
  [BUILDING_NUDGE_TYPE_CONGRATULATIONS]: 'Congratulations',
  [BUILDING_NUDGE_TYPE_CONDOLENCES]: 'Condolences',
  [BUILDING_NUDGE_TYPE_OTHER]: 'Other',
}

// ------------------------------------------------------------------------------
// Building action "categories"
// these are a purely front end construct

export const CATEGORIES_PURCHASED_ITEMS = 'PURCHASED_ITEMS'
export const CATEGORIES_PURCHASED_ALCOHOL = 'PURCHASED_ALCOHOL'
export const CATEGORIES_PURCHASED_FOOD = 'PURCHASED_FOOD'
export const CATEGORIES_PERSONALIZED_INTERACTION = 'PERSONALIZED_INTERACTION'

export const CATEGORY_LABELS = {
  [CATEGORIES_PURCHASED_ITEMS]: 'Purchased items',
  [CATEGORIES_PURCHASED_ALCOHOL]: 'Purchased alcohol',
  [CATEGORIES_PURCHASED_FOOD]: 'Purchased food/beverage',
  [CATEGORIES_PERSONALIZED_INTERACTION]: 'Created personalized interaction',
}

// ------------------------------------------------------------------------------
// BuildingActionType
// These should match the BuildingNudgeType enum types in graphql schema

export const BUILDING_ACTION_TYPE_RETAIL_GIFT_CARD = 'RETAIL_GIFT_CARD'
export const BUILDING_ACTION_TYPE_TEDDY_BEAR = 'TEDDY_BEAR'
export const BUILDING_ACTION_TYPE_FLOWERS = 'FLOWERS'
export const BUILDING_ACTION_TYPE_BALLOON = 'BALLOON'
export const BUILDING_ACTION_TYPE_GLASSES = 'GLASSES'
export const BUILDING_ACTION_TYPE_CANDLE = 'CANDLE'
export const BUILDING_ACTION_TYPE_COFFEE_MUG = 'COFFEE_MUG'
export const BUILDING_ACTION_TYPE_BABY_CLOTHES = 'BABY_CLOTHES'
export const BUILDING_ACTION_TYPE_PICTURE_FRAME = 'PICTURE_FRAME'
export const BUILDING_ACTION_TYPE_PLANT = 'PLANT'
export const BUILDING_ACTION_TYPE_WHITE_WINE = 'WHITE_WINE'
export const BUILDING_ACTION_TYPE_RED_WINE = 'RED_WINE'
export const BUILDING_ACTION_TYPE_ROSE = 'ROSE'
export const BUILDING_ACTION_TYPE_CHAMPAGNE = 'CHAMPAGNE'
export const BUILDING_ACTION_TYPE_WHISKEY = 'WHISKEY'
export const BUILDING_ACTION_TYPE_VODKA = 'VODKA'
export const BUILDING_ACTION_TYPE_TEQUILA = 'TEQUILA'
export const BUILDING_ACTION_TYPE_GIN = 'GIN'
export const BUILDING_ACTION_TYPE_RUM = 'RUM'
export const BUILDING_ACTION_TYPE_OTHER_LIQUOR = 'OTHER_LIQUOR'
export const BUILDING_ACTION_TYPE_BEER = 'BEER'
export const BUILDING_ACTION_TYPE_COFFEE = 'COFFEE'
export const BUILDING_ACTION_TYPE_JUICE = 'JUICE'
export const BUILDING_ACTION_TYPE_TEA = 'TEA'
export const BUILDING_ACTION_TYPE_OTHER_CANDY = 'OTHER_CANDY'
export const BUILDING_ACTION_TYPE_CHOCOLATES = 'CHOCOLATES'
export const BUILDING_ACTION_TYPE_CAKE = 'CAKE'
export const BUILDING_ACTION_TYPE_BASKET_OF_FRUITS = 'BASKET_OF_FRUITS'
export const BUILDING_ACTION_TYPE_FOOD_BEVERAGE_GIFT_CARD = 'FOOD_BEVERAGE_GIFT_CARD'
export const BUILDING_ACTION_TYPE_SNACKS = 'SNACKS'
export const BUILDING_ACTION_TYPE_REACHED_OUT_VIA_CHAT = 'REACHED_OUT_VIA_CHAT'
export const BUILDING_ACTION_TYPE_REACHED_OUT_IN_PERSON = 'REACHED_OUT_IN_PERSON'
export const BUILDING_ACTION_TYPE_WROTE_A_KIND_NOTE = 'WROTE_A_KIND_NOTE'
export const BUILDING_ACTION_TYPE_GAVE_THEM_COMPLIMENTARY_SERVICE = 'GAVE_THEM_COMPLIMENTARY_SERVICE'
export const BUILDING_ACTION_TYPE_TOYS = 'TOYS'
export const BUILDING_ACTION_TYPE_BOOKS = 'BOOKS'

export const BUILDING_ACTION_TYPE_LABELS = {
  [BUILDING_ACTION_TYPE_RETAIL_GIFT_CARD]: 'Retail gift card',
  [BUILDING_ACTION_TYPE_TEDDY_BEAR]: 'Teddy bear',
  [BUILDING_ACTION_TYPE_FLOWERS]: 'Flowers',
  [BUILDING_ACTION_TYPE_BALLOON]: 'Balloon',
  [BUILDING_ACTION_TYPE_GLASSES]: 'Glasses',
  [BUILDING_ACTION_TYPE_CANDLE]: 'Candle',
  [BUILDING_ACTION_TYPE_COFFEE_MUG]: 'Coffee mug',
  [BUILDING_ACTION_TYPE_BABY_CLOTHES]: 'Baby clothes',
  [BUILDING_ACTION_TYPE_PICTURE_FRAME]: 'Picture frame',
  [BUILDING_ACTION_TYPE_PLANT]: 'Plant',
  [BUILDING_ACTION_TYPE_WHITE_WINE]: 'White wine',
  [BUILDING_ACTION_TYPE_RED_WINE]: 'Red wine',
  [BUILDING_ACTION_TYPE_ROSE]: 'Rosé',
  [BUILDING_ACTION_TYPE_CHAMPAGNE]: 'Champagne',
  [BUILDING_ACTION_TYPE_WHISKEY]: 'Whiskey',
  [BUILDING_ACTION_TYPE_VODKA]: 'Vodka',
  [BUILDING_ACTION_TYPE_TEQUILA]: 'Tequila',
  [BUILDING_ACTION_TYPE_GIN]: 'Gin',
  [BUILDING_ACTION_TYPE_RUM]: 'Rum',
  [BUILDING_ACTION_TYPE_OTHER_LIQUOR]: 'Other liquor',
  [BUILDING_ACTION_TYPE_BEER]: 'Beer',
  [BUILDING_ACTION_TYPE_COFFEE]: 'Coffee',
  [BUILDING_ACTION_TYPE_JUICE]: 'Juice',
  [BUILDING_ACTION_TYPE_TEA]: 'Tea',
  [BUILDING_ACTION_TYPE_OTHER_CANDY]: 'Other candy',
  [BUILDING_ACTION_TYPE_CHOCOLATES]: 'Chocolates',
  [BUILDING_ACTION_TYPE_CAKE]: 'Cake',
  [BUILDING_ACTION_TYPE_BASKET_OF_FRUITS]: 'Basket of fruits',
  [BUILDING_ACTION_TYPE_FOOD_BEVERAGE_GIFT_CARD]: 'Food beverage gift card',
  [BUILDING_ACTION_TYPE_SNACKS]: 'Snacks',
  [BUILDING_ACTION_TYPE_REACHED_OUT_VIA_CHAT]: 'Reached out via chat',
  [BUILDING_ACTION_TYPE_REACHED_OUT_IN_PERSON]: 'Reached out in person',
  [BUILDING_ACTION_TYPE_WROTE_A_KIND_NOTE]: 'Wrote a kind note',
  [BUILDING_ACTION_TYPE_GAVE_THEM_COMPLIMENTARY_SERVICE]: 'Gave them complimentary service',
  [BUILDING_ACTION_TYPE_TOYS]: 'Toys',
  [BUILDING_ACTION_TYPE_BOOKS]: 'Books',
}

export const BUILDING_ACTION_TYPE_TO_CATEGORY = {
  [BUILDING_ACTION_TYPE_RETAIL_GIFT_CARD]: CATEGORIES_PURCHASED_ITEMS,
  [BUILDING_ACTION_TYPE_TEDDY_BEAR]: CATEGORIES_PURCHASED_ITEMS,
  [BUILDING_ACTION_TYPE_FLOWERS]: CATEGORIES_PURCHASED_ITEMS,
  [BUILDING_ACTION_TYPE_BALLOON]: CATEGORIES_PURCHASED_ITEMS,
  [BUILDING_ACTION_TYPE_GLASSES]: CATEGORIES_PURCHASED_ITEMS,
  [BUILDING_ACTION_TYPE_CANDLE]: CATEGORIES_PURCHASED_ITEMS,
  [BUILDING_ACTION_TYPE_COFFEE_MUG]: CATEGORIES_PURCHASED_ITEMS,
  [BUILDING_ACTION_TYPE_BABY_CLOTHES]: CATEGORIES_PURCHASED_ITEMS,
  [BUILDING_ACTION_TYPE_PICTURE_FRAME]: CATEGORIES_PURCHASED_ITEMS,
  [BUILDING_ACTION_TYPE_PLANT]: CATEGORIES_PURCHASED_ITEMS,
  [BUILDING_ACTION_TYPE_TOYS]: CATEGORIES_PURCHASED_ITEMS,
  [BUILDING_ACTION_TYPE_BOOKS]: CATEGORIES_PURCHASED_ITEMS,

  [BUILDING_ACTION_TYPE_WHITE_WINE]: CATEGORIES_PURCHASED_ALCOHOL,
  [BUILDING_ACTION_TYPE_RED_WINE]: CATEGORIES_PURCHASED_ALCOHOL,
  [BUILDING_ACTION_TYPE_ROSE]: CATEGORIES_PURCHASED_ALCOHOL,
  [BUILDING_ACTION_TYPE_CHAMPAGNE]: CATEGORIES_PURCHASED_ALCOHOL,
  [BUILDING_ACTION_TYPE_WHISKEY]: CATEGORIES_PURCHASED_ALCOHOL,
  [BUILDING_ACTION_TYPE_VODKA]: CATEGORIES_PURCHASED_ALCOHOL,
  [BUILDING_ACTION_TYPE_TEQUILA]: CATEGORIES_PURCHASED_ALCOHOL,
  [BUILDING_ACTION_TYPE_GIN]: CATEGORIES_PURCHASED_ALCOHOL,
  [BUILDING_ACTION_TYPE_RUM]: CATEGORIES_PURCHASED_ALCOHOL,
  [BUILDING_ACTION_TYPE_BEER]: CATEGORIES_PURCHASED_ALCOHOL,
  [BUILDING_ACTION_TYPE_OTHER_LIQUOR]: CATEGORIES_PURCHASED_ALCOHOL,

  [BUILDING_ACTION_TYPE_COFFEE]: CATEGORIES_PURCHASED_FOOD,
  [BUILDING_ACTION_TYPE_JUICE]: CATEGORIES_PURCHASED_FOOD,
  [BUILDING_ACTION_TYPE_TEA]: CATEGORIES_PURCHASED_FOOD,
  [BUILDING_ACTION_TYPE_OTHER_CANDY]: CATEGORIES_PURCHASED_FOOD,
  [BUILDING_ACTION_TYPE_CHOCOLATES]: CATEGORIES_PURCHASED_FOOD,
  [BUILDING_ACTION_TYPE_CAKE]: CATEGORIES_PURCHASED_FOOD,
  [BUILDING_ACTION_TYPE_BASKET_OF_FRUITS]: CATEGORIES_PURCHASED_FOOD,
  [BUILDING_ACTION_TYPE_FOOD_BEVERAGE_GIFT_CARD]: CATEGORIES_PURCHASED_FOOD,
  [BUILDING_ACTION_TYPE_SNACKS]: CATEGORIES_PURCHASED_FOOD,

  [BUILDING_ACTION_TYPE_REACHED_OUT_VIA_CHAT]: CATEGORIES_PERSONALIZED_INTERACTION,
  [BUILDING_ACTION_TYPE_REACHED_OUT_IN_PERSON]: CATEGORIES_PERSONALIZED_INTERACTION,
  [BUILDING_ACTION_TYPE_WROTE_A_KIND_NOTE]: CATEGORIES_PERSONALIZED_INTERACTION,
  [BUILDING_ACTION_TYPE_GAVE_THEM_COMPLIMENTARY_SERVICE]: CATEGORIES_PERSONALIZED_INTERACTION,
}

export const NUDGE_TYPE_BIRTHDAY = [
  BUILDING_NUDGE_TYPE_RESIDENT_BIRTHDAY,
  BUILDING_NUDGE_TYPE_PET_BIRTHDAY,
  BUILDING_NUDGE_TYPE_FAMILY_MEMBER_BIRTHDAY,
  BUILDING_NUDGE_TYPE_RESIDENT_BUILDING_ANNIVERSARY,
]

export const NUDGE_TYPE_ISSUE = [
  BUILDING_NUDGE_TYPE_MAINTENANCE_DELAY,
  BUILDING_NUDGE_TYPE_APP_ISSUE,
  BUILDING_NUDGE_TYPE_PARKING_ISSUE,
  BUILDING_NUDGE_TYPE_CLEANING_ISSUE,
  BUILDING_NUDGE_TYPE_PACKAGE_ISSUE,
]

export const BTN_ADD = 'Log new action'
export const IGNORE_CTA = 'Ignore'
export const IGNORE_TITLE = 'Ignore insight'
export const IGNORE_MESSAGE = 'Are you sure you don’t want to act on this insight?'
export const BTN_SAVE = 'Save'
export const BTN_CANCEL = 'Cancel'

export const buildingActionTypeToCategoryLabel = type => {
  return CATEGORY_LABELS[BUILDING_ACTION_TYPE_TO_CATEGORY[type]]
}

export const INSIGHT_CATEGORY_INSIGHT = 'INSIGHT'
export const INSIGHT_CATEGORY_ISSUE = 'ISSUE'
export const INSIGHT_CATEGORY_LIFE_EVENT = 'LIFE_EVENT'
export const INSIGHT_CATEGORY_OCCASION = 'OCCASION'
export const INSIGHT_CATEGORY_OTHER = 'OTHER'

// these values must match the
export const EVENT_TYPE_TO_FILTER = {
  [BUILDING_NUDGE_TYPE_APP_ISSUE]: INSIGHT_CATEGORY_ISSUE,
  [BUILDING_NUDGE_TYPE_MAINTENANCE_DELAY]: INSIGHT_CATEGORY_ISSUE,
  [BUILDING_NUDGE_TYPE_PARKING_ISSUE]: INSIGHT_CATEGORY_ISSUE,
  [BUILDING_NUDGE_TYPE_CLEANING_ISSUE]: INSIGHT_CATEGORY_ISSUE,
  [BUILDING_NUDGE_TYPE_PACKAGE_ISSUE]: INSIGHT_CATEGORY_ISSUE,

  [BUILDING_NUDGE_TYPE_NEW_RESIDENT]: INSIGHT_CATEGORY_INSIGHT,
  [BUILDING_NUDGE_TYPE_RESIDENT_BUILDING_ANNIVERSARY]: INSIGHT_CATEGORY_INSIGHT,
  [BUILDING_NUDGE_TYPE_LEASE_ENDING]: INSIGHT_CATEGORY_INSIGHT,
  [BUILDING_NUDGE_TYPE_LEASE_RENEWAL]: INSIGHT_CATEGORY_INSIGHT,

  [BUILDING_NUDGE_TYPE_JUST_BECAUSE]: INSIGHT_CATEGORY_OCCASION,
  [BUILDING_NUDGE_TYPE_GET_WELL_SOON]: INSIGHT_CATEGORY_OCCASION,
  [BUILDING_NUDGE_TYPE_THANK_YOU]: INSIGHT_CATEGORY_OCCASION,
  [BUILDING_NUDGE_TYPE_CONGRATULATIONS]: INSIGHT_CATEGORY_OCCASION,

  [BUILDING_NUDGE_TYPE_RESIDENT_BIRTHDAY]: INSIGHT_CATEGORY_LIFE_EVENT,
  [BUILDING_NUDGE_TYPE_PET_BIRTHDAY]: INSIGHT_CATEGORY_LIFE_EVENT,
  [BUILDING_NUDGE_TYPE_ROMANTIC_ANNIVERSARY]: INSIGHT_CATEGORY_LIFE_EVENT,
  [BUILDING_NUDGE_TYPE_NEW_BABY]: INSIGHT_CATEGORY_LIFE_EVENT,
  [BUILDING_NUDGE_TYPE_ENGAGEMENT]: INSIGHT_CATEGORY_LIFE_EVENT,
  [BUILDING_NUDGE_TYPE_PROMOTION]: INSIGHT_CATEGORY_LIFE_EVENT,
  [BUILDING_NUDGE_TYPE_WEDDING]: INSIGHT_CATEGORY_LIFE_EVENT,
  [BUILDING_NUDGE_TYPE_GRADUATION]: INSIGHT_CATEGORY_LIFE_EVENT,
  [BUILDING_NUDGE_TYPE_FAMILY_MEMBER_BIRTHDAY]: INSIGHT_CATEGORY_LIFE_EVENT,

  [BUILDING_NUDGE_TYPE_CONDOLENCES]: INSIGHT_CATEGORY_OTHER,
  [BUILDING_NUDGE_TYPE_OTHER]: INSIGHT_CATEGORY_OTHER,

  DEFAULT: INSIGHT_CATEGORY_OTHER,
}

export const INSIGHT_CATEGORY_TO_LABEL = {
  [INSIGHT_CATEGORY_INSIGHT]: 'Insight',
  [INSIGHT_CATEGORY_ISSUE]: 'Issue',
  [INSIGHT_CATEGORY_LIFE_EVENT]: 'Life event',
  [INSIGHT_CATEGORY_OCCASION]: 'Occasion',
  [INSIGHT_CATEGORY_OTHER]: 'Other',
}

export const NUDGE_TYPE_TO_ICON = {
  [BUILDING_NUDGE_TYPE_RESIDENT_BIRTHDAY]: RXRIcon.BIRTHDAY,
  [BUILDING_NUDGE_TYPE_PET_BIRTHDAY]: RXRIcon.PET_BIRTHDAY,
  [BUILDING_NUDGE_TYPE_FAMILY_MEMBER_BIRTHDAY]: RXRIcon.BIRTHDAY,

  [BUILDING_NUDGE_TYPE_RESIDENT_BUILDING_ANNIVERSARY]: RXRIcon.BUILDING_ANNIVERSARY,

  [BUILDING_NUDGE_TYPE_APP_ISSUE]: RXRIcon.MAINTENANCE,
  [BUILDING_NUDGE_TYPE_MAINTENANCE_DELAY]: RXRIcon.MAINTENANCE,
  [BUILDING_NUDGE_TYPE_PARKING_ISSUE]: RXRIcon.MAINTENANCE,
  [BUILDING_NUDGE_TYPE_CLEANING_ISSUE]: RXRIcon.MAINTENANCE,
  [BUILDING_NUDGE_TYPE_PACKAGE_ISSUE]: RXRIcon.MAINTENANCE,

  [BUILDING_NUDGE_TYPE_LEASE_ENDING]: RXRIcon.CLOCK,
  [BUILDING_NUDGE_TYPE_LEASE_RENEWAL]: RXRIcon.CLOCK,

  [BUILDING_NUDGE_TYPE_ROMANTIC_ANNIVERSARY]: RXRIcon.CELEBRATION,
  [BUILDING_NUDGE_TYPE_NEW_BABY]: RXRIcon.CELEBRATION,
  [BUILDING_NUDGE_TYPE_ENGAGEMENT]: RXRIcon.CELEBRATION,
  [BUILDING_NUDGE_TYPE_PROMOTION]: RXRIcon.CELEBRATION,
  [BUILDING_NUDGE_TYPE_WEDDING]: RXRIcon.CELEBRATION,
  [BUILDING_NUDGE_TYPE_GRADUATION]: RXRIcon.CELEBRATION,

  [BUILDING_NUDGE_TYPE_NEW_RESIDENT]: RXRIcon.PEN,
  DEFAULT: RXRIcon.PEN,
}

export function renderActionsLabelForBuildingAction(buildingAction) {
  return buildingAction.actions
    .map(a => BUILDING_ACTION_TYPE_LABELS[a])
    .sort()
    .join(', ')
}

export function renderCategoryLabelsForBuildingAction(buildingAction) {
  return [...new Set(buildingAction.actions.map(buildingActionTypeToCategoryLabel))].sort().join(', ')
}

/**
 * @param {BuildingNudgeFragment} a
 * @param {BuildingNudgeFragment} b
 * @returns {*}
 */
export function buildingNudgeComparator(a, b) {
  return a.isHighPriority === b.isHighPriority
    ? a.expiredAt < b.expiredAt
      ? -1
      : a.expiredAt > b.expiredAt
      ? 1
      : 0
    : a.isHighPriority
    ? -1
    : 1
}
