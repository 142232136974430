import useResidentLookup from './useResidentLookup'
import {doesResidentNeedForecasting} from '../ResidentSentiment/residentSentimentUtilities'
import {useSelector} from 'react-redux'
import {selectLastTimeResidentForecasted} from '../../reducers/selectors'

/**
 * @param {string} residentId
 * @return {{
 *   isNeeded: boolean,
 *   lastForecastedAt: Date|null
 * }}
 */
function useResidentNeedsSentimentForecast(residentId) {
  const {getResident} = useResidentLookup()
  const recentlyForecastedResidents = useSelector(state => state.Feedback.recentlyForecastedResidents)
  const resident = getResident(residentId)
  const lastForecasted = useSelector(state => selectLastTimeResidentForecasted(state, residentId))

  return {
    isNeeded: doesResidentNeedForecasting(resident, recentlyForecastedResidents),
    lastForecastedAt: lastForecasted,
  }
}

export default useResidentNeedsSentimentForecast
