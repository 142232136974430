import React, {useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {useSelector, useDispatch} from 'react-redux'
import Routes from '../../constants/RouteConstants'
import {useHistory, useLocation, matchPath} from 'react-router-dom'
import PageHeader from '../PageHeader'
import {Grid} from '@material-ui/core'
import {AntTab, AntTabs} from '../AntTabs'
import ClearableInput from '../ClearableInput'
import ReservationsTable from './ReservationsTable'
import moment from 'moment'
import Constant from './AmenitiesConstants'
import EmptyStateIconAndMessage from '../EmptyStateIconAndMessage'
import {rxrBlueColor} from '../../assets/styles/color'
import DateRangeInput from '../DateRangeInput'
import AmenityReservationEditor from './AmenityReservationEditor'
import {RXRButton} from '../RXRButton'
import {loadBuildingStaffForBuilding} from '../../actions/staffActions'

import Loader from '../Loader'
import {loadAmenitiesForBuilding, loadAmenityReservationsForBuilding} from '../../actions/amenitiesActions'
import {PageContainer} from '../../assets/styles/layout'
import SidePanel from '../SidePanel'
import useResidentLookup from '../hooks/useResidentLookup'
import useBuildingDataRefresh from '../hooks/useBuildingDataRefresh'
import RXRIcon from '../RXRIcon'

const useStyles = makeStyles(() => ({
  cursorHand: {
    cursor: 'pointer',
  },
  dateFilterContainer: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',

    '& .MuiInput-underline:before': {
      display: 'block',
    },
  },
  textAuthDate: {
    width: '193px',
    borderBottom: '0px',
    textAlign: 'center',
  },

  placeSearch: {
    width: '418px',
    borderBottom: '0px',
  },

  slidingPaneContainer: {},
  selectGuestCloseIcon: {
    textAlign: 'right',
  },
  peopleIconContainer: {
    fontSize: 80,
    lineHeight: 0,
    color: rxrBlueColor,
  },
  selectDeliveryCloseIcon: {
    textAlign: 'right',
  },
}))

const EMPTY_FORM_STATE = {
  id: null,
  amenityId: null,
  residentId: null,
  reservationDate: null,
  startAt: null,
  endAt: null,
  partySize: 1,
  notes: null,
}

export function reservationNeedsApproval(r) {
  return r.reservationState === Constant.STATE_REQUESTED
}

export function reservationIsUpcoming(r) {
  return [Constant.STATE_CONFIRMED, Constant.STATE_UNDERWAY, Constant.STATE_CONFIRMED_APPROVED].includes(r.reservationState)
}

export function reservationIsPast(r) {
  return [Constant.STATE_DECLINED, Constant.STATE_CANCELLED, Constant.STATE_COMPLETED, Constant.STATE_TIMEDOUT].includes(r.reservationState)
}

function ViewAmenityReservationsPage(props) {
  const classes = useStyles()

  const [tab, setTab] = useState(Constant.TAB_NEEDS_APPROVAL)
  const [filterTerm, setFilterTerm] = useState('')
  const [filterDateStart, setFilterDateStart] = useState(null)
  const [filterDateEnd, setFilterDateEnd] = useState(null)
  const history = useHistory()
  const currentLocation = useLocation()
  let focusedItemId = null
  let isCreateMode = false
  let createMatch = matchPath(currentLocation.pathname, {path: Routes.AMENITIES_RESERVATIONS_CREATE})
  let viewSingleMatch = matchPath(currentLocation.pathname, {path: Routes.AMENITIES_RESERVATIONS_VIEW_SINGLE})
  if (createMatch && createMatch.isExact) {
    isCreateMode = true
  } else if (viewSingleMatch && viewSingleMatch.isExact) {
    isCreateMode = false
    focusedItemId = viewSingleMatch.params['reservationId']
  }

  const dispatch = useDispatch()
  const amenityReservationsLookup = useSelector(state => state.Amenities.amenityReservationsLookup)
  const amenitiesLookup = useSelector(state => state.Amenities.amenitiesLookup)
  const {getResident} = useResidentLookup()
  const {activeBuildingId, isLoadingData: isLoadingReservations} = useBuildingDataRefresh(() => {
    return Promise.all([
      loadAmenityReservationsForBuilding(dispatch, activeBuildingId),
      loadAmenitiesForBuilding(dispatch, activeBuildingId),
    ])
  })

  const allReservations = Object.values(amenityReservationsLookup)
    .filter(r => amenitiesLookup[r.amenityId] && !amenitiesLookup[r.amenityId].isOnboardingOnly)
    .map(amenityReservation => {
      // Setting state of amenityReservation status to TIMEDOUT if exist in past date and is not approved/denied.
      if (amenityReservation.reservationState === Constant.STATE_REQUESTED && moment(amenityReservation.startAt) < moment()) {
        amenityReservation.reservationState = Constant.STATE_TIMEDOUT
      }
      return amenityReservation
    })

  // USES THE VIEW_SINGLE / CREATE LOAD PATTERN
  useEffect(() => {
    if (focusedItemId) {
      const item = amenityReservationsLookup[focusedItemId]
      if (item) {
        if (reservationNeedsApproval(item)) {
          setTab(Constant.TAB_NEEDS_APPROVAL)
        } else if (reservationIsUpcoming(item)) {
          setTab(Constant.TAB_UPCOMING_RESERVATION)
        } else {
          setTab(Constant.TAB_PAST_RESERVATION)
        }
      }
    }
  }, [focusedItemId, amenityReservationsLookup, isLoadingReservations])

  // Load the staffLookup on activeBuildingId changes
  useEffect(() => {
    loadBuildingStaffForBuilding(dispatch, activeBuildingId).then()
  }, [activeBuildingId])

  const formattedStartDate = filterDateStart ? filterDateStart.toISOString() : null
  const formattedEndDate = filterDateEnd ? filterDateEnd.toISOString() : null
  const formattedTerm = filterTerm.toLowerCase()

  let needsApprovalCount = 0
  let allReservationsFiltered = allReservations.filter(r => {
    // we're going to overload our filter function to also count needs approval
    if (reservationNeedsApproval(r)) {
      needsApprovalCount++
    }

    const resident = getResident(r.residentId)

    return (
      (!formattedStartDate || r.startAt >= formattedStartDate) &&
      (!formattedEndDate || r.startAt <= formattedEndDate) &&
      (amenitiesLookup[r.amenityId].label.toLowerCase().includes(formattedTerm) ||
        resident.displayName.toLowerCase().includes(formattedTerm) ||
        resident.occupancy.unit.number.toLowerCase().includes(formattedTerm))
    )
  })

  // now we filter a second time based on which tab we're on.
  let reservationsOnTab = allReservationsFiltered.filter(r => {
    switch (tab) {
      case Constant.TAB_NEEDS_APPROVAL:
        return reservationNeedsApproval(r)

      case Constant.TAB_UPCOMING_RESERVATION:
        return reservationIsUpcoming(r)

      case Constant.TAB_PAST_RESERVATION:
        return reservationIsPast(r)
    }
  })

  let visibleTableComponent

  if (reservationsOnTab.length === 0) {
    visibleTableComponent = (
      <EmptyStateIconAndMessage
        message={`There are currently no reservation requests. Select “Make a reservation” to create a new reservation.`}
        icon={RXRIcon.AMENITIES}
      />
    )
  } else {
    visibleTableComponent = <ReservationsTable reservations={reservationsOnTab} tab={tab} filterTerm={filterTerm} />
  }

  if (isLoadingReservations) {
    return <Loader />
  }

  let needsApprovalTabLabel = `Needs approval ${needsApprovalCount > 0 ? '(' + needsApprovalCount + ')' : ''}`
  return (
    <div style={PageContainer}>
      <PageHeader
        title={Constant.PAGE_TITLE}
        rightControlComponent={
          <RXRButton type={RXRButton.TYPE_LARGE} onClick={() => history.push(Routes.AMENITIES_RESERVATIONS_CREATE)}>
            {Constant.BTN_ADD}
          </RXRButton>
        }
      >
        <Grid container spacing={3}>
          <Grid item lg={6} md={6}>
            <div className={classes.placeSearch}>
              <ClearableInput value={filterTerm} onChange={setFilterTerm} placeholder={Constant.ENTER_SEARCH_KEYWORDS_CONSTANT} />
            </div>
          </Grid>

          <Grid item lg={6} md={6}>
            <div className={classes.dateFilterContainer}>
              <DateRangeInput
                onChange={(start, end) => {
                  setFilterDateStart(start)
                  setFilterDateEnd(end)
                }}
                startDate={filterDateStart}
                endDate={filterDateEnd}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={8}>
            <AntTabs value={tab} onChange={(e, t) => setTab(t)}>
              <AntTab label={needsApprovalTabLabel} />
              <AntTab label="Upcoming reservations" />
              <AntTab label="Previous reservations" />
            </AntTabs>
          </Grid>
        </Grid>
      </PageHeader>

      <div style={{height: '100%'}}>{visibleTableComponent}</div>

      <SidePanel
        isOpen={!!(focusedItemId || isCreateMode)}
        onClose={() => history.push(Routes.AMENITIES_RESERVATIONS)}
        title={focusedItemId ? 'Reservation request details' : 'Make a reservation'}
      >
        <AmenityReservationEditor
          amenityReservationId={focusedItemId}
          onComplete={() => history.push(Routes.AMENITIES_RESERVATIONS)}
          amenitiesFilter={a => !a.isOnboardingOnly}
        />
      </SidePanel>
    </div>
  )
}

export default ViewAmenityReservationsPage
