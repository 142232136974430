import React, {useEffect, useRef, useState} from 'react'
import SimpleSpinner from './SimpleSpinner'
import {Avatar, makeStyles} from '@material-ui/core'
import {
  blackOverlay80,
  rxrErrorColor,
  rxrGreenColor,
  rxrMediumGreyColor,
  rxrTealColor,
  rxrWarmBrown,
  rxrWhiteColor,
  rxrYellowColor,
} from '../assets/styles/color'
import PropTypes from 'prop-types'
import FileHelper from '../lib/FileHelper'
import RXRIcon from './RXRIcon'
import {v4 as uuid} from 'uuid'
import {constructClassString} from '../Utils/objectUtil'

const colors = [rxrGreenColor, rxrYellowColor, rxrErrorColor, rxrTealColor, rxrWarmBrown, rxrMediumGreyColor]

const MAX_INITIALS = 2

/**
 * @param {string} id
 * @returns {string}
 */
function getColorById(id) {
  if (typeof id === 'string' && id.length > 0) {
    return colors[id.charCodeAt(0) % colors.length]
  } else {
    return colors[0]
  }
}

function ProfileImage(props) {
  const idRef = useRef(uuid())
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [imageURI, setImageURI] = useState(null)
  const [genericColor, setGenericColor] = useState(null)

  useEffect(() => {
    setGenericColor(getColorById(props.colorId))
  }, [props.colorId])

  useEffect(() => {
    if (props.s3Object) {
      FileHelper.Instance()
        .getFileUrlFromS3Object(props.s3Object)
        .then(image => {
          const i = new Image()
          i.onload = () => setImageURI(image)
          i.src = image
        })
        .catch(err => {
          console.error('image fetching error: ', err)
        })
        .finally(() => setIsLoading(false))
    } else {
      setImageURI(null)
      setIsLoading(false)
    }
  }, [props.s3Object])

  const handleImages = e => {
    setIsLoading(true)
    FileHelper.Instance()
      .handleSelectFilesInputEventUploadToS3(e, FileHelper.IMAGE_FILE_TYPES)
      .then(uploaded => {
        // uploaded is an array of S3 objects, but we only want the first one
        props.onUploaded(uploaded[0])
      })
      .catch(err => {
        window.alert(err.message)
      })
      .finally(() => setIsLoading(false))
  }

  const initials = (props.displayName || '')
    .split(' ')
    .filter(n => typeof n === 'string' && n.length > 0)
    .slice(0, MAX_INITIALS)
    .map(n => n[0].toUpperCase())

  return (
    <div className={[classes.profileImageContainer, props.className].join(' ')}>
      {typeof props.onUploaded === 'function' && !isLoading && (
        <div
          className={constructClassString(classes.uploadImageContainer, {
            [classes.visibleOverlay]: props.showUploadByDefault && !props.s3Object,
          })}
        >
          <input name="image" onChange={handleImages} accept="image/*" style={{display: 'none'}} id={idRef.current} multiple type="file" />
          <label htmlFor={idRef.current}>
            <div className={classes.uploadIcon}>
              <RXRIcon icon={RXRIcon.EDIT} size={RXRIcon.SIZE_LARGE} />
            </div>
          </label>
        </div>
      )}
      {isLoading ? (
        <SimpleSpinner size={'30%'} />
      ) : imageURI ? (
        <Avatar className={classes.personAvatar} alt="Profile picture" src={imageURI} />
      ) : (
        <Avatar className={classes.personAvatar} alt="Profile picture">
          {/*By using an SVG we can be assured the text scales with the container size*/}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" style={{width: '100%', backgroundColor: genericColor}}>
            <text
              style={{
                fill: rxrWhiteColor,
                fontSize: 40,
                // 28px is perfect for exactly 2 initials, but if there's 1 we want to center it
                transform: `translate(${28 + (MAX_INITIALS - initials.length) * 10}px, 64px)`,
                fontFamily: 'monospace',
              }}
            >
              {initials}
            </text>
          </svg>
        </Avatar>
      )}
      {props.children}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  personAvatar: {
    height: '100%',
    width: '100%',
    backgroundColor: 'transparent',
  },
  profileImageContainer: {
    borderRadius: '50%',
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  uploadImageContainer: {
    zIndex: 2,
    borderRadius: '50%',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: blackOverlay80,
    color: rxrWhiteColor,
    opacity: 0,
    transition: 'opacity 0.2s ease-in-out',
    '&:hover': {
      opacity: 1,
    },
  },

  uploadIcon: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  visibleOverlay: {
    opacity: 1,
  },
}))

ProfileImage.propTypes = {
  className: PropTypes.string,
  s3Object: PropTypes.any,
  colorId: PropTypes.string,
  displayName: PropTypes.string,
  children: PropTypes.any,
  onUploaded: PropTypes.func, // if supplied, will allow the user to upload a new image
  showUploadByDefault: PropTypes.bool, // if true, the upload button will be visible by default without hover
}

export default ProfileImage
