export default {
  MESSAGE_EXISTING_RESIDENT_CONSTANT: 'Search existing messages or for a new resident if you’d like to start a conversation with them',
  MESSAGE_NO_CONTENT: 'Search for a resident in the search bar to open the conversation with them.',
  MESSAGES_SEARCH_CONSTANT: 'Search',
  MESSAGES_RESULTS_FOUND_CONSTANT: 'search results found',
  MESSAGESVIEWER_OPEN_CONSTANT: 'open',
  MESSAGESVIEWER_INPROGRESS_CONSTANT: 'in progress',
  MESSAGESVIEWER_CLOSED_CONSTANT: 'closed',
  MESSAGE_MARKED_EVENT_CONSTANT: 'marked this thread as',
  MESSAGE_THIS_EVENT_CONSTANT: 'this thread',
  MESSAGE_SEND_CONSTANT: 'Send',
  MESSAGE_TYPE_CONSTANT: 'Type a message',
  MESSAGE_ENTER_SEARCH_KEYWORDS_CONSTANT: 'Filter messages by resident, unit or message.',
  MESSAGE_ANNOUNCEMENT_CONSTANT: 'There are currently no announcements. Click on ‘Add new’ to create one.',
  MESSAGE_EVENT_CONSTANT: 'There are currently no scheduled events. Click on ‘Add new’ to create one.',
  EMPTY_STRING: '',
  OOO_MESSAGE: 'You cannot message residents while offline. Change your status to send a message.',
  DEFAULT_OOO_MESSAGE:
    'Your Resident Experience Officer is currently offline and will get back to you as quickly as possible during business hours.',
}
