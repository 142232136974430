import {
  ONBOARDING_STATE_AVAILABLE,
  ONBOARDING_STATE_CHANGES_REQUESTED,
  ONBOARDING_STATE_COMPLETED,
  ONBOARDING_STATE_IN_PROGRESS,
} from '../../../constants/ModelConstants'
import FileAttacher from '../FileAttacher'
import React, {useState} from 'react'
import useSharedStyles from '../useSharedStyles'
import {makeStyles} from '@material-ui/core/styles'
import {spaceMedium} from '../../../assets/styles/spacing'
import EditCTA from './EditCTA'
import RejectOrAcceptAndUploadToYardiCTAs from './RejectOrAcceptAndUploadToYardiCTAs'
import PropTypes from 'prop-types'
import {getSyncedAndNotSyncedFilesFromSubmission} from '../../../Utils/onboardingUtils'

function RejectOrAcceptWithFileAttacher(props) {
  const sharedClasses = useSharedStyles()
  const classes = useStyles()
  const {isEditing} = props
  const [isEditingCompletedSubmissionState, setIsEditingCompletedSubmission] = useState(false)

  const isEditingCompletedSubmission = isEditing !== undefined ? isEditing : isEditingCompletedSubmissionState

  const {syncedAttachments, notSyncedAttachments} = getSyncedAndNotSyncedFilesFromSubmission(props.submission)

  const handleMarkRejected = () => {
    if (props.submission.state === ONBOARDING_STATE_CHANGES_REQUESTED) {
      return
    }

    // update our submission state to CHANGES_REQUESTED
    props.onUpdateSubmission({
      ...props.submission,
      state: ONBOARDING_STATE_CHANGES_REQUESTED,
    })
  }

  const handleAcceptAndUploadToYardi = () => {
    // Note, we allow updating the submission to state COMPLETED even if it is already state COMPLETED
    // so that the user is able to upload any other attachments to Yardi that may be required

    // update our submission state to COMPLETED
    props.onUpdateSubmission({
      ...props.submission,
      state: ONBOARDING_STATE_COMPLETED,
    })

    // NOTE: the residentOnboarding lambda function will then upload any attachments
    // (that are not already uploaded) to Yardi
  }

  const onEdit = () => {
    if (isEditing === undefined) {
      setIsEditingCompletedSubmission(true)
    }
    props.onEdit && props.onEdit()
  }

  const onFinishEditing = () => {
    props.onUpdateSubmission({
      ...props.submission,
    })
    if (isEditing === undefined) {
      setIsEditingCompletedSubmission(false)
    }
    props.onFinishEditing && props.onFinishEditing()
  }

  const determineCTAsFromSubmissionState = () => {
    if (!props.submission || !props.submission.state) {
      return null
    }

    if (props.submission.state === ONBOARDING_STATE_COMPLETED) {
      return (
        <EditCTA onEdit={onEdit} onFinishEditing={onFinishEditing} isEditing={isEditingCompletedSubmission} isDisabled={props.isDisabled} />
      )
    }

    return (
      <RejectOrAcceptAndUploadToYardiCTAs
        onReject={handleMarkRejected}
        onAcceptAndUploadToYardi={handleAcceptAndUploadToYardi}
        isRejected={props.submission.state === ONBOARDING_STATE_CHANGES_REQUESTED}
        isDisabled={props.isDisabled}
      />
    )
  }

  const handleNotSyncedAttachments = newNotSyncedAttachmentsArray => {
    let updatedSubmissionState = props.submission.state
    if (newNotSyncedAttachmentsArray.length > 0 && props.submission.state === ONBOARDING_STATE_AVAILABLE) {
      updatedSubmissionState = ONBOARDING_STATE_IN_PROGRESS
    }

    props.onUpdateSubmission({
      ...props.submission,
      attachments: [...syncedAttachments, ...newNotSyncedAttachmentsArray],
      state: updatedSubmissionState,
    })
  }

  return (
    <div className={sharedClasses.ctaContainer}>
      {(props.submission.state !== ONBOARDING_STATE_COMPLETED || isEditingCompletedSubmission) && (
        <div className={classes.fileAttacherContainer}>
          <FileAttacher value={notSyncedAttachments} onChange={handleNotSyncedAttachments} />
        </div>
      )}
      {determineCTAsFromSubmissionState()}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  fileAttacherContainer: {
    marginBottom: spaceMedium,
  },
}))

RejectOrAcceptWithFileAttacher.propTypes = {
  submission: PropTypes.object.isRequired,
  onUpdateSubmission: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  onEdit: PropTypes.func,
  onFinishEditing: PropTypes.func,
  isDisabled: PropTypes.bool,
}

export default RejectOrAcceptWithFileAttacher
