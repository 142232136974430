import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import {DatePicker} from '@material-ui/pickers'
import moment from 'moment'
import {IconButton} from '@material-ui/core'
import {rxrBlackColor, rxrWhiteColor} from '../assets/styles/color'
import RXRIcon from './RXRIcon'
import {Colors, Spacing, Typography} from '../assets/styles'
import {iconButton} from '../assets/styles/buttons'
import {constructClassString} from '../Utils/objectUtil'

// these styles are also used by DateInputWithArrows. Could one day refactor...
export const useStyles = makeStyles(theme => ({
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit',
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: rxrWhiteColor,
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },

  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },

  calendarLabelContainer: {
    // just looks nice with this width
    margin: '0 auto',
    width: 360,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dateTitleContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dateRangeTitle: {
    fontSize: Typography.fontSizeExtraLarge,
    textAlign: 'center',
    letterSpacing: 3,
    textTransform: 'uppercase',
    color: rxrBlackColor,
  },

  iconButtonRoot: iconButton,
  iconButtonRootHover: {
    '&:hover': {
      backgroundColor: 'unset',
      cursor: 'unset',
    },
  },
}))

function DateInputWeek(props) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)

  const handleWeekChange = momentDate => {
    props.onChange(
      momentDate.toDate(),
      moment(momentDate)
        .startOf('week')
        .toDate(),
      moment(momentDate)
        .endOf('week')
        .toDate(),
    )
  }

  const formatWeekSelectLabel = () => {
    if (!props.date || isNaN(props.date.getTime())) {
      return 'Invalid date'
    }
    let momentDate = moment(props.date)
    return `${momentDate.startOf('week').format('MMM D')} - ${momentDate.endOf('week').format('MMM D')}`
  }

  /**
   * @param {Date} date
   * @param {Date} selectedDate
   * @param {boolean} dayInCurrentMonth
   * @return {JSX.Element}
   */
  const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const start = moment(selectedDate)
      .startOf('week')
      .toDate()
    const end = moment(selectedDate)
      .endOf('week')
      .toDate()

    const dayIsBetween = date >= start && date <= end
    const isFirstDay = moment(date).isSame(start, 'day')
    const isLastDay = moment(date).isSame(end, 'day')

    const wrapperClassName = constructClassString({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    })

    const dayClassName = constructClassString(
      {
        [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
        [classes.iconButtonRootHover]: !dayInCurrentMonth,
        [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
      },
      classes.day,
      classes.iconButtonRoot,
    )

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName} disableRipple={!dayInCurrentMonth}>
          <span> {moment(date).format('D')} </span>
        </IconButton>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className={classes.calendarLabelContainer}>
        <IconButton className={classes.iconButtonRoot} onClick={() => handleWeekChange(moment(props.date).subtract(1, 'weeks'))}>
          <RXRIcon icon={RXRIcon.ARROW_LEFT} />
        </IconButton>

        <div className={classes.dateTitleContainer}>
          <p className={classes.dateRangeTitle}>{formatWeekSelectLabel()}</p>
          <IconButton style={{marginLeft: Spacing.spaceSmall}} className={classes.iconButtonRoot} onClick={() => setIsOpen(true)}>
            <RXRIcon icon={RXRIcon.CALENDAR} color={Colors.rxrBlackColor} size={RXRIcon.SIZE_LARGE} />
          </IconButton>
        </div>
        <IconButton className={classes.iconButtonRoot} onClick={() => handleWeekChange(moment(props.date).add(1, 'weeks'))}>
          <RXRIcon icon={RXRIcon.ARROW_RIGHT} />
        </IconButton>
      </div>
      <div>
        <DatePicker
          value={props.date}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          variant="dialog"
          disableToolbar
          format="MM/dd/yyyy"
          minDate={props.min}
          maxDate={props.max}
          onChange={d => handleWeekChange(moment(d))}
          okLabel={'Select'}
          cancelLabel={'Cancel'}
          disabled={props.disabled}
          renderDay={renderWrappedWeekDay}
          TextFieldComponent={() => null}
        />
      </div>
    </React.Fragment>
  )
}

DateInputWeek.propTypes = {
  onChange: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
}

export default DateInputWeek
