import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import NumberInput from '../NumberInput'
import SelectInput from '../SelectInput'

const NOTICE_OPTIONS = [{value: 1, label: 'Minute'}, {value: 60, label: 'Hour'}, {value: 1440, label: 'Day'}]

function UnitAndMagnitudeInput(props) {
  const [unit, setUnit] = useState(NOTICE_OPTIONS[0].value)

  useEffect(() => {
    const u = findBiggestUnit(props.value)
    setUnit(u)
  }, [])

  return (
    <Grid container spacing={3} className={props.className}>
      <Grid item md={3}>
        <NumberInput
          label={props.label + ' (number)'}
          min={0}
          onChange={val => props.onChange(val * unit)}
          value={props.value / unit}
          isRequired={props.isRequired}
          integerOnly={true}
          error={props.hasError}
        />
      </Grid>
      <Grid item md={3}>
        <SelectInput
          onChange={val => {
            props.onChange(props.value * val)
            setUnit(val)
          }}
          label={props.label + ' (unit)'}
          options={NOTICE_OPTIONS}
          value={unit}
        />
      </Grid>
    </Grid>
  )
}

/**
 *
 * @param {number} startingValue
 * @returns {number}
 */
function findBiggestUnit(startingValue) {
  let retVal = startingValue
  let retUnit = 1
  NOTICE_OPTIONS.some(o => {
    let val = o.value
    if (retVal === val) {
      retUnit = val
    } else if (retVal > val && retVal % val === 0) {
      retUnit = val
      return false
    }
    return true
  })

  return retUnit
}

UnitAndMagnitudeInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
  className: PropTypes.any,
  isRequired: PropTypes.bool,
  hasError: PropTypes.bool,
}

export default UnitAndMagnitudeInput
