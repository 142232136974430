import moment from 'moment'

export const VENDOR_SERVICE_REQUESTED = 'REQUESTED'
export const VENDOR_SERVICE_CONFIRMED = 'CONFIRMED'
export const VENDOR_SERVICE_CONFIRMED_APPROVED = 'CONFIRMED_APPROVED'
export const VENDOR_SERVICE_CANCELLED = 'CANCELLED'
export const VENDOR_SERVICE_COMPLETED = 'COMPLETED'
export const VENDOR_SERVICE_UNDERWAY = 'UNDERWAY'
export const VENDOR_SERVICE_DECLINED = 'DECLINED'
export const VENDOR_SERVICE_COMPLETED_PAID = 'COMPLETED_PAID'
export const VENDOR_SERVICE_VENDOR_NO_SHOW = 'VENDOR_NO_SHOW'
export const VENDOR_SERVICE_NEEDS_RESCHEDULE = 'NEEDS_RESCHEDULE'

// residents get notified 2 weeks before their appointment if they need to reschedule
// @see rexVendors/src/vendorsCloudBridge.js -> createVendorAppointmentFromRecurring
const appointmentRescheduleNoticeDate = moment()
  .add(2, 'weeks')
  .startOf('day')

/**
 * @param {{startAt: string, status: string}} appointment
 * @returns {boolean}
 */
export function appointmentShouldBeHighlightedAsNeedingReschedule(appointment) {
  return moment(appointment.startAt).isBefore(appointmentRescheduleNoticeDate) && appointment.status === VENDOR_SERVICE_NEEDS_RESCHEDULE
}
