import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import {RXOTextField} from '../assets/styles'
import {Colors} from '../assets/styles/index'
import RXRIcon from './RXRIcon'

const useStyles = makeStyles(theme => ({
  dateContainer: {
    '& .MuiFormHelperText-root': {...Colors.red},
  },
}))

function DateTimeInput(props) {
  const classes = useStyles()
  const label = props.isRequired ? `${props.label} *` : props.label
  const calendarColor = props.error ? Colors.rxrRedColor : props.disabled ? Colors.rxrDarkGreyColor : Colors.rxrBlueColor

  const onChangeHandler = dateSelected => {
    props.onChange(dateSelected)
  }

  return (
    <RXOTextField.FormDateTimeField
      inputVariant="outlined"
      className={`${classes.dateContainer} ${props.className || ''}`}
      InputLabelProps={{
        shrink: true,
      }}
      label={label}
      minDate={props.min}
      maxDate={props.max}
      placeholder={' '}
      value={props.value}
      helperText={props.helperText}
      onChange={onChangeHandler}
      disabled={props.disabled}
      format="MM/dd/yyyy, hh:mm a"
      keyboardIcon={<RXRIcon icon={RXRIcon.CALENDAR} size={RXRIcon.SIZE_LARGE} color={calendarColor} />}
      error={props.error}
      fullWidth
    />
  )
}

DateTimeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.bool,
}

export default DateTimeInput
