import useDoOnceTimer from './useDoOnceTimer'
import {useEffect, useCallback, useRef, useState} from 'react'
import throttle from './throttle'

const DRAG_TIMER = 'drag-timer'
const DRAG_TIMEOUT = 500

/**
 * @typedef DragPosition
 * @property {number} x
 * @property {number} y
 */

/**
 * @typedef OnDragCallback
 * @param {DragPosition} position
 * @param {boolean?} isComplete
 */

/**
 * @typedef UseDraggableConfig
 * @property {OnDragCallback} onDrag
 * @property {function?} onClick
 */

/**
 * @param {UseDraggableConfig} config
 * @return {{isDragging: boolean, onMouseUp: function, onMouseMove: function, onMouseDown: function}}
 */
function useDraggable({onDrag, onClick}) {
  const startingPos = useRef(null)
  const movementDif = useRef(null)
  const {setTimer, cancelTimer, isTimerSet} = useDoOnceTimer()
  const [isDragging, setIsDragging] = useState(false)
  const ref = useRef()
  const unsubscribe = useRef()
  const legacyRef = useCallback(elem => {
    ref.current = elem
    if (unsubscribe.current) {
      unsubscribe.current()
    }
    if (!elem) {
      return
    }
    const handleMouseDown = e => {
      // don't forget to disable text selection during drag and drop
      // operations
      e.target.style.userSelect = 'none'
      setTimer(DRAG_TIMER, () => setIsDragging(true), DRAG_TIMEOUT)
    }

    const handleMouseUp = e => {
      if (isTimerSet(DRAG_TIMER)) {
        cancelTimer(DRAG_TIMER)
        if (typeof onClick === 'function') {
          onClick()
        }
      }
    }

    elem.addEventListener('mousedown', handleMouseDown)
    elem.addEventListener('mouseup', handleMouseUp)
    unsubscribe.current = () => {
      elem.removeEventListener('mousedown', handleMouseDown)
      elem.removeEventListener('mouseup', handleMouseUp)
    }
  }, [])

  // this logic is based off of https://stackoverflow.com/a/39192992/657280
  useEffect(() => {
    if (!isDragging) {
      return
    }

    const handleMouseMove = throttle(ev => {
      // record the mouse position when we started to drag
      if (!startingPos.current) {
        startingPos.current = {
          x: ev.clientX,
          y: ev.clientY,
        }
      }

      /** @type DragPosition */
      const newPos = {
        x: ev.clientX - startingPos.current.x,
        y: ev.clientY - startingPos.current.y,
      }
      movementDif.current = newPos

      // report the total distance moved from the starting position
      if (typeof onDrag === 'function') {
        try {
          onDrag(newPos, false)
        } catch (err) {
          console.error(err)
        }
      }
    })

    const handleMouseUp = e => {
      // we're definitely not dragging on mouse up
      setIsDragging(false)

      // report the final drag position (with true as second param
      if (movementDif.current) {
        /** @type DragPosition */
        const finalPos = {...movementDif.current}
        try {
          onDrag(finalPos, true)
        } catch (err) {
          console.error(err)
        }
      }

      // reset our starting pos so it can be marked anew on next drag
      movementDif.current = null
      startingPos.current = null
    }

    // subscribe to mousemove and mouseup on document, otherwise you
    // can escape bounds of element while dragging and get stuck
    // dragging it forever
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
    return () => {
      handleMouseMove.cancel()
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging, onDrag])

  return {
    isDragging,
    ref: legacyRef,
  }
}

export default useDraggable
