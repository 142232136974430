import ActionTypes from '../actions/types'
import {CACHE_SuperForecaster} from '../constants/AppConstants'

const cachedForecasted = localStorage[CACHE_SuperForecaster] ? JSON.parse(localStorage[CACHE_SuperForecaster]) : {}

const initialState = {
  recentlyForecastedResidents: cachedForecasted, // keyed by residentId
}

function markResidentResponded(state, residentId) {
  const recentlyForecastedResidents = {...state.recentlyForecastedResidents, [residentId]: Date.now()}
  localStorage[CACHE_SuperForecaster] = JSON.stringify(recentlyForecastedResidents)

  return {...state, recentlyForecastedResidents: recentlyForecastedResidents}
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FEEDBACK_NEW_FEEDBACK:
      return markResidentResponded(state, action.payload.residentId)

    case ActionTypes.FEEDBACK_SKIP_FORECAST:
      return markResidentResponded(state, action.payload)

    default:
      /* pass through */
      return state
  }
}
