import React, {useContext} from 'react'
import useSharedStyles from '../useSharedStyles'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import {rxrBlueColor} from '../../../assets/styles/color'
import SyncedFiles from '../SyncedFiles'
import {spaceMedium} from '../../../assets/styles/spacing'
import RejectOrAcceptWithFileAttacher from '../FormCTAs/RejectOrAcceptWithFileAttacher'
import {getSyncedAndNotSyncedFilesFromSubmission} from '../../../Utils/onboardingUtils'
import {WhitelabelContext} from '../OnboardingWhitelabel'

function SecurityDepositAndRent_Check(props) {
  const sharedClasses = useSharedStyles()
  const classes = useStyles()

  const {syncedAttachments, notSyncedAttachments} = getSyncedAndNotSyncedFilesFromSubmission(props.submission)
  const {securityDepositAndRent} = useContext(WhitelabelContext)

  const handleSyncedAttachments = newSyncedAttachmentsArray => {
    props.onUpdateSubmission({
      ...props.submission,
      attachments: [...notSyncedAttachments, ...newSyncedAttachmentsArray],
    })
  }

  return (
    <React.Fragment>
      <div className={sharedClasses.bodyContainer}>
        <div className={classes.linkOutCopy}>
          <p>
            {securityDepositAndRent.checkOnlyDescription
              ? `The resident was provided with the following instructions: "${securityDepositAndRent.checkOnlyDescription}". Please confirm that the resident's submission is correct.`
              : 'Only physical checks are accepted. Make sure all information is correct.'}
          </p>
        </div>
        <div className={classes.syncedAttachmentsContainer}>
          <SyncedFiles value={syncedAttachments} onChange={handleSyncedAttachments} />
        </div>
      </div>
      <RejectOrAcceptWithFileAttacher submission={props.submission} onUpdateSubmission={props.onUpdateSubmission} />
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  linkOutCopy: {
    display: 'flex',
    alignItems: 'center',
  },
  linkOut: {
    color: rxrBlueColor,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  syncedAttachmentsContainer: {
    marginTop: spaceMedium,
  },
}))

SecurityDepositAndRent_Check.propTypes = {
  onUpdateSubmission: PropTypes.func.isRequired,
  submission: PropTypes.object,
}

export default SecurityDepositAndRent_Check
