import React from 'react'
import {useSelector} from 'react-redux'
import {makeStyles} from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {RXOTextField} from '../../assets/styles'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import Grid from '@material-ui/core/Grid'
import {Colors} from '../../assets/styles'
import {selectResidentsByUnit} from '../../Utils/residentUtils'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  item: {
    ...Colors.black,
    fontSize: '16px',
    lineHeight: '19px',
  },
  '@global': {
    '.MuiAutocomplete-option[data-focus="true"]': {
      backgroundColor: Colors.rxrMonotoneLightGreyColor,
    },
  },
  option: {
    paddingBottom: 0,
  },
  listbox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))

export default function NamePicker(props) {
  const classes = useStyles()

  const residentsLookup = useSelector(state => state.Residents.residentsLookup)
  let residentNames = []
  if (props.selectedPackageUnit) {
    residentNames = selectResidentsByUnit(residentsLookup, props.selectedPackageUnit).map(r => r.displayName)
  }

  return (
    <Autocomplete
      options={residentNames}
      classes={{option: classes.option, listbox: classes.listbox}}
      onChange={(event, newValue) => props.setName(newValue)}
      inputValue={props.name ? props.name : ''}
      onInputChange={(event, newInputValue) => {
        props.setName(newInputValue)
      }}
      freeSolo
      popupIcon={null}
      renderInput={params => (
        <RXOTextField.FormTextField
          {...params}
          label="Name"
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      renderOption={(option, {inputValue}) => {
        const matches = match(option, inputValue)
        const parts = parse(option, matches)
        return (
          <Grid container>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} className={classes.item} style={{fontWeight: part.highlight ? 700 : 400}}>
                  {part.text}
                </span>
              ))}
              <hr style={{marginTop: '12px'}} />
            </Grid>
          </Grid>
        )
      }}
    />
  )
}

NamePicker.propTypes = {
  selectedPackageUnit: PropTypes.string,
  name: PropTypes.string,
  setName: PropTypes.func.isRequired,
}
