import ActionTypes from './types'
import {updateOnboardingTask} from '../lib/queries'
import {ONBOARDING_STATE_COMPLETED} from '../constants/ModelConstants'

/**
 * @param {function} dispatch
 * @param {*} updateOnboardingTaskInput
 * @return {Promise<*>}
 */
export const updateOnboardingTaskAction = async (dispatch, updateOnboardingTaskInput) => {
  let updatedOnboardingTask = await updateOnboardingTask(updateOnboardingTaskInput)

  // If the updateOnboardingTaskInput has a state of COMPLETED
  // then we must update the form.submissions array such that each submission
  // has an attachments array in which all attachments have an isSynced boolean of TRUE
  let form = JSON.parse(updatedOnboardingTask.form)
  if (updatedOnboardingTask.state === ONBOARDING_STATE_COMPLETED) {
    if (form) {
      // entered if form
      let submissions = form.submissions
      if (submissions && Array.isArray(submissions) && submissions.length > 0) {
        // entered if submissions
        for (let i = 0; i < submissions.length; i++) {
          let submission = submissions[i]
          if (submission && submission.attachments && Array.isArray(submission.attachments) && submission.attachments.length > 0) {
            // entered if submission.attachments
            for (let j = 0; j < submission.attachments.length; j++) {
              let attachment = submission.attachments[j]
              if (attachment) {
                // entered if attachment
                attachment.isSynced = true
              }
            }
          }
        }
      }
    }
  }

  updatedOnboardingTask = {
    ...updatedOnboardingTask,
    form,
  }

  dispatch({
    type: ActionTypes.OCCUPANCY_ONBOARDING_TASK_UPDATE,
    payload: updatedOnboardingTask,
  })

  return updatedOnboardingTask
}
