import React, {useContext} from 'react'
import useSharedStyles from '../useSharedStyles'
import PropTypes from 'prop-types'
import {ONBOARDING_STATE_AVAILABLE, ONBOARDING_STATE_IN_PROGRESS} from '../../../constants/ModelConstants'
import {makeStyles} from '@material-ui/core/styles'
import {rxrBlueColor} from '../../../assets/styles/color'
import SyncedFiles from '../SyncedFiles'
import {spaceMedium} from '../../../assets/styles/spacing'
import SelectInput from '../../SelectInput'
import RejectOrAcceptWithFileAttacher from '../FormCTAs/RejectOrAcceptWithFileAttacher'
import {getSyncedAndNotSyncedFilesFromSubmission} from '../../../Utils/onboardingUtils'
import {WhitelabelContext} from '../OnboardingWhitelabel'

const paymentMethodsOptions = [
  {value: 'Smart Rent', label: 'Payment submitted on Smart Rent'},
  {value: 'Physical check', label: 'Physical check submitted'},
]

function SecurityDepositAndRent_Hybrid(props) {
  const sharedClasses = useSharedStyles()
  const classes = useStyles()

  const {syncedAttachments, notSyncedAttachments} = getSyncedAndNotSyncedFilesFromSubmission(props.submission)
  const {securityDepositAndRent} = useContext(WhitelabelContext)

  const handleLinkOut = () => {
    window.open(securityDepositAndRent.paymentLinkOutURL, '_blank')
  }

  const handleSyncedAttachments = newSyncedAttachmentsArray => {
    props.onUpdateSubmission({
      ...props.submission,
      attachments: [...notSyncedAttachments, ...newSyncedAttachmentsArray],
    })
  }

  const handleSelectPaymentMethod = option => {
    props.onUpdateSubmission({
      ...props.submission,
      paymentMethod: option,
      state: props.submission.state === ONBOARDING_STATE_AVAILABLE ? ONBOARDING_STATE_IN_PROGRESS : props.submission.state,
    })
  }

  return (
    <React.Fragment>
      <div className={sharedClasses.bodyContainer}>
        <div className={classes.linkOutCopy}>
          <a className={classes.linkOut} onClick={handleLinkOut}>
            Smart Rent
          </a>
          <p>&nbsp;or physical checks are accepted.</p>
        </div>
        <div className={classes.paymentMethodContainer}>
          <SelectInput
            onChange={handleSelectPaymentMethod}
            label={'Payment method'}
            isRequired={true}
            options={paymentMethodsOptions}
            value={props.submission.paymentMethod}
          />
        </div>
        <div className={classes.syncedAttachmentsContainer}>
          <SyncedFiles value={syncedAttachments} onChange={handleSyncedAttachments} />
        </div>
      </div>
      <RejectOrAcceptWithFileAttacher submission={props.submission} onUpdateSubmission={props.onUpdateSubmission} />
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  linkOutCopy: {
    display: 'flex',
    alignItems: 'center',
  },
  linkOut: {
    color: rxrBlueColor,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  paymentMethodContainer: {
    marginTop: spaceMedium,
  },
  syncedAttachmentsContainer: {
    marginTop: spaceMedium,
  },
}))

SecurityDepositAndRent_Hybrid.propTypes = {
  onUpdateSubmission: PropTypes.func.isRequired,
  submission: PropTypes.object,
}

export default SecurityDepositAndRent_Hybrid
