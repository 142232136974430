import React from 'react'
import {makeStyles} from '@material-ui/core'
import {spaceMedium} from '../../assets/styles/spacing'
import {rxrBlackColor, rxrDarkGreyColor, rxrGreenColor, rxrOrangeColor, rxrRedColor} from '../../assets/styles/color'
import {fontSizeExtraSmall, fontSizeSmall, lightFontWeight} from '../../assets/styles/typography'
import {getChangeStyle} from './utils'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  npsScoreBreakdownTable: {
    marginTop: spaceMedium,
  },
  dot: {
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    display: 'inline-block',
  },
  greenDot: {
    backgroundColor: rxrGreenColor,
  },
  orangeDot: {
    backgroundColor: rxrOrangeColor,
  },
  redDot: {
    backgroundColor: rxrRedColor,
  },
  tableLabel: {
    color: rxrBlackColor,
    fontSize: fontSizeSmall,
    fontWeight: lightFontWeight,
  },
  tableValue: {
    fontSize: fontSizeExtraSmall,
    fontWeight: lightFontWeight,
  },

  tableRow: {
    display: 'flex',
    alignItems: 'center',
  },

  columnOneTableData: {
    width: '15px',
  },

  columnTwoTableData: {
    width: '80px',
  },

  columnThreeTableData: {
    width: '60px',
  },

  columnFourTableData: {
    width: '140px',
  },
}))

function NPSScoreBreakdownTable(props) {
  const classes = useStyles()
  const data = [
    {
      dotClass: classes.greenDot,
      label: 'Promoters',
      percentage: props.npsScorePercentagePromoters,
      change: props.npsScorePercentagePromotersChangeFromLastMonth,
    },
    {
      dotClass: classes.orangeDot,
      label: 'Passives',
      percentage: props.npsScorePercentagePassives,
      change: props.npsScorePercentagePassivesChangeFromLastMonth,
    },
    {
      dotClass: classes.redDot,
      label: 'Detractors',
      percentage: props.npsScorePercentageDetractors,
      change: props.npsScorePercentageDetractorsChangeFromLastMonth,
    },
  ]

  const formatPercentage = value => (isNaN(value) ? 'N/A' : `${(value * 100).toFixed(0)}%`)

  return (
    <table className={classes.npsScoreBreakdownTable}>
      <tbody>
        {data.map((item, index) => {
          const {color, sign} = getChangeStyle(item.change)
          return (
            <tr key={index} className={classes.tableRow}>
              <td className={classes.columnOneTableData}>
                <span className={`${classes.dot} ${item.dotClass}`}></span>
              </td>
              <td className={`${classes.tableLabel} ${classes.columnTwoTableData}`}>{item.label}</td>
              <td className={`${classes.tableLabel} ${classes.columnThreeTableData}`}>{formatPercentage(item.percentage)}</td>
              <td className={`${classes.tableLabel} ${classes.columnFourTableData}`} style={{color: color}}>
                {sign}
                {formatPercentage(item.change)}
                <br />
                <span style={{color: rxrDarkGreyColor}}>from last month</span>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

NPSScoreBreakdownTable.propTypes = {
  npsScorePercentagePromoters: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  npsScorePercentagePromotersChangeFromLastMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  npsScorePercentagePassives: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  npsScorePercentagePassivesChangeFromLastMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  npsScorePercentageDetractors: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  npsScorePercentageDetractorsChangeFromLastMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
}

export default NPSScoreBreakdownTable
