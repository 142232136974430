import React, {useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {useDispatch, useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'
import PageHeader from '../PageHeader'
import RXRButton from '../RXRButton'
import AmenityManagerTable from './AmenityManagerTable'
import Constant from './AmenitiesConstants'
import EmptyStateIconAndMessage from '../EmptyStateIconAndMessage'
import {rxrBlueColor} from '../../assets/styles/color'
import {loadAmenitiesForBuilding} from '../../actions/amenitiesActions'

import Routes from '../../constants/RouteConstants'
import Loader from '../Loader'
import {PageContainer} from '../../assets/styles/layout'
import useBuildingDataRefresh from '../hooks/useBuildingDataRefresh'
import RXRIcon from '../RXRIcon'

const useStyles = makeStyles(theme => ({
  peopleIconContainer: {
    fontSize: 80,
    lineHeight: 0,
    color: rxrBlueColor,
  },
  cancel: {
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: 16,
    color: '#2E9CCA',
    alignItems: 'center',
    textAlign: 'center',
  },
  selectDeliveryCloseIcon: {
    textAlign: 'right',
  },
}))

function AmenityManagerPage(props) {
  const classes = useStyles()
  const amenitiesLookup = useSelector(state => state.Amenities.amenitiesLookup)
  const dispatch = useDispatch()
  const [isLoadAmenities, setIsLoadAmenities] = useState(true)
  const {activeBuildingId} = useBuildingDataRefresh(async () => {
    await loadAmenitiesForBuilding(dispatch, activeBuildingId)
    setIsLoadAmenities(false)
  })

  let visibleTableComponent

  if (Object.keys(amenitiesLookup).length === 0) {
    visibleTableComponent = <EmptyStateIconAndMessage message={Constant.NO_AMENITY_MESSAGE} icon={RXRIcon.AMENITIES} />
  } else {
    visibleTableComponent = <AmenityManagerTable />
  }

  if (isLoadAmenities) {
    return <Loader />
  }

  return (
    <div style={PageContainer}>
      <PageHeader
        title={Constant.PAGE_TITLE_AMENITY_MANAGER}
        rightControlComponent={
          <RXRButton type={RXRButton.TYPE_LARGE} onClick={() => props.history.push(Routes.AMENITIES_CREATE)}>
            {Constant.BTN_ADD_AMENITY_MANAGER}
          </RXRButton>
        }
      />

      <div style={{height: '100%'}}>{visibleTableComponent}</div>
    </div>
  )
}

export default withRouter(AmenityManagerPage)
