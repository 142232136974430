export const OCCUPANCY_STATUS_CURRENT = 'Current'
export const OCCUPANCY_STATUS_NOTICE = 'Notice'
export const OCCUPANCY_STATUS_FUTURE = 'Future'

/**
 * @param {string} status
 * @return {boolean}
 */
export function isStatusActive(status) {
  // This should use the same logic as in /dep/backend-helpers/YardiAPIHelper.js -> isStatusActive
  return [OCCUPANCY_STATUS_CURRENT, OCCUPANCY_STATUS_NOTICE, OCCUPANCY_STATUS_FUTURE].includes(status)
}

/**
 * @param {ResidentFragment} residentObj
 * @return {boolean}
 */
export function isResidentActive(residentObj) {
  if (!residentObj) {
    return false
  }
  return isStatusActive(residentObj.statusOverride || residentObj.occupancy.status)
}

/**
 * @param {Object<id: ResidentFragment>} residentsLookup
 * @returns {Array<ResidentFragment>}
 */
export function getActiveResidents(residentsLookup) {
  return Object.values(residentsLookup).filter(r => isResidentActive(r))
}

/**
 * @param {Object<id: ResidentFragment>} residentsLookup
 * @param {string} unitId
 * @param {boolean?} includePast
 * @returns {Array<ResidentFragment>}
 */
export function selectResidentsByUnit(residentsLookup, unitId, includePast = false) {
  if (includePast) {
    return Object.values(residentsLookup).filter(r => r.occupancy.unit.id === unitId)
  }
  return Object.values(residentsLookup).filter(r => r.occupancy.unit.id === unitId && isResidentActive(r))
}

/**
 * @param {Object<id: ResidentFragment>} residentsLookup
 * @param {string} occupancyId
 * @param {boolean?} includePast
 * @returns {Array<ResidentFragment>}
 */
export function selectResidentsByOccupancyId(residentsLookup, occupancyId, includePast = false) {
  if (includePast) {
    return Object.values(residentsLookup).filter(r => r.occupancy.id === occupancyId)
  }
  return Object.values(residentsLookup).filter(r => r.occupancy.id === occupancyId && isResidentActive(r))
}
