/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "d6c7d50e656e4f57969c98fde2d7f03f",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "d6c7d50e656e4f57969c98fde2d7f03f",
            "region": "us-east-1"
        }
    },
    "Notifications": {
        "Push": {
            "AWSPinpoint": {
                "appId": "d6c7d50e656e4f57969c98fde2d7f03f",
                "region": "us-east-1"
            }
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "rexAmenitiesApi",
            "endpoint": "https://et5em97l1e.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "rexPayLease",
            "endpoint": "https://6esl5urg79.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "rexRedirectApi",
            "endpoint": "https://72vpsyvqf2.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "rexVendorsApi",
            "endpoint": "https://twe225y9wi.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://jpc2hyjcmvffjby3tvjjifhajy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:5c082260-b7b9-4fe2-8712-9c443b24033f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_keV8spGqB",
    "aws_user_pools_web_client_id": "5gg87idifpjbqgghkeg6krfd4v",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_content_delivery_bucket": "rex-staff-backend-20190827085136-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://rex-staff-backend-20190827085136-hostingbucket-prod.s3-website-us-east-1.amazonaws.com",
    "aws_user_files_s3_bucket": "rex-staff-backend36ea2eacf859423c8dbbd874a05e8d82-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
