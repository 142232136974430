import React, {useState, createContext, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {listPartnersByBuilding} from '../../../lib/queries'

export const PartnersContext = createContext({})

function PartnersProvider(props) {
  const [partnerIds, setPartnerIds] = useState([])
  const [partnersLookup, setPartnersLookup] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)

  useEffect(() => {
    if (!activeBuildingId) {
      return
    }

    setIsLoading(true)
    listPartnersByBuilding(activeBuildingId)
      .then(p => {
        const lookup = p.reduce((acc, p) => {
          acc[p.id] = p
          return acc
        }, {})
        setPartnersLookup(lookup)
      })
      .finally(() => setIsLoading(false))
  }, [activeBuildingId])

  // make sure PartnerIds always reflects partners order
  useEffect(() => {
    setPartnerIds(Object.keys(partnersLookup).sort((a, b) => partnersLookup[a].order - partnersLookup[b].order))
  }, [partnersLookup])

  /**
   * @param {PartnerRecord} newObj
   */
  const createOrUpdatePartner = newObj => {
    if (!partnersLookup[newObj.id]) {
      // If this is the first partner, we initialize order position to 0
      // if it's not the first partner, we initialize it to the last partner's order + 1
      newObj.order = partnerIds.length > 0 ? partnersLookup[partnerIds[partnerIds.length - 1]].order + 1 : 0
    }
    setPartnersLookup(p => ({...p, [newObj.id]: newObj}))
  }

  /**
   * @param {string|PartnerRecord} partnerId
   */
  const removePartner = partnerId => {
    partnerId = typeof partnerId === 'string' ? partnerId : partnerId.id
    setPartnersLookup(p => {
      const clone = {...p}
      delete clone[partnerId]
      return clone
    })
  }

  return (
    <PartnersContext.Provider
      value={{
        partnerIds: partnerIds,
        partnersLookup: partnersLookup,
        isLoading: isLoading,

        createOrUpdatePartner: createOrUpdatePartner,
        removePartner: removePartner,
      }}
    >
      {props.children}
    </PartnersContext.Provider>
  )
}

export default PartnersProvider
