import React, {useState} from 'react'
import moment from 'moment'
import SearchResident from '../SearchResident'
import SelectInput from '../SelectInput'
import DateInput from './../DateInput'
import NumberInput from '../NumberInput'
import {Grid, Typography, makeStyles} from '@material-ui/core'
import {H4HeaderWithoutBold} from '../../assets/styles/typography'
import {rxrRedColor} from '../../assets/styles/color'
import {spaceMedium} from '../../assets/styles/spacing'
import MultilineTextInput from '../MultilineTextInput'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {RXRButton} from '../RXRButton'
import Constant from './AmenitiesConstants'
import ToggleButtons from '../ToggleButtons'
import DiscardChangesDialog from '../DiscardChangesDialog'
import RXRIcon from '../RXRIcon'
import ConfirmDeleteDialog from '../ConfirmDeleteDialog'
import TextInput from '../TextInput'
import {reservationNeedsApproval, reservationIsUpcoming, reservationIsPast} from './ViewAmenityReservationsPage'
import useAmenityReservationScheduling from './useAmenityReservationScheduling'

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    marginBottom: 22,
  },
  userTypeToggleContainer: {
    width: 240,
  },
  errorText: {
    marginTop: spaceMedium,
    color: rxrRedColor,
  },
}))

function AmenityReservationEditor(props) {
  const classes = useStyles()
  const [timeRanges, setTimeRanges] = useState([])
  const [endTimeRanges, setEndTimeRanges] = useState([])
  const [showDenyRequestDialog, setShowDenyRequestDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const amenitiesLookup = useSelector(state => state.Amenities.amenitiesLookup)

  const {
    isEditMode,
    form,
    setForm,
    focusedReservation,
    formChanged,
    invalidItems,
    amenityData,
    isLoadingCalendar,
    isSaving,
    saveError,
    isLoadingAmenityDetails,
    saveOrApprove,
    makeReservation,
    handleReservationDate,
    loadMonthData,
    shouldDisableDate,
    deleteRequest,
    resetForm,
    isFormComplete,
    updateRequest,
    reservationDate,
    isPlacingStaffReservation,
    setIsPlacingStaffReservation,
    chatResident,
    denyRequest,
  } = useAmenityReservationScheduling(props, nextTimeRanges => {
    setTimeRanges(nextTimeRanges)

    // this ensures the start and end time are properly seeded when editing an existing reservation
    if (nextTimeRanges.length > 0 && form.startAt) {
      handleStartTimeChange(form.startAt, form.endAt, nextTimeRanges)
    } else {
      setEndTimeRanges([])
    }
  })

  const handleStartTimeChange = (startDate, endDate, timeRangeOverride) => {
    // we always set the end time to null when start time is changed
    setForm({startAt: new Date(startDate), endAt: endDate || null})
    const latestOption = moment(startDate)
      .add(amenityData.maxReservationDuration, 'm')
      .toISOString()
    const earliestOption = moment(startDate)
      .add(amenityData.minReservationDuration, 'm')
      .toISOString()
    let e = []
    e = (timeRangeOverride || timeRanges).filter(t => {
      return t.value >= earliestOption && t.value <= latestOption
    })

    setEndTimeRanges(e)
  }

  const handleEndTimeChange = date => {
    setForm({endAt: new Date(date)})
  }

  const handleChangeGuestCount = guests => {
    // + 1 for the resident
    setForm({partySize: guests + 1})
  }

  const hasNecessaryDataToSelectNumGuests =
    (!isPlacingStaffReservation && form.residentId && amenityData) || (isPlacingStaffReservation && amenityData)
  const filteredAmenities =
    typeof props.amenitiesFilter === 'function'
      ? Object.values(amenitiesLookup).filter(props.amenitiesFilter)
      : Object.values(amenitiesLookup)
  const amenitiesOptions = filteredAmenities.map(a => ({
    value: a.id,
    label: a.label,
  }))
  const isReservationSystemControlled = !!focusedReservation && focusedReservation.isSystemControlled
  const isReservationPast = !!focusedReservation && reservationIsPast(focusedReservation)

  const isStartAtDisabled = !!(!form.partySize || !reservationDate || isReservationSystemControlled || isReservationPast)
  const isEndAtDisabled = !!(isStartAtDisabled || !form.startAt || isReservationSystemControlled || isReservationPast)

  return (
    <React.Fragment>
      <div className={classes.container}>
        {isReservationSystemControlled && (
          <Typography style={{...H4HeaderWithoutBold, color: rxrRedColor, marginBottom: spaceMedium}}>
            This reservation is controlled by the system and cannot be modified
          </Typography>
        )}

        {!isEditMode ? (
          <div className={classes.userTypeToggleContainer}>
            <ToggleButtons
              selected={isPlacingStaffReservation ? 1 : 0}
              buttonLabels={['Resident', 'Staff']}
              onChange={v => setIsPlacingStaffReservation(!!v)}
              inverseColors={true}
            />
          </div>
        ) : null}

        <Grid container spacing={6} style={{marginTop: 0}}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <SelectInput
              onChange={id => setForm({amenityId: id})}
              label={'Amenity'}
              isRequired={true}
              options={amenitiesOptions}
              value={form.amenityId}
              disabled={!!(isEditMode || (props.initialValues && props.initialValues.amenityId))}
              error={invalidItems.includes('amenityId')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {!isPlacingStaffReservation ? (
              <SearchResident
                value={form.residentId}
                isRequired={true}
                disabled={isEditMode}
                onSelectResident={r => setForm({residentId: r ? r.id : null})}
                error={invalidItems.includes('residentId')}
              />
            ) : (
              <TextInput
                isRequired={true}
                error={invalidItems.includes('title')}
                label="Title"
                value={form.title}
                onChange={val => setForm({title: val})}
              />
            )}
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <NumberInput
              label="Number of guests"
              onChange={handleChangeGuestCount}
              helperText={amenityData ? `Max guests per booking is ${amenityData.maxPartySize - 1}` : ''}
              max={amenityData ? amenityData.maxPartySize - 1 : 0}
              min={0}
              value={typeof form.partySize === 'number' ? form.partySize - 1 : null}
              isDisabled={!hasNecessaryDataToSelectNumGuests || isReservationSystemControlled || isReservationPast}
              isLoading={isLoadingAmenityDetails}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <DateInput
              onChange={handleReservationDate}
              onMonthChange={loadMonthData}
              shouldDisableDate={shouldDisableDate}
              min={moment()
                .startOf('day')
                .toDate()}
              label={'Reservation date'}
              isRequired={true}
              value={reservationDate}
              disabled={!hasNecessaryDataToSelectNumGuests || isReservationSystemControlled || isReservationPast}
              error={invalidItems.includes('startAt')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {isReservationPast ? (
              <TextInput isDisabled={true} value={moment(form.startAt).format('hh:mm A')} label={'Start time'} />
            ) : (
              <SelectInput
                onChange={handleStartTimeChange}
                label={'Start time'}
                isRequired={true}
                options={timeRanges.filter(time => time.canStart)}
                value={form.startAt ? form.startAt.toISOString() : ''}
                disabled={isStartAtDisabled}
                error={invalidItems.includes('startAt')}
                isLoading={isLoadingCalendar}
              />
            )}
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {isReservationPast ? (
              <TextInput isDisabled={true} value={moment(form.endAt).format('hh:mm A')} label={'Start time'} />
            ) : (
              <SelectInput
                onChange={handleEndTimeChange}
                label={'End time'}
                isRequired={true}
                options={endTimeRanges}
                value={form.endAt ? form.endAt.toISOString() : ''}
                disabled={isEndAtDisabled}
                helperText={amenityData ? `Max booking duration is ${amenityData.maxReservationDuration} min` : ''}
                error={invalidItems.includes('endAt')}
                isLoading={isLoadingCalendar}
              />
            )}
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <MultilineTextInput
              label="Notes"
              placeholder=""
              rows={4}
              rowsMax={4}
              isRequired={false}
              maxLength={2000}
              onChange={val => setForm({notes: val})}
              isDisabled={isReservationSystemControlled || isReservationPast}
              value={form.notes}
              error={invalidItems.includes('notes')}
            />
          </Grid>
        </Grid>
      </div>
      {saveError ? <div className={classes.errorText}>{`${saveError}`}</div> : null}
      <Grid style={{marginTop: spaceMedium}}>
        {/* Create mode CTA's */}
        {!props.amenityReservationId && (
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <RXRButton onClick={() => makeReservation()} isLoading={isSaving}>
                {Constant.BTN_SAVE}
              </RXRButton>
            </Grid>
            <Grid item className={classes.cursorHand}>
              <RXRButton type={RXRButton.TYPE_TEXT} onClick={resetForm} isLoading={isSaving}>
                {Constant.BTN_CANCEL}
              </RXRButton>
            </Grid>
          </Grid>
        )}
        {/* Edit mode needs approval CTA's */}
        {!!props.amenityReservationId && reservationNeedsApproval(form) && (
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <RXRButton onClick={() => saveOrApprove()} disabled={!isFormComplete(form)} isLoading={isSaving}>
                {formChanged ? Constant.BTN_SAVE_AND_APPROVE_REQUEST : Constant.BTN_APPROVE_REQUEST}
              </RXRButton>
            </Grid>
            <Grid item>
              <RXRButton type={RXRButton.TYPE_DESTRUCTIVE} onClick={() => setShowDenyRequestDialog(true)} isLoading={isSaving}>
                {Constant.BTN_DENY_REQUEST}
              </RXRButton>
            </Grid>
            <Grid item className={classes.cursorHand}>
              <RXRButton type={RXRButton.TYPE_TEXT} onClick={resetForm} isLoading={isSaving}>
                {Constant.BTN_CANCEL}
              </RXRButton>
            </Grid>
          </Grid>
        )}
        {/* Edit mode past CTA's */}
        {!!props.amenityReservationId && reservationIsUpcoming(form) && (
          <Grid container spacing={2} alignItems="center">
            <RXRButton
              onClick={() => updateRequest()}
              disabled={!isFormComplete(form) || form.isSystemControlled || !formChanged}
              isLoading={isSaving}
            >
              {Constant.BTN_UPDATE_REQUEST}
            </RXRButton>
            {!isPlacingStaffReservation ? (
              <Grid item>
                <RXRButton onClick={() => chatResident()}>{Constant.BTN_CHAT_RESIDENT}</RXRButton>
              </Grid>
            ) : null}
            <Grid item>
              <RXRButton
                type={RXRButton.TYPE_DESTRUCTIVE}
                onClick={() => setShowDeleteDialog(true)}
                disabled={form.isSystemControlled}
                isLoading={isSaving}
              >
                {Constant.BTN_DELETE}
              </RXRButton>
            </Grid>
          </Grid>
        )}
        {/* Edit mode past CTA's */}
        {isReservationPast && (
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <RXRButton onClick={() => chatResident()}>{Constant.BTN_CHAT_RESIDENT}</RXRButton>
            </Grid>
          </Grid>
        )}
      </Grid>
      <DiscardChangesDialog
        isOpen={showDenyRequestDialog}
        discardIcon={RXRIcon.NOT_ALLOWED}
        discardTitle={'Deny request'}
        discardMessage={'Are you sure you want to deny this request?'}
        discardButton={'Deny'}
        onCancel={() => {
          setShowDenyRequestDialog(false)
        }}
        onDiscard={() => {
          setShowDenyRequestDialog(false)
          denyRequest().then()
        }}
      />
      <DiscardChangesDialog hasChanges={formChanged} onDiscard={resetForm} />
      <ConfirmDeleteDialog
        isOpen={showDeleteDialog}
        prompt={'Are you sure you want to delete this reservation?'}
        onConfirmDelete={() => {
          setShowDeleteDialog(false)
          deleteRequest().then()
        }}
        onCancel={() => setShowDeleteDialog(false)}
      />
    </React.Fragment>
  )
}

AmenityReservationEditor.propTypes = {
  onComplete: PropTypes.func.isRequired,
  amenityReservationId: PropTypes.string,
  initialValues: PropTypes.object,
  onUpdateForm: PropTypes.func,
  amenitiesFilter: PropTypes.func,
}

export default AmenityReservationEditor
