import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import HighlightText from '../HighlightText'
import Routes from '../../constants/RouteConstants'
import moment from 'moment'
import {Colors} from './../../assets/styles'
import {useSelector} from 'react-redux'
import {CONVERSATION_CLOSED, CONVERSATION_IN_PROGRESS, CONVERSATION_OPEN} from '../../constants/ModelConstants'
import {useHistory} from 'react-router-dom'
import PropTypes from 'prop-types'
import useResidentLookup from '../hooks/useResidentLookup'
import {fontSizeMediumLarge, fontSizeSmall} from '../../assets/styles/typography'
import {constructClassString} from '../../Utils/objectUtil'
import MultiResidentProfileImages from '../ResidentProfiles/MultiResidentProfileImages'
import {getResidentUsersFromChatRoom} from '../../Utils/chatUtils'
import ResidentStatusTag from '../UITags/ResidentStatusTag'
import GenericTag, {VARIANT_COMPACT} from '../UITags/GenericTag'

const useStyles = makeStyles(theme => ({
  cardStyle: {
    //width: '${messageListWidth}px',
    width: '100%', //'375px',
    height: '97px',
    borderBottom: '.5px solid #D3D3D3',
    borderRadius: 0,
    cursor: 'pointer',
    boxShadow: 'none',
    backgroundColor: Colors.rxrWhiteColor,
    transition: 'background-color 0.2s',
  },
  backgroundHighlighted: {
    backgroundColor: Colors.rxrTeal15Color,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  contentText: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '20px',
  },
  contentBody: {
    display: 'flex',
    textOverflow: 'ellipsis',
    marginLeft: '55px',
    overflow: 'hidden',
  },
  title: {
    fontSize: 16,
    fontStyle: 'normal',
    ...Colors.black,
    lineHeight: '19px',
  },
  subText: {
    fontSize: fontSizeSmall,
    ...Colors.darkGrey,
    lineHeight: '14px',
  },
  msgText: {
    fontSize: fontSizeMediumLarge,
    fontStyle: 'normal',
    ...Colors.darkGrey,
    lineHeight: '19px',
  },
  statusText: {
    fontSize: fontSizeSmall,
    textTransform: 'capitalize',
    lineHeight: '14px',
    [`&.${CONVERSATION_OPEN}`]: {color: Colors.rxrGreenColor},
    [`&.${CONVERSATION_IN_PROGRESS}`]: {color: Colors.rxrYellowColor},
    [`&.${CONVERSATION_CLOSED}`]: {color: Colors.rxrRedColor},
  },
  avatarContainer: {
    marginBottom: '10px',
    height: '34px',
    width: '34px',
    flexShrink: 0,
  },
  statusStyle: {
    marginLeft: 'auto',
    textAlign: 'right',
  },
}))

function ChatListItem(props) {
  /** @type {GenericChatRoom} conversation */
  const conversation = useSelector(state => state.Messages.conversationsLookup[props.conversationId])
  const activeBuilding = useSelector(state => state.Buildings.buildingsLookup[state.Buildings.activeBuildingId])
  const classes = useStyles()
  const history = useHistory()
  const {getResident} = useResidentLookup()

  const searchTerm = useSelector(state => state.Messages.filterString)
  const residentParticipants = getResidentUsersFromChatRoom(conversation)

  // only render if we have a resident, a conversation, and a last message
  if (residentParticipants.length === 0 || !conversation) {
    return null
  }

  const residents = residentParticipants.map(r => getResident(r.userId))

  const receivedString = conversation.lastMessage
    ? ` | Received: ${moment(conversation.lastMessage.createdAt).format('MM/DD/YYYY, h:mma')}`
    : ''
  const messageText = props.messageTextOverride
    ? props.messageTextOverride
    : conversation.lastMessage
    ? conversation.lastMessage.hasAttachments
      ? 'IMAGE'
      : conversation.lastMessage.content
    : ''

  // NOTE: this is copied from ChatContainerHeader
  // if this chat is not an onboarding chat, but it is for a future state resident and we have an onboarding schema, show the future tag
  // the logic here is we don't want to only show Onboarding tag to ensure this chat appears different than the onboarding group chat room
  const showFutureTag =
    !conversation.isOnboardingChat && residents.some(r => r.occupancy.status === 'Future') && !!activeBuilding.onboardingSchema

  return (
    <Card
      className={constructClassString({[classes.backgroundHighlighted]: props.isHighlighted}, classes.cardStyle)}
      onClick={() => {
        history.push(
          props.messageId
            ? Routes.constructPath(Routes.MESSAGES_VIEW_SINGLE_JUMP_TO_MESSAGE, {
                conversationId: props.conversationId,
                messageId: props.messageId,
              })
            : Routes.constructPath(Routes.MESSAGES_VIEW_SINGLE, {
                conversationId: props.conversationId,
              }),
        )
      }}
    >
      <CardContent>
        <div className={classes.content}>
          <MultiResidentProfileImages residentIds={residents.map(r => r.id)} className={classes.avatarContainer} />
          <div className={classes.contentText}>
            <Typography className={classes.title} style={conversation.hasUnread ? {fontWeight: 'bold'} : null}>
              <HighlightText search={searchTerm}>{residents.map(r => r.displayName).join(', ')}</HighlightText>
            </Typography>
            <Typography className={classes.subText}>
              <HighlightText search={searchTerm}>{residents[0].occupancy.unit.number}</HighlightText>
              {receivedString}
            </Typography>
          </div>
          <div className={classes.statusStyle}>
            {showFutureTag ? (
              <GenericTag text="Future" background={Colors.purple} variant={VARIANT_COMPACT} />
            ) : (
              <ResidentStatusTag variant={VARIANT_COMPACT} residentId={residents[0].id} />
            )}
            <Typography className={constructClassString(null, classes.statusText, conversation.status)}>
              {(conversation.status || '').toLowerCase().replace('_', ' ')}
            </Typography>
          </div>
        </div>
        <div className={classes.contentBody}>
          <Typography noWrap className={classes.msgText}>
            <HighlightText search={searchTerm}>{messageText}</HighlightText>
          </Typography>
        </div>
      </CardContent>
    </Card>
  )
}

ChatListItem.propTypes = {
  conversationId: PropTypes.string.isRequired,
  messageId: PropTypes.string,
  isHighlighted: PropTypes.bool,
  messageTextOverride: PropTypes.string,
}

export default ChatListItem
