import gql from 'graphql-tag'
import {StaffNoteFragment} from '../../../lib/modelFragments'

export const ListStaffNotesForBuilding = gql`
  ${StaffNoteFragment}
  query ListStaffNotesByBuilding(
    $buildingId: ID!
    $createdAt: ModelStringKeyConditionInput
    $nextToken: String
    $limit: Int
    $filter: ModelStaffNoteFilterInput
  ) {
    listStaffNotesByBuilding(
      buildingId: $buildingId
      createdAt: $createdAt
      nextToken: $nextToken
      limit: $limit
      sortDirection: DESC
      filter: $filter
    ) {
      items {
        ...StaffNoteFragment
      }
      nextToken
    }
  }
`
