// --------------------------------------------------------------------------
// COMMAND CENTER

/**
 * @typedef CommandCenterMetricDataPayload
 * @property {number} sentiment
 * @property {number} sentimentDelta
 * @property {number} sentimentRevenue
 * @property {number} engagement
 * @property {number} engagementDelta
 * @property {number} engagementRevenue
 * @property {number} guests
 * @property {number} guestsDelta
 * @property {number} guestsRevenue
 * @property {number} incomingDeliveries
 * @property {number} incomingDeliveriesDelta
 * @property {number} incomingDeliveriesRevenue
 * @property {number} outgoingDeliveries
 * @property {number} outgoingDeliveriesDelta
 * @property {number} outgoingDeliveriesRevenue
 * @property {number} cleanings
 * @property {number} cleaningsDelta
 * @property {number} cleaningsRevenue
 * @property {number} amenities
 * @property {number} amenitiesDelta
 * @property {number} amenitiesRevenue
 * @property {number} maintenance
 * @property {number} maintenanceDelta
 * @property {number} maintenanceRevenue
 * @property {number} dogWalking
 * @property {number} dogWalkingDelta
 * @property {number} dogWalkingRevenue
 * @property {number} massage
 * @property {number} massageDelta
 * @property {number} massageRevenue
 * @property {number} personalTraining
 * @property {number} personalTrainingDelta
 * @property {number} personalTrainingRevenue
 * @property {number} events
 * @property {number} eventsDelta
 * @property {number} eventsRevenue
 */

/**
 * @typedef {{data: Array<{score: number, change: number, totalAmount: number}>}} CommandCenterMetricResponse
 */

/**
 * @param {CommandCenterMetricResponse} response
 * @param {string} prop
 * @returns {*}
 */
function getScoreAndDeltaFromResponse(response, prop) {
  const valueProp = prop
  const deltaProp = `${prop}Delta`
  const revenueProp = `${prop}Revenue`

  if (!response || !response.data || !response.data[0]) {
    this[valueProp] = this[deltaProp] = undefined
  } else {
    this[valueProp] = response.data[0].score
    this[deltaProp] = response.data[0].change
    this[revenueProp] = response.data[0].totalAmount
  }

  return this
}

/**
 * --- 14 total metrics
 * @param {Object<string, CommandCenterMetricResponse>} res
 * @returns {CommandCenterMetricDataPayload}
 */
export function constructCommandCenterMetricDataPayloadFromResponses(res) {
  // the props we want from the response payload
  const propNames = [
    'sentiment',
    // 'engagement',
    'guests',
    'incomingDeliveries',
    'outgoingDeliveries',
    'cleanings',
    'amenities',
    'maintenance',
    'dogWalking',
    'massage',
    'personalTraining',
    'events',
  ]

  return propNames.reduce((acc, prop) => {
    getScoreAndDeltaFromResponse.call(acc, res[prop], prop)
    return acc
  }, {})
}

// --------------------------------------------------------------------------
// RESIDENT SENTIMENT
/**
 * @typedef FeedbackDetailsPayload
 * @property {number} total
 * @property {number} average
 * @property {Array<{label: string, value: number, createdAt: Date, comments: string, denominator: number}>} items
 */

/**
 * @param {*} response
 * @returns {FeedbackDetailsPayload}
 */
export function constructFeedbackDetailsPayloadFromResponse(response) {
  return {
    total: response.total,
    average: response.average,
    items: response.items,
  }
}

/**
 * @typedef TimeSeriesData
 * @property {Array<Array<number>>} data
 * @property {Array<string>} columns
 */

/**
 * TODO: what's the final payload look like?
 * @typedef ResidentFeedbackSummaryAndTimeSeriesPayload
 * @property {TimeSeriesData} nps
 * @property {TimeSeriesData} amenities
 * @property {TimeSeriesData} sentiment
 * @property {TimeSeriesData} services
 * @property {TimeSeriesData} maintenance
 * @property {{
 *   npsResponses: number,
 *   npsScore: number,
 *   npsScoreChange: number,
 *   npsScorePercentagePromoters: number,
 *   npsScorePercentagePromotersChangeFromLastMonth: number,
 *   npsScorePercentagePassives: number,
 *   npsScorePercentagePassivesChangeFromLastMonth: number,
 *   npsScorePercentageDetractors: number,
 *   npsScorePercentageDetractorsChangeFromLastMonth: number,
 *   messageSentiment: number,
 *   messageSentimentChangeFromLastMonth: number,
 *   serviceRating: number,
 *   serviceRatingChangeFromLastMonth: number,
 *   amenitiesRating: number,
 *   amenitiesRatingChangeFromLastMonth: number,
 *   maintenanceRating: number,
 *   maintenanceRatingChangeFromLastMonth: number,
 * }} summary
 */

/**
 * @param {*} response
 * @returns {ResidentFeedbackSummaryAndTimeSeriesPayload}
 */
export function constructResidentFeedbackSummaryAndTimeSeriesPayload(response) {
  return {
    sentiment: convertTimeSeriesApiPayloadToFrontEndLineGraphStructure(response?.time_series?.sentiment, 'sentiment_thirty_days'),
    nps: convertTimeSeriesApiPayloadToFrontEndLineGraphStructure(response?.time_series?.nps_score, 'nps_score_average_thirty_days'),
    amenities: convertTimeSeriesApiPayloadToFrontEndLineGraphStructure(
      response?.time_series?.amenity_rating,
      'amenity_rating_average_thirty_days',
    ),
    services: convertTimeSeriesApiPayloadToFrontEndLineGraphStructure(
      response?.time_series?.service_rating,
      'service_rating_average_thirty_days',
    ),
    maintenance: convertTimeSeriesApiPayloadToFrontEndLineGraphStructure(
      response?.time_series?.maintenance_rating,
      'maintenance_rating_average_thirty_days',
    ),
    summary: {
      npsResponses: response?.static?.data?.[0].nps_score_responses_thirty_days,
      npsScore: response?.static?.data?.[0].nps_score_average_thirty_days,
      npsScoreChange: response?.static?.data?.[0].nps_score_average_thirty_days_difference_one_month,
      npsScorePercentagePromoters: response?.static?.data?.[0].nps_percentage_promoters_thirty_days,
      npsScorePercentagePromotersChangeFromLastMonth: response?.static?.data?.[0].nps_percentage_promoters_thirty_days_difference_one_month,
      npsScorePercentagePassives: response?.static?.data?.[0].nps_percentage_passives_thirty_days,
      npsScorePercentagePassivesChangeFromLastMonth: response?.static?.data?.[0].nps_percentage_passives_thirty_days_difference_one_month,
      npsScorePercentageDetractors: response?.static?.data?.[0].nps_percentage_detractors_thirty_days,
      npsScorePercentageDetractorsChangeFromLastMonth:
        response?.static?.data?.[0].nps_percentage_detractors_thirty_days_difference_one_month,
      messageSentiment: response?.static?.data?.[0].sentiment_thirty_days,
      messageSentimentChangeFromLastMonth: response?.static?.data?.[0].sentiment_thirty_days_difference_last_month,
      serviceRating: response?.static?.data?.[0].service_rating_average_thirty_days,
      serviceRatingChangeFromLastMonth: response?.static?.data?.[0].service_rating_average_thirty_days_difference_one_month,
      amenitiesRating: response?.static?.data?.[0].amenities_rating_average_thirty_days,
      amenitiesRatingChangeFromLastMonth: response?.static?.data?.[0].amenities_rating_average_thirty_days_difference_one_month,
      maintenanceRating: response?.static?.data?.[0].maintenance_rating_average_thirty_days,
      maintenanceRatingChangeFromLastMonth: response?.static?.data?.[0].maintenance_rating_average_thirty_days_difference_one_month,
    },
  }
}

function convertTimeSeriesApiPayloadToFrontEndLineGraphStructure(payload, metricOfInterest) {
  if (!payload) {
    return {data: [], columns: []}
  }

  const data = []
  payload.data.forEach(line => {
    const newDataEnty = []
    line.graph.forEach(dataPoint => {
      newDataEnty.push(dataPoint[metricOfInterest])
    })
    data.push(newDataEnty)
  })

  const columns = payload.data[0].graph.map(dataPoint => dataPoint.dt)

  return {data, columns}
}
