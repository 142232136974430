import React, {useEffect, useState, Fragment} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core'
import {KYRLoaderScreen} from '../../assets/styles/layout'
import moment from 'moment'
import KYRDataField from './KYRDataField'
import {getGuestInfo} from '../../lib/queries'
import Loader from '../Loader'
import {PERMANENT_GUEST_EXPIRY_DATE_ISO} from '../../constants/AppConstants'
import TimelineEvent from './TimelineEvent'

const useStyles = makeStyles(theme => ({
  loader: {
    ...KYRLoaderScreen,
  },
}))

function GuestRegistration(props) {
  const classes = useStyles()
  const [guestInfo, setGuestInfo] = useState({})
  const [isGuestInfoLoading, setIsGuestInfoLoading] = useState(true)

  useEffect(() => {
    getGuestInfo(props.guestId)
      .then(res => {
        setGuestInfo(res)
        setIsGuestInfoLoading(false)
      })
      .catch(err => console.error(err))
  }, [])

  const registrationExpiry = guestInfo.registrationExpiry ? `${moment(guestInfo.registrationExpiry).format('LL')}` : ''
  const typeOfGuest = guestInfo.registrationExpiry !== PERMANENT_GUEST_EXPIRY_DATE_ISO ? 'One-time guest' : 'Permanent guest'
  const status = !guestInfo.isDeleted ? 'Registered' : 'Deleted'

  const MEETING_OPTION_MAP = {
    ['CALL_RESIDENT']: 'Call resident',
    ['SEND_TO_UNIT']: 'Send to unit',
    ['GIVE_UNIT_ACCESS']: 'Give access to unit',
  }

  return (
    <Fragment>
      {isGuestInfoLoading ? (
        <Loader className={classes.loader} />
      ) : (
        <div>
          <KYRDataField title="Status" data={status} />
          <KYRDataField title="Type of guest" data={typeOfGuest} />
          <KYRDataField title="Guest name" data={`${guestInfo.firstName} ${guestInfo.lastName}`} />
          <KYRDataField title="Expiration date" data={registrationExpiry} />
          <KYRDataField title="Meeting option" data={MEETING_OPTION_MAP[guestInfo.meetingOption]} />
          <KYRDataField title="Special instructions" data={guestInfo.instructions} />
          <TimelineEvent primaryRecordId={props.guestId} />
        </div>
      )}
    </Fragment>
  )
}

GuestRegistration.propTypes = {
  guestId: PropTypes.string.isRequired,
}

export default GuestRegistration
