import {useEffect, useState} from 'react'
import useFormChanged, {
  ConstructGreaterThanOtherDateValidator,
  DateObjectValidator,
  MuxValidator,
  NonEmptyStringValidator,
  NonEmptyValidator,
  NumberValidator,
} from '../hooks/useFormChanged'
import {useCalendarMonthAvailablity} from '../../Utils/useCalendarMonthAvailablity'
import {getAmenityCalendar, getAmenityDetails} from '../../lib/queries'
import moment from 'moment/moment'
import {useDispatch, useSelector} from 'react-redux'
import {convertAvailabilityToTimeRangeOptions} from './amenityCalendarHelpers'
import {reservationIsPast} from './ViewAmenityReservationsPage'
import {
  approveAmenityReservation,
  cancelAmenityReservation,
  createAmenityReservation,
  declineAmenityReservation,
  updateAmenityReservation,
} from '../../actions/amenitiesActions'
import Constant from './AmenitiesConstants'
import Routes from '../../constants/RouteConstants'
import useResidentLookup from '../hooks/useResidentLookup'
import {useHistory} from 'react-router-dom'

const EMPTY_FORM_STATE = {
  id: null,
  amenityId: null,
  residentId: null,
  staffId: null,
  title: null,
  startAt: null,
  endAt: null,
  partySize: 1,
  notes: null,
}

function useAmenityReservationScheduling(props, handleNewTimeRanges) {
  const [amenityData, setAmenityData] = useState(null)
  const [isLoadingAmenityDetails, setIsLoadingAmenityDetails] = useState(false)
  const [isLoadingCalendar, setIsLoadingCalendar] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [saveError, setSaveError] = useState('')
  const [isPlacingStaffReservation, setIsPlacingStaffReservation] = useState(false)
  const [reservationDate, setReservationDate] = useState(null)
  const history = useHistory()

  const {form, setForm, formChanged, resetInitialForm, invalidItems, validateForm} = useFormChanged(
    EMPTY_FORM_STATE,
    useFormChanged.PropLevelValidation({
      amenityId: NonEmptyValidator,
      endAt: ConstructGreaterThanOtherDateValidator('startAt'),
      startAt: DateObjectValidator,
      partySize: MuxValidator([NumberValidator, v => v > 0]),
      residentId: (rId, form) => !!rId || !!form.title, // either title or residentId MUST be set
      title: (v, form) => NonEmptyStringValidator(v) || !!form.residentId, // either title or residentId MUST be set
    }),
  )
  const {handleMonthChange, shouldDisableDate, loadAvailabilityForSelectedDate} = useCalendarMonthAvailablity(startDate =>
    getAmenityCalendar(
      form.amenityId,
      form.residentId,
      form.partySize,
      moment(startDate)
        .startOf('month')
        .toISOString(),
      moment(startDate)
        .endOf('month')
        .toISOString(),
      props.amenityReservationId,
    ),
  )

  const {getResident} = useResidentLookup()

  const dispatch = useDispatch()
  const amenitiesLookup = useSelector(state => state.Amenities.amenitiesLookup)
  const amenityReservationsLookup = useSelector(state => state.Amenities.amenityReservationsLookup)
  const authedStaffModel = useSelector(state => state.Staff.authedStaffModel)

  const isEditMode = !!props.amenityReservationId
  const focusedReservation = isEditMode ? amenityReservationsLookup[props.amenityReservationId] : null

  useEffect(() => {
    if (!props.initialValues) {
      return
    }

    setForm(props.initialValues)
    if (props.initialValues.startAt) {
      setReservationDate(
        moment(props.initialValues.startAt)
          .startOf('day')
          .toDate(),
      )
    }
  }, [props.initialValues])

  // If we are passed in a props.amenityReservationId, then we need to reset the initialForm state to the
  // values of the reservation data we are editing
  useEffect(() => {
    if (props.amenityReservationId && focusedReservation) {
      resetInitialForm({
        id: focusedReservation.id,
        amenityId: focusedReservation.amenityId,
        residentId: focusedReservation.residentId,
        staffId: focusedReservation.staffId,
        title: focusedReservation.title,
        startAt: new Date(focusedReservation.startAt),
        endAt: new Date(focusedReservation.endAt),
        partySize: focusedReservation.partySize,
        reservationState: focusedReservation.reservationState, // this is a hidden field added so we can check it when rendering CTAs
        notes: focusedReservation.notes,
        isSystemControlled: focusedReservation.isSystemControlled,
      })
      setReservationDate(
        moment(props.initialValues && props.initialValues.startAt ? props.initialValues.startAt : focusedReservation.startAt)
          .startOf('day')
          .toDate(),
      )

      // we don't do this inside the resetInitial because we want it to be appear as a change (formChanged === true)
      if (props.initialValues) {
        setForm(props.initialValues)
      }

      if (focusedReservation.staffId) {
        setIsPlacingStaffReservation(true)
      }
    }
  }, [props.amenityReservationId, focusedReservation])

  useEffect(() => {
    // NOTE: we don't care of residentId is valid because they could be booking for Staff
    if (/*form.residentId &&*/ form.partySize && reservationDate && amenityData) {
      loadMonthData(reservationDate, true)
    }
  }, [form.residentId, form.partySize, reservationDate, amenityData, form.id])

  useEffect(() => {
    const id = props.amenityId || form.amenityId
    // if there isn't one to load, or it's already loading, or it's the same as the one we already loaded...
    if (!id || isLoadingAmenityDetails || (amenityData && amenityData.id === id)) {
      return
    }

    if (id !== form.amenityId) {
      setForm({amenityId: props.amenityId})
    }

    setIsLoadingAmenityDetails(true)
    getAmenityDetails(id).then(amenityDetail => {
      setIsLoadingAmenityDetails(false)
      setAmenityData(amenityDetail)
    })
  }, [form.amenityId, props.amenityId])

  useEffect(() => {
    if (typeof props.onUpdateForm === 'function') {
      props.onUpdateForm(form)
    }
  }, [form])

  const loadAvailability = async () => {
    const amenityCalendarDetail = loadAvailabilityForSelectedDate(reservationDate)
    let nextTimeRanges = []

    if (amenityData && amenityCalendarDetail.availability?.length > 0) {
      nextTimeRanges = convertAvailabilityToTimeRangeOptions(
        amenityCalendarDetail.availability,
        amenityCalendarDetail.generalAvailability,
        amenityCalendarDetail.reservationIncrement,
        amenityData.minReservationDuration,
      )

      // This is a little hacky, but basically if the resident has a restricted max duration due to a weekly limit
      // we want to surface that new max amount so I'm just overwriting the original object with the updated value.
      // If they change dates to one outside the week with the restriction, it should similarly revert the value.
      if (amenityCalendarDetail.maxReservationDuration !== amenityData.maxReservationDuration) {
        setAmenityData({...amenityData, maxReservationDuration: amenityCalendarDetail.maxReservationDuration})
      }
    } else {
      // nextTimeRanges should stay as an empty []
    }

    handleNewTimeRanges(nextTimeRanges, amenityCalendarDetail)
  }

  const loadMonthData = (d, force) => {
    if (!!focusedReservation && reservationIsPast(focusedReservation)) {
      return
    }

    setIsLoadingCalendar(true)
    handleMonthChange(d, force)
      .then(loadAvailability)
      .finally(() => setIsLoadingCalendar(false))
  }

  const handleReservationDate = date => {
    if (!date || !moment(date).isValid()) {
      return
    }
    setReservationDate(
      moment(date)
        .startOf('day')
        .toDate(),
    )

    setForm({startAt: null, endAt: null})
  }

  const makeReservation = async (isOnboardingOnly = null) => {
    if (!validateForm()) {
      return
    }

    setIsSaving(true)

    const input = {
      amenityId: form.amenityId,
      // if we're placing a staff reservation, we nullify the resident id
      residentId: isPlacingStaffReservation ? null : form.residentId,
      startAt: form.startAt,
      endAt: form.endAt,
      partySize: form.partySize,
      notes: form.notes,
      // if we're placing a staff reservation, we set staffId it our id
      staffId: isPlacingStaffReservation ? authedStaffModel.id : null,
      title: form.title,
      isOnboardingOnly: isOnboardingOnly,
    }

    try {
      await createAmenityReservation(dispatch, input)

      resetInitialForm(EMPTY_FORM_STATE, resetForm)
    } catch (err) {
      console.error(err)
      setSaveError(err)
    }

    setIsSaving(false)
  }

  const saveOrApprove = () => {
    if (form.isSystemControlled) {
      return window.alert('Cannot modify a system controlled reservation')
    }
    setIsSaving(true)

    // if formchanged then updateReservation then approve.
    if (formChanged) {
      updateReservation().then(res => {
        if (res) {
          resetInitialForm(form, approveRequest)
        }
      })
    } else {
      return approveRequest()
    }
    setIsSaving(false)
  }

  const denyRequest = async () => {
    setIsSaving(true)

    if (form.isSystemControlled) {
      return window.alert('Cannot modify a system controlled reservation')
    }

    if (props.amenityReservationId) {
      try {
        const declineDetail = await declineAmenityReservation(dispatch, props.amenityReservationId)

        // make sure the response is good
        if (!declineDetail || declineDetail.reservationState === form.reservationState) {
          throw new Error('The window to deny has past')
        }
        resetForm()
      } catch (err) {
        console.error(err)
        setSaveError(err.message)
      }
    }
    setIsSaving(false)
  }

  const approveRequest = async () => {
    setIsSaving(true)
    if (form.isSystemControlled) {
      return window.alert('Cannot modify a system controlled reservation')
    }

    if (props.amenityReservationId) {
      try {
        const approvedDetail = await approveAmenityReservation(dispatch, props.amenityReservationId)

        // make sure the response is good
        if (!approvedDetail || approvedDetail.reservationState === form.reservationState) {
          throw new Error('The window to approve has past')
        }
        resetForm()
      } catch (err) {
        console.error(err)
        setSaveError(err.message)
      }
    }
    setIsSaving(false)
  }

  const updateReservation = async () => {
    const input = {
      id: props.amenityReservationId,
      startAt: form.startAt,
      endAt: form.endAt,
      partySize: form.partySize,
      notes: form.notes,
      title: form.title,
    }

    try {
      return await updateAmenityReservation(dispatch, input)
    } catch (err) {
      console.error(err)
      setSaveError(err.message)
    }
  }

  const updateRequest = async () => {
    if (form.isSystemControlled) {
      return window.alert('Cannot modify a system controlled reservation')
    }
    setIsSaving(true)

    // if formchanged then updateReservation.
    if (formChanged) {
      const res = await updateReservation()

      if (res) {
        resetInitialForm(form)
      }
    }
    setIsSaving(false)
  }

  const deleteRequest = async () => {
    setIsSaving(true)

    if (form.isSystemControlled) {
      return window.alert('Cannot modify a system controlled reservation')
    }

    if (props.amenityReservationId) {
      try {
        const deleteDetail = await cancelAmenityReservation(dispatch, props.amenityReservationId)

        // make sure the response is good
        if (!deleteDetail || deleteDetail.reservationState === form.reservationState) {
          throw new Error('The window to cancel has past')
        }

        resetForm()
      } catch (err) {
        console.error(err)
        setSaveError(err.message)
      }
    }
    setIsSaving(false)
  }

  function isFormComplete(form) {
    return (
      form.amenityId &&
      form.startAt &&
      form.endAt &&
      form.partySize &&
      form.partySize >= 0 &&
      amenityData &&
      form.partySize <= amenityData.maxPartySize
    )
  }

  const resetForm = () => {
    props.onComplete(formChanged)
    if (!formChanged) {
      setAmenityData(null)
    }
  }

  const chatResident = async () => {
    const residentInfo = getResident(form.residentId)
    if (form.amenityId && Object.keys(amenitiesLookup).length > 0 && residentInfo.conversation) {
      const currentReservationAmenity = amenitiesLookup[form.amenityId]
      if (currentReservationAmenity && form.partySize && form.startAt && authedStaffModel && authedStaffModel.displayName) {
        const message = `Hi ${residentInfo.displayName.split(' ')[0]}, this is ${
          authedStaffModel.displayName
        }. I have a question about your reservation request for the ${currentReservationAmenity.label} with ${form.partySize -
          1} guests for ${moment(form.startAt).format(Constant.RESERVATION_DATE_FORMAT)}`
        const params = {conversationId: residentInfo.conversation.id}
        const state = {prefillMessage: message}
        history.push(Routes.constructPath(Routes.MESSAGES_VIEW_SINGLE, params), state)
      } else {
        history.push(Routes.MESSAGES)
      }
    } else {
      history.push(Routes.MESSAGES)
    }
  }

  return {
    reservationDate,
    form,
    setForm,
    formChanged,
    resetInitialForm,
    invalidItems,
    validateForm,
    amenityData,
    focusedReservation,
    saveError,
    isEditMode,

    isLoadingAmenityDetails,
    isLoadingCalendar,
    isSaving,
    isPlacingStaffReservation,
    setIsPlacingStaffReservation,
    chatResident,
    loadMonthData,
    handleReservationDate,
    shouldDisableDate,
    makeReservation,
    saveOrApprove,
    updateRequest,
    deleteRequest,
    denyRequest,
    isFormComplete,
    resetForm,
  }
}

export default useAmenityReservationScheduling
