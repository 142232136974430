import React, {useState} from 'react'
import {Grid, makeStyles} from '@material-ui/core'
import RXRIcon from '../RXRIcon'
import {BUILDING_NUDGE_TYPE_LABELS, buildingNudgeComparator, NUDGE_TYPE_TO_ICON} from '../RXOAssistant/AssistantConstants'
import {rxrBlackColor, rxrBlueColor, rxrTealColor} from '../../assets/styles/color'
import PropTypes from 'prop-types'
import {spaceExtraLarge, spaceExtraSmall, spaceLarge, spaceMedium} from '../../assets/styles/spacing'
import {useSelector} from 'react-redux'
import {pluralizeWord} from '../../Utils/StringFormatter'
import InsightCardComponent from '../RXOAssistant/InsightCardComponent'
import RXRButton from '../RXRButton'
import EmptyStateIconAndMessage from '../EmptyStateIconAndMessage'
import Routes from '../../constants/RouteConstants'
import SidePanel from '../SidePanel'
import CreateNewBuildingActionForm from '../RXOAssistant/CreateNewBuildingActionForm'
import {matchPath, useLocation, useHistory} from 'react-router-dom'
import {lightFontWeight, fontSizeMedium, fontSizeMediumLarge} from '../../assets/styles/typography'
import {Typography} from '@material-ui/core'

function NudgesSummary(props) {
  const classes = useStyles()
  const [isExpanded, setIsExpanded] = useState(false)

  const nudgesLookup = useSelector(state => state.Nudges.nudgesLookup)

  const nudgesByType = Object.values(nudgesLookup).reduce((agr, n) => {
    if (!agr[n.type]) {
      agr[n.type] = []
    }
    agr[n.type].push(n)
    return agr
  }, {})

  const nudgesAreEmpty = Object.keys(nudgesByType).length === 0

  if (!nudgesAreEmpty) {
    // sort our nudge arrays
    Object.values(nudgesByType).forEach(nudgesArr => nudgesArr.sort(buildingNudgeComparator))
  }

  const history = useHistory()
  const currentLocation = useLocation()

  let logNewActionMatch = matchPath(currentLocation.pathname, {path: Routes.COMMAND_CENTER_LOG_ACTION_FROM_NUDGE})

  let buildingNudgeId
  if (logNewActionMatch && logNewActionMatch.isExact) {
    buildingNudgeId = logNewActionMatch.params.buildingNudgeId
  }

  const closeSidePanel = () => history.push(Routes.COMMAND_CENTER)

  return (
    <React.Fragment>
      <div className={`${classes.container} ${props.className}`}>
        <Typography className={classes.title}>RXO Assistant</Typography>
        {!nudgesAreEmpty && (
          <RXRButton type={RXRButton.TYPE_TEXT} onClick={() => setIsExpanded(!isExpanded)} className={classes.expandToggle}>
            {isExpanded ? 'collapse' : 'expand'}
          </RXRButton>
        )}
        <Grid container>
          {nudgesAreEmpty && (
            <EmptyStateIconAndMessage message={'No building nudges to show'} icon={RXRIcon.NUDGE} iconHeight={50} iconWidth={50} />
          )}
          {Object.entries(nudgesByType).map(([type, nudgesArr]) => {
            return isExpanded ? (
              <Grid key={type} item xs={12} className={classes.typeExpandedContainer}>
                <div className={classes.expandedTypeTitle}>
                  <RXRIcon icon={NUDGE_TYPE_TO_ICON[type]} color={rxrBlueColor} size={RXRIcon.SIZE_EXTRA_LARGE} />
                  <span>{pluralizeWord(BUILDING_NUDGE_TYPE_LABELS[type], nudgesArr.length, true)}</span>
                </div>
                {nudgesArr.map(nudge => (
                  <InsightCardComponent
                    key={nudge.id}
                    insightData={nudge}
                    createRoute={Routes.constructPath(Routes.COMMAND_CENTER_LOG_ACTION_FROM_NUDGE, {buildingNudgeId: nudge.id})}
                  />
                ))}
              </Grid>
            ) : (
              <Grid key={type} item xs={12} md={6} lg={3} className={classes.typeSummaryContainer}>
                <RXRIcon icon={NUDGE_TYPE_TO_ICON[type]} color={rxrBlueColor} size={RXRIcon.SIZE_EXTRA_LARGE} />
                <div className={classes.nudgesCount}>{nudgesArr.length}</div>
                <div className={classes.nudgeSummaryTypeLabel}>{pluralizeWord(BUILDING_NUDGE_TYPE_LABELS[type], nudgesArr.length)}</div>
              </Grid>
            )
          })}
        </Grid>
      </div>

      <SidePanel isOpen={!!buildingNudgeId} onClose={closeSidePanel} title={'Log an action'}>
        <CreateNewBuildingActionForm buildingNudgeId={buildingNudgeId} onCancel={closeSidePanel} />
      </SidePanel>
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    width: '100%',
    paddingTop: `${spaceExtraLarge}px !important`,
  },

  title: {
    position: 'absolute',
    top: spaceMedium,
    left: spaceMedium,
    fontStyle: 'normal',
    fontSize: fontSizeMediumLarge,
    lineHeight: '17px',
    letterSpacing: '1.3px',
    textTransform: 'uppercase',
  },

  expandToggle: {
    position: 'absolute',
    top: 0,
    right: spaceExtraSmall,
  },

  expandedTypeTitle: {
    marginBottom: spaceMedium,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& span': {
      marginLeft: spaceMedium,
      fontSize: 26,
      color: rxrBlackColor,
    },
  },

  typeExpandedContainer: {
    marginBottom: spaceLarge,
  },

  nudgesCount: {
    color: rxrTealColor,
    marginLeft: spaceExtraSmall,
    marginRight: spaceExtraSmall,
    fontSize: 48,
  },
  typeSummaryContainer: {
    width: '100%',
    paddingRight: spaceExtraLarge,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  nudgeSummaryTypeLabel: {},
}))

NudgesSummary.propTypes = {
  className: PropTypes.any,
}

export default NudgesSummary
