import React, {useEffect, useRef} from 'react'
import * as d3 from 'd3'
import PropTypes from 'prop-types'
import {fontSizeExtraLarge, fontSizeExtraSmall, mediumFontWeight, lightFontWeight} from '../../assets/styles/typography'
import {rxrGreyColor, rxrBlackColor, rxrDarkGreyColor} from '../../assets/styles/color'

function ArcChart(props) {
  const ref = useRef()
  const score = isNaN(props.score) ? null : props.score
  const height = props.height || 150
  const width = props.width || 300

  useEffect(() => {
    const marginBetweenArcAndAxisLabels = 20
    const outerRadius = Math.min(width, height) / 2 - marginBetweenArcAndAxisLabels
    const arcThickness = 5
    const innerRadius = outerRadius - arcThickness
    const minNpsScore = -100
    const maxNpsScore = 100

    const svg = d3
      .select(ref.current)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`)

    const startAngle = (7 * Math.PI) / 6 // 210 degrees
    const fullCircle = 2 * Math.PI
    const endAngle = startAngle + fullCircle - Math.PI / 3

    if (score !== null) {
      const scoreAsAPercentage = (score - minNpsScore) / (maxNpsScore - minNpsScore)
      const scoreAngle = startAngle + scoreAsAPercentage * (endAngle - startAngle)

      const scoreArc = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .startAngle(startAngle)
        .endAngle(scoreAngle)

      svg
        .append('path')
        .attr('d', scoreArc)
        .attr('fill', props.arcColor)

      const backgroundArc = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .startAngle(scoreAngle)
        .endAngle(endAngle)

      svg
        .append('path')
        .attr('d', backgroundArc)
        .attr('fill', rxrGreyColor)
    } else {
      const greyArc = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .startAngle(startAngle)
        .endAngle(endAngle)

      svg
        .append('path')
        .attr('d', greyArc)
        .attr('fill', rxrGreyColor)
    }

    const textScore = score !== null ? score : 'N/A'

    svg
      .append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr('text-anchor', 'middle')
      .attr('dy', '0.35em')
      .style('font-size', fontSizeExtraLarge)
      .style('font-weight', mediumFontWeight)
      .style('fill', rxrBlackColor)
      .text(textScore)

    const startX = Math.cos(startAngle - Math.PI / 2) * outerRadius
    const startY = Math.sin(startAngle - Math.PI / 2) * outerRadius + marginBetweenArcAndAxisLabels

    svg
      .append('text')
      .attr('x', startX)
      .attr('y', startY)
      .attr('text-anchor', 'middle')
      .style('font-size', fontSizeExtraSmall)
      .style('font-weight', lightFontWeight)
      .style('fill', rxrDarkGreyColor)
      .text(`${minNpsScore}`)

    const endX = Math.cos(endAngle - Math.PI / 2) * outerRadius
    const endY = Math.sin(endAngle - Math.PI / 2) * outerRadius + marginBetweenArcAndAxisLabels

    svg
      .append('text')
      .attr('x', endX)
      .attr('y', endY)
      .attr('text-anchor', 'middle')
      .style('font-size', fontSizeExtraSmall)
      .style('font-weight', lightFontWeight)
      .style('fill', rxrDarkGreyColor)
      .text(`${maxNpsScore}`)
  }, [score, height, width])

  return <svg ref={ref}></svg>
}

ArcChart.propTypes = {
  score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.number,
  width: PropTypes.number,
  arcColor: PropTypes.string,
}

export default ArcChart
