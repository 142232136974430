import React, {useState} from 'react'
import RXRIcon from '../../RXRIcon'
import {makeStyles} from '@material-ui/core'
import {spaceSmall} from '../../../assets/styles/spacing'
import MultilineTextInput from '../../MultilineTextInput'
import {createStaffNoteForBuilding, getStaffNote, updateStaffNote} from '../../../lib/queries'
import RXRButton from '../../RXRButton'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {CRUD_ACTION_UPDATE, CRUD_ACTION_CREATE} from './constants'
import {selectAuthedUserId} from '../../../reducers/selectors'

const SendIcon = <RXRIcon icon={RXRIcon.ARROW_LONG_UP} size={RXRIcon.SIZE_DEFAULT} />

function CreateNewNote(props) {
  const [noteContent, setNoteContent] = useState(props.editingStartingContent || '')
  const [isSaving, setIsSaving] = useState(false)
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const authedStaffId = useSelector(selectAuthedUserId)

  const handleSubmit = async () => {
    if (!!props.editingNoteId) {
      return await handleUpdate()
    } else {
      return await handleCreate()
    }
  }

  const handleUpdate = async () => {
    try {
      setIsSaving(true)
      const input = {
        id: props.editingNoteId,
        content: noteContent,
      }
      const updatedNote = await updateStaffNote(input)
      setIsSaving(false)
      props.onSave(updatedNote, CRUD_ACTION_UPDATE)
      props.stopEditing()
    } catch (err) {
      setIsSaving(false)
    }
  }

  const handleCreate = async () => {
    setIsSaving(true)

    const staffNoteId = await determineHighestLevelParentStaffNoteId(props.staffNoteId)

    try {
      const createdStaffNote = await createStaffNoteForBuilding(activeBuildingId, authedStaffId, noteContent, staffNoteId)
      setNoteContent('')
      setIsSaving(false)
      props.onSave(createdStaffNote, CRUD_ACTION_CREATE)
      if (props.stopEditing) {
        props.stopEditing()
      }
    } catch (err) {
      window.alert(err.message)
      setIsSaving(false)
    }
  }

  const determineHighestLevelParentStaffNoteId = async staffNoteId => {
    if (!staffNoteId) return null
    let currentStaffNote = await getStaffNote(staffNoteId)
    let parentStaffNoteId = currentStaffNote.staffNoteId
    let parentStaffNote = null
    let maxIterations = 1000000
    let currentIterations = 0
    while (parentStaffNoteId && currentIterations < maxIterations) {
      parentStaffNote = await getStaffNote(parentStaffNoteId)
      currentStaffNote = parentStaffNote
      parentStaffNoteId = currentStaffNote.staffNoteId
      currentIterations++
    }
    // Once we have exited the while loop, that means we did not have a
    // parentStaffNoteId, which means that the currentStaffNote is a
    // top level parent, so we can return the id of our currentStaffNote
    return currentStaffNote.id
  }

  const classes = useStyles()
  return (
    <div className={classes.sendContainer}>
      <MultilineTextInput
        className={classes.input}
        placeholder={props.placeholder}
        value={noteContent}
        onChange={setNoteContent}
        onKeyDown={e => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            if (noteContent.trim() !== '') {
              handleSubmit().then()
            }
          }
        }}
      />
      <RXRButton
        className={props.useCompact ? classes.sendButtonCompact : classes.sendButton}
        onClick={handleSubmit}
        disabled={!noteContent || noteContent === props.editingStartingContent}
        isLoading={isSaving}
      >
        {props.useCompact ? SendIcon : 'Send'}
      </RXRButton>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  sendButton: {
    marginLeft: spaceSmall,
    flexGrow: 0,
    height: '54px',
    width: '80px',
    flexShrink: 0,
  },

  sendButtonCompact: {
    position: 'absolute',
    top: spaceSmall,
    right: spaceSmall,
    width: '32px',
    height: '32px',
    borderRadius: '16px',
    minWidth: 0,
    padding: 0,
  },

  input: {
    width: '100%',
  },

  sendContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
}))

CreateNewNote.propTypes = {
  onSave: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  staffNoteId: PropTypes.string,
  editingNoteId: PropTypes.string,
  editingStartingContent: PropTypes.string,
  useCompact: PropTypes.bool,
  stopEditing: PropTypes.func,
}

export default CreateNewNote
