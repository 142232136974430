import React, {useState} from 'react'
import {Typography} from '@material-ui/core'
import {rxrMediumGreyColor} from '../../assets/styles/color'
import {useDispatch, useSelector} from 'react-redux'
import RXRIcon from '../RXRIcon'
import {setBuildingOOOStatus} from '../../actions/buildingsActions'
import {spaceExtraSmall, spaceLarge, spaceMedium, spaceSmall} from '../../assets/styles/spacing'
import {makeStyles} from '@material-ui/core/styles'
import {fontSizeSmall} from '../../assets/styles/typography'
import MessageConstant from './MessageConstant'
import MultilineTextInput from '../MultilineTextInput'
import RXRButton from '../RXRButton'
import SettingButton from './SettingButton'
import CustomCheckbox from '../CustomCheckbox'

const useStyles = makeStyles(theme => ({
  replyContainer: {
    width: '400px',
    paddingRight: spaceSmall,
  },
  replyText: {
    marginLeft: spaceLarge,
    fontSize: fontSizeSmall,
    fontStyle: 'italic',
    paddingBottom: spaceExtraSmall,
  },
  customMessageContainer: {
    borderTop: `1px solid ${rxrMediumGreyColor}`,
    marginTop: spaceMedium,
    paddingTop: spaceMedium,
  },
  saveButtonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}))

const MULTILINE_TEXT_INPUT_ROWS = 4
const CUSTOM_OOO_MESSAGE_CHAR_LIMIT = 200

function OOOButton(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const outOfOfficeStatus = useSelector(state => state.Buildings.buildingsLookup[state.Buildings.activeBuildingId].outOfOfficeStatus)
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const permissionsObject = useSelector(state => state.GroupPermissions.permissionsObject)
  const [showAwayMessageInput, setShowAwayMessageInput] = useState(false)
  const [selectedDefaultReply, setSelectedDefaultReply] = useState(false)
  const [selectedCustomReply, setSelectedCustomReply] = useState(false)
  const [customReply, setCustomReply] = useState('')

  const handleClick = () => {
    if (!permissionsObject.messagesWrite) {
      window.alert('You do not have permission to change the OOO status')
      return
    }

    // If currently in office
    if (outOfOfficeStatus) {
      setShowAwayMessageInput(true)
    } else {
      setBuildingOOOStatus(dispatch, activeBuildingId, !outOfOfficeStatus).then()
    }
  }

  const handleSelectDefaultReply = () => {
    setSelectedDefaultReply(true)
    setSelectedCustomReply(false)
  }

  const handleSelectCustomReply = () => {
    setSelectedCustomReply(true)
    setSelectedDefaultReply(false)
  }

  const handleSave = () => {
    if (selectedDefaultReply) {
      setBuildingOOOStatus(dispatch, activeBuildingId, !outOfOfficeStatus).then(() => setShowAwayMessageInput(!showAwayMessageInput))
    } else {
      setBuildingOOOStatus(dispatch, activeBuildingId, !outOfOfficeStatus, customReply).then(() =>
        setShowAwayMessageInput(!showAwayMessageInput),
      )
    }
  }

  return (
    <SettingButton
      onOpen={handleClick}
      onClose={() => setShowAwayMessageInput(false)}
      isOpen={showAwayMessageInput}
      isActive={outOfOfficeStatus}
      icon={outOfOfficeStatus ? RXRIcon.MESSAGES : RXRIcon.MESSAGES_OFF}
      label={outOfOfficeStatus ? 'RXO online' : 'RXO offline'}
      title={'Set Auto-Reply Message'}
    >
      <div className={classes.replyContainer}>
        <div>
          <CustomCheckbox onChange={handleSelectDefaultReply} label={`Default reply`} isChecked={selectedDefaultReply} />
          <Typography className={classes.replyText}>{`"${MessageConstant.DEFAULT_OOO_MESSAGE}"`}</Typography>
        </div>
        <div className={classes.customMessageContainer}>
          <CustomCheckbox onChange={handleSelectCustomReply} label={`Custom reply`} isChecked={selectedCustomReply} />
        </div>
        <div className={classes.replyText}>
          <MultilineTextInput
            rows={MULTILINE_TEXT_INPUT_ROWS}
            rowsMax={MULTILINE_TEXT_INPUT_ROWS}
            maxLength={CUSTOM_OOO_MESSAGE_CHAR_LIMIT}
            onChange={setCustomReply}
            value={customReply}
            isDisabled={!selectedCustomReply}
          />
        </div>
        <div className={classes.saveButtonContainer}>
          <RXRButton
            onClick={handleSave}
            disabled={(!selectedCustomReply && !selectedDefaultReply) || (selectedCustomReply && !customReply)}
          >
            Save
          </RXRButton>
        </div>
      </div>
    </SettingButton>
  )
}

OOOButton.propTypes = {}

export default OOOButton
