import ActionTypes from './types'
import {getOccupancy, updateOccupancy} from '../lib/queries'

/**
 * @param {Dispatch} dispatch
 * @param {*} updateOccupancyInput
 * @return {Promise<*>}
 */
export const updateOccupancyAction = async (dispatch, updateOccupancyInput) => {
  let response = await updateOccupancy(updateOccupancyInput)

  dispatch({
    type: ActionTypes.OCCUPANCY_UPDATE,
    payload: response,
  })

  return response
}

/**
 * @param {Dispatch} dispatch
 * @param {string} occupancyId
 * @return {Promise<*>}
 */
export const loadOccupancyAction = async (dispatch, occupancyId) => {
  const response = await getOccupancy(occupancyId)

  dispatch({
    type: ActionTypes.OCCUPANCY_UPDATE,
    payload: response,
  })

  return response
}
