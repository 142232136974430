import React from 'react'
import PropTypes from 'prop-types'
import {useHistory} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import {spaceExtraSmall} from '../assets/styles/spacing'
import {rxrBlackColor, rxrDarkGreyColor} from '../assets/styles/color'
import {constructClassString} from '../Utils/objectUtil'

function BreadCrumbs(props) {
  const classes = useStyles()
  const history = useHistory()
  return (
    <div className={constructClassString(classes.breadcrumbsContainer, props.className)}>
      {props.paths.map(pathObj => {
        return (
          <span
            className={constructClassString(classes.crumb, {'no-link': !pathObj.path})}
            key={pathObj.path}
            onClick={() => (pathObj.path ? history.push(pathObj.path) : null)}
          >
            {pathObj.label}
          </span>
        )
      })}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  breadcrumbsContainer: {},

  crumb: {
    cursor: 'pointer',
    display: 'inline-block',
    textDecoration: 'underline',
    color: rxrDarkGreyColor,

    '&.no-link': {
      textDecoration: 'none',
      color: rxrBlackColor,
      cursor: 'auto',
    },

    '&:hover': {
      color: rxrBlackColor,
    },
    '&:after': {
      display: 'inline-block',
      content: '">"',
      margin: `0 ${spaceExtraSmall}px`,
      cursor: 'default',
    },

    '&:last-child:after': {
      display: 'none',
    },
  },
}))

BreadCrumbs.propTypes = {
  paths: PropTypes.arrayOf(PropTypes.object), // Array<{path: string, label: string}>
  className: PropTypes.string,
}

export default BreadCrumbs
