import ActionTypes from '../actions/types'
import {ROOT_SCREEN} from '../components/KnowYourResident/KYRConstants'
import * as Sentry from '@sentry/react'
import {v4 as uuid} from 'uuid'

class KYRScreenVisit {
  /**
   * @param {string} screenName
   * @param {object?} params
   * @param {object?} state
   */
  constructor(screenName, params, state) {
    this.id = uuid()
    this.name = screenName
    this.params = Object.assign({}, params)
    this.frozenState = Object.assign({}, state)
  }

  freezeState() {
    if (typeof this.__extractFreezableStateFunction === 'function') {
      try {
        this.frozenState = this.__extractFreezableStateFunction()
      } catch (err) {
        console.error(err)
        Sentry.withScope(scope => {
          scope.setTag('KYRNavigation', 'extractingFrozenState')
          Sentry.captureException(err)
        })

        this.frozenState = {}
      }
    }
  }

  /**
   * @param {function} func
   */
  setExtractFreezableStateFunction(func) {
    this.__extractFreezableStateFunction = func
  }

  /**
   * @param {KYRScreenVisit} other
   * @returns {boolean}
   */
  equals(other) {
    return other instanceof KYRScreenVisit && this.name === other.name && JSON.stringify(this.params) === JSON.stringify(other.params)
  }
}

const initialState = {
  history: [], // all pages accessed before the current page (NOTE: current will not be in the list)
  current: new KYRScreenVisit(ROOT_SCREEN), // always start on the root page
  isWidgetOpen: false,
}

// --------------------------------------------------------------------------------
// State permutation functions:

function pushScreen(state, screenName, params) {
  let newPage = new KYRScreenVisit(screenName, params)
  if (newPage.equals(state.current)) {
    // not going to navigate
    return state
  }

  // if we have a current one (that will soon become top of history)
  if (state.current) {
    // we save our state
    state.current.freezeState()
  }

  return {...state, current: newPage, history: [...state.history, state.current]}
}

function popHistory(state) {
  let prev = state.history.pop()
  if (!prev) {
    if (ROOT_SCREEN === state.current.name) {
      // we're already at dashboard or landing, do nothing
      return state
    } else {
      // if they aren't on the root screen yet, we take them to it
      prev = new KYRScreenVisit(ROOT_SCREEN)
    }
  }

  return {...state, current: prev, history: [...state.history]}
}

// --------------------------------------------------------------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.KYR_WIDGET_NAVIGATE_TO_SCREEN:
      return pushScreen(state, action.screen, action.params)

    case ActionTypes.KYR_WIDGET_NAVIGATE_BACK:
      return popHistory(state)

    case ActionTypes.KYR_SET_IS_WIDGET_OPEN:
      return {...state, isWidgetOpen: action.isWidgetOpen}
    default:
      return state
  }
}
