import {createFeedback} from '../lib/queries'
import ActionTypes from './types'

/**
 * @param {Dispatch} dispatch
 * @param {string} buildingId
 * @param {string} staffId
 * @param {string} residentId
 * @param {string} type
 * @param {number} rating
 * @param {string?} comments
 * @return {Promise<*>}
 */
export async function createFeedbackForResident(dispatch, buildingId, staffId, residentId, type, rating, comments) {
  const feedback = await createFeedback({
    buildingId: buildingId,
    staffId: staffId,
    residentId: residentId,
    type: type,
    rating: rating,
    feedback: comments,
  })

  dispatch({
    type: ActionTypes.FEEDBACK_NEW_FEEDBACK,
    payload: feedback,
  })
}

/**
 * @param {Dispatch} dispatch
 * @param {string} residentId
 * @return {Promise<void>}
 */
export async function skipForecastForResident(dispatch, residentId) {
  dispatch({
    type: ActionTypes.FEEDBACK_SKIP_FORECAST,
    payload: residentId,
  })
}
