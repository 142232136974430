import React from 'react'
import ReadOnlyTask from '../ReadOnlyTask'
import {FormComponentPropTypes} from '../onboardingTypes'

function DownloadRXOHome(props) {
  return <ReadOnlyTask {...props} />
}

DownloadRXOHome.propTypes = FormComponentPropTypes

export default DownloadRXOHome
