import React from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {getActiveResidents} from '../Utils/residentUtils'
import PrefixComplete from './PrefixComplete'

function residentToString(resident, futureLabel) {
  if (!resident) {
    return ''
  }

  let retVal = resident.displayName + ', Unit ' + resident.occupancy.unit.number
  if (resident.occupancy.status === 'Future') {
    retVal += ` (${futureLabel})`
  }

  return retVal
}

function SearchResident(props) {
  const residentsLookup = useSelector(state => state.Residents.residentsLookup)
  const residents = getActiveResidents(residentsLookup)
  const activeBuilding = useSelector(state => state.Buildings.buildingsLookup[state.Buildings.activeBuildingId])
  const hasOnboarding = activeBuilding && !!activeBuilding.onboardingSchema

  const residentValue =
    typeof props.value === 'string'
      ? residentsLookup[props.value]
      : props.value && typeof props.value === 'object'
      ? residentsLookup[props.value.id]
      : null

  return (
    <PrefixComplete
      options={residents}
      getOptionLabel={r => residentToString(r, hasOnboarding ? 'Onboarding' : 'Future')}
      value={residentValue}
      onChange={props.onSelectResident}
      isRequired={props.isRequired}
      isDisabled={props.disabled}
      label={props.labelText || props.labelText === '' ? props.labelText : 'Tenant and unit'}
      error={props.error}
      textFieldStyle={props.textFieldStyle}
      placeholder={props.placeholderText}
    />
  )
}

SearchResident.ResidentToString = residentToString

SearchResident.propTypes = {
  onSelectResident: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.any, // could be a resident object OR just a residentID
  isRequired: PropTypes.bool,
  labelText: PropTypes.string,
  textFieldStyle: PropTypes.any,
  error: PropTypes.bool,
  placeholderText: PropTypes.string,
}

export default SearchResident
