import React from 'react'
import Constants from './AmenitiesConstants'
import Routes from '../../constants/RouteConstants'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import RXRIcon from '../RXRIcon'
import {MenuItem, Menu, IconButton} from '@material-ui/core'
import {iconButton} from '../../assets/styles/buttons'

function ActionMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const items = props.amenity.isOnboardingOnly
    ? [Constants.ACTIONS_MENU_EDIT_AMENITY, Constants.ACTIONS_MENU_BOOK_FOR_TENANT]
    : [Constants.ACTIONS_MENU_EDIT_AMENITY, Constants.ACTIONS_MENU_BOOK_FOR_TENANT, Constants.ACTIONS_MENU_VIEW_RESERVATIONS]

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickActions = itemIndex => {
    switch (itemIndex) {
      case 0:
        props.history.push(Routes.constructPath(Routes.AMENITIES_EDIT, {amenityId: props.amenity.id}))
        break

      case 1:
        // TODO: should pre-select this amenity in the form
        props.amenity.isOnboardingOnly
          ? props.history.push(Routes.ELEVATOR_RESERVATIONS_CREATE)
          : props.history.push(Routes.AMENITIES_RESERVATIONS_CREATE)
        break

      case 2:
        // TODO: should filter table by this amenity
        props.history.push(Routes.AMENITIES_RESERVATIONS)
        break
    }
  }

  return (
    <div>
      <IconButton style={iconButton} onClick={handleClick}>
        <RXRIcon icon={RXRIcon.ELLIPSIS_VERTICAL} />
      </IconButton>
      <Menu id="action-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {items.map((label, index) => (
          <MenuItem key={label} onClick={() => handleClickActions(index)}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

ActionMenu.propTypes = {
  amenity: PropTypes.any.isRequired,
}

export default withRouter(ActionMenu)
