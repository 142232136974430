import React, {useState} from 'react'
import {PageContainer} from '../../assets/styles/layout'
import PageHeader from '../PageHeader'
import RXRButton from '../RXRButton'
import {AntTab, AntTabs} from '../AntTabs'
import SidePanel from '../SidePanel'
import VendorAppointmentForm from './VendorAppointmentForm'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import useBuildingDataRefresh from '../hooks/useBuildingDataRefresh'
import {
  cancelVendorAppointment,
  chargeForAppointment,
  markVendorNoShow,
  createNewAppointmentForResident,
  loadAppointmentsForBuilding,
  updateVendorAppointment,
} from '../../actions/vendorAppointmentsActions'
import VendorAppointmentsTable from './VendorAppointmentsTable'
import useListCreateViewPattern from '../hooks/useListCreateViewPattern'
import {Grid, Tooltip} from '@material-ui/core'
import {VENDOR_SERVICE_CANCELLED, VENDOR_SERVICE_COMPLETED} from './VendorServiceConstants'
import DiscardChangesDialog from '../DiscardChangesDialog'
import {rxrDarkGreyColor} from '../../assets/styles/color'
import {
  FREQUENCY_ONCE,
  VendorServiceTypeCleaning,
  VendorServiceTypeDogwalking,
  VendorServiceTypeMassage,
  VendorServiceTypePersonalTraining,
} from './ServicesConstants'
import useFormChanged, {NonEmptyValidator, DateObjectValidator} from '../hooks/useFormChanged'
import Routes from '../../constants/RouteConstants'
import RXRIcon from '../RXRIcon'
import VendorServiceDiscount from '../../lib/VendorServiceDiscount'
import {calculateTaxAndTotalForVendorAppointment, statusIsPast} from './servicesUtilities'
import ClearableInput from '../ClearableInput'
import useResidentLookup from '../hooks/useResidentLookup'
import SimpleSpinner from '../SimpleSpinner'
import {spaceExtraLarge} from '../../assets/styles/spacing'
import {updateRecurringVendorAppointment} from '../../lib/queries'
import {
  CORE_FEATURE_DOG_WALKING,
  CORE_FEATURE_CLEANINGS,
  CORE_FEATURE_MASSAGE,
  CORE_FEATURE_PERSONAL_TRAINING,
} from '../../constants/ModelConstants'

const TAB_UPCOMING = 0
const TAB_PREVIOUS = 1

const EMPTY_FORM_STATE = {
  id: null,
  residentId: null,
  vendorServiceId: null,
  startAt: null,
  endAt: null,
  notes: '',
  paymentMethodId: null,
  frequency: FREQUENCY_ONCE,
  addOns: [], // add on objects/fragments
  discounts: [], // VendorServiceDiscount objects
}

const serviceTypeToFeatureMap = {
  [VendorServiceTypeCleaning]: CORE_FEATURE_CLEANINGS,
  [VendorServiceTypeDogwalking]: CORE_FEATURE_DOG_WALKING,
  [VendorServiceTypeMassage]: CORE_FEATURE_MASSAGE,
  [VendorServiceTypePersonalTraining]: CORE_FEATURE_PERSONAL_TRAINING,
}

function GenericServicesPage(props) {
  const [tab, setTab] = useState(TAB_UPCOMING)
  const [isSaving, setIsSaving] = useState(false)
  const [isBilling, setIsBilling] = useState(false)
  const [chargingForAppointment, setChargingForAppointment] = useState(null)
  const [cancellingVendorAppointment, setCancellingVendorAppointment] = useState(null)
  const [cancellingRecurringVendorAppointment, setCancellingRecurringVendorAppointment] = useState(null)
  const [isCancelling, setIsCancelling] = useState(false)
  const [filterTerm, setFilterTerm] = useState('')
  const [isMarkingNoShow, setIsMarkingNoShow] = useState(false)
  const {getResident} = useResidentLookup()

  const dispatch = useDispatch()
  const history = useHistory()

  const appointmentsLookup = useSelector(state => state.Appointments.appointmentsLookup)
  const activeBuilding = useSelector(state => state.Buildings.buildingsLookup[state.Buildings.activeBuildingId])
  const coreFeatures = useSelector(state => state.GroupPermissions.coreFeatures)

  const isServiceCurrentlyDisabled = Object.hasOwnProperty.call(serviceTypeToFeatureMap, props.serviceType)
    ? !coreFeatures[serviceTypeToFeatureMap[props.serviceType]]
    : false

  const {activeBuildingId, isLoadingData} = useBuildingDataRefresh(
    () => loadAppointmentsForBuilding(dispatch, activeBuildingId),
    'ServicesPage',
  )
  const {form, setForm, resetInitialForm, formChanged, changedItems, validateForm, invalidItems} = useFormChanged(
    EMPTY_FORM_STATE,
    useFormChanged.PropLevelValidation({
      residentId: NonEmptyValidator,
      vendorServiceId: NonEmptyValidator,
      paymentMethodId: NonEmptyValidator,
      startAt: DateObjectValidator,
      endAt: DateObjectValidator,
      ...props.extraFormValidation,
    }),
  )
  const {isCreateMode, focusedItemId} = useListCreateViewPattern(
    props.createNewRoute,
    props.viewSingleRoute,
    'vendorAppointmentId',
    EMPTY_FORM_STATE,
    form,
    resetInitialForm,
    appointmentsLookup,
    item => ({
      ...item,
      startAt: new Date(item.startAt),
      endAt: new Date(item.endAt),
      frequency: item.recurringVendorAppointment ? item.recurringVendorAppointment.frequency : FREQUENCY_ONCE,
      // this prop is derived from the vendorServiceCredits connection whose purpose is to stage changes to promotions/credits
      discounts: (item.vendorServiceCredits.items || []).map(c => new VendorServiceDiscount(c, VendorServiceDiscount.TYPE_CREDIT)),
    }),
    isLoadingData,
  )

  // --------------------------------------------------------

  const handleCancel = () => {
    if (isCancelling) {
      return
    }

    if (!cancellingVendorAppointment) {
      setCancellingVendorAppointment(focusedItemId)
      return
    }

    setIsCancelling(true)

    cancelVendorAppointment(dispatch, cancellingVendorAppointment)
      .then(() => {
        setIsCancelling(false)
        setCancellingVendorAppointment(null)
        history.push(props.baseRoute)
      })
      .catch(err => {
        console.error(err)
        window.alert(err.message)
        setIsCancelling(false)
      })
  }

  const handleCancelRecurring = () => {
    if (isCancelling) {
      return
    }

    if (!cancellingRecurringVendorAppointment) {
      setCancellingRecurringVendorAppointment(selectedAppointment.recurringVendorAppointmentId)
      return
    }

    setIsCancelling(true)

    updateRecurringVendorAppointment({
      id: cancellingRecurringVendorAppointment,
      isDeleted: true,
    })
      .then(() => {
        setIsCancelling(false)
        setCancellingVendorAppointment(null)
        history.push(props.baseRoute)
      })
      .catch(err => {
        console.error(err)
        window.alert(err.message)
        setIsCancelling(false)
      })
  }

  const saveAppointment = () => {
    if (isSaving) {
      return
    }

    if (!validateForm()) {
      return
    }

    setIsSaving(true)

    if (form.id) {
      updateVendorAppointment(
        dispatch,
        form.id,
        isAppointmentPast ? undefined : form.startAt, // cannot change date for past appointment
        isAppointmentPast ? undefined : form.endAt, // cannot change date for past appointment
        form.paymentMethodId,
        form.notes,
        form.addOns,
        form.discounts,
      )
        // we refresh our appointments list
        .then(() => {
          setIsSaving(false)
          resetInitialForm(form, () => history.push(props.baseRoute))
        })
        .catch(err => {
          console.error(err)
          window.alert(err.message)
          setIsSaving(false)
        })
    } else {
      // only set it when booking
      const finalNotes = `${form.extraNotes || ''}${form.notes || ''}`

      createNewAppointmentForResident(
        dispatch,
        form.residentId,
        form.vendorServiceId,
        form.startAt,
        form.endAt,
        form.paymentMethodId,
        finalNotes,
        form.addOns,
        form.discounts,
        // only pass frequency if it's NOT the one time only value
        form.frequency === FREQUENCY_ONCE || !!focusedItemId ? null : form.frequency,
        form.metaPetProfileIds,
      )
        .then(newAppointment => {
          if (typeof props.onCreate === 'function') {
            return props.onCreate(newAppointment, form)
          }
          return newAppointment
        })
        // we refresh our appointments list
        .then(() => {
          setIsSaving(false)
          resetInitialForm(EMPTY_FORM_STATE, () => history.push(props.baseRoute))
        })
        .catch(err => {
          console.error(err)
          window.alert(err.message)
          setIsSaving(false)
        })
    }
  }

  const handleChargeForService = () => {
    if (isBilling) {
      return
    }

    if (!chargingForAppointment) {
      setChargingForAppointment(focusedItemId)
      return
    }

    setIsBilling(true)
    chargeForAppointment(dispatch, chargingForAppointment)
      .then(() => {
        setIsBilling(false)
        setChargingForAppointment(null)
      })
      .catch(err => {
        console.error(err)
        window.alert(err.message)
        setIsBilling(false)
      })
  }

  const handleMarkNoShow = () => {
    setIsMarkingNoShow(true)

    markVendorNoShow(dispatch, focusedItemId)
      .then(() => {
        setIsMarkingNoShow(false)
      })
      .catch(err => {
        console.error(err)
        window.alert(err.message)
        setIsMarkingNoShow(false)
      })
  }

  // --------------------------------------------------------

  const selectedAppointment = focusedItemId ? appointmentsLookup[focusedItemId] : undefined
  const hasFailedPayment = selectedAppointment && selectedAppointment.payment && selectedAppointment.payment.status === 'DECLINED'
  const isPaid = selectedAppointment && !!selectedAppointment.payment && selectedAppointment.payment.status !== 'DECLINED'
  const isAppointmentPast = selectedAppointment && statusIsPast(selectedAppointment.status)
  const taxAndTotalAfterDiscounts = selectedAppointment
    ? calculateTaxAndTotalForVendorAppointment(activeBuilding, form.cost, form.addOns, form.discounts)
    : undefined

  const formattedTerm = filterTerm.toLowerCase()

  // React doesn't like having multiple history Prompts rendered at once so we need to conditionally
  // render one by basically just leveraging their isOpen condition.
  // TODO: We should refactor all components that have multiple Router components to this pattern
  let discardComponent
  if (cancellingVendorAppointment) {
    discardComponent = (
      <DiscardChangesDialog
        isOpen={!!cancellingVendorAppointment}
        discardTitle={'Cancel appointment'}
        discardMessage={`Are you sure you want to cancel this appointment?`}
        discardButton={'Yes'}
        isLoading={isCancelling}
        onDiscard={handleCancel}
        onCancel={() => setCancellingVendorAppointment(null)}
      />
    )
  } else if (cancellingRecurringVendorAppointment) {
    discardComponent = (
      <DiscardChangesDialog
        isOpen={!!cancellingRecurringVendorAppointment}
        discardTitle={'Cancel schedule'}
        discardMessage={`Are you sure you want to cancel this recurring schedule? This will cancel all future appointments in the series and stop the system from creating new appointments.\n\nOnce cancelled, it will take a few minutes to reflect in the dashboard.`}
        discardButton={'Yes'}
        isLoading={isCancelling}
        onDiscard={handleCancelRecurring}
        onCancel={() => setCancellingRecurringVendorAppointment(null)}
      />
    )
  } else if (selectedAppointment && !!chargingForAppointment) {
    discardComponent = (
      <DiscardChangesDialog
        isOpen={!!chargingForAppointment}
        discardIcon={<RXRIcon icon={RXRIcon.DOLLAR_SIGN} color={rxrDarkGreyColor} size={RXRIcon.SIZE_LARGE} />}
        discardTitle={'Charge the resident'}
        discardMessage={`Are you sure you want to charge the resident $${taxAndTotalAfterDiscounts.total}?`}
        discardButton={'Yes'}
        isLoading={isBilling}
        onDiscard={handleChargeForService}
        onCancel={() => setChargingForAppointment(null)}
      />
    )
  } else {
    discardComponent = <DiscardChangesDialog hasChanges={formChanged} onDiscard={() => history.push(props.baseRoute)} />
  }

  return (
    <div style={PageContainer}>
      <PageHeader
        title={props.title}
        rightControlComponent={
          <RXRButton type={RXRButton.TYPE_LARGE} onClick={() => history.push(props.createNewRoute)} disabled={isServiceCurrentlyDisabled}>
            {props.createNewCTA}
          </RXRButton>
        }
      >
        <Grid container spacing={3}>
          <Grid item lg={4} md={6}>
            <ClearableInput value={filterTerm} onChange={setFilterTerm} placeholder={'Search by resident, unit, or package'} />
          </Grid>
        </Grid>
        <AntTabs value={tab} onChange={(e, t) => setTab(t)}>
          <AntTab label="Upcoming" />
          <AntTab label="Previous" />
        </AntTabs>
      </PageHeader>

      <div style={{height: '100%'}}>
        {isLoadingData ? (
          <div style={{padding: spaceExtraLarge, textAlign: 'center'}}>
            <SimpleSpinner size={SimpleSpinner.SIZE_LARGE} />
          </div>
        ) : (
          <VendorAppointmentsTable
            isArchiveTab={tab === TAB_PREVIOUS}
            serviceType={props.serviceType}
            vendorAppointments={Object.values(appointmentsLookup).filter(a => {
              const isPast = statusIsPast(a.status)
              const isCorrectTab = a.vendorService.type === props.serviceType && isPast === (tab === TAB_PREVIOUS)

              if (!isCorrectTab) {
                return false
              }

              if (formattedTerm.length > 0) {
                const resident = getResident(a.residentId)
                return (
                  resident.displayName.toLowerCase().includes(formattedTerm) ||
                  resident.occupancy.unit.number.toLowerCase().includes(formattedTerm) ||
                  a.vendorService.label.toLowerCase().includes(formattedTerm)
                )
              }

              return true
            })}
            onClickAppointment={item => {
              history.push(Routes.constructPath(props.viewSingleRoute, {vendorAppointmentId: item.id}))
            }}
            isServiceCurrentlyDisabled={isServiceCurrentlyDisabled}
          />
        )}
      </div>

      <SidePanel
        isOpen={!!(focusedItemId || isCreateMode)}
        onClose={() => history.push(props.baseRoute)}
        title={focusedItemId ? 'View appointment' : 'Book appointment'}
      >
        <VendorAppointmentForm
          {...form}
          serviceType={props.serviceType}
          invalidItems={invalidItems}
          updateForm={setForm}
          changedItems={changedItems}
          AddOnsComponent={props.AddOnsComponent}
        />
        <Grid container spacing={2} alignItems="center">
          {selectedAppointment ? (
            <React.Fragment>
              <Grid item>
                <RXRButton disabled={!formChanged} isLoading={isSaving} onClick={saveAppointment}>
                  Save
                </RXRButton>
              </Grid>

              {hasFailedPayment && (
                <Grid item>
                  <Tooltip placement={'top'} title={`Payment failed due to "${selectedAppointment.payment.failureReason || 'UNKNOWN'}"`}>
                    <div>
                      <RXRButton
                        isLoading={isBilling}
                        onClick={handleChargeForService}
                        disabled={formChanged || isPaid || selectedAppointment.cost === 0}
                      >
                        Re-Charge Resident ${taxAndTotalAfterDiscounts.total}
                      </RXRButton>
                    </div>
                  </Tooltip>
                </Grid>
              )}
              {selectedAppointment.status === VENDOR_SERVICE_COMPLETED && (
                <React.Fragment>
                  <Grid item>
                    <RXRButton
                      isLoading={isBilling}
                      onClick={handleChargeForService}
                      disabled={formChanged || isPaid || selectedAppointment.cost === 0}
                    >
                      Charge Resident ${taxAndTotalAfterDiscounts.total}
                    </RXRButton>
                  </Grid>
                  <Grid item>
                    <RXRButton
                      isLoading={isMarkingNoShow}
                      onClick={handleMarkNoShow}
                      disabled={formChanged || isPaid || selectedAppointment.cost === 0}
                      type={RXRButton.TYPE_DESTRUCTIVE}
                    >
                      Mark No Show
                    </RXRButton>
                  </Grid>
                </React.Fragment>
              )}

              {selectedAppointment.status === VENDOR_SERVICE_CANCELLED && (
                <Grid item>
                  <RXRButton
                    isLoading={isBilling}
                    onClick={handleChargeForService}
                    disabled={formChanged || isPaid || selectedAppointment.cost === 0}
                    type={RXRButton.TYPE_DESTRUCTIVE}
                  >
                    Charge Resident ${taxAndTotalAfterDiscounts.total}
                  </RXRButton>
                </Grid>
              )}

              {!isAppointmentPast && (
                <Grid item>
                  <RXRButton type={RXRButton.TYPE_DESTRUCTIVE} isLoading={isCancelling} onClick={handleCancel}>
                    Cancel appointment
                  </RXRButton>
                </Grid>
              )}

              {!isAppointmentPast && selectedAppointment.recurringVendorAppointmentId && (
                <Grid item>
                  <RXRButton type={RXRButton.TYPE_DESTRUCTIVE} isLoading={isCancelling} onClick={handleCancelRecurring}>
                    Cancel series
                  </RXRButton>
                </Grid>
              )}

              <Grid item>
                <RXRButton type={RXRButton.TYPE_TEXT} onClick={() => history.push(props.baseRoute)}>
                  Cancel
                </RXRButton>
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item xs={12}>
              <RXRButton isLoading={isSaving} onClick={saveAppointment}>
                Book appointment
              </RXRButton>
            </Grid>
          )}
        </Grid>
        {discardComponent}
      </SidePanel>
    </div>
  )
}

GenericServicesPage.propTypes = {
  serviceType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  createNewCTA: PropTypes.string.isRequired,
  baseRoute: PropTypes.string.isRequired,
  createNewRoute: PropTypes.string.isRequired,
  viewSingleRoute: PropTypes.string.isRequired,
  AddOnsComponent: PropTypes.func,
  extraFormValidation: PropTypes.object,
  onCreate: PropTypes.func,
}

export default GenericServicesPage
