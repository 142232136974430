import React, {useState} from 'react'
import {ReactComponent as PrintIcon} from '../assets/images/Icon/PrintIcon.svg'
import {Dialog, DialogActions, DialogTitle, Typography, Tooltip} from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import {Colors} from '../assets/styles'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import RXRButton from './RXRButton'
import {followDotNotation} from '../Utils/objectUtil'
import moment from 'moment'
import {useSelector} from 'react-redux'

export const generateCSVFromDataKeyMap = (dataArray, keyArr) => {
  let csvTxt = ''
  csvTxt += Object.values(keyArr).join(',')
  csvTxt += '\n'
  dataArray.forEach((data, i) => {
    Object.keys(keyArr).forEach(key => {
      let value = followDotNotation(data, key)
      // just to be safe, we replace any double quotes with single quotes
      csvTxt += `"${value.replaceAll('"', "'")}",`
    })
    // remove the very last comma
    csvTxt = csvTxt.substring(0, csvTxt.length - 1)

    // add a new line to all but the very last line
    i < dataArray.length - 1 && (csvTxt += '\n')
  })
  return csvTxt
}

/**
 * @param {Array<*>} data
 * @param {Array<ColumnConfig>} columns
 */
export function generateCSVFromColumnsConfig(data, columns) {
  let columnsToInclude = columns.filter(c => typeof c.renderPrimitive === 'function')

  let titleRow = columnsToInclude.map(c => `"${c.title}"`).join(',') + '\n'
  return data.reduce((agr, d) => {
    return (
      agr +
      columnsToInclude
        .map(c => c.renderPrimitive(d))
        .map(str => `"${typeof str === 'string' ? str.replaceAll('"', "'") : str}"`)
        .join(',') +
      `\n`
    )
  }, titleRow)
}

export function downloadCSV(filename, text) {
  let element = document.createElement('a')
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

const Constants = {
  BTN_CONFIRM: 'Confirm',
  BTN_CANCEL: 'Cancel',
  TITLE: 'Download CSV file',
  MESSAGE: 'The CSV file will represent the full table of data. Apply some filters to get a filtered view.',
  MESSAGE_FILTERED: 'You have applied at least one filter, and the CSV file will represent that filtered view.',
}

const useStyles = makeStyles(theme => ({
  dialog: {
    maxWidth: '286px',
    '& .MuiDialogActions-root': {
      padding: '0px 8px 16px 16px',
      justifyContent: 'flex-start',
    },
  },
  cursorHand: {
    cursor: 'pointer',
  },
  messageText: {
    fontSize: '16px',
    lineHeight: '19px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  confirmButton: {
    marginLeft: 8,
  },
  cancelButton: {
    marginLeft: '24px !important',
  },
  title: {
    color: Colors.rxrBlackColor,
    fontSize: 16,
    fontHeight: '19px',
  },
  dialogTitle: {
    paddingBottom: '8px',
  },
  dialogContent: {
    paddingTop: 0,
  },
  printIcon: {
    color: Colors.rxrMonotoneLightGreyColor,
  },
}))

function DownloadData({fileName, keyMap, columns, data, filtered, className}) {
  const [showModal, setShowModal] = useState(false)

  const activeBuilding = useSelector(state => state.Buildings.buildingsLookup[state.Buildings.activeBuildingId])

  let handleClose = () => {
    setShowModal(false)
  }

  let handleConfirm = () => {
    let txt
    if (keyMap) {
      txt = generateCSVFromDataKeyMap(data, keyMap)
    } else if (columns) {
      txt = generateCSVFromColumnsConfig(data, columns)
    } else {
      throw new Error('Cannot download data without providing "keyMap" or "columns"')
    }

    downloadCSV(`${fileName}_${activeBuilding.displayName}_${moment().format('YYYYMMDD_HHMMSS')}.csv`, txt)
    setShowModal(false)
  }

  const classes = useStyles()
  return (
    <React.Fragment>
      <div className={className}>
        <Tooltip title="Download Data" enterDelay={1000} leaveDelay={100}>
          <PrintIcon className={classes.cursorHand} stroke={Colors.rxrMonotoneLightGreyColor} onClick={() => setShowModal(true)} />
        </Tooltip>
      </div>
      <Dialog open={showModal} onClose={handleClose} classes={{paper: classes.dialog}}>
        <DialogTitle className={classes.dialogTitle}>
          <PrintIcon width="24" height="24" />
          <Typography className={classes.title}>{Constants.TITLE}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.messageText}>{filtered ? Constants.MESSAGE_FILTERED : Constants.MESSAGE}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <RXRButton type={RXRButton.TYPE_TEXT} className={classes.confirmButton} onClick={handleConfirm}>
            {Constants.BTN_CONFIRM}
          </RXRButton>
          <RXRButton type={RXRButton.TYPE_TEXT_SECONDARY} className={classes.cancelButton} onClick={handleClose}>
            {Constants.BTN_CANCEL}
          </RXRButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

DownloadData.propTypes = {
  className: PropTypes.any,
  fileName: PropTypes.string.isRequired, //Name of CSV file to be downloaded
  keyMap: PropTypes.object, //keyMap with keys as Delivery Object key and Values as the label of the column in the csv file
  columns: PropTypes.array, // if keyMap is not provided, columns must be -- an array of ColumnConfig objects with renderPrimitive functions
  data: PropTypes.array.isRequired, //data is array of Objects with fields to be written to the CSV file
  filtered: PropTypes.bool, //if data is filtered or not
}

export default DownloadData
