import ActionTypes from '../actions/types'

const initialState = {
  unitsLookup: {},
  unitsLookupByNumber: {},
}

// --------------------------------------------------------------------------------
// State permutation functions:

function reduceUnitsListToObj(state, buildingsList) {
  let byId = {}
  let byNumber = {}
  buildingsList.forEach(u => {
    byNumber[u.number] = u
    byId[u.id] = u
  })
  return {...state, unitsLookup: byId, unitsLookupByNumber: byNumber}
}

// --------------------------------------------------------------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.UNITS_UPDATE_ALL:
      return reduceUnitsListToObj(state, action.payload)

    default:
      return state
  }
}
