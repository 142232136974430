import {rxrBlueColor, rxrGreyColor, rxrDarkGreyColor, rxrMediumGreyColor, rxrRedColor, rxrWhiteColor, rxrSoftHighlightedBlue} from './color'
import {iconButtonSizeMedium} from './spacing'

export const buttonHeight = 43

const basicButton = {
  height: buttonHeight,
  lineHeight: `${buttonHeight}px`,
  textAlign: 'center',
  fontSize: 16,
  textTransform: 'none',
  borderRadius: 4,
  outline: 'none !important',
  padding: '0px 19px 0px 19px',
  cursor: 'pointer',

  '&:focus': {
    outline: 'none !important',
  },
}

const roundedPrimaryButtonStyles = {
  borderRadius: 22,
  fontSize: 18,
  width: 250,
}

export const primaryButton = {
  ...basicButton,
  outline: 0,
  color: rxrWhiteColor,
  backgroundColor: rxrBlueColor,
  '&:hover': {
    backgroundColor: rxrSoftHighlightedBlue,
  },
  transition: 'background-color 0.2s ease-in-out',
}

export const primaryButtonDisabled = {
  ...basicButton,
  backgroundColor: rxrGreyColor,
  border: 'none !important',
  color: rxrMediumGreyColor,
  '&:hover': {
    backgroundColor: rxrGreyColor,
  },
}

export const roundedPrimaryButton = {
  ...primaryButton,
  ...roundedPrimaryButtonStyles,
}

export const roundedPrimaryButtonDisabled = {
  ...primaryButtonDisabled,
  ...roundedPrimaryButtonStyles,
}

export const secondaryButton = {
  ...basicButton,
  borderColor: rxrBlueColor,
  borderStyle: 'solid',
  borderWidth: 1,
  backgroundColor: rxrWhiteColor,
  color: rxrBlueColor,
  '&:hover': {
    backgroundColor: rxrWhiteColor,
  },
}

export const secondaryButtonDisabled = {
  ...secondaryButton,
  borderColor: rxrGreyColor,
  color: rxrGreyColor,
}

export const deleteButton = {
  ...secondaryButton,
  borderColor: rxrRedColor,
  color: rxrRedColor,
}

export const deleteButtonDisabled = {
  ...deleteButton,
  borderColor: rxrGreyColor,
  color: rxrGreyColor,
}

export const primaryTextButton = {
  fontWeight: 'bold',
  fontSize: '16px',
  color: '#2E9CCA',
  textTransform: 'none',
  cursor: 'pointer',
  outline: 'none !important',
}

export const secondaryTextButton = {
  fontWeight: 'bold',
  fontSize: '16px',
  color: rxrDarkGreyColor,
  textTransform: 'none',
  cursor: 'pointer',
  outline: 'none !important',
}

export const iconButton = {
  cursor: 'pointer',
  lineHeight: 0,
  '&:focus': {
    outline: 'none',
  },
  width: iconButtonSizeMedium,
  height: iconButtonSizeMedium,
}
