import React, {useEffect, useState, Fragment} from 'react'
import PropTypes from 'prop-types'
import {getListResidentEventsByPrimaryRecordId} from '../../lib/queries'
import {Grid, makeStyles} from '@material-ui/core'
import {rxrBlueColor, rxrSecondaryBlueGrayColor, rxrTeal25to100} from '../../assets/styles/color'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  timelineMainBox: {
    flex: 1,
    marginBottom: '4px',
  },
  timelineBox: {
    flex: 1,
    marginBottom: '16px',
  },
  headingBox: {
    marginBottom: 4,
    flex: 1,
    justifyContent: 'flex-start',
  },
  heading: {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: rxrSecondaryBlueGrayColor,
  },
  dateTimeText: {
    fontSize: '12px',
    lineHeight: '15px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: rxrBlueColor,
    display: 'inline-block',
    width: '160px',
  },
  statusText: {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: rxrBlueColor,
  },
  blueLine: {
    borderLeftWidth: 1,
    marginBottom: 2,
    marginLeft: 2,
    borderLeftColor: rxrTeal25to100,
    borderLeftStyle: 'solid',
    height: 35,
  },
}))
function TimelineEvent(props) {
  const classes = useStyles()
  const [timeLineEvents, setTimeLineEvents] = useState([])

  useEffect(() => {
    getListResidentEventsByPrimaryRecordId(props.primaryRecordId, props.filterResidentId)
      .then(res => {
        setTimeLineEvents(res)
      })
      .catch(err => console.error(err))
  }, [])

  return (
    <div className={classes.timelineMainBox}>
      <div className={classes.headingBox}>
        <div className={classes.heading}>Timeline</div>
      </div>
      <div className={classes.timelineBox}>
        {timeLineEvents.length > 0 ? (
          timeLineEvents.map((timeline, index) => (
            <Fragment key={index}>
              <div>
                <span className={classes.dateTimeText}>{`${moment(timeline.createdAt).format('M/DD/YY')} \u2022 ${moment(
                  timeline.createdAt,
                ).format('h:mma')}`}</span>
                <span className={classes.statusText}>{timeline.shortLabel}</span>
              </div>
              {timeLineEvents.length - 1 > index && <div className={classes.blueLine}></div>}
            </Fragment>
          ))
        ) : (
          <div className={classes.statusText}>--</div>
        )}
      </div>
    </div>
  )
}

TimelineEvent.propTypes = {
  primaryRecordId: PropTypes.string.isRequired,
}

export default TimelineEvent
