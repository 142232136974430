import React from 'react'
import SubHeader from '../SubHeader'
import KYRGenericCardComponent from '../KYRGenericCardComponent'
import {KYRScreenLayout} from '../../../assets/styles/layout'
import GuestRegistration from '../GuestRegistration'
import AbstractKYRScreen from './AbstractKYRScreen'
import {withStyles} from '@material-ui/core'
import {SCREEN_TIMELINE} from '../KYRConstants'
import {navigateToScreen} from '../../../actions/kyrWidgetActions'
import {connect} from 'react-redux'
import {compose} from 'redux'
import KYRWidgetBackButton from '../KYRWidgetBackButton'

const styles = theme => ({
  scrollContainer: {
    ...KYRScreenLayout,
    marginLeft: '40px',
  },
})

class GuestRegistrationComponent extends AbstractKYRScreen {
  render() {
    return (
      <KYRGenericCardComponent residentId={this.props.navParams.residentId}>
        <SubHeader title={'Guest Registration'} LeftIconComponent={KYRWidgetBackButton} />
        <div className={this.props.classes.scrollContainer}>
          <GuestRegistration guestId={this.props.navParams.primaryRecordId} />
        </div>
      </KYRGenericCardComponent>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchNavigateToTimeline: residentId => navigateToScreen(dispatch, SCREEN_TIMELINE, {residentId: residentId}),
})

export default compose(
  withStyles(styles),
  connect(
    undefined,
    mapDispatchToProps,
  ),
)(GuestRegistrationComponent)
