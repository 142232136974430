import React from 'react'
import {useSelector} from 'react-redux'
import moment from 'moment'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {Colors} from '../../assets/styles'
import {selectLoggedActionFromNudgeActions} from '../../reducers/selectors'
import {BUILDING_ACTION_TYPE_LABELS, buildingActionTypeToCategoryLabel, BUILDING_NUDGE_TYPE_LABELS} from './AssistantConstants'

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    marginBottom: 22,
  },
  gridstyling: {
    marginTop: 20,
  },
  infoTitle: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: Colors.rxrSecondaryMidDarkBlue,
  },
  infoData: {
    fontSize: 16,
    lineHeight: '16px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: Colors.rxrBlueColor,
    paddingTop: '12px',
  },
}))

export default function ViewLogActionForm(props) {
  const classes = useStyles()

  const loggedAction = useSelector(state => selectLoggedActionFromNudgeActions(state, props.actionId))
  const residentsLookup = useSelector(state => state.Residents.residentsLookup)

  if (!loggedAction) {
    return null
  }

  /**
   * Common function for rendeing data point.
   * @param {*} title
   * @param {*} data
   * @returns {string}
   */
  const renderInfo = (title, data) => (
    <Grid container direction="column">
      <Grid item>
        <div className={classes.infoTitle}>{title}</div>
      </Grid>
      <Grid item>
        <div className={classes.infoData}>{data ? data : '--'}</div>
      </Grid>
    </Grid>
  )

  const getResidentNameAndUnit = residentId => {
    let resident = residentsLookup[residentId]

    let residentName = resident.displayName
    let unit = resident.occupancy.unit.number
    return `${residentName}, ${unit}`
  }

  const getCategoriesAndActions = actions => {
    if (!actions) {
      return null
    }

    let categoryToActionsObj = actions.reduce((agr, a) => {
      let categoryLabel = buildingActionTypeToCategoryLabel(a)
      if (!agr[categoryLabel]) {
        agr[categoryLabel] = []
      }
      agr[categoryLabel].push(BUILDING_ACTION_TYPE_LABELS[a])
      return agr
    }, {})

    return (
      <ul>
        {Object.entries(categoryToActionsObj).map(([categoryLabel, actionLabels], index) => (
          <li key={index}>
            {categoryLabel}: {actionLabels.join(', ')}
          </li>
        ))}
      </ul>
    )
  }

  return (
    <div className={classes.container}>
      <Grid container spacing={6}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {renderInfo('Name', getResidentNameAndUnit(loggedAction.residentId))}
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.gridstyling}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {renderInfo('Category & action', getCategoriesAndActions(loggedAction.actions))}
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.gridstyling}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {renderInfo('Date action was taken', moment(loggedAction.actionTakenAt).format('MM/DD/YY'))}
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.gridstyling}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {renderInfo('Date and time the action was logged', moment(loggedAction.createdAt).format('MM/DD/YY, h:mma'))}
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.gridstyling}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {renderInfo('Total price', `$${loggedAction.cost ? loggedAction.cost : 0}`)}
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {renderInfo('Reason', BUILDING_NUDGE_TYPE_LABELS[loggedAction.reason] || loggedAction.reason)}
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {renderInfo('Notes', loggedAction.notes)}
        </Grid>
      </Grid>
    </div>
  )
}
