import {useRef} from 'react'
import moment from 'moment'

export const useCalendarMonthAvailablity = getCalendarAvailabilityForTheSelectedMonth => {
  const lastMonthSynced = useRef(null)
  const availabilityResponseState = useRef({})
  const availableDaysInThisMonth = useRef([])

  const shouldDisableDate = date => {
    // Disable the date if it's not in the list of available days
    return !availableDaysInThisMonth.current.includes(moment(date).format('YYYY-MM-DD'))
  }

  const clearCurrentAvailabilityInfo = () => {
    availableDaysInThisMonth.current = []
    availabilityResponseState.current = {}
    lastMonthSynced.current = null //clears the selected month
  }

  /**
   * @param {Moment|Date|string} d
   * @param {boolean?} force
   */
  const handleMonthChange = async (d, force) => {
    const momDate = moment(d || undefined)
    const date = momDate.toDate()

    const newMonth = momDate.format('Y/M')
    if (force || newMonth !== lastMonthSynced.current) {
      lastMonthSynced.current = newMonth
      return loadAvailabilityForMonth(date)
    }
  }

  /**
   * @param {Date|string} date
   * @returns {string}
   */
  const getHashKeyFromDate = date => {
    return moment(date).format('YYYY-MM-DD')
  }

  const loadAvailabilityForSelectedDate = selectedDate => {
    return {
      ...availabilityResponseState.current,
      availability: (availabilityResponseState.current.availability || []).filter(availabilityObj => {
        return getHashKeyFromDate(availabilityObj.start) === getHashKeyFromDate(selectedDate)
      }),
    }
  }
  /**
   * @param {Date} startDate
   */
  // This function behaves as expected only in US time zone, because the start and end date of a particular availability in the response
  // will not be the same day in case of time zones other than US. This is one of the limitation that needs to be addressed
  async function loadAvailabilityForMonth(startDate) {
    return getCalendarAvailabilityForTheSelectedMonth(startDate)
      .then(resp => {
        availabilityResponseState.current = resp
        const availableDays = []
        resp.availability.forEach(obj => {
          const start = getHashKeyFromDate(obj.start)
          availableDays.push(start)
        })
        availableDaysInThisMonth.current = availableDays
      })
      .catch(err => {
        console.error('unable to fetch the calendar month availability', err.message)
      })
  }

  return {handleMonthChange, shouldDisableDate, loadAvailabilityForSelectedDate, clearCurrentAvailabilityInfo}
}
