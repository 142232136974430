import React from 'react'
import GenericServicesPage from './GenericServicesPage'
import {VendorServiceTypePersonalTraining} from './ServicesConstants'
import Routes from '../../constants/RouteConstants'

function PersonalTrainingPage() {
  return (
    <GenericServicesPage
      serviceType={VendorServiceTypePersonalTraining}
      title={'Personal Training'}
      createNewCTA={'Book a training session'}
      baseRoute={Routes.PERSONALTRAINING}
      createNewRoute={Routes.PERSONALTRAINING_CREATE}
      viewSingleRoute={Routes.PERSONALTRAINING_VIEW_SINGLE}
    />
  )
}

export default PersonalTrainingPage
