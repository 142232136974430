import React, {useState} from 'react'
import {Grid} from '@material-ui/core'
import PageHeader from '../../PageHeader'
import ClearableInput from '../../ClearableInput'
import RXRButton from '../../RXRButton'
import {PageContainer} from '../../../assets/styles/layout'
import {AntTab, AntTabs} from '../../AntTabs'
import {makeStyles} from '@material-ui/core/styles'
import PromotionsTable from './PromotionsTable'
import {rxrContainerColor} from '../../../assets/styles/color'
import Routes from '../../../constants/RouteConstants'
import {useHistory} from 'react-router-dom'
import PromotionsProvider from './PromotionsContext.js'
import CreateOrEditPromotion from './createOrEditPromotion.js'
import {TAB_ACTIVE} from './PromotionConstants.js'

function PartnersPage(props) {
  const classes = useStyles()
  const [filterTerm, setFilterTerm] = useState('')
  const [tab, setTab] = useState(TAB_ACTIVE)
  const history = useHistory()

  return (
    <PromotionsProvider>
      <div style={PageContainer}>
        <PageHeader
          title={'Promotions'}
          rightControlComponent={
            <RXRButton type={RXRButton.TYPE_LARGE} onClick={() => history.push(Routes.SETTINGS_PROMOTIONS_CREATE)}>
              {'Create new promotion'}
            </RXRButton>
          }
        >
          <Grid container>
            <Grid item lg={6} md={6}>
              <div className={classes.placeSearch}>
                <ClearableInput value={filterTerm} onChange={setFilterTerm} placeholder={'Search by name or service type'} />
              </div>
              <AntTabs value={tab} onChange={(e, t) => setTab(t)}>
                <AntTab label={'Active'} />
                <AntTab label={'Inactive'} />
              </AntTabs>
            </Grid>
          </Grid>
        </PageHeader>
        <div style={{height: '100%'}}>
          <div className={classes.mainContainer}>
            <PromotionsTable filterTerm={filterTerm} currentTab={tab} />
          </div>
        </div>
        <CreateOrEditPromotion />
      </div>
    </PromotionsProvider>
  )
}

const useStyles = makeStyles(theme => ({
  placeSearch: {
    width: '367px',
  },
  mainContainer: {
    backgroundColor: rxrContainerColor,
    flex: 1,
    width: '100%',
    height: '100%',
  },
}))

export default PartnersPage
