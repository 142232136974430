import ActionTypes from '../actions/types'
import {
  CORE_FEATURE_AMENITIES,
  CORE_FEATURE_CLEANINGS,
  CORE_FEATURE_DATA_COLLECTION,
  CORE_FEATURE_DOG_WALKING,
  CORE_FEATURE_KORMAN,
  CORE_FEATURE_MAINTENACE,
  CORE_FEATURE_MASSAGE,
  CORE_FEATURE_PACKAGE_DELIVERY,
  CORE_FEATURE_PAL,
  CORE_FEATURE_PERSONAL_TRAINING,
  CORE_FEATURE_RENT,
  CORE_FEATURE_RXR_VOLUNTEER,
  CORE_FEATURE_SMART_HUB,
  PERMISSION_GROUP_NOT_RESTRICTED,
  PERMISSION_GROUP_HIGHLY_RESTRICTED,
  PERMISSION_GROUP_MODERATELY_RESTRICTED,
  PERMISSION_GROUP_LIGHTLY_RESTRICTED,
  PERMISSION_GROUP_SUPER_ADMIN,
  CORE_FEATURE_ELEVATORS,
} from '../constants/ModelConstants'

import {ALL_PERMISSIONS} from '../constants/AppConstants'

const allCoreFeatures = [
  CORE_FEATURE_AMENITIES,
  CORE_FEATURE_CLEANINGS,
  CORE_FEATURE_PACKAGE_DELIVERY,
  CORE_FEATURE_PAL,
  CORE_FEATURE_MAINTENACE,
  CORE_FEATURE_RENT,
  CORE_FEATURE_PERSONAL_TRAINING,
  CORE_FEATURE_MASSAGE,
  CORE_FEATURE_DOG_WALKING,
  CORE_FEATURE_RXR_VOLUNTEER,
  CORE_FEATURE_KORMAN,
  CORE_FEATURE_SMART_HUB,
  CORE_FEATURE_DATA_COLLECTION,
  CORE_FEATURE_ELEVATORS,
]

const DashboardGroups = [
  PERMISSION_GROUP_NOT_RESTRICTED,
  PERMISSION_GROUP_LIGHTLY_RESTRICTED,
  PERMISSION_GROUP_MODERATELY_RESTRICTED,
  PERMISSION_GROUP_HIGHLY_RESTRICTED,
]

// initially all features start off as enabled
const initialFeatureSettings = allCoreFeatures.reduce((agr, f) => {
  agr[f] = true
  return agr
}, {})

const initialState = {
  permissionGroup: '',
  permissionsObject: ALL_PERMISSIONS.reduce((agr, p) => {
    agr[p] = true
    return agr
  }, {}),
  coreFeatures: initialFeatureSettings,
}

function setDisabledFeatures(state, disabledFeatures) {
  const newState = {...state}
  try {
    // the raw field from dynamo is a string that needs to be parsed
    if (typeof disabledFeatures === 'string') {
      disabledFeatures = JSON.parse(disabledFeatures)
    }
  } catch (err) {
    console.log(err)
  }
  // reset all features to true
  newState.coreFeatures = {...initialFeatureSettings}

  // disable the ones that are in the disabledFeatures array at this property
  if (Array.isArray(disabledFeatures)) {
    disabledFeatures.forEach(feature => {
      newState.coreFeatures[feature] = false
    })
  }
  return newState
}

/**
 * @param {*} state
 * @param {AuthedUser|null} authedUser
 */
function setPermissionObject(state, authedUser) {
  if (!authedUser) {
    return state
  }

  const permissionGroup = authedUser.groups.find(group => DashboardGroups.includes(group))
  const tempPermissionsObject = getPermissionObjectForPermissionGroup(permissionGroup)

  return {...state, permissionGroup: permissionGroup, permissionsObject: tempPermissionsObject}
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.AUTH_SET_AUTHED_USER:
      return setPermissionObject(state, action.payload)
    case ActionTypes.SET_DISABLED_FEATURES:
      return setDisabledFeatures(state, action.payload)
    default:
      return state
  }
}

/**
 * @param {string} group
 */
export function getPermissionObjectForPermissionGroup(group) {
  let tempPermissionsObject = {...initialState.permissionsObject}

  switch (group) {
    case PERMISSION_GROUP_HIGHLY_RESTRICTED:
      tempPermissionsObject.messagesRead = false
    // no break

    // eslint-disable-next-line no-fallthrough
    case PERMISSION_GROUP_MODERATELY_RESTRICTED:
      tempPermissionsObject.socialEventsWrite = false
      tempPermissionsObject.announcementsWrite = false
    // no break

    // eslint-disable-next-line no-fallthrough
    case PERMISSION_GROUP_LIGHTLY_RESTRICTED:
      tempPermissionsObject.messagesWrite = false
      tempPermissionsObject.sensitiveInformationRead = false
      tempPermissionsObject.settingsControl = false
    // no break

    // eslint-disable-next-line no-fallthrough
    case PERMISSION_GROUP_NOT_RESTRICTED:
      // no restrictions
      break

    case PERMISSION_GROUP_SUPER_ADMIN:
      // no restrictions
      break
  }

  return tempPermissionsObject
}
