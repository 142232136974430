import React from 'react'
import {makeStyles} from '@material-ui/core'
import Header from './Header'

const useStyles = makeStyles(theme => ({
  KYRCardContainer: {
    minHeight: '669px',
    minWidth: '400px',
    maxWidth: '400px',
    backgroundColor: '#fafafc',
    position: 'absolute',
    bottom: '11.15%',
    right: '1.89%',
    borderRadius: '10px',
    filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15))',
  }

}))

function KYRGenericCardComponent(props) {
  const classes = useStyles()

    return (
      <div className={classes.KYRCardContainer}>
        <Header residentId={props.residentId} />
        {props.children}
      </div>
    )
}

export default KYRGenericCardComponent
