import {Grid} from '@material-ui/core'
import React from 'react'
import {useHistory} from 'react-router-dom'
import {makeStyles, Typography} from '@material-ui/core'
import RXRButton from '../RXRButton'
import {spaceExtraLarge, spaceMedium, spaceExtraSmall, spaceLarge} from '../../assets/styles/spacing'
import {rxrBlueColor, rxrWhiteColor} from '../../assets/styles/color'
import {useSelector, useDispatch} from 'react-redux'
import {fontSizeExtraLarge, lightFontWeight, fontSizeSmall} from '../../assets/styles/typography'
import {updateAuthenticatedStaffUser} from '../../actions/staffActions'
import SimpleSpinner from '../SimpleSpinner'
import NotificationSettings from './NotificationSettings'
import {useNotificationSettings} from './useNotificationHook'
import Routes from '../../constants/RouteConstants'
import useProfilePictureEditor from './useProfilePictureEditor'
import ProfilePictureEditor from './ProfilePictureEditor'

function AccountPreferences() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const permissionsObject = useSelector(state => state.GroupPermissions.permissionsObject)

  const authedStaffModel = useSelector(state => state.Staff.authedStaffModel)
  const [isSavingChanges, setIsSavingChanges] = React.useState(false)
  const [saveChangesError, setSaveChangesError] = React.useState(null)

  const staffFirstName = authedStaffModel.displayName.split(' ')[0]
  const {
    updateNotificationSettings,
    userHasMadeNotificationChanges,
    cancelNotificationPreferences,
    ...notificationSettingsComponentProps
  } = useNotificationSettings()

  const {
    stagedImage,
    setStagedImage,
    cancelImageUpload,
    userHasMadeChangesToProfileImage,
    ...profilePictureEditorProps
  } = useProfilePictureEditor()

  const handleSave = async () => {
    try {
      setIsSavingChanges(true)
      await updateStaff()
      await updateNotificationSettings()
      setStagedImage(null)
      setIsSavingChanges(false)
      sessionStorage.removeItem('showAccountPreferences')
      history.push(Routes.COMMAND_CENTER)
    } catch (err) {
      console.error('Error saving changes:', err)
      setSaveChangesError(`Failed to save changes. Error: ${err}`)
      setStagedImage(null)
      setIsSavingChanges(false)
    }
  }

  const updateStaff = async () => {
    const userHasMadeChangesToProfileImageOrDisplayName = userHasMadeChangesToProfileImage

    // Return early as we do not need to make any changes to the staff record
    if (!userHasMadeChangesToProfileImageOrDisplayName) {
      return
    }

    const updateGroupUserInput = {
      staffId: authedStaffModel.id,
    }
    if (stagedImage) updateGroupUserInput.profileImage = stagedImage

    await updateAuthenticatedStaffUser(dispatch, updateGroupUserInput)
  }

  const handleCancel = () => {
    cancelNotificationPreferences()
    cancelImageUpload()
    sessionStorage.removeItem('showAccountPreferences')
    history.push(Routes.COMMAND_CENTER)
  }

  const userHasMadeChanges =
    (stagedImage &&
      (authedStaffModel.profileImage ? JSON.stringify(authedStaffModel.profileImage) : authedStaffModel.profileImage) !==
        JSON.stringify(stagedImage)) ||
    userHasMadeNotificationChanges

  return (
    <div className={classes.root}>
      {isSavingChanges && (
        <div className={classes.loaderContainer}>
          <SimpleSpinner size={SimpleSpinner.SIZE_X_LARGE} />
        </div>
      )}
      <Grid container item className={classes.mainContainer}>
        <div className={classes.contentCotainer}>
          <div className={classes.headerContainer}>
            <Typography className={classes.title}>{`Hi ${staffFirstName}, please set your account preferences.`}</Typography>
            <Typography className={classes.subHeading}>You will be able to make changes at any time in your profile page.</Typography>
          </div>
          <div className={classes.editorContainer}>
            <div className={classes.profilePictureEditorContainer}>
              <ProfilePictureEditor stagedImage={stagedImage} {...profilePictureEditorProps} />
            </div>
            {permissionsObject.messagesWrite && (
              <React.Fragment>
                <Typography className={classes.subHeadingNotification}>Email Preferences</Typography>
                <div className={classes.notificationSettingsContainer}>
                  <NotificationSettings {...notificationSettingsComponentProps} />
                </div>
              </React.Fragment>
            )}
          </div>
          <Grid container className={classes.footerContainer}>
            <Grid item xs={12} className={classes.skipButtonContainer}>
              <RXRButton
                className={classes.skipButton}
                onClick={() => {
                  handleCancel()
                }}
              >
                Skip
              </RXRButton>
            </Grid>
            <Grid item xs={12} className={classes.saveButtonContainer}>
              <RXRButton type={RXRButton.TYPE_PRIMARY} onClick={handleSave} disabled={!userHasMadeChanges}>
                Save my preferences
              </RXRButton>
            </Grid>
          </Grid>
          {saveChangesError && <div>{saveChangesError}</div>}
        </div>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContainer: {
    minHeight: '635px',
    maxWidth: '870px',
    borderRadius: '12px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: spaceExtraLarge,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: rxrWhiteColor,
    position: 'relative',
  },
  contentCotainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  headerContainer: {}, // div's with this className was added to accomodate styles if the user don't have access to notification Settings
  editorContainer: {}, // div's with this className was added to accomodate styles if the user don't have access to notification Settings
  title: {
    color: rxrBlueColor,
    fontSize: fontSizeExtraLarge,
    fontWeight: lightFontWeight,
  },
  subHeading: {
    fontSize: fontSizeSmall,
    fontWeight: lightFontWeight,
    color: rxrBlueColor,
    marginTop: spaceExtraSmall,
  },

  profilePictureEditorContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: spaceExtraLarge,
  },

  subHeadingNotification: {
    fontSize: fontSizeSmall,
    fontWeight: lightFontWeight,
    color: rxrBlueColor,
    marginTop: 80,
  },

  notificationSettingsContainer: {
    marginTop: spaceMedium,
  },
  footerContainer: {
    marginTop: spaceLarge,
  },
  skipButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    top: '42px',
  },
  skipButton: {
    backgroundColor: 'transparent',
    color: rxrBlueColor,
    border: 'none',
    boxShadow: 'none',
    textDecoration: 'underline',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      textDecoration: 'underline',
    },
  },
  saveButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1500,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
})

export default AccountPreferences
