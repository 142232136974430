import React, {useState, useEffect} from 'react'
import AppAuth from '../../lib/AppAuth'
import {makeStyles} from '@material-ui/core/styles'
import RXRButton from '../RXRButton'
import TextInput from '../TextInput'
import {setAuthenticatedUser} from '../../actions/authActions'
import {useDispatch} from 'react-redux'
import AuthPage from './AuthPage'
import {spaceSmall} from '../../assets/styles/spacing'
import {heading, inputRow} from './authStyles'
import Routes from '../../constants/RouteConstants'
import {useHistory} from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  heading: heading,
  inputRow: inputRow,
  forgotPasswordBtn: {
    fontSize: '0.75rem',
  },
}))

export default function Login() {
  const classes = useStyles()
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState('')
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const handleSubmit = async event => {
    event.preventDefault()
    setIsLoggingIn(true)

    try {
      const user = await AppAuth.Instance().logIn(userName, password)
      if (!user) {
        throw new Error('Not authenticated')
      }
      await setAuthenticatedUser(dispatch, user)
    } catch (err) {
      setLoginError(err.message)
    }

    setIsLoggingIn(false)
  }

  return (
    <AuthPage isLoading={isLoggingIn}>
      <p className={classes.heading}>Sign in to RXO Home</p>
      <div className={classes.inputRow}>
        <TextInput label="User Name" isRequired={true} onChange={setUserName} value={userName} error={!!loginError} />
      </div>
      <div className={classes.inputRow}>
        <TextInput
          label="Password"
          isRequired={true}
          helperText={loginError ? 'Login failed. Contact administrator for help.' : undefined}
          error={!!loginError}
          onChange={setPassword}
          htmlType="password"
          value={password}
        />
        <div style={{textAlign: 'left'}}>
          <RXRButton type={RXRButton.TYPE_TEXT} onClick={() => history.push(Routes.PASSWORD_FORGOT)} className={classes.forgotPasswordBtn}>
            Forgot password?
          </RXRButton>
        </div>
      </div>
      <div className={classes.inputRow} style={{marginTop: spaceSmall}}>
        <RXRButton onClick={handleSubmit} disabled={!password || !userName}>
          Log in
        </RXRButton>
      </div>
    </AuthPage>
  )
}
