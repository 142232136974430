import ActionTypes from '../actions/types'

const initialState = {
  appointmentsLookup: {},
  servicesLookup: {},
}

// --------------------------------------------------------------------------------
// State mutation functions:

function setAllAppointments(state, appointmentsArr) {
  const newLookup = appointmentsArr.reduce((agr, a) => {
    agr[a.id] = a
    return agr
  }, {})
  return {...state, appointmentsLookup: newLookup}
}

function markPaid(state, vendorAppointmentId, paymentId) {
  const newAppointmentsLookup = {...state.appointmentsLookup}
  newAppointmentsLookup[vendorAppointmentId].paymentId = paymentId

  return {...state, appointmentsLookup: newAppointmentsLookup}
}

function setAllServices(state, services) {
  const newLookup = services.reduce((agr, s) => {
    agr[s.id] = s
    return agr
  }, {})
  return {...state, servicesLookup: newLookup}
}

function updateVendorAppointment(state, updatedAppointment) {
  const newAppointmentsLookup = {...state.appointmentsLookup}
  newAppointmentsLookup[updatedAppointment.id] = updatedAppointment

  return {...state, appointmentsLookup: newAppointmentsLookup}
}

// --------------------------------------------------------------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.APPOINTMENTS_UPDATE_ALL:
      return setAllAppointments(state, action.payload)

    case ActionTypes.APPOINTMENTS_MARK_AS_PAID:
      return markPaid(state, action.vendorAppointmentId, action.paymentId)

    case ActionTypes.SERVICES_UPDATE_ALL:
      return setAllServices(state, action.payload)

    case ActionTypes.APPOINTMENTS_UPDATE_SINGLE:
      return updateVendorAppointment(state, action.payload)

    default:
      /* pass through */
      return state
  }
}
