export default {
  GUEST_ENTER_SEARCH_KEYWORDS_CONSTANT: 'Search by resident, guest, or unit number...',
  BTN_SAVE_CHECKIN: 'Save and check in',
  BTN_SAVE: 'Save',
  BTN_CANCEL: 'Cancel',
  BTN_ADD: 'Add new guest',
  BTN_DISCARD_CHANGES: 'Discard changes',
  MEETING_OPTIONS: [
    {value: 'CALL_RESIDENT', label: 'Call resident'},
    {value: 'SEND_TO_UNIT', label: 'Send to unit'},
    {value: 'GIVE_UNIT_ACCESS', label: 'Give access to unit'},
  ],
}
