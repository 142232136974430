import React, {useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Pollable from '../lib/Pollable'
import {loadConversations} from '../actions/messagesActions'
import {loadAmenityReservationsForBuilding} from '../actions/amenitiesActions'
import {loadNudgesAndActionsForBuilding} from '../actions/nudgesActions'
import {loadVendorServicesForBuilding, loadAppointmentsForBuilding} from '../actions/vendorAppointmentsActions'
import {useLocation, matchPath} from 'react-router-dom'
import useSound from 'use-sound'
import newMessageTone from '../assets/audio/new_message.mp3'
import Routes from '../constants/RouteConstants'
import {setBuildingViewing} from '../actions/buildingsActions'
import {CONVERSATION_CLOSED} from '../constants/ModelConstants'
import {isResidentActive} from '../Utils/residentUtils'
import NewMessages from './Overlays/NewMessages'
import {MESSAGES_POLL_TIME_MS} from '../constants/AppConstants'
import {updateChatRoomStatus} from './Messages/ChatContainerQueries'
import {loadBuildingStaffForBuilding} from '../actions/staffActions'
import {selectAuthedUserId} from '../reducers/selectors'

function BackgroundDataSync() {
  const pollables = useRef([])
  const permissionsObject = useSelector(state => state.GroupPermissions.permissionsObject)
  const authedStaffId = useSelector(selectAuthedUserId)
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const residentsLookup = useSelector(state => state.Residents.residentsLookup)
  const latestResidentMessageTimestamp = useSelector(state => state.Messages.latestResidentMessageTimestamp)
  const isMuted = useSelector(state => state.App.muteSounds)
  const previousLastMessageTimestamp = useRef(undefined)
  const dispatch = useDispatch()
  const currentLocation = useLocation()
  const [playNewMessageTone] = useSound(newMessageTone)

  useEffect(() => {
    pollables.current.forEach(p => p.stopPolling())

    if (!activeBuildingId || !authedStaffId) {
      return
    }

    pollables.current = [
      // 2 minute
      new Pollable('AmenityReservations', 120000, () => loadAmenityReservationsForBuilding(dispatch, activeBuildingId)),

      // 2 minute
      new Pollable('RXOAssistant', 120000, () => loadNudgesAndActionsForBuilding(dispatch, activeBuildingId)),

      // 10 minutes
      new Pollable('VendorAppointments', 600000, () => loadAppointmentsForBuilding(dispatch, activeBuildingId)),

      // 10 minutes
      new Pollable('BuildingData', 600000, () => setBuildingViewing(dispatch, activeBuildingId, activeBuildingId)),

      // 1 hour
      new Pollable('StaffUsers', 3600000, () => loadBuildingStaffForBuilding(dispatch, activeBuildingId)),

      // 1 hour
      new Pollable('VendorServices', 3600000, () => loadVendorServicesForBuilding(dispatch, activeBuildingId)),
      // ----- add more pollables here
    ]

    if (permissionsObject.messagesRead) {
      pollables.current.push(
        // refresh messages every 5 seconds
        new Pollable('Messages', MESSAGES_POLL_TIME_MS, async () => {
          const conversations = await loadConversations(dispatch, activeBuildingId)

          // this is a little hacky. The idea is sometimes a resident will become inactive, but still have an open conversation
          // we don't want to include inactive residents in the list, but we also don't want their conversation status counted
          // in the nav panel. Ideally, we'd have some async process that ensures past resident conversations are set to CLOSED
          // but until then, we'll just update it when they come to the Messages page

          // ALSO, this code assumes every conversation that needs to close is a Conversation type (never ChatRoom).
          // Eventually, this assumption will be wrong. At that time we need to refactor 2 things:
          //  1. The lostConversations payload will need to return both ChatRooms and Conversation objects
          //  2. This code should be refactored to use GenericChatRoom models

          const conversationsToClose = conversations.filter(c => {
            const res = residentsLookup[c.conversationResidentId]
            return res && !isResidentActive(res) && c.status !== CONVERSATION_CLOSED
          })
          if (conversationsToClose.length > 0) {
            console.log(`Auto closing ${conversationsToClose.length} conversations from past residents`)
            await Promise.all(
              conversationsToClose.map(con =>
                updateChatRoomStatus(
                  dispatch,
                  {id: con.id, isOnboardingChat: false, chatUsers: [{userId: con.conversationResidentId}]},
                  {userId: authedStaffId},
                  CONVERSATION_CLOSED,
                ),
              ),
            )
          }
        }),
      )
    }

    pollables.current.forEach(p => p.startPolling())
  }, [activeBuildingId, authedStaffId, permissionsObject])

  // This effect controls playing a tone when a new chat message arrives.
  // if we do many more of these, we may want to split it into its own component
  // but I figured it made sense here [for now] because the backgroundDataSync is what initiates requests for new messages
  useEffect(() => {
    if (!permissionsObject.messagesRead) {
      return
    }

    if (
      // if our sounds are not muted
      !isMuted &&
      // if we had a previous value
      typeof previousLastMessageTimestamp.current === 'number' &&
      // and it doesn't match the new value
      previousLastMessageTimestamp.current < latestResidentMessageTimestamp &&
      // and we're not currently reading a conversation
      !matchPath(currentLocation.pathname, {path: Routes.MESSAGES_VIEW_SINGLE})
    ) {
      // play the new message tone
      playNewMessageTone()
    }

    // no matter what, we cache the last value
    previousLastMessageTimestamp.current = latestResidentMessageTimestamp
  }, [latestResidentMessageTimestamp, permissionsObject, currentLocation, isMuted])

  return <NewMessages />
}

export default BackgroundDataSync
