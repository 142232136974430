/*
This code repurposed from https://codesandbox.io/s/popper-with-arrow-58jhe?file=/src/RichTooltip.tsx
*/

import React, {forwardRef, useState} from 'react'
import {Box, ClickAwayListener, Fade, makeStyles, Paper, Popper} from '@material-ui/core'
import PropTypes from 'prop-types'
import {Colors} from '../assets/styles'

const RichTooltip = props => {
  const classes = useStyles(props.style)
  const [arrowRef, setArrowRef] = useState(null)
  return (
    <Popper
      open={props.open}
      anchorEl={props.anchorEl}
      placement={props.placement}
      transition
      className={classes.popper}
      modifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: 'scrollParent',
        },
        arrow: {
          enabled: true,
          element: arrowRef,
        },
      }}
    >
      {({TransitionProps}) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            <ClickAwayListener
              /* @see https://github.com/mui/material-ui/issues/12034 */
              mouseEvent="onMouseUp"
              onClickAway={props.onClose}
            >
              <Paper className={classes.popoverRoot}>
                <span className={classes.arrow} ref={setArrowRef} />
                <Box className={classes.content}>{props.children}</Box>
              </Paper>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  )
}

const arrowTipSize = 24
const arrowBaseSize = arrowTipSize / Math.sqrt(2) /* = width / sqrt(2) = (length of the hypotenuse) */

const useStyles = makeStyles(theme => ({
  popoverRoot: {
    maxWidth: 1000,
  },
  content: style => ({
    padding: theme.spacing(2),
    backgroundColor: style.backgroundColor || Colors.rxrWhiteColor,
  }),
  // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js and https://github.com/mui-org/material-ui/blob/4f2a07e140c954b478a6670c009c23a59ec3e2d4/docs/src/pages/components/popper/ScrollPlayground.js
  popper: {
    zIndex: 100,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: -arrowBaseSize,
      marginLeft: 4,
      marginRight: 4,
      '&::before': {
        transformOrigin: '0 100%',
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: -arrowBaseSize,
      marginLeft: 4,
      marginRight: 4,
      '&::before': {
        transformOrigin: '100% 0',
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: -arrowBaseSize,
      height: arrowTipSize,
      width: arrowBaseSize,
      marginTop: 4,
      marginBottom: 4,
      '&::before': {
        transformOrigin: '100% 100%',
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: -arrowBaseSize,
      height: arrowTipSize,
      width: arrowBaseSize,
      marginTop: 4,
      marginBottom: 4,
      '&::before': {
        transformOrigin: '0 0',
      },
    },
  },
  // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js
  arrow: style => ({
    overflow: 'hidden',
    position: 'absolute',
    width: arrowTipSize,
    height: arrowBaseSize,
    boxSizing: 'border-box',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      boxShadow: theme.shadows[1],
      backgroundColor: style.backgroundColor || Colors.rxrWhiteColor,
      transform: 'rotate(45deg)',
    },
  }),
}))

RichTooltip.propTypes = {
  anchorEl: PropTypes.any,
  placement: PropTypes.string, // one of PopperPlacementType
  onClose: PropTypes.func,
  open: PropTypes.bool,
}

export default RichTooltip
