import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import {constructClassString} from '../../../Utils/objectUtil'
import ResidentProfileImage from '../../ResidentProfiles/ResidentProfileImage'
import useResidentLookup from '../../hooks/useResidentLookup'
import RXRButton from '../../RXRButton'
import {Buttons, Colors, Spacing, Typography} from '../../../assets/styles'

const renewalOptions = [
  {value: 1, label: 'Strongly unlikely'},
  {value: 2, label: 'Unlikely'},
  {value: 3, label: 'Not sure'},
  {value: 4, label: 'Likely'},
  {value: 5, label: 'Strongly likely'},
]

const sentimentOptions = [
  {value: 1, label: 'Very low'},
  {value: 2, label: 'Low'},
  {value: 3, label: 'Average'},
  {value: 4, label: 'High'},
  {value: 5, label: 'Very high'},
]

function ResidentSentimentForecaster(props) {
  const classes = useStyles()
  const {getResident} = useResidentLookup()
  const resident = getResident(props.residentId)

  const [renewalProbability, setRenewalProbability] = useState(0)
  const [sentimentScore, setSentimentScore] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)

  // reset the values if resident id changes
  useEffect(() => {
    setRenewalProbability(0)
    setSentimentScore(0)
  }, [props.residentId])

  const handleOnSubmit = async () => {
    setIsSubmitting(true)
    try {
      await props.onSubmit(props.residentId, renewalProbability, sentimentScore)
      setRenewalProbability(0)
      setSentimentScore(0)
    } catch (err) {
      console.error(err)
    }
    setIsSubmitting(false)
  }

  const handleOnSkip = async () => {
    setIsSubmitting(true)
    try {
      await props.onSkip(props.residentId)
      setRenewalProbability(0)
      setSentimentScore(0)
    } catch (err) {
      console.error(err)
    }
    setIsSubmitting(false)
  }

  return (
    <div className={constructClassString(classes.container, props.className)}>
      <h3 className={classes.prompt}>Based on your perception, what is the resident's overall sentiment and probability of renewal?</h3>
      <div className={classes.content}>
        {!props.compact && (
          <div className={classes.profile}>
            <h4>{resident.displayName}</h4>
            <h5>{resident.occupancy.unit.number}</h5>
            <ResidentProfileImage className={classes.profileImage} residentId={props.residentId} />
          </div>
        )}
        <div className={classes.questions}>
          <ForecasterInterface
            label={'Probability of renewal'}
            onChange={setRenewalProbability}
            value={renewalProbability}
            options={renewalOptions}
          />
          <hr />
          <ForecasterInterface label={'Overall sentiment'} onChange={setSentimentScore} value={sentimentScore} options={sentimentOptions} />
        </div>
      </div>
      <div className={classes.controls}>
        <div style={{flex: props.compact ? 1.3 : 2}}>
          {typeof props.onSkip === 'function' && (
            <RXRButton className={classes.skipButton} type={RXRButton.TYPE_TEXT} onClick={handleOnSkip} isLoading={isSubmitting}>
              {props.skipText || 'Skip'}
            </RXRButton>
          )}
        </div>

        <div className={classes.submitButtonContainer}>
          <RXRButton
            className={classes.submitButton}
            onClick={handleOnSubmit}
            disabled={!(sentimentScore && renewalProbability)}
            isLoading={isSubmitting}
          >
            Submit
          </RXRButton>
        </div>
      </div>
    </div>
  )
}

const IMAGE_SIZE = 80
const BUTTON_HEIGHT = 30
const useStyles = makeStyles(theme => ({
  container: {},
  prompt: {},
  content: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    margin: `${Spacing.spaceMedium}px 0`,

    '& h4': {
      marginTop: 6, // this is to vertically align the name with the first question label
      fontWeight: 'bold',
    },
  },

  profile: {
    marginRight: Spacing.spaceLarge,
  },
  profileImage: {
    height: IMAGE_SIZE,
    width: IMAGE_SIZE,
    marginTop: Spacing.spaceSmall,
  },
  questions: {
    width: '100%',
    flex: 1,
    '& hr': {
      margin: `${Spacing.spaceMedium}px 0`,
    },
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  submitButtonContainer: {
    flex: 5,
    width: '100%',
    textAlign: 'center',
  },
  submitButton: {
    width: '50%',
  },
  skipButton: {
    padding: 0,
  },

  // -------------------------------------

  forecasterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    fontWeight: 'bold',
    width: '100%',
    flex: 1.3,
    display: 'block',
  },

  optionButton: {
    flex: 1,
    height: BUTTON_HEIGHT,
    lineHeight: `${BUTTON_HEIGHT}px`,
    textAlign: 'center',
    border: `1px solid ${Colors.rxrMediumLightGreyColor}`,
    color: Colors.rxrMediumLightGreyColor,
    width: '100%',
    marginLeft: Spacing.spaceSmall,
    borderRadius: BUTTON_HEIGHT / 2,
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      borderColor: Colors.rxrTealColor,
      color: Colors.rxrTealColor,
      backgroundColor: Colors.rxrWhiteColor,
    },
  },

  optionSelected: {
    backgroundColor: `${Colors.rxrTealColor} !important`,
    color: `${Colors.rxrWhiteColor} !important`,
    borderColor: `${Colors.rxrTealColor} !important`,
  },
}))

ResidentSentimentForecaster.propTypes = {
  residentId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSkip: PropTypes.func,
  skipText: PropTypes.string,
  compact: PropTypes.bool,
  className: PropTypes.string,
}

export default ResidentSentimentForecaster

function ForecasterInterface(props) {
  const classes = useStyles()

  return (
    <div className={classes.forecasterContainer}>
      <label className={classes.label}>{props.label}</label>
      {props.options.map(option => {
        return (
          <div
            key={option.value}
            onClick={() => props.onChange(option.value)}
            className={constructClassString(classes.optionButton, {[classes.optionSelected]: props.value === option.value})}
          >
            {option.label}
          </div>
        )
      })}
    </div>
  )
}

ForecasterInterface.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ),
}
