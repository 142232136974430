import React, {useState, useEffect} from 'react'
import {Grid, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {connect, useSelector} from 'react-redux'
import Routes from '../../constants/RouteConstants'
import {compose} from 'redux'
import {matchPath, useLocation, withRouter} from 'react-router-dom'
import AmenityConstants from './AmenitiesConstants'
import DiscardChangesDialog from '../DiscardChangesDialog'
import AmenityForm from './AmenityForm'
import ElevatorForm from './ElevatorForm'
import {RXRButton} from '../RXRButton'
import {createNewAmenity, updateAmenity, getAmenityDetails, deleteClosure, deleteGeneralAvailability} from '../../lib/queries'
import Loader from '../Loader'
import useFormChanged, {NonEmptyValidator, NonEmptyArrayValidator, NumberValidator} from '../hooks/useFormChanged'
import FileHelper from '../../lib/FileHelper'
import SelectInput from '../SelectInput'
import {Typography as TypographyStyle} from './../../assets/styles'
import {CORE_FEATURE_ELEVATORS} from '../../constants/ModelConstants'

const useStyles = makeStyles(theme => ({
  gridstyling: {
    paddingTop: 10,
  },
  formContainer: {
    maxWidth: theme.breakpoints.values.lg,
  },
  cancel: {
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: 16,
    color: '#2E9CCA',
    alignItems: 'center',
    textAlign: 'center',
  },
  cursorHand: {
    cursor: 'pointer',
  },
  headerTitle: {
    ...TypographyStyle.H3Header,
    lineHeight: '22px',
    marginTop: '14px',
    marginBottom: '30px',

    fontSize: 40,
    color: '#0C4067',
    fontWeight: 'bold',
    fontStyle: 'normal',
  },
}))

const RESERVATION_INCREMENT = 15
const NON_ELEVATOR_AMENITY = 'Non-elevator amenities'
const ELEVATOR_AMENITY = 'Elevator'

const EMPTY_STATE = {
  buildingId: null,
  label: '',
  description: '',
  rules: '',
  needsApproval: false,
  attachments: [],
  isVisible: true,
  advancedNoticeCancel: 0,
  advancedNoticeRequest: 0,
  minReservationDuration: 15,
  maxReservationDuration: 180,
  mustAgreeToRules: false,
  ignoreBookInAdvanceForStaff: false,
  reservationPadding: 0,
  maxPartySize: 1,
  maxOccupancy: 1,
  maxConcurrentReservations: 1,
  weeklyReservationLimit: 0,
  reservationIncrement: RESERVATION_INCREMENT,
  generalAvailability: [],
  closures: [],
}

function CreateAmenityPage(props) {
  const classes = useStyles()
  const [formTypeState, setFormTypeState] = useState(NON_ELEVATOR_AMENITY)
  const coreFeatures = useSelector(state => state.GroupPermissions.coreFeatures)

  const {form, setForm, formChanged, resetInitialForm, invalidItems, validateForm} = useFormChanged(
    EMPTY_STATE,
    formTypeState === NON_ELEVATOR_AMENITY
      ? useFormChanged.PropLevelValidation({
          label: NonEmptyValidator,
          description: NonEmptyValidator,
          attachments: NonEmptyArrayValidator,
          advancedNoticeCancel: NumberValidator,
          advancedNoticeRequest: NumberValidator,
          reservationPadding: NumberValidator,
          maxPartySize: NumberValidator,
          maxOccupancy: NumberValidator,
          weeklyReservationLimit: NumberValidator,
          maxConcurrentReservations: NumberValidator,
          generalAvailability: NonEmptyArrayValidator,
        })
      : useFormChanged.PropLevelValidation({
          label: NonEmptyValidator,
          description: NonEmptyValidator,
          generalAvailability: NonEmptyArrayValidator,
        }),
  )

  const currentLocation = useLocation()
  const [editingAmenityId, setEditingAmenityId] = useState(null)
  const [isLoadingAmenityDetails, setIsLoadingAmenityDetails] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  // these will store lists of IDs that need to be deleted on save
  const [availabilitiesToRemove, setAvailabilitiesToRemove] = useState([])
  const [closuresToRemove, setClosuresToRemove] = useState([])

  useEffect(() => {
    const editMatch = matchPath(currentLocation.pathname, {path: Routes.AMENITIES_EDIT})

    if (!props.isCreate && editMatch && editMatch.params.amenityId !== editingAmenityId) {
      setEditingAmenityId(editMatch.params.amenityId)
    } else {
      setEditingAmenityId(null)
    }
  }, [currentLocation, props.isCreate])

  useEffect(() => {
    if (editingAmenityId) {
      setIsLoadingAmenityDetails(true)
      getAmenityDetails(editingAmenityId)
        .then(amenityDetails => {
          setForm(amenityDetails)
          setIsLoadingAmenityDetails(false)
          setFormTypeState(amenityDetails.isOnboardingOnly ? ELEVATOR_AMENITY : NON_ELEVATOR_AMENITY)
        })
        .catch(err => {
          window.alert(err.message)
        })
    }
  }, [editingAmenityId])

  const updateForm = newFormVals => {
    setForm({...form, ...newFormVals})
  }

  const handleRemoveGeneralAvailability = gA => {
    if (gA.id) {
      setAvailabilitiesToRemove([...availabilitiesToRemove, gA.id])
    }
  }

  const handleRemoveClosure = c => {
    if (c.id) {
      setClosuresToRemove([...closuresToRemove, c.id])
    }
  }

  const saveAmenityHandler = async () => {
    if (!validateForm()) {
      return
    }

    setIsSaving(true)

    let input = {
      buildingId: props.activeBuildingId,
      label: form.label,
      description: form.description,
      rules: form.rules,
      mustAgreeToRules: form.mustAgreeToRules,
      ignoreBookInAdvanceForStaff: form.ignoreBookInAdvanceForStaff,
      needsApproval: form.needsApproval,
      attachments: form.attachments.map(a => FileHelper.formatS3ObjectForInput(a)),
      advancedNoticeCancel: form.advancedNoticeCancel,
      advancedNoticeRequest: form.advancedNoticeRequest,
      minReservationDuration: form.minReservationDuration,
      maxReservationDuration: form.maxReservationDuration,
      maxPartySize: form.maxPartySize,
      maxOccupancy: form.maxOccupancy,
      maxConcurrentReservations: form.maxConcurrentReservations,
      weeklyReservationLimit: form.weeklyReservationLimit,
      reservationPadding: form.reservationPadding,
      reservationIncrement: RESERVATION_INCREMENT,
      isVisible: form.isVisible,
      isOnboardingOnly: formTypeState === ELEVATOR_AMENITY ? true : false,
    }

    try {
      if (editingAmenityId) {
        await updateAmenity({...input, id: editingAmenityId}, form.closures, form.generalAvailability)
      } else {
        await createNewAmenity(input, form.closures, form.generalAvailability)
      }

      if (closuresToRemove.length > 0) {
        await Promise.all(closuresToRemove.map(deleteClosure))
      }

      if (availabilitiesToRemove.length > 0) {
        await Promise.all(availabilitiesToRemove.map(deleteGeneralAvailability))
      }

      resetInitialForm(EMPTY_STATE, () => props.history.push(Routes.AMENITIES_MANAGER))
    } catch (er) {
      console.error(er)
      window.alert('Sorry, we could not process this request. Please re-enter the amenity information.')
    }
    setIsSaving(false)
  }

  return isLoadingAmenityDetails || isSaving ? (
    <Loader />
  ) : (
    <React.Fragment>
      <div>
        <div style={{margin: '24px'}}>
          <DiscardChangesDialog hasChanges={formChanged} />
          <div className={classes.formContainer}>
            <Grid container spacing={3} className={classes.formRow}>
              <Grid item>
                <Typography className={classes.headerTitle}>{!editingAmenityId ? 'New amenity' : form.label}</Typography>
              </Grid>
            </Grid>
            {coreFeatures[CORE_FEATURE_ELEVATORS] ? (
              <Grid container spacing={3}>
                <Grid item md={3}>
                  <SelectInput
                    label={'Amenity type'}
                    onChange={v => {
                      setFormTypeState(v)
                    }}
                    options={[
                      {label: NON_ELEVATOR_AMENITY, value: NON_ELEVATOR_AMENITY},
                      {
                        label: ELEVATOR_AMENITY,
                        value: ELEVATOR_AMENITY,
                      },
                    ]}
                    value={formTypeState}
                    isRequired={true}
                    disabled={editingAmenityId}
                  />
                </Grid>
              </Grid>
            ) : null}
            {formTypeState === NON_ELEVATOR_AMENITY ? (
              <AmenityForm
                mode={editingAmenityId ? 'editAmenity' : 'newAmenity'} //may or may not required
                onRemoveGeneralAvailability={handleRemoveGeneralAvailability}
                onRemoveClosure={handleRemoveClosure}
                updateForm={updateForm}
                {...form}
                invalidItems={invalidItems}
              />
            ) : (
              <ElevatorForm
                mode={editingAmenityId ? 'editAmenity' : 'newAmenity'} //may or may not required
                onRemoveGeneralAvailability={handleRemoveGeneralAvailability}
                onRemoveClosure={handleRemoveClosure}
                updateForm={updateForm}
                {...form}
                invalidItems={invalidItems}
              />
            )}
          </div>
          <Grid container spacing={2} alignItems="center" className={classes.gridstyling}>
            <Grid item>
              <RXRButton onClick={() => saveAmenityHandler()}>{AmenityConstants.BTN_SAVE}</RXRButton>
            </Grid>
            <Grid item className={classes.cursorHand}>
              <Typography className={classes.cancel} onClick={() => props.history.push(Routes.AMENITIES_MANAGER)}>
                {AmenityConstants.BTN_CANCEL}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  activeBuildingId: state.Buildings.activeBuildingId,
})

export default compose(
  withRouter,
  connect(mapStateToProps),
)(CreateAmenityPage)
