import React from 'react'
import PropTypes from 'prop-types'
import SimpleSpinner from './SimpleSpinner'
import {MenuItem} from '@material-ui/core'
import {Colors, Typography, RXOTextField} from '../assets/styles'
import {makeStyles} from '@material-ui/core/styles'
import RXRIcon from './RXRIcon'

const useStyles = makeStyles(theme => ({
  selectContainer: {
    width: '100%',
    textAlign: 'left',
    '& .MuiSelect-select': {
      paddingLeft: 16,
      '&:focus': {
        backgroundColor: ({disabled}) => {
          return disabled ? Colors.rxrGreyColor : Colors.rxrWhiteColor
        },
      },
    },

    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& .MuiSelect-iconOutlined': {
      color: ({error, disabled}) => {
        return error ? `${Colors.rxrRedColor}` : disabled ? `${Colors.rxrDarkGreyColor}` : `${Colors.rxrBlackColor}`
      },
    },
  },

  menuItemRoot: {
    color: `${Colors.rxrBlackColor} !important`,
  },

  noBorder: {
    '& fieldset': {
      border: 'none !important',
    },
  },
}))

function SelectInput(props) {
  const onChangeHandler = e => {
    props.onChange(e.target.value)
  }
  const classes = useStyles({error: props.error, disabled: props.disabled})

  return (
    <RXOTextField.FormTextField
      select
      label={props.label}
      required={props.isRequired}
      variant="outlined"
      // if there are no options, material will complain that the value is not in the list of options, so if there are no options assume no value
      value={props.options.length > 0 ? props.value || '' : ''}
      SelectProps={{
        IconComponent: props.isLoading ? SimpleSpinner : p => <RXRIcon {...p} style={{margin: 4}} icon={RXRIcon.ARROW_DOWN} />,
      }}
      onChange={onChangeHandler}
      className={`${classes.selectContainer} ${typeof props.label === 'string' ? '' : classes.noBorder} ${props.className || ''}`}
      InputLabelProps={{
        shrink: true,
      }}
      disabled={props.disabled || props.isLoading || props.options.length === 0}
      helperText={props.helperText}
      error={props.error}
    >
      {props.options.map((o, i) => {
        return (
          <MenuItem
            key={i}
            value={o.value}
            style={o.value === props.value ? Typography.MenuItemSelected : Typography.MenuItemNotSelected}
            disabled={o.value === '' || o.isDisabled} // if no value then disabled it.
            className={classes.menuItemRoot}
          >
            {o.label}
          </MenuItem>
        )
      })}
    </RXOTextField.FormTextField>
  )
}

/**
 * @typedef {Object} SelectOption
 * @property {string} value
 * @property {string} label
 */

SelectInput.propTypes = {
  onChange: PropTypes.func.isRequired, // callback invoked with the selected option value prop
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ),
  value: PropTypes.any, // the value of the SelectOption that is currently selected
  label: PropTypes.string, // to appear above the select input (will cause the select input to appear in a box)
  isRequired: PropTypes.bool, // will append * to label
  className: PropTypes.string, // classes to apply to the select input
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
}

export default SelectInput
