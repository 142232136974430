import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextInput from '../TextInput'
import MultilineTextInput from '../MultilineTextInput'
import SelectInput from '../SelectInput'
import {CATEGORY_LABELS} from './ServiceRequestConstants'
import {convertKeyLabelMapIntoSelectOptions} from '../../Utils/objectUtil'
import {spaceMedium} from '../../assets/styles/spacing'
import SearchResident from '../SearchResident'
import CustomCheckbox from '../CustomCheckbox'

const selectOptions = convertKeyLabelMapIntoSelectOptions(CATEGORY_LABELS)

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  formRow: {
    marginBottom: spaceMedium,
  },
}))

function ServiceRequestForm(props) {
  const classes = useStyles()

  const isViewingExisting = !!props.id

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.formRow}>
        <Grid item xs={12}>
          <SearchResident
            value={props.residentId}
            disabled={isViewingExisting}
            isRequired={true}
            onSelectResident={r => props.updateForm({residentId: r.id})}
            error={props.invalidItems.includes('residentId')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.formRow}>
        <Grid item xs={12}>
          <SelectInput
            disabled={isViewingExisting}
            label={'Category'}
            value={props.category}
            onChange={val => props.updateForm({category: val})}
            options={selectOptions}
            isRequired={true}
            error={props.invalidItems.includes('category')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.formRow}>
        <Grid item xs={12}>
          <CustomCheckbox
            isDisabled={isViewingExisting}
            label={'Has permission to enter'}
            isChecked={props.hasPermissionToEnter}
            onChange={val => props.updateForm({hasPermissionToEnter: val})}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.formRow}>
        <Grid item xs={12}>
          <MultilineTextInput
            isDisabled={isViewingExisting}
            rows={6}
            label="Description"
            placeholder=""
            maxLength={1000}
            onChange={val => props.updateForm({description: val})}
            value={props.description}
            isRequired={true}
            error={props.invalidItems.includes('description')}
          />
        </Grid>
      </Grid>

      {isViewingExisting && (
        <Grid container spacing={3} className={classes.formRow}>
          <Grid item xs={12}>
            <TextInput isDisabled={true} label="Work Order ID" placeholder="" value={props.yardiServiceRequestId} />
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default ServiceRequestForm
