import React, {useContext} from 'react'
import useSharedStyles from '../useSharedStyles'
import {makeStyles} from '@material-ui/core/styles'
import {rxrBlueColor} from '../../../assets/styles/color'
import {spaceMedium} from '../../../assets/styles/spacing'
import {FormComponentPropTypes} from '../onboardingTypes'
import {WhitelabelContext} from '../OnboardingWhitelabel'
import MarkTaskCompletedCTAs from '../FormCTAs/MarkTaskCompletedCTAs'
import {ONBOARDING_STATE_COMPLETED} from '../../../constants/ModelConstants'

function CobuLinkout(props) {
  const sharedClasses = useSharedStyles()
  const classes = useStyles()

  const {cobuLinkout} = useContext(WhitelabelContext)

  const handleLinkOut = () => {
    window.open(cobuLinkout.cobuUrl, '_blank')
  }

  const handleMarkCompleted = () => {
    // update our submission state to completed
    props.onUpdateSubmission({
      ...props.submission,
      state: ONBOARDING_STATE_COMPLETED,
    })
  }

  return (
    <React.Fragment>
      <div className={sharedClasses.bodyContainer}>
        <div className={classes.linkOutCopy}>
          <div>
            This is a read-only requirement and doesn't need staff approval.{' '}
            <a className={classes.linkOut} onClick={handleLinkOut}>
              View Cobu link
            </a>
          </div>
        </div>
      </div>
      {props.submission.state !== ONBOARDING_STATE_COMPLETED && <MarkTaskCompletedCTAs onMarkCompleted={handleMarkCompleted} />}
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  linkOutCopy: {
    display: 'flex',
    alignItems: 'center',
  },
  linkOut: {
    color: rxrBlueColor,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  syncedAttachmentsContainer: {
    marginTop: spaceMedium,
  },
}))

CobuLinkout.propTypes = FormComponentPropTypes

export default CobuLinkout
