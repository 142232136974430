import React from 'react'
import PropTypes from 'prop-types'
import SortableTable, {ColumnConfig} from '../SortableTable'
import {withRouter} from 'react-router-dom'
import moment from 'moment'
import HighlightText from '../HighlightText'
import Routes from '../../constants/RouteConstants'
import {renderCategoryLabelsForBuildingAction, renderActionsLabelForBuildingAction, BUILDING_NUDGE_TYPE_LABELS} from './AssistantConstants'
import useResidentLookup from '../hooks/useResidentLookup'

function LoggedActionTable(props) {
  const {getResident} = useResidentLookup()

  const handleClickLogAction = action => {
    props.history.push(Routes.constructPath(Routes.RXO_ASSISTANT_VIEW_LOG_ACTION, {loggedActionId: action.id}))
  }

  const columns = [
    new ColumnConfig({
      title: 'Unit',
      render: v => <HighlightText search={props.filterTerm}>{getResident(v.residentId).occupancy.unit.number}</HighlightText>,
      comparator: ColumnConfig.residentUnitComparatorGenerator(getResident),
    }),
    new ColumnConfig({
      title: 'Resident',
      render: v => <HighlightText search={props.filterTerm}>{getResident(v.residentId).displayName}</HighlightText>,
      comparator: ColumnConfig.residentNameComparatorGenerator(getResident),
    }),
    new ColumnConfig({
      title: 'Category',
      render: renderCategoryLabelsForBuildingAction,
      comparator: (a, b) => {
        let aVal = renderCategoryLabelsForBuildingAction(a)
        let bVal = renderCategoryLabelsForBuildingAction(b)
        return aVal < bVal ? -1 : aVal > bVal ? 1 : 0
      },
    }),
    new ColumnConfig({
      title: 'Action',
      render: renderActionsLabelForBuildingAction,
      comparator: (a, b) => {
        let aVal = renderActionsLabelForBuildingAction(a)
        let bVal = renderActionsLabelForBuildingAction(b)
        return aVal < bVal ? -1 : aVal > bVal ? 1 : 0
      },
    }),
    new ColumnConfig({
      title: 'Date',
      render: a => moment(a.actionTakenAt).format('MM/DD/YY'),
      comparator: (a, b) => moment(a.actionTakenAt) - moment(b.actionTakenAt),
      isDefaultSort: true,
      sortingDirection: 'desc',
    }),
    new ColumnConfig({
      title: 'Reason',
      render: a => BUILDING_NUDGE_TYPE_LABELS[a.reason] || a.reason,
      comparator: ColumnConfig.simpleComparatorGenerator('reason'),
    }),
  ]

  return (
    <SortableTable downloadFileName={'Logged Actions'} data={props.loggedActions} onClickRow={handleClickLogAction} columns={columns} />
  )
}

LoggedActionTable.propTypes = {
  loggedActions: PropTypes.array.isRequired,
}

export default withRouter(LoggedActionTable)
