// React function that renders an OnboardingTask with a icon on the left, title / description in the middle,
// and optional Click to Review button on the right. The OnboardingTask will be passed in as a prop.

import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {
  ONBOARDING_STATE_COMPLETED_AMENDING_PENDING_APPROVAL,
  ONBOARDING_STATE_COMPLETED_AMENDING_CHANGES_REQUESTED,
} from '../../constants/ModelConstants'
import {rxrOrangeColor, rxrWhiteColor} from '../../assets/styles/color'
import {spaceExtraExtraSmall, spaceExtraSmall} from '../../assets/styles/spacing'
import {fontSizeExtraExtraSmall} from '../../assets/styles/typography'
import {isOnboardingTaskStateCompleted, isOnboardingTaskStatePendingApproval} from '../../Utils/onboardingUtils'
import PropTypes from 'prop-types'
import TaskStateIcon from './TaskStateIcon'
import useSharedStyles from './useSharedStyles'

const useStyles = makeStyles(theme => {
  return {
    button: {
      width: 80,
      height: 15,
      borderRadius: 7.5,
      fontSize: fontSizeExtraExtraSmall,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: rxrOrangeColor,
      color: rxrWhiteColor,
      marginLeft: spaceExtraSmall,
    },
  }
})

const determineIconFromTask = task => {
  const taskForm = task.form
  if (
    isOnboardingTaskStateCompleted(task.state) &&
    ((task.state === ONBOARDING_STATE_COMPLETED_AMENDING_PENDING_APPROVAL ||
      task.state === ONBOARDING_STATE_COMPLETED_AMENDING_CHANGES_REQUESTED) &&
      taskForm &&
      taskForm.submissions &&
      taskForm.submissions.length > 1)
  ) {
    // In the case where there are multiple submissions in the form, we need to render multiple icons
    return (
      <div style={{display: 'flex'}}>
        {taskForm.submissions.map((submission, index) => {
          return (
            <TaskStateIcon
              key={index}
              state={submission.state}
              style={{marginRight: index === taskForm.submissions.length - 1 ? 0 : spaceExtraExtraSmall}}
            />
          )
        })}
      </div>
    )
  }

  return <TaskStateIcon state={task.state} importance={task.importance} />
}

const OnboardingChecklistTask = function(props) {
  const sharedClasses = useSharedStyles()
  const classes = useStyles()

  const task = props.task
  if (!task) {
    return null
  }

  const icon = determineIconFromTask(task)
  const taskIsPendingApproval = isOnboardingTaskStatePendingApproval(task.state)

  return (
    <div className={sharedClasses.taskItemCheckContainer} onClick={() => props.navigateToTask(task)}>
      <div className={sharedClasses.taskItemCheckIcon}>{icon}</div>
      <div className={sharedClasses.taskItemCheckLabel}>{task.label}</div>
      {taskIsPendingApproval && <div className={classes.button}>Click to review</div>}
    </div>
  )
}

OnboardingChecklistTask.propTypes = {
  task: PropTypes.object.isRequired,
  navigateToTask: PropTypes.func.isRequired,
}

export default OnboardingChecklistTask
