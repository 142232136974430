import {RXOTextField, Colors} from '../assets/styles'
import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import SimpleSpinner from './SimpleSpinner'
import {InputAdornment} from '@material-ui/core'
import {spaceExtraSmall} from '../assets/styles/spacing'

const useStyles = makeStyles(theme => ({
  input: props => {
    const retVal = {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input[type=number]': {},
    }

    if (props.small) {
      retVal.padding = spaceExtraSmall
    }

    return retVal
  },
}))

function NumberInput(props) {
  const classes = useStyles(props)
  const label = props.isRequired ? `${props.label} *` : props.label

  const formatValue = useCallback(
    val => {
      val = props.integerOnly ? parseInt(val) : parseFloat(val)

      if (typeof props.min === 'number') {
        val = Math.max(val, props.min)
      }

      if (typeof props.max === 'number') {
        val = Math.min(val, props.max)
      }

      return val
    },
    [props.integerOnly, props.min, props.max],
  )

  const handleChange = useCallback(
    newVal => {
      props.onChange(formatValue(newVal))
    },
    [props.onChange, formatValue],
  )

  const inputProps = {
    min: props.min,
    max: props.max,
    className: classes.input,
  }

  const prefix = props.prefix ? props.prefix : props.showDollarSign ? '$' : undefined
  const suffix = props.suffix ? props.suffix : props.showPercentSign ? '%' : undefined

  return (
    <RXOTextField.FormTextField
      label={label}
      placeholder={props.placeholder}
      variant="outlined"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={inputProps}
      InputProps={{
        startAdornment: prefix && (
          <InputAdornment position="start">
            <span style={{color: Colors.rxrBlueColor}}>{prefix}</span>
          </InputAdornment>
        ),
        endAdornment: props.isLoading ? (
          <InputAdornment position="end">
            <SimpleSpinner />
          </InputAdornment>
        ) : suffix ? (
          <InputAdornment position="end">
            <span style={{color: Colors.rxrBlueColor}}>{suffix}</span>
          </InputAdornment>
        ) : (
          undefined
        ),
      }}
      type="number"
      disabled={props.isDisabled}
      onChange={e => handleChange(formatValue(e.target.value))}
      value={'' + (typeof props.value === 'number' ? props.value : '')}
      helperText={props.helperText}
      error={props.error}
    />
  )
}

NumberInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool, // will append * to label
  integerOnly: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  helperText: PropTypes.string,
  isLoading: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  small: PropTypes.bool,
  error: PropTypes.bool,

  // ------------------------------
  // deprecated props
  showDollarSign: PropTypes.bool,
  showPercentSign: PropTypes.bool,
  // ------------------------------
}

export default NumberInput
