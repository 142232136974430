import React from 'react'
import PropTypes from 'prop-types'
import CustomCheckbox from './CustomCheckbox'

function MultiSelectInput(props) {
  const selectedLookup = (props.value || []).reduce((agr, v) => {
    agr[v] = true
    return agr
  }, {})

  /**
   * @param {SelectOption} o
   * @param {boolean} newVal
   */
  const handleChange = (o, newVal) => {
    if (newVal) {
      // now selected, add it to our list
      props.onChange(Object.keys(selectedLookup).concat(o.value))
    } else {
      // no longer selected, remove it from our list
      props.onChange(Object.keys(selectedLookup).filter(k => k !== o.value))
    }
  }

  return (
    <div className={props.className}>
      {props.options.map(o => (
        <div key={o.label} className={props.optionClass}>
          <CustomCheckbox
            isDisabled={props.isDisabled}
            onChange={newVal => handleChange(o, newVal)}
            isChecked={!!selectedLookup[o.value]}
            label={o.label}
          />
        </div>
      ))}
    </div>
  )
}

MultiSelectInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array, // an array of SelectOptions values
  placeholder: PropTypes.string,
  className: PropTypes.string,
  optionClass: PropTypes.string,
  isDisabled: PropTypes.bool,
}

export default MultiSelectInput
