import {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import Pollable from '../../lib/Pollable'

// 30 seconds
const DEFAULT_POLL_RATE = 30000
/**
 * @param {useBuildingDataRefresh~fetchFunc} fetchDataFunction
 * @param {string?} pollName
 * @return {{
 *   isLoadingData: boolean,
 *   dataLoadedForBuilding: string,
 *   activeBuildingId: string,
 * }}
 */
function useBuildingDataRefresh(fetchDataFunction, pollName) {
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [dataLoadedForBuilding, setDataLoadedForBuilding] = useState(null)
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)

  const pollable = useRef(
    new Pollable(pollName, DEFAULT_POLL_RATE, () => {
      // do nothing, will configure the fetch query when useEffect triggers
    }),
  ).current

  useEffect(() => {
    return () => {
      pollable.stopPolling()
    }
  }, [])

  useEffect(() => {
    if (pollName) {
      pollable.updateQueryFunction(fetchDataFunction)
      pollable.startPolling()
    } else {
      pollable.stopPolling()
    }
  }, [activeBuildingId, pollName])

  useEffect(() => {
    if (activeBuildingId && dataLoadedForBuilding !== activeBuildingId) {
      setDataLoadedForBuilding(activeBuildingId)
      setIsLoadingData(true)
      // TODO: if we need to pass anything here, make sure to add it to the JSDoc below
      //  and double check it plays nice with current implementations
      fetchDataFunction().finally(() => setIsLoadingData(false))
    }
  }, [dataLoadedForBuilding, activeBuildingId])

  return {
    isLoadingData,
    dataLoadedForBuilding,
    activeBuildingId,
  }
}
/**
 * @callback useBuildingDataRefresh~fetchFunc
 */

export default useBuildingDataRefresh
