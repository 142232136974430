import React from 'react'
import {makeStyles, Typography} from '@material-ui/core'
import RXRButton from '../RXRButton'
import {spaceExtraLarge, spaceSmall, spaceLarge, spaceMedium} from '../../assets/styles/spacing'
import {rxrTealColor, rxrBlueColor} from '../../assets/styles/color'
import {useHistory} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import TextInput from '../TextInput'
import {heavyFontWeight} from '../../assets/styles/typography'
import {updateAuthenticatedStaffUser} from '../../actions/staffActions'
import {residentProfileTabHeading} from '../../assets/styles/typography'
import NotificationSettings from './NotificationSettings'
import {useNotificationSettings} from './useNotificationHook'
import Routes from '../../constants/RouteConstants'
import useProfilePictureEditor from './useProfilePictureEditor'
import ProfilePictureEditor from './ProfilePictureEditor'

function StaffProfile() {
  const classes = useStyles()
  const dispatch = useDispatch()

  const history = useHistory()
  const [isSavingChanges, setIsSavingChanges] = React.useState(false)
  const [saveChangesError, setSaveChangesError] = React.useState(null)
  const permissionsObject = useSelector(state => state.GroupPermissions.permissionsObject)

  const authedStaffModel = useSelector(state => state.Staff.authedStaffModel)
  const [stagedDisplayName, setStagedDisplayName] = React.useState(authedStaffModel.displayName)

  const {
    updateNotificationSettings,
    userHasMadeNotificationChanges,
    cancelNotificationPreferences,
    ...notificationSettingsComponentProps
  } = useNotificationSettings()

  const {
    stagedImage,
    setStagedImage,
    cancelImageUpload,
    userHasMadeChangesToProfileImage,
    ...profilePictureEditorProps
  } = useProfilePictureEditor()

  const handleSave = async () => {
    try {
      setIsSavingChanges(true)
      await updateStaff()
      await updateNotificationSettings()
      setStagedImage(null)
      setIsSavingChanges(false)
    } catch (err) {
      console.error('Error saving changes:', err)
      setSaveChangesError(`Failed to save changes. Error: ${err}`)
      setStagedImage(null)
      setIsSavingChanges(false)
    }
  }

  const updateStaff = async () => {
    const userHasMadeChangesToProfileImageOrDisplayName =
      userHasMadeChangesToProfileImage || stagedDisplayName !== authedStaffModel.displayName

    // Return early as we do not need to make any changes to the staff record
    if (!userHasMadeChangesToProfileImageOrDisplayName) {
      return
    }

    const updateGroupUserInput = {
      staffId: authedStaffModel.id,
    }
    if (stagedImage) updateGroupUserInput.profileImage = stagedImage
    if (stagedDisplayName) updateGroupUserInput.displayName = stagedDisplayName

    await updateAuthenticatedStaffUser(dispatch, updateGroupUserInput)
  }

  const handleCancel = () => {
    cancelNotificationPreferences()
    setStagedDisplayName(authedStaffModel.displayName)
    cancelImageUpload()
    sessionStorage.removeItem('showAccountPreferences')
    history.push(Routes.COMMAND_CENTER)
  }

  const userHasMadeChanges =
    (stagedImage &&
      (authedStaffModel.profileImage ? JSON.stringify(authedStaffModel.profileImage) : authedStaffModel.profileImage) !==
        JSON.stringify(stagedImage)) ||
    userHasMadeNotificationChanges ||
    stagedDisplayName !== authedStaffModel.displayName

  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        Profile and Preferences
      </Typography>

      <div className={classes.profilePictureEditorContainer}>
        <ProfilePictureEditor stagedImage={stagedImage} {...profilePictureEditorProps} />
      </div>

      <div>
        <TextInput label="Display Name" value={stagedDisplayName} className={classes.textField} onChange={setStagedDisplayName} />
      </div>
      <div>
        <TextInput label="User Name" value={authedStaffModel.userName} className={classes.textField} isDisabled={true} />
      </div>
      {permissionsObject.messagesWrite && (
        <React.Fragment>
          <Typography className={classes.subHeading}>Email notification settings</Typography>
          <NotificationSettings {...notificationSettingsComponentProps} />
        </React.Fragment>
      )}
      <div className={classes.buttonsContainer}>
        <RXRButton type={RXRButton.TYPE_PRIMARY} onClick={handleSave} isLoading={isSavingChanges} disabled={!userHasMadeChanges}>
          Save changes
        </RXRButton>
        <div className={classes.cancelCTA} onClick={handleCancel}>
          Cancel
        </div>
      </div>
      {saveChangesError && <div>{saveChangesError}</div>}
    </div>
  )
}

const useStyles = makeStyles({
  container: {
    padding: spaceExtraLarge,
  },
  title: {
    color: rxrBlueColor,
    marginBottom: spaceMedium,
  },
  profilePictureEditorContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spaceSmall,
  },
  buttonsContainer: {
    display: 'flex',
    marginTop: spaceMedium,
    alignItems: 'center',
  },
  textField: {
    marginTop: spaceSmall,
    maxWidth: '420px',
  },
  cancelCTA: {
    marginLeft: spaceMedium,
    cursor: 'pointer',
    color: rxrTealColor,
    fontWeight: heavyFontWeight,
  },
  subHeading: {
    ...residentProfileTabHeading,
    marginTop: spaceLarge,
  },
})

export default StaffProfile
