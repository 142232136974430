import React, {useState} from 'react'
import MultiSelectInput from './MultiSelectInput'
import PropTypes from 'prop-types'
import TextInput from './TextInput'
import {Menu} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {spaceSmall} from '../assets/styles/spacing'
import RXRIcon from './RXRIcon'
import {rxrWhiteColor} from '../assets/styles/color'
import {Colors} from '../assets/styles/index'

function SlimMultiSelectInput(props) {
  const classes = useStyles(props)
  const [widthOverride, setWidthOverride] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = e => {
    if (props.isDisabled) {
      return
    }

    setWidthOverride(e.currentTarget.clientWidth)
    setAnchorEl(e.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  let valueLabel = props.valueLabel
  if (typeof valueLabel !== 'string') {
    valueLabel = (props.value || [])
      .map(v => {
        let option = props.options.find(o => o.value === v)
        return option ? option.label : v
      })
      .join(', ')
  }

  return (
    <div className={props.className}>
      <div className={classes.container} onClick={handleClick}>
        <TextInput label={props.label} isDisabled={props.isDisabled} value={valueLabel} error={props.error} />
        <div className={classes.openIconButton}>
          <RXRIcon icon={open ? RXRIcon.ARROW_UP : RXRIcon.ARROW_DOWN} />
        </div>
      </div>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <div style={{width: `${widthOverride}px`}}>
          <MultiSelectInput options={props.options} value={props.value} onChange={props.onChange} className={classes.menuWrapper} />
        </div>
      </Menu>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    backgroundColor: rxrWhiteColor,
    position: 'relative',
  },
  menuContainer: {
    width: '100%',
  },
  menuWrapper: {
    paddingLeft: spaceSmall,
  },
  openIconButton: {
    position: 'absolute',
    top: 19,
    right: 11,
    cursor: 'pointer',
    color: ({error}) => (error ? Colors.rxrRedColor : Colors.rxrBlackColor),
  },
}))

SlimMultiSelectInput.propTypes = {
  ...MultiSelectInput.propTypes,
  label: PropTypes.string,
  valueLabel: PropTypes.string,
  error: PropTypes.bool,
}

export default SlimMultiSelectInput
