import React from 'react'
import LoaderSvg from './../assets/images/Icon/Loader.svg'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'

function SimpleSpinner({className, size}) {
  const classes = useStyles()
  return (
    <img
      alt="Spinner Loading icon"
      src={LoaderSvg}
      className={[classes.root, className].join(' ')}
      style={size && {height: size, width: size}}
    />
  )
}

SimpleSpinner.SIZE_DEFAULT = 24
SimpleSpinner.SIZE_LARGE = 40
SimpleSpinner.SIZE_X_LARGE = 72

const useStyles = makeStyles(theme => ({
  root: {
    opacity: 1,
    height: SimpleSpinner.SIZE_DEFAULT,
    width: SimpleSpinner.SIZE_DEFAULT,
  },
}))

SimpleSpinner.propTypes = {
  size: PropTypes.any,
  className: PropTypes.string,
}

export default SimpleSpinner
