import React, {useContext} from 'react'
import useSharedStyles from '../useSharedStyles'
import {makeStyles} from '@material-ui/core/styles'
import {rxrBlueColor} from '../../../assets/styles/color'
import {spaceMedium} from '../../../assets/styles/spacing'
import {FormComponentPropTypes} from '../onboardingTypes'
import {WhitelabelContext} from '../OnboardingWhitelabel'
import RejectOrAcceptCTAs from '../FormCTAs/RejectOrAcceptCTAs'
import {ONBOARDING_STATE_COMPLETED, ONBOARDING_STATE_CHANGES_REQUESTED} from '../../../constants/ModelConstants'
import RejectOrAcceptWithFileAttacher from '../FormCTAs/RejectOrAcceptWithFileAttacher'

function RentersInsurance_Willough(props) {
  const sharedClasses = useSharedStyles()
  const classes = useStyles()

  const theGuarantorsLink = 'https://www.theguarantors.com/'

  const handleLinkOut = () => {
    window.open(theGuarantorsLink, '_blank')
  }

  return (
    <React.Fragment>
      <div className={sharedClasses.bodyContainer}>
        <div className={classes.linkOutCopy}>
          <div>
            The resident has verified their renter's insurance via the Guarantors. Please visit{' '}
            <a className={classes.linkOut} onClick={handleLinkOut}>
              TheGuarantors
            </a>{' '}
            and verify that the renter's insurance policy meets all requirements.
          </div>
        </div>
      </div>
      <RejectOrAcceptWithFileAttacher submission={props.submission} onUpdateSubmission={props.onUpdateSubmission} />
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  linkOutCopy: {
    display: 'flex',
    alignItems: 'center',
  },
  linkOut: {
    color: rxrBlueColor,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

RentersInsurance_Willough.propTypes = FormComponentPropTypes

export default RentersInsurance_Willough
