import {fontSizeMedium} from '../../assets/styles/typography'
import {makeStyles} from '@material-ui/core/styles'
import {spaceSmall} from '../../assets/styles/spacing'

export const stylesRaw = {
  bodyContainer: {
    flexGrow: 1,
  },
  ctaContainer: {
    marginTop: 'auto',
  },
  CTA: {
    fontSize: fontSizeMedium,
    width: '100%',
  },
  taskItemCheckLabel: {
    height: '100%',
    marginLeft: spaceSmall,
    textDecoration: 'underline',
  },
  taskItemCheckContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 20,
    cursor: 'pointer',
    marginBottom: spaceSmall,
  },
  taskItemCheckIcon: {
    height: '100%',
    flexShrink: 0,
  },
  multipleButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  oneOfTwoCTA: {
    width: '49%',
    fontSize: fontSizeMedium,
  },
}

export default makeStyles(theme => stylesRaw)
