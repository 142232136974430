import React, {useState, useEffect, useRef} from 'react'
import GenericServicesPage from './GenericServicesPage'
import {VendorServiceTypeMassage} from './ServicesConstants'
import Routes from '../../constants/RouteConstants'

function MassagesPage() {
  return (
    <GenericServicesPage
      serviceType={VendorServiceTypeMassage}
      title={'Massages'}
      createNewCTA={'Book a massage'}
      baseRoute={Routes.MASSAGE}
      createNewRoute={Routes.MASSAGE_CREATE}
      viewSingleRoute={Routes.MASSAGE_VIEW_SINGLE}
    />
  )
}

export default MassagesPage
