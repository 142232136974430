import ActionTypes from './types'
import {loadChatRoomsByBuilding, loadConversationsByBuilding} from '../lib/queries'

/**
 * @param {function} dispatch
 * @param {string} filterStr
 */
export const setMessagesFilterString = (dispatch, filterStr) => {
  dispatch({
    type: ActionTypes.MESSAGES_UPDATE_FILTER_ACTION,
    payload: filterStr,
  })
}

/**
 * @param {function} dispatch
 * @param {string }buildingId
 * @return {Promise<Array<*>>}
 */
export async function loadConversations(dispatch, buildingId) {
  let conversations = await loadConversationsByBuilding(buildingId)
  let chatRooms = await loadChatRoomsByBuilding(buildingId)

  dispatch({
    type: ActionTypes.MESSAGES_SYNC_CONVERSATIONS,
    conversations: conversations,
    chatRooms: chatRooms,
  })

  // Note: we just return conversations because the only thing that cares about this function's return value
  // only cares about conversations. if we want to start processing chatRooms as well, we can refactor
  return conversations
}

/**
 * @param {function} dispatch
 * @param {GenericChatMessage} newMessage
 * @return {Promise<*>}
 */
export async function sendNewMessage(dispatch, newMessage) {
  dispatch({
    type: ActionTypes.MESSAGES_CREATED_NEW_MESSAGE,
    payload: newMessage,
  })

  return newMessage
}

/**
 * @param {function} dispatch
 * @param {string} conversationId
 * @param {string?} message
 */
export function saveDraftMessage(dispatch, conversationId, message) {
  dispatch({
    type: ActionTypes.MESSAGES_SET_DRAFT_MESSAGE,
    conversationId: conversationId,
    message: message,
  })
}
