import React, {useState, useEffect, useRef} from 'react'
import {matchPath, useHistory, useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import ChatsList from './ChatsList'
import ChatContainer from './ChatContainer'
import Constant from '../Messages/MessageConstant'
import Routes from '../../constants/RouteConstants'
import PageHeader from '../PageHeader'
import EmptyStateIconAndMessage from '../EmptyStateIconAndMessage'
import {markLastSeenAt} from './ChatContainerQueries'
import RXRIcon from '../RXRIcon'
import OOOButton from './OOOButton'
import {spaceMedium, spaceSmall} from '../../assets/styles/spacing'
import {makeStyles} from '@material-ui/core/styles'
import NotificationsButton from './NotificationsButton'
import {getAuthedUserFromChatRoom, FILTER_CURRENT, FILTER_NONE, FILTER_ONBOARDING} from '../../Utils/chatUtils'
import RXRButton from '../RXRButton'
import {selectAuthedUserId} from '../../reducers/selectors'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },

  sidebarStyle: ({isMobile, isViewingConversation}) =>
    isMobile && isViewingConversation
      ? {display: 'none'}
      : {
          width: isMobile ? '100%' : `400px`,
          height: '100%',
        },

  viewContainer: ({isMobile, isViewingConversation}) =>
    isMobile && !isViewingConversation
      ? {display: 'none'}
      : {
          borderLeft: isMobile ? 'none' : '1px solid rgba(0,0,0,.1)',
          flex: 10,
        },

  controls: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  filterControls: ({isMobile}) => ({
    display: 'flex',
    marginBottom: isMobile ? spaceSmall : spaceMedium,

    '& > button': {
      marginRight: isMobile ? spaceSmall : spaceMedium,
    },
  }),
}))

function Messages() {
  const headerRef = useRef()
  const history = useHistory()
  const location = useLocation()
  const [convoId, setConvoId] = useState(null)
  const [targetMessageId, setTargetMessageId] = useState(null)
  const [filter, setFilter] = useState(FILTER_NONE)
  const authedStaffId = useSelector(selectAuthedUserId)
  const permissionsObject = useSelector(state => state.GroupPermissions.permissionsObject)
  const staffLookup = useSelector(state => state.Staff.staffLookup)
  const isMobile = useSelector(state => state.App.isMobile)

  const focusedConversation = useSelector(state => state.Messages.conversationsLookup[convoId])
  const activeBuilding = useSelector(state => state.Buildings.buildingsLookup[state.Buildings.activeBuildingId])
  const [headerHeight, setHeaderHeight] = useState(PageHeader.MIN_HEIGHT)

  const classes = useStyles({isMobile, isViewingConversation: !!convoId})

  useEffect(() => {
    const viewSingleMatch = matchPath(location.pathname, {path: Routes.MESSAGES_VIEW_SINGLE})
    const viewSingleWithMessageMatch = matchPath(location.pathname, {path: Routes.MESSAGES_VIEW_SINGLE_JUMP_TO_MESSAGE})
    // are we viewing a specific conversation?
    if (viewSingleMatch) {
      // grab the conversation id
      let focusedConversationId = viewSingleMatch.params.conversationId

      // if it's different from the one we were viewing
      if (focusedConversationId !== convoId) {
        // set the conversation id in state
        setConvoId(focusedConversationId)
      }

      if (viewSingleWithMessageMatch) {
        setTargetMessageId(viewSingleWithMessageMatch.params.messageId)
      } else {
        setTargetMessageId(null)
      }
    } else if (convoId !== null) {
      setConvoId(null)
      setTargetMessageId(null)
    }
  }, [location.pathname])

  useEffect(() => {
    if (focusedConversation && authedStaffId) {
      const authedChatUser = getAuthedUserFromChatRoom(focusedConversation, authedStaffId, staffLookup)
      markLastSeenAt(focusedConversation, authedChatUser).catch(err => console.error(err))
    }
  }, [focusedConversation])

  useEffect(() => {
    if (!focusedConversation) {
      return
    }

    // if we're changing to a tab that this current focused conversation is not in, we revert to no-conversation
    if (
      (filter === FILTER_ONBOARDING && !focusedConversation.isOnboardingChat) ||
      (filter === FILTER_CURRENT && focusedConversation.isOnboardingChat)
    ) {
      setConvoId(null)
    }
  }, [filter])

  useEffect(() => {
    if (!headerRef.current) {
      return
    }

    setHeaderHeight(headerRef.current.clientHeight)
  }, [])

  if (!authedStaffId) {
    return null
  }

  if (!permissionsObject.messagesRead) {
    history.push(Routes.PROFILE)
    return null
  }

  return (
    <div style={{height: '100%', overflow: 'hidden'}}>
      <PageHeader
        ref={headerRef}
        title={'Messages'}
        rightControlComponent={
          <div className={classes.controls}>
            <NotificationsButton style={{marginRight: spaceSmall}} />
            <OOOButton />
          </div>
        }
      >
        {!!activeBuilding.onboardingSchema && (
          <div className={classes.filterControls}>
            <RXRButton onClick={() => setFilter(FILTER_NONE)} type={filter === FILTER_NONE ? RXRButton.TYPE_PRIMARY : RXRButton.TYPE_TEXT}>
              All {!isMobile ? 'messages' : ''}
            </RXRButton>
            <RXRButton
              onClick={() => setFilter(FILTER_CURRENT)}
              type={filter === FILTER_CURRENT ? RXRButton.TYPE_PRIMARY : RXRButton.TYPE_TEXT}
            >
              Current {!isMobile ? 'residents' : ''}
            </RXRButton>
            <RXRButton
              onClick={() => setFilter(FILTER_ONBOARDING)}
              type={filter === FILTER_ONBOARDING ? RXRButton.TYPE_PRIMARY : RXRButton.TYPE_TEXT}
            >
              Onboarding {!isMobile ? 'residents' : ''}
            </RXRButton>
          </div>
        )}
      </PageHeader>
      <div className={classes.container} style={{height: `calc(100% - ${headerHeight}px`}}>
        <div className={classes.sidebarStyle}>
          <ChatsList filter={filter} />
        </div>
        <div className={classes.viewContainer}>
          {focusedConversation ? (
            <ChatContainer conversationId={convoId} targetMessageId={targetMessageId} />
          ) : (
            <EmptyStateIconAndMessage message={Constant.MESSAGE_NO_CONTENT} icon={RXRIcon.UNREAD_MESSAGES} />
          )}
        </div>
      </div>
    </div>
  )
}

export default Messages
