import React, {useState, createContext, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {getVendorServicePromotionsByBuilding} from '../../../lib/queries'
import {createVendorServicePromotion, updateVendorServicePromotion} from '../../../lib/queries'

export const PromotionsContext = createContext({})

function PromotionsProvider(props) {
  const [promotionsLookup, setPromotionsLookup] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)

  useEffect(() => {
    if (activeBuildingId) {
      setIsLoading(true)
      getVendorServicePromotionsByBuilding(activeBuildingId)
        .then(p => {
          const lookup = p.reduce((acc, p) => {
            acc[p.id] = p
            return acc
          }, {})
          setPromotionsLookup(lookup)
        })
        .finally(() => setIsLoading(false))
    }
  }, [activeBuildingId])

  /**
   * @param {PromotionRecord} input
   */
  async function createOrEditPromotion(input) {
    let record = {}
    if (!promotionsLookup[input.id]) {
      record = await createVendorServicePromotion(input)
    } else {
      record = await updateVendorServicePromotion(input)
    }
    if (record.id) {
      setPromotionsLookup({...promotionsLookup, [record.id]: record})
    }
  }
  return (
    <PromotionsContext.Provider
      value={{
        promotionsLookup: promotionsLookup,
        isLoading: isLoading,
        createOrEditPromotion: createOrEditPromotion,
      }}
    >
      {props.children}
    </PromotionsContext.Provider>
  )
}

export default PromotionsProvider
