import React, {useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {useLocation, Switch, useHistory} from 'react-router-dom'
import {Route} from 'react-router-dom'
import Login from './components/Auth/Login.js'
import ResetPassword from './components/Auth/ResetPassword'
import CreatePassword from './components/Auth/CreatePassword'
import Messages from './components/Messages/Messages'
import AppAuth from './lib/AppAuth'
import CleaningsPage from './components/Services/CleaningsPage.js'
import DogwalkingPage from './components/Services/DogwalkingPage.js'
import MassagesPage from './components/Services/MassagesPage.js'
import UserProfile from './components/ResidentProfiles/UserProfile'
import ViewEventsPage from './components/Events/ViewEventsPage'
import ViewAnnouncementsPage from './components/Announcements/ViewAnnouncementsPage'
import ViewGuestAuthorizationsPage from './components/GuestAuthorization/ViewGuestAuthorizationsPage'
import './App.css'
import {Grid} from '@material-ui/core'
import Loader from './components/Loader'
import {ThemeProvider} from '@material-ui/core/styles'
import {useTheme} from './theme'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import NavPanel, {MobileNavClosedHeight} from './components/NavPanel'
import {useSelector, useDispatch} from 'react-redux'
import {setAuthenticatedUser} from './actions/authActions'
import Routes from './constants/RouteConstants'
import {rxrLightGreyColor} from './assets/styles/color'

// Components
import BackgroundDataSync from './components/BackgroundDataSync'
import IncomingViewDeliveriesPage from './components/Deliveries/IncomingViewDeliveriesPage'
import OutgoingViewDeliveriesPage from './components/Deliveries/OutgoingViewDeliveriesPage'
import ViewAmenityReservationsPage from './components/Amenities/ViewAmenityReservationsPage'
import CalendarViewPage from './components/Amenities/CalendarViewPage.js'
import ElevatorCalendarPage from './components/Amenities/ElevatorCalendarPage.js'
import AmenityManagerPage from './components/Amenities/AmenityManagerPage'
import CreateAmenityPage from './components/Amenities/CreateAmenityPage'
import KYRWidgetContainer from './components/KnowYourResident/KYRWidgetContainer'
import DashboardUsers from './components/Settings/DashboardUsersPage.js'
import Assistant from './components/RXOAssistant/Assistant'
import ServiceRequestsPage from './components/ServiceRequests/ServiceRequestsPage'
import PersonalTrainingPage from './components/Services/PersonalTrainingPage'
import CommandCenter from './components/CommandCenter/CommandCenter'
import VersionChecker from './components/VersionChecker'
import Onboarding from './components/Onboarding/Onboarding'
import ResidentSentiment from './components/ResidentSentiment/ResidentSentiment'
import PartnersPage from './components/Settings/Partners/PartnersPage'
import PromotionsPage from './components/Settings/Promotions/PromotionsPage.js'
import StaffProfile from './components/AccountPreferences/StaffProfile.js'

import {selectAuthedUserId} from './reducers/selectors'
import {ErrorBoundary} from 'react-error-boundary'
import ErrorBoundaryFallback from './components/ErrorBoundaryFallback'
import ForgotPassword from './components/Auth/ForgotPassword'
import AccountPreferences from './components/AccountPreferences/AccountPreferences.js'

const useStyles = makeStyles(theme => ({
  interfaceContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  content: props => ({
    width: '100%',
    height: '100%',
    background: rxrLightGreyColor,
    overflowY: 'auto',
    paddingTop: props.isMobile ? MobileNavClosedHeight : 0,
  }),
  loader: props => ({
    paddingLeft: props.navPanelWidth,
  }),
}))

// these routes are only accessible to unauthenticated users and are the only routes accessible to unauthenticated users
const UN_AUTHED_ROUTES = [Routes.LOGIN, Routes.PASSWORD_CREATE, Routes.PASSWORD_RESET, Routes.PASSWORD_FORGOT]
// these routes should not show the NavPanel
const HIDE_NAV_PANEL_ROUTES = [Routes.ACCOUNT_PREFERENCES]

function App() {
  const dispatch = useDispatch()
  const authedUserId = useSelector(selectAuthedUserId)
  const activeBuilding = useSelector(state => state.Buildings.buildingsLookup[state.Buildings.activeBuildingId])
  const navPanelWidth = useSelector(state => state.App.navPanelWidth)
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles({navPanelWidth, isMobile})
  const history = useHistory()
  const [isReady, setIsReady] = useState(false)
  const [isLoading, setLoading] = useState(true)

  const {pathname} = useLocation()
  const showAccountPreferences =
    sessionStorage.getItem('showAccountPreferences') && sessionStorage.getItem('showAccountPreferences') === 'true' ? true : false
  useEffect(() => {
    //check if there is a currently authenticated user
    AppAuth.Instance()
      .getAuthenticatedUser()
      .then(user => {
        if (user) {
          return setAuthenticatedUser(dispatch, user)
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        setIsReady(true)
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (!isReady) {
      return
    }

    // if we're authed and on any pages unauthed pages (or root) we go to Comand Center
    if (authedUserId && [...UN_AUTHED_ROUTES, Routes.ROOT].includes(pathname)) {
      if (showAccountPreferences) {
        history.push(Routes.ACCOUNT_PREFERENCES)
      } else {
        history.push(Routes.COMMAND_CENTER)
      }
      // add the new preferences screen over here as of now
    } else if (!authedUserId && !UN_AUTHED_ROUTES.includes(pathname)) {
      history.push(Routes.LOGIN)
    }
  }, [authedUserId, isReady, pathname])

  const [currentTheme, setCurrentTheme] = useTheme()

  if (!isReady) {
    return <Loader />
  }

  const shouldShowPanelAndWidget = !HIDE_NAV_PANEL_ROUTES.includes(pathname)

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={currentTheme}>
        <div className={classes.interfaceContainer}>
          {isLoading && <Loader className={classes.loader} />}
          {!!authedUserId && activeBuilding ? (
            <React.Fragment>
              <VersionChecker />
              {shouldShowPanelAndWidget && <NavPanel />}
              <BackgroundDataSync />
              {!isMobile && shouldShowPanelAndWidget && <KYRWidgetContainer />}
            </React.Fragment>
          ) : null}
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Grid className={classes.content}>
              {/*
            We're using a switch here because we only ever want one to render at a time.
            So the first matching route it finds will be the one that is displayed.
            Because of this, the order is important.
            For best results: place more descriptive routes above less descriptive routes
            For readability: please keep similar routes grouped together
            */}
              <Switch>
                {/* ROOT ----------------------------------------------------------------- */}
                <Route exact path={Routes.ROOT} render={() => <CommandCenter />} />

                {/* LOGIN ----------------------------------------------------------------- */}
                <Route path={Routes.LOGIN} render={() => <Login />} />

                {/* PASSWORD FLOWS ----------------------------------------------------------------- */}
                <Route path={Routes.PASSWORD_RESET} render={() => <ResetPassword />} />
                <Route path={Routes.PASSWORD_CREATE} render={() => <CreatePassword />} />
                <Route path={Routes.PASSWORD_FORGOT} render={() => <ForgotPassword />} />

                {/* ACCOUNT PREFERENCES ------------------------------------------------------- */}
                <Route path={Routes.ACCOUNT_PREFERENCES} render={() => <AccountPreferences />} />

                {/* COMMAND CENTER ----------------------------------------------------------- */}
                <Route path={Routes.COMMAND_CENTER} render={() => <CommandCenter />} />

                {/* SERVICES ----------------------------------------------------------------- */}
                <Route path={Routes.CLEANING} render={() => <CleaningsPage />} />
                <Route path={Routes.DOGWALKING} render={() => <DogwalkingPage />} />
                <Route path={Routes.MASSAGE} render={() => <MassagesPage />} />
                <Route path={Routes.PERSONALTRAINING} render={() => <PersonalTrainingPage />} />

                {/* MESSAGES ----------------------------------------------------------------- */}
                <Route path={Routes.MESSAGES} render={() => <Messages />} />

                {/* EVENTS ----------------------------------------------------------------- */}
                <Route path={Routes.EVENTS} render={() => <ViewEventsPage />} />

                {/* RESIDENT PROFILE ----------------------------------------------------------------- */}
                <Route path={Routes.PROFILE} render={() => <UserProfile />} />

                {/* STAFF PROFILE ----------------------------------------------------------------- */}
                <Route path={Routes.STAFF_PROFILE} render={() => <StaffProfile />} />

                {/* ANNOUNCEMENTS ----------------------------------------------------------------- */}
                <Route path={Routes.ANNOUNCEMENTS} render={() => <ViewAnnouncementsPage />} />

                {/* GUEST AUTH ----------------------------------------------------------------- */}
                <Route path={Routes.GUESTAUTHORIZATION} render={() => <ViewGuestAuthorizationsPage />} />

                {/* DELIVERIES ----------------------------------------------------------------- */}
                <Route path={Routes.DELIVERIES_IN} render={() => <IncomingViewDeliveriesPage />} />
                <Route path={Routes.DELIVERIES_OUT} render={() => <OutgoingViewDeliveriesPage />} />

                {/* SERVICE REQUESTS ----------------------------------------------------------------- */}
                <Route path={Routes.SERVICE_REQUESTS} render={() => <ServiceRequestsPage />} />

                {/* AMENITY ----------------------------------------------------------------- */}
                <Route path={Routes.AMENITIES_RESERVATIONS} render={() => <ViewAmenityReservationsPage />} />
                <Route path={Routes.AMENITIES_CALENDAR_VIEW} render={() => <CalendarViewPage />} />
                <Route path={Routes.ELEVATOR_CALENDAR_VIEW} render={() => <ElevatorCalendarPage />} />
                <Route exact path={Routes.AMENITIES_MANAGER} render={() => <AmenityManagerPage />} />
                <Route exact path={Routes.AMENITIES_CREATE} render={() => <CreateAmenityPage isCreate={true} />} />
                <Route exact path={Routes.AMENITIES_EDIT} render={() => <CreateAmenityPage />} />

                {/* DASHBOARD ----------------------------------------------------------------- */}
                <Route path={Routes.SETTINGS_DASHBOARD_USERS} render={() => <DashboardUsers />} />
                <Route path={Routes.SETTINGS_PARTNERS} render={() => <PartnersPage />} />
                <Route path={Routes.SETTINGS_PROMOTIONS} render={() => <PromotionsPage />} />

                {/* RXO ASSISTANT ----------------------------------------------------------------- */}
                <Route path={Routes.RXO_ASSISTANT} render={() => <Assistant />} />

                {/* ONBOARDING ----------------------------------------------------------------- */}
                <Route path={Routes.ONBOARDING} render={() => <Onboarding />} />

                {/* RESIDENT SENTIMENT ----------------------------------------------------------------- */}
                <Route path={Routes.RESIDENT_SENTIMENT} render={() => <ResidentSentiment />} />
              </Switch>
            </Grid>
          </ErrorBoundary>
        </div>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

export default App
