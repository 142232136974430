import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Grid} from '@material-ui/core'
import DateInputWithArrows from '../DateInputWithArrows'
import {Spacing, Typography as TypographyStyle} from '../../assets/styles'
import MultipleAmenitiesCalendar from './MultipleAmenitiesCalendar'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'

function AllAmenitiesCalendarView(props) {
  const classes = useStyles()
  const [date, setDate] = useState(new Date())

  const amenitiesLookup = useSelector(state => state.Amenities.amenitiesLookup)
  let amenityIds

  if (typeof props.amenitiesFilter === 'function') {
    amenityIds = Object.values(amenitiesLookup)
      .filter(props.amenitiesFilter)
      .map(a => a.id)
  } else {
    amenityIds = Object.keys(amenitiesLookup)
  }

  return (
    <React.Fragment>
      <Grid container className={classes.controlsContainer} alignItems={'center'} justifyContent={'center'}>
        <Grid item sm={12}>
          <DateInputWithArrows onChange={setDate} date={date} />
        </Grid>
      </Grid>
      {amenityIds.length > 0 ? (
        <MultipleAmenitiesCalendar amenityIds={amenityIds} date={date} />
      ) : (
        <div className={classes.noDataMessage}>Select an Amenity to display calendar</div>
      )}
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  controlsContainer: {
    padding: Spacing.spaceLarge,
  },
  noDataMessage: {
    ...TypographyStyle.H3Header,
    fontStyle: 'italic',
    fontWeight: 'normal',
    textAlign: 'center',
    marginTop: '48px',
  },
}))

AllAmenitiesCalendarView.propTypes = {
  amenitiesFilter: PropTypes.func,
}

export default AllAmenitiesCalendarView
