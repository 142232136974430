import React, {useContext} from 'react'
import SortableTable, {ColumnConfig} from '../../SortableTable'
import PropTypes from 'prop-types'
import HighlightText from '../../HighlightText'
import {PartnersContext} from './PartnersContext'
import RouteConstants from '../../../constants/RouteConstants'
import {useHistory} from 'react-router-dom'

function ActivePartnersTable(props) {
  const {partnersLookup, partnerIds} = useContext(PartnersContext)
  const history = useHistory()

  const columns = [
    new ColumnConfig({
      title: 'Partner Name',
      renderPrimitive: e => e.displayName,
      render: (item, c) =>
        props.searchTerm ? <HighlightText search={props.searchTerm}>{c.renderPrimitive(item)}</HighlightText> : c.renderPrimitive(item),
      comparator: ColumnConfig.simpleComparatorGenerator('displayName'),
    }),

    ...STATIC_COLUMNS,
  ]

  let data = partnerIds.map(p => partnersLookup[p])
  if (props.searchTerm) {
    const lowerCaseTerm = props.searchTerm.toLowerCase()
    data = data.filter(p => p.displayName.toLowerCase().includes(lowerCaseTerm))
  }

  return (
    <SortableTable
      onClickRow={p => history.push(RouteConstants.constructPath(RouteConstants.SETTINGS_PARTNERS_VIEW_SINGLE, {partnerId: p.id}))}
      data={data}
      columns={columns}
    />
  )
}

ActivePartnersTable.propTypes = {
  searchTerm: PropTypes.string,
}

export default ActivePartnersTable

// These columns don't change with component state so we can declare them once outside the render cycle

const STATIC_COLUMNS = [
  new ColumnConfig({
    title: 'Is Featured',
    renderPrimitive: e => (e.isFeatured ? 'Yes' : 'No'),
    comparator: ColumnConfig.simpleComparatorGenerator('isFeatured'),
  }),

  new ColumnConfig({
    title: 'CTA Text',
    renderPrimitive: e => e.callToAction,
    comparator: ColumnConfig.simpleComparatorGenerator('callToAction'),
  }),

  new ColumnConfig({
    title: 'App display order',
    renderPrimitive: e => e.order,
    comparator: ColumnConfig.simpleComparatorGenerator('order'),
    isDefaultSort: true,
  }),
]
