import {select, curveLinear} from 'd3'

const LINE_THICKNESS = 2
const DOT_THICKNESS = 4

/**
 * @param {*} canvas
 * @param {*} x
 * @param {*} y
 * @param {string} id
 * @param {Array<DataPoint>} data
 * @param {string} color
 * @param {boolean?} isProjected
 * @param {*} tooltip
 * @param {function} onHover
 * @param {*} verticalLine
 */
export function drawLineToGraph(canvas, x, y, id, data, color, isProjected, tooltip, onHover, verticalLine) {
  const dotClass = `lineDot-${id}`
  const lineClass = `line-${id}`

  const linePathData = generateLinePath(data, x, y)
  const linePath = canvas
    .append('path')
    .attr('class', lineClass)
    .attr('stroke', color)
    .attr('stroke-width', LINE_THICKNESS)
    .attr('fill', 'none')
    .attr('d', linePathData)

  if (isProjected) {
    linePath.attr('stroke-dasharray', '5,5')
  }

  // Dots for defined values
  canvas
    .selectAll(`.${dotClass}`)
    .data(data)
    .enter()
    .filter(d => d.yValue !== null)
    .append('circle')
    .attr('class', dotClass)
    .attr('cx', d => x(d.xValue) + x.bandwidth() / 2)
    .attr('cy', d => y(d.yValue))
    .attr('r', DOT_THICKNESS)
    .style('fill', color)
    .on('mouseover', function(e, v) {
      select(this)
        .transition()
        .duration('50')
        .attr('opacity', '.85')
      if (onHover && typeof onHover === 'function') {
        const content = onHover.call(this, v, tooltip, e)
        tooltip.style('background-color', color)
        tooltip.html(content)
        tooltip.style('display', 'block')
      }
      verticalLine.style('display', 'block')
    })
    .on('mousemove', function(e) {
      const Xpos = Math.ceil(e.target.getBoundingClientRect().x + DOT_THICKNESS / 2)
      tooltip.style('top', e.pageY - 10 + 'px').style('left', Xpos + 10 + 'px')
      verticalLine.style('top', e.pageY - e.layerY + 'px').style('left', Xpos + 'px')
    })
    .on('mouseout', function() {
      select(this)
        .transition()
        .duration('50')
        .attr('opacity', '1')
      tooltip.style('display', 'none')
      verticalLine.style('display', 'none')
    })
}

function generateLinePath(data, x, y) {
  let path = ''
  let lastValidPoint = null

  data.forEach(d => {
    if (d.yValue !== null) {
      if (lastValidPoint !== null) {
        path += `M${x(lastValidPoint.xValue) + x.bandwidth() / 2},${y(lastValidPoint.yValue)} `
        path += `L${x(d.xValue) + x.bandwidth() / 2},${y(d.yValue)} `
      }
      lastValidPoint = d
    }
  })

  return path
}
