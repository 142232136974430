import React from 'react'
import PropTypes from 'prop-types'
import {
  isOnboardingTaskStateChangesRequested,
  isOnboardingTaskStateCompleted,
  isOnboardingTaskStatePendingApproval,
} from '../../Utils/onboardingUtils'
import {rxrBlueColor, rxrMediumGreyColor, rxrOrangeColor, rxrRedColor, rxrStatusGreenColor, rxrWhiteColor} from '../../assets/styles/color'
import {MAP_ONBOARDING_STATE_TO_PLAIN_ENGLISH} from '../../constants/ModelConstants'
import moment from 'moment'
import {spaceMedium, spaceSmall} from '../../assets/styles/spacing'
import {fontSizeSmall} from '../../assets/styles/typography'
import {makeStyles} from '@material-ui/core/styles'
import useStaffLookup from '../hooks/useStaffLookup'
import useResidentLookup from '../hooks/useResidentLookup'

function TaskStatusComponent(props) {
  const classes = useStyles()
  const {getStaff} = useStaffLookup()
  const {getResident} = useResidentLookup()

  let stateMetadataCopy = ''
  if (props.modifiedById) {
    let stateAuthor = getStaff(props.modifiedById)
    if (!stateAuthor.displayName) {
      stateAuthor = getResident(props.modifiedById)
    }

    stateMetadataCopy = `last modified on ${moment(props.modifiedByDate).format('MMMM Do, YYYY, h:mm a')}${
      stateAuthor && stateAuthor.displayName ? ` by ${stateAuthor.displayName}` : ''
    }`
  }

  const dynamicColor = isOnboardingTaskStatePendingApproval(props.state)
    ? rxrOrangeColor
    : isOnboardingTaskStateChangesRequested(props.state)
    ? rxrRedColor
    : isOnboardingTaskStateCompleted(props.state)
    ? rxrStatusGreenColor
    : rxrMediumGreyColor

  return (
    <div className={classes.subTitleContainer}>
      <div className={classes.stateButton} style={{backgroundColor: dynamicColor}}>
        {MAP_ONBOARDING_STATE_TO_PLAIN_ENGLISH[props.state]}
      </div>
      <div className={classes.stateMetadataCopy}>{stateMetadataCopy}</div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  subTitleContainer: {
    display: 'flex',
    marginBottom: spaceMedium,
    alignItems: 'center',
  },
  stateButton: {
    width: 'fit-content',
    paddingRight: spaceSmall,
    paddingLeft: spaceSmall,
    height: '20px',
    borderRadius: '10px',
    color: rxrWhiteColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stateMetadataCopy: {
    marginLeft: spaceSmall,
    color: rxrBlueColor,
    fontSize: fontSizeSmall,
  },
}))

TaskStatusComponent.propTypes = {
  state: PropTypes.string.isRequired,
  modifiedById: PropTypes.string,
  modifiedByDate: PropTypes.string,
}

export default TaskStatusComponent
