import React, {useState} from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import SelectInput from '../SelectInput'
import {makeStyles} from '@material-ui/core/styles'
import {spaceMedium} from '../../assets/styles/spacing'

export default function SingleGeneralAvailabilityInput(props) {
  const classes = useStyles()
  const daysOptions = [
    {
      label: 'Sunday',
      value: '0',
    },
    {
      label: 'Monday',
      value: '1',
    },
    {
      label: 'Tuesday',
      value: '2',
    },
    {
      label: 'Wednesday',
      value: '3',
    },
    {
      label: 'Thursday',
      value: '4',
    },
    {
      label: 'Friday',
      value: '5',
    },
    {
      label: 'Saturday',
      value: '6',
    },
  ]

  let start = moment()
    .startOf('day')
    .format('hh:mm A')
  let end = moment()
    .endOf('day')
    .format('hh:mm A')
  let startTime = moment(start, 'hh:mm A')
  let endTime = moment(end, 'hh:mm A')

  let timeOptions = []
  let time
  let timeValue = 0 // midnight
  // for first option
  time = startTime.add(0, 'm').format('hh:mm A')
  timeOptions.push({
    label: time,
    value: '' + timeValue,
  })
  while (startTime.isBefore(endTime)) {
    let time = startTime.add(props.increment, 'm').format('hh:mm A')
    timeValue = +timeValue + props.increment
    timeOptions.push({
      label: time,
      value: '' + timeValue,
    })
  }

  const [startTimeOptions, setStartTimeOptions] = useState(timeOptions)
  const [endTimeOptions, setEndTimeOptions] = useState(timeOptions)
  const [inValidFields, setInvalidFields] = useState(['dayOfWeek', 'startTime', 'endTime'])

  const handleDayChange = (day, obj) => {
    let newObj = {...obj, dayOfWeek: parseInt(day)}
    props.onChange(newObj)
    setInvalidFields([...inValidFields].filter(e => e !== 'dayOfWeek'))
  }

  const handleStartTimeChange = (startTime, obj) => {
    let newObj = {...obj, startTime: parseInt(startTime)}
    props.onChange(newObj)
    let endTimeOptions = startTimeOptions.filter(t => {
      return parseInt(t.value) > parseInt(startTime)
    })

    setEndTimeOptions(endTimeOptions)
    setInvalidFields([...inValidFields].filter(e => e !== 'startTime'))
  }

  const handleEndTimeChange = (time, obj) => {
    let newObj = {...obj, endTime: parseInt(time)}
    props.onChange(newObj)
    setInvalidFields([...inValidFields].filter(e => e !== 'endTime'))
  }

  return (
    <React.Fragment>
      <SelectInput
        className={classes.gutter}
        onChange={val => {
          handleDayChange(val, props.value)
        }}
        label={'Bookable days'}
        isRequired={props.isRequired}
        options={daysOptions}
        value={'' + props.value.dayOfWeek}
        error={props.error && inValidFields.includes('dayOfWeek')}
      />
      <SelectInput
        className={classes.gutter}
        onChange={val => {
          handleStartTimeChange(val, props.value)
        }}
        label={'Hours open start'}
        isRequired={props.isRequired}
        options={startTimeOptions}
        value={'' + (typeof props.value.startTime === 'number' ? props.value.startTime : '')}
        error={props.error && inValidFields.includes('startTime')}
      />
      <SelectInput
        onChange={val => {
          handleEndTimeChange(val, props.value)
        }}
        label={'Hours open end'}
        isRequired={props.isRequired}
        options={endTimeOptions}
        value={'' + (typeof props.value.endTime === 'number' ? props.value.endTime : '')}
        disabled={typeof props.value.startTime !== 'number'}
        error={props.error && inValidFields.includes('endTime')}
      />
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  gutter: {
    marginRight: spaceMedium,
  },
}))

SingleGeneralAvailabilityInput.propTypes = {
  onChange: PropTypes.func.isRequired, // returns the array of GeneralAvaiability objects
  value: PropTypes.object.isRequired, // an array of GeneralAvailablityModal with `rowId as unique identifire`, `dayOfWeek`, `startTime`, `endTime`)
  increment: PropTypes.number.isRequired, // reservationIncrement (time interval)
}
