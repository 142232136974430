import RXRIcon from '../RXRIcon'
import Routes from '../../constants/RouteConstants'
import {rxrBlueDarkColor} from '../../assets/styles/color'

// ---------------- Primary Record Constants -------------
const PRIMARY_RECORD_CLEANING = 'Cleaning'
const PRIMARY_RECORD_DOG_WALKING = 'DogWalking'
const PRIMARY_RECORD_MASSAGE = 'Massage'
const PRIMARY_RECORD_PERSONAL_TRAINING = 'PersonalTraining'
const PRIMARY_RECORD_SERVICE_REQUEST = 'ServiceRequest'
const PRIMARY_RECORD_AMENITY_RESERVATION = 'AmenityReservation'
const PRIMARY_RECORD_DELIVERY = 'Delivery'
const PRIMARY_RECORD_OUTGOING_DELIVERY = 'OutgoingDelivery'
const PRIMARY_RECORD_PAYMENT = 'Payment'
const PRIMARY_RECORD_INVOICE = 'Invoice'
const PRIMARY_RECORD_GUEST = 'Guest'
const PRIMARY_RECORD_BUILDING_ACTION = 'BuildingAction'

// ---------------- Filter Constants ----------------------

const FILTER_LABEL_CLEANING = 'Cleaning'
const FILTER_LABEL_DOG_WALKING = 'DogWalking'
const FILTER_LABEL_MASSAGE = 'Massage'
const FILTER_LABEL_PERSONAL_TRAINING = 'PersonalTraining'
const FILTER_LABEL_SERVICE_REQUEST = 'ServiceRequest'
const FILTER_LABEL_AMENITY_RESERVATION = 'AmenityReservation'
const FILTER_LABEL_DELIVERY = 'Delivery'
const FILTER_LABEL_PAYMENT = 'Payment' // TODO: unclear if this filter label is in use, because it is not included in MAP_FILTER_LABEL_TO_COPY
const FILTER_LABEL_INVOICE = 'Invoice'
const FILTER_LABEL_GUEST = 'Guest'
const FILTER_LABEL_BUILDING_ACTION = 'BuildingAction'

// ---------------- Vendor Appointment Constants ----------------------

export const VENDOR_APPOINTMENT_TYPE_CLEANING = 'Cleaning'
export const VENDOR_APPOINTMENT_TYPE_DOG_WALKING = 'DogWalking'
export const VENDOR_APPOINTMENT_TYPE_MASSAGE = 'Massage'
export const VENDOR_APPOINTMENT_TYPE_PERSONAL_TRAINING = 'PersonalTraining'

// ------------------ Screens --------------------------------------------

export const SCREEN_SEARCH = 'SCREEN_SEARCH'
export const SCREEN_RESIDENT_PROFILE = 'SCREEN_RESIDENT_PROFILE'
export const SCREEN_EDIT_RESIDENT_PROFILE = 'SCREEN_EDIT_RESIDENT_PROFILE'
export const SCREEN_TIMELINE = 'SCREEN_TIMELINE'
export const SCREEN_GUEST_REGISTRATION = 'SCREEN_GUEST_REGISTRATION'
export const SCREEN_INCOMING_DELIVERY = 'SCREEN_INCOMING_DELIVERY'
export const SCREEN_OUTGOING_DELIVERY = 'SCREEN_OUTGOING_DELIVERY'
export const SCREEN_MAINTENANCE = 'SCREEN_MAINTENANCE'
export const SCREEN_AMENITY_RESERVATION = 'SCREEN_AMENITY_RESERVATION'
export const SCREEN_PAYMENT = 'SCREEN_PAYMENT'
export const SCREEN_CLEANING = 'SCREEN_CLEANING'
export const SCREEN_DOG_WALKING = 'SCREEN_DOG_WALKING'
export const SCREEN_MASSAGE = 'SCREEN_MASSAGE'
export const SCREEN_PERSONAL_TRAINING = 'SCREEN_PERSONAL_TRAINING'
export const SCREEN_BUILDING_ACTION = 'SCREEN_BUILDING_ACTION'

// this is the starting screen -> you can't back out from it
export const ROOT_SCREEN = SCREEN_SEARCH

// ---------------- Maps ----------------------

export const MAP_PRIMARY_RECORD_TO_FILTER_LABEL = {
  [PRIMARY_RECORD_CLEANING]: FILTER_LABEL_CLEANING,
  [PRIMARY_RECORD_DOG_WALKING]: FILTER_LABEL_DOG_WALKING,
  [PRIMARY_RECORD_MASSAGE]: FILTER_LABEL_MASSAGE,
  [PRIMARY_RECORD_PERSONAL_TRAINING]: FILTER_LABEL_PERSONAL_TRAINING,
  [PRIMARY_RECORD_SERVICE_REQUEST]: FILTER_LABEL_SERVICE_REQUEST,
  [PRIMARY_RECORD_AMENITY_RESERVATION]: FILTER_LABEL_AMENITY_RESERVATION,
  [PRIMARY_RECORD_DELIVERY]: FILTER_LABEL_DELIVERY,
  [PRIMARY_RECORD_OUTGOING_DELIVERY]: FILTER_LABEL_DELIVERY,
  [PRIMARY_RECORD_PAYMENT]: FILTER_LABEL_PAYMENT, // TODO: unclear if this filter label is in use, because it is not included in MAP_FILTER_LABEL_TO_COPY
  [PRIMARY_RECORD_INVOICE]: FILTER_LABEL_INVOICE,
  [PRIMARY_RECORD_GUEST]: FILTER_LABEL_GUEST,
  [PRIMARY_RECORD_BUILDING_ACTION]: FILTER_LABEL_BUILDING_ACTION,
}

export const MAP_FILTER_LABEL_TO_COPY = {
  [FILTER_LABEL_CLEANING]: 'Cleaning',
  [FILTER_LABEL_DOG_WALKING]: 'Dog walking',
  [FILTER_LABEL_MASSAGE]: 'Spa services',
  [FILTER_LABEL_PERSONAL_TRAINING]: 'Personal training',
  [FILTER_LABEL_SERVICE_REQUEST]: 'Maintenance',
  [FILTER_LABEL_AMENITY_RESERVATION]: 'Amenity booking',
  [FILTER_LABEL_DELIVERY]: 'Deliveries',
  [FILTER_LABEL_INVOICE]: 'Payments',
  [FILTER_LABEL_GUEST]: 'Guest registration',
  [FILTER_LABEL_BUILDING_ACTION]: 'RXO Assistant',
}

export const MAP_PRIMARY_RECORD_TO_EVENT_CATEGORY_NAME = {
  [PRIMARY_RECORD_DELIVERY]: 'DELIVERY',
  [PRIMARY_RECORD_OUTGOING_DELIVERY]: 'DELIVERY',
  [PRIMARY_RECORD_SERVICE_REQUEST]: 'MAINTENANCE',
  [PRIMARY_RECORD_INVOICE]: 'PAYMENTS',
  [PRIMARY_RECORD_CLEANING]: 'CLEANING',
  [PRIMARY_RECORD_DOG_WALKING]: 'DOG WALKING',
  [PRIMARY_RECORD_MASSAGE]: 'SPA SERVICES',
  [PRIMARY_RECORD_PERSONAL_TRAINING]: 'PERSONAL TRAINING',
  [PRIMARY_RECORD_GUEST]: 'GUEST REGISTRATION',
  [PRIMARY_RECORD_AMENITY_RESERVATION]: 'AMENITY RESERVATION',
  [PRIMARY_RECORD_BUILDING_ACTION]: 'RXO ASSISTANT',
}

export const MAP_PRIMARY_RECORD_TO_SCREENS = {
  [PRIMARY_RECORD_GUEST]: SCREEN_GUEST_REGISTRATION,
  [PRIMARY_RECORD_DELIVERY]: SCREEN_INCOMING_DELIVERY,
  [PRIMARY_RECORD_OUTGOING_DELIVERY]: SCREEN_OUTGOING_DELIVERY,
  [PRIMARY_RECORD_SERVICE_REQUEST]: SCREEN_MAINTENANCE,
  [PRIMARY_RECORD_AMENITY_RESERVATION]: SCREEN_AMENITY_RESERVATION,
  [PRIMARY_RECORD_INVOICE]: SCREEN_PAYMENT,
  [PRIMARY_RECORD_CLEANING]: SCREEN_CLEANING,
  [PRIMARY_RECORD_DOG_WALKING]: SCREEN_DOG_WALKING,
  [PRIMARY_RECORD_MASSAGE]: SCREEN_MASSAGE,
  [PRIMARY_RECORD_PERSONAL_TRAINING]: SCREEN_PERSONAL_TRAINING,
  [PRIMARY_RECORD_BUILDING_ACTION]: SCREEN_BUILDING_ACTION,
}

export const MAP_PRIMARY_RECORD_TO_ICON = {
  [PRIMARY_RECORD_DELIVERY]: {
    icon: RXRIcon.DELIVERIES,
    color: '#00A1BD',
  },
  [PRIMARY_RECORD_OUTGOING_DELIVERY]: {
    icon: RXRIcon.DELIVERIES,
    color: '#00A1BD',
  },
  [PRIMARY_RECORD_SERVICE_REQUEST]: {
    icon: RXRIcon.MAINTENANCE,
    color: '#31CAE4',
  },
  [PRIMARY_RECORD_INVOICE]: {
    icon: RXRIcon.HOME,
    color: '#A9C7DD',
  },
  [PRIMARY_RECORD_CLEANING]: {
    icon: RXRIcon.CLEANING,
    color: rxrBlueDarkColor,
  },
  [PRIMARY_RECORD_DOG_WALKING]: {
    icon: RXRIcon.DOG_WALKING,
    color: rxrBlueDarkColor,
  },
  [PRIMARY_RECORD_MASSAGE]: {
    icon: RXRIcon.MASSAGE,
    color: rxrBlueDarkColor,
  },
  [PRIMARY_RECORD_PERSONAL_TRAINING]: {
    icon: RXRIcon.PERSONAL_TRAINING,
    color: rxrBlueDarkColor,
  },
  [PRIMARY_RECORD_GUEST]: {
    icon: RXRIcon.GUEST_AUTH,
    color: '#A9C7DD',
  },
  [PRIMARY_RECORD_AMENITY_RESERVATION]: {
    icon: RXRIcon.AMENITIES,
    color: '#7B87D5',
  },
  [PRIMARY_RECORD_BUILDING_ACTION]: {
    icon: RXRIcon.RXO_ASSISTANT,
    color: '#B062B2',
  },
}

export const MAP_PRIMARY_RECORD_TO_ROUTE_CONSTANTS = {
  [PRIMARY_RECORD_DELIVERY]: Routes.PROFILE_TIMELINE_INCOMING_DELIVERY,
  [PRIMARY_RECORD_OUTGOING_DELIVERY]: Routes.PROFILE_TIMELINE_OUTGOING_DELIVERY,
  [PRIMARY_RECORD_CLEANING]: Routes.PROFILE_TIMELINE_CLEANING,
  [PRIMARY_RECORD_DOG_WALKING]: Routes.PROFILE_TIMELINE_DOG_WALKING,
  [PRIMARY_RECORD_MASSAGE]: Routes.PROFILE_TIMELINE_MASSAGE,
  [PRIMARY_RECORD_PERSONAL_TRAINING]: Routes.PROFILE_TIMELINE_PERSONAL_TRAINING,
  [PRIMARY_RECORD_GUEST]: Routes.PROFILE_TIMELINE_GUEST_REGISTRATION,
  [PRIMARY_RECORD_AMENITY_RESERVATION]: Routes.PROFILE_TIMELINE_AMENITY_RESERVATION,
  [PRIMARY_RECORD_SERVICE_REQUEST]: Routes.PROFILE_TIMELINE_MAINTENANCE,
  [PRIMARY_RECORD_BUILDING_ACTION]: Routes.PROFILE_TIMELINE_BUILDING_ACTION,
}

// --------------- Filter Copy Export --------------------------

export const FILTER_COPY = Object.keys(MAP_FILTER_LABEL_TO_COPY)
