import awsConfig from '../aws-exports'

const PRODUCTION_ENVIRONMENTS = [
  'master', // probably
  'production', // probably
  'prod', // rex app
  'brprod', // bookable-resource app
]
/**
 * @param {*} awsConfig
 * @returns {string}
 */
const getEnvironmentFromAWSConfig = awsConfig => {
  let bucket = awsConfig && awsConfig.aws_content_delivery_bucket
  let configuredEnv = bucket && bucket.replace(/^.*hostingbucket-(\w+)$/, '$1')

  if (!configuredEnv) {
    throw new Error('Could not get environment from awsConfig')
  }

  return configuredEnv
}

/**
 * @returns {boolean}
 */
export const isProductionEnvironment = () => {
  let environment
  if (typeof awsConfig === 'object') {
    environment = getEnvironmentFromAWSConfig(awsConfig)
  } else {
    environment = awsConfig
  }

  return PRODUCTION_ENVIRONMENTS.includes(environment)
}
