import React from 'react'
import ReadOnlyTask from '../ReadOnlyTask'
import {FormComponentPropTypes} from '../onboardingTypes'

function MovingServices(props) {
  return <ReadOnlyTask {...props} />
}

MovingServices.propTypes = FormComponentPropTypes

export default MovingServices
