import React from 'react'
import PropTypes from 'prop-types'
import VendorAppointment from './VendorAppointment'

function PersonalTraining(props) {
  return (
    <VendorAppointment
      vendorAppointmentId={props.vendorAppointmentId}
      frequencyLabel={'One-time personal training'}
      label={'Personal training type'}
      timeframe={'Personal training timeframe'}
    />
  )
}

PersonalTraining.propTypes = {
  vendorAppointmentId: PropTypes.string.isRequired,
}

export default PersonalTraining
