import React from 'react'
import {IconButton} from '@material-ui/core'
import {rxrMidBlue} from '../../../assets/styles/color'
import SubHeader from '../SubHeader'
import KYRGenericCardComponent from '../KYRGenericCardComponent'
import ResidentProfile from '../ResidentProfile'
import AbstractKYRScreen from './AbstractKYRScreen'
import {withStyles} from '@material-ui/core'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {navigateToScreen} from '../../../actions/kyrWidgetActions'
import {SCREEN_EDIT_RESIDENT_PROFILE} from '../KYRConstants'
import RXRIcon from '../../RXRIcon'
import {Colors} from '../../../assets/styles'
import {iconButton} from '../../../assets/styles/buttons'

const styles = theme => ({
  headerContainer: {
    width: '400px',
    backgroundColor: rxrMidBlue,
    minHeight: '154px',
    borderRadius: '10px 10px 0px 0px',
    boxShadow: '0px 1px 18px rgba(151, 151, 151, 0.2)',
    justifyContent: 'space-around',
  },
  buttonIconOutline: {
    ...iconButton,
    width: RXRIcon.BUTTON_SIZE_LARGE,
    height: RXRIcon.BUTTON_SIZE_LARGE,
  },
})

class ResidentProfileComponent extends AbstractKYRScreen {
  rightIconClick = () => {
    this.props.dispatchNavigateToEditResidentProfile(this.props.navParams.residentId)
  }

  render() {
    const RightIconComponent = () => {
      return (
        <IconButton className={this.props.classes.buttonIconOutline} onClick={this.rightIconClick.bind(this)}>
          <RXRIcon icon={RXRIcon.EDIT_PROFILE} color={Colors.rxrBlueColor} />
        </IconButton>
      )
    }

    return (
      <KYRGenericCardComponent residentId={this.props.navParams.residentId}>
        <SubHeader title={'Resident Profile'} RightIconComponent={RightIconComponent} />
        <ResidentProfile residentId={this.props.navParams.residentId} />
      </KYRGenericCardComponent>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchNavigateToEditResidentProfile: residentId => navigateToScreen(dispatch, SCREEN_EDIT_RESIDENT_PROFILE, {residentId: residentId}),
})

export default compose(
  withStyles(styles),
  connect(
    undefined,
    mapDispatchToProps,
  ),
)(ResidentProfileComponent)
