export const GIFT_PREFERENCE_BABY_CLOTHES = 'baby_clothes'
export const GIFT_PREFERENCE_BALLOONS = 'balloons'
export const GIFT_PREFERENCE_BOOKS = 'books'
export const GIFT_PREFERENCE_CANDLES = 'candles'
export const GIFT_PREFERENCE_COFFEE_MUGS = 'coffee_mugs'
export const GIFT_PREFERENCE_FLOWERS = 'flowers'
export const GIFT_PREFERENCE_GLASSWARE = 'glassware'
export const GIFT_PREFERENCE_PICTURE_FRAME = 'picture_frame'
export const GIFT_PREFERENCE_PLANT = 'plant'
export const GIFT_PREFERENCE_GIFT_CARD = 'gift_card'
export const GIFT_PREFERENCE_TEDDY_BEAR = 'teddy_bear'
export const GIFT_PREFERENCE_TOYS = 'toys'

export const GIFT_PREFERENCES_LABELS = {
  [GIFT_PREFERENCE_BABY_CLOTHES]: 'Baby clothes',
  [GIFT_PREFERENCE_BALLOONS]: 'Balloons',
  [GIFT_PREFERENCE_BOOKS]: 'Books',
  [GIFT_PREFERENCE_CANDLES]: 'Candles',
  [GIFT_PREFERENCE_COFFEE_MUGS]: 'Coffee mugs',
  [GIFT_PREFERENCE_FLOWERS]: 'Flowers',
  [GIFT_PREFERENCE_GLASSWARE]: 'Glassware',
  [GIFT_PREFERENCE_PICTURE_FRAME]: 'Picture frame',
  [GIFT_PREFERENCE_PLANT]: 'Plant',
  [GIFT_PREFERENCE_GIFT_CARD]: 'Gift card',
  [GIFT_PREFERENCE_TEDDY_BEAR]: 'Teddy bear',
  [GIFT_PREFERENCE_TOYS]: 'Toys',
}

export const GIFT_PREFERENCES_OPTIONS = Object.entries(GIFT_PREFERENCES_LABELS).map(([value, label]) => ({label, value}))

export const FOOD_PREFERENCE_FRUIT = 'fruit'
export const FOOD_PREFERENCE_CAKE = 'cake'
export const FOOD_PREFERENCE_CHOCOLATE = 'chocolate'
export const FOOD_PREFERENCE_COFFEE = 'coffee'
export const FOOD_PREFERENCE_GIFT_CARD = 'food_gift_card'
export const FOOD_PREFERENCE_JUICE = 'juice'
export const FOOD_PREFERENCE_CANDY = 'candy'
export const FOOD_PREFERENCE_SALTY_SNACKS = 'salty_snacks'
export const FOOD_PREFERENCE_TEA = 'tea'

export const FOOD_PREFERENCES_LABELS = {
  [FOOD_PREFERENCE_FRUIT]: 'Basket of fruit',
  [FOOD_PREFERENCE_CAKE]: 'Cake',
  [FOOD_PREFERENCE_CHOCOLATE]: 'Chocolate',
  [FOOD_PREFERENCE_COFFEE]: 'Coffee',
  [FOOD_PREFERENCE_GIFT_CARD]: 'Food gift card',
  [FOOD_PREFERENCE_JUICE]: 'Juice',
  [FOOD_PREFERENCE_CANDY]: 'Candy',
  [FOOD_PREFERENCE_SALTY_SNACKS]: 'Salty snacks',
  [FOOD_PREFERENCE_TEA]: 'Tea',
}

export const FOOD_PREFERENCES_OPTIONS = Object.entries(FOOD_PREFERENCES_LABELS).map(([value, label]) => ({label, value}))

export const ALCOHOL_PREFERENCE_NONE = 'no_alcohol'
export const ALCOHOL_PREFERENCE_BEER = 'beer'
export const ALCOHOL_PREFERENCE_CHAMPAGNE = 'champagne'
export const ALCOHOL_PREFERENCE_GIN = 'gin'
export const ALCOHOL_PREFERENCE_OTHER = 'other_liquor'
export const ALCOHOL_PREFERENCE_RED_WINE = 'red_wine'
export const ALCOHOL_PREFERENCE_ROSE = 'rose'
export const ALCOHOL_PREFERENCE_RUM = 'rum'
export const ALCOHOL_PREFERENCE_TEQUILA = 'tequila'
export const ALCOHOL_PREFERENCE_VODKA = 'vodka'
export const ALCOHOL_PREFERENCE_WHISKEY = 'whiskey'
export const ALCOHOL_PREFERENCE_WHITE_WINE = 'white_wine'

export const ALCOHOL_PREFERENCES_LABELS = {
  [ALCOHOL_PREFERENCE_NONE]: 'No alcohol',
  [ALCOHOL_PREFERENCE_BEER]: 'Beer',
  [ALCOHOL_PREFERENCE_CHAMPAGNE]: 'Champagne',
  [ALCOHOL_PREFERENCE_GIN]: 'Gin',
  [ALCOHOL_PREFERENCE_OTHER]: 'Other liquor',
  [ALCOHOL_PREFERENCE_RED_WINE]: 'Red wine',
  [ALCOHOL_PREFERENCE_ROSE]: 'Rose',
  [ALCOHOL_PREFERENCE_RUM]: 'Rum',
  [ALCOHOL_PREFERENCE_TEQUILA]: 'Tequila',
  [ALCOHOL_PREFERENCE_VODKA]: 'Vodka',
  [ALCOHOL_PREFERENCE_WHISKEY]: 'Whiskey',
  [ALCOHOL_PREFERENCE_WHITE_WINE]: 'White wine',
}

export const ALCOHOL_PREFERENCES_OPTIONS = Object.entries(ALCOHOL_PREFERENCES_LABELS).map(([value, label]) => ({label, value}))

export const NO_RESIDENT_CONTENT = 'Search for a resident in the search bar to view their profile and timeline.'
export const RESIDENT_SEARCH_PLACEHOLDER = 'Search by resident name or keyword'
export const RESIDENT_DETAILS = 'Resident details'
export const UNIT_DETAILS = 'Unit details'
export const RENT_DETAILS = 'Rent details'
export const RESIDENT_PREFERENCES = 'Resident preferences'
export const RESIDENT_MAINTENANCE = 'Maintenance'
export const RESIDENT_CLEANING = 'Cleaning'
export const RESIDENT_PACKAGE_DELIVERY = 'Package delivery'
export const RESIDENT_THIRD_PARTY_SERVICE_PROVIDER = '3rd party service provider'
export const BTN_POST = 'Submit'
export const BTN_UPLOAD = 'Upload image'
export const BTN_MAKE_IN_ACTIVE = 'Make inactive'
export const ALL_FIELDS_REQUIRED = '* All starred fields are required.'
export const BTN_SAVE_CHANGES = 'Save changes'
export const BTN_DISCARD_CHANGES = 'Discard changes'
export const LBL_PACKAGES = 'Packages'
export const LBL_PTE = 'PTE'
export const BTN_CANCEL = 'Cancel'
export const TAB_RESIDENT_PROFILE = 0
export const TAB_TIMELINE = 1
export const TAB_PETS = 2
