import {matchPath, useLocation} from 'react-router-dom'
import {useEffect} from 'react'

// TODO: There are still a few components using a similar pattern, but they have some special cases that need to be handled
//  search for "// USES THE VIEW_SINGLE / CREATE LOAD PATTERN" to find them and refactor some day

/**
 * @param {string} createRoute
 * @param {string} viewRoute
 * @param {string} viewRouteParamName
 * @param {*} emptyForm
 * @param {*} currentForm
 * @param {function} resetFormFunction
 * @param {Array<*>|*} data
 * @param {function} itemToFormFunction
 * @param {boolean} isLoadingData
 * @param {function?} onFocusedItem
 * @return {{
 *   isCreateMode: boolean,
 *   focusedItemId: string,
 * }}
 */
function useListCreateViewPattern(
  createRoute,
  viewRoute,
  viewRouteParamName,
  emptyForm,
  currentForm,
  resetFormFunction,
  data,
  itemToFormFunction,
  isLoadingData,
  onFocusedItem,
) {
  const currentLocation = useLocation()

  let focusedItemId
  let isCreateMode = false
  let isViewSingleMode = false
  let createMatch = matchPath(currentLocation.pathname, {path: createRoute})
  let viewSingleMatch = matchPath(currentLocation.pathname, {path: viewRoute})

  if (createMatch && createMatch.isExact) {
    isCreateMode = true
  } else if (viewSingleMatch && viewSingleMatch.isExact) {
    isViewSingleMode = true
    focusedItemId = viewSingleMatch.params[viewRouteParamName]
  }

  // USES THE VIEW_SINGLE / CREATE LOAD PATTERN
  useEffect(() => {
    if ((!focusedItemId && currentForm.id) || (!focusedItemId && !isCreateMode)) {
      // reset the form
      resetFormFunction(emptyForm)
    } else if (focusedItemId && currentForm.id !== focusedItemId) {
      const item = Array.isArray(data) ? data.find(g => g.id === focusedItemId) : data[focusedItemId]
      if (item) {
        if (typeof onFocusedItem === 'function') {
          onFocusedItem(item)
        }
        let initialForm = itemToFormFunction(item)
        resetFormFunction(initialForm)
      }
    }
  }, [data, focusedItemId, isCreateMode, isLoadingData])

  return {
    isCreateMode,
    isViewSingleMode,
    focusedItemId,
  }
}

export default useListCreateViewPattern
