import React, {useEffect, useRef, useState} from 'react'
import TextInput from '../TextInput'
import RXRButton from '../RXRButton'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import {inputRow} from './authStyles'
import {passwordIsAcceptable, STATE_ERROR, STATE_SENDING, STATE_SENT} from './authConstants'
import AppAuth from '../../lib/AppAuth'
import {Colors} from '../../assets/styles'
import {useLocation} from 'react-router-dom'
import SimpleSpinner from '../SimpleSpinner'

function ChangePasswordInputs(props) {
  const classes = useStyles()
  const {search} = useLocation()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [confirmError, setConfirmError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const queryParams = useRef(null)
  const [resetError, setResetError] = useState('')
  const [staffUser, setStaffUser] = useState(null)

  useEffect(() => {
    queryParams.current = new URLSearchParams(search)
    AppAuth.Instance()
      .validatePasswordResetToken(queryParams.current.get('staffId'), queryParams.current.get('token'))
      .then(s => {
        setStaffUser(s)
      })
      .catch(err => {
        setResetError(err.message)
        props.onStateChange(STATE_ERROR)
      })
  }, [search])

  useEffect(() => {
    setConfirmError('')
  }, [confirmPassword])

  useEffect(() => {
    setConfirmError('')
    setPasswordError('')
  }, [password])

  const handleResetPassword = () => {
    if (!passwordIsAcceptable(password)) {
      setPasswordError('Password must be at least 8 characters long and contain at least 1 number')
      return
    }
    if (password !== confirmPassword) {
      setConfirmError('Password does not match')
      return
    }

    props.onStateChange(STATE_SENDING)
    AppAuth.Instance()
      .submitNewPassword(queryParams.current.get('staffId'), queryParams.current.get('token'), password)
      .then(() => {
        props.onStateChange(STATE_SENT)
      })
      .catch(err => {
        setResetError(err.message)
        props.onStateChange(STATE_ERROR)
      })
  }

  return (
    <React.Fragment>
      {resetError && (
        <div className={classes.inputRow}>
          <p style={{color: Colors.rxrErrorColor}}>
            ERROR: {resetError}. {!staffUser ? props.errorHelperText : ''}
          </p>
        </div>
      )}
      {staffUser ? (
        <React.Fragment>
          <div className={classes.inputRow}>
            <TextInput isDisabled={true} label="Username" value={staffUser.userName} isReadOnly={true} />
          </div>
          <div className={classes.inputRow}>
            <TextInput
              label="Enter new password"
              value={password}
              onChange={setPassword}
              error={!!passwordError}
              helperText={passwordError}
              htmlType={'password'}
            />
          </div>
          <div className={classes.inputRow}>
            <TextInput
              label="Confirm new password"
              value={confirmPassword}
              onChange={setConfirmPassword}
              error={!!confirmError}
              helperText={confirmError}
              htmlType={'password'}
            />
          </div>
          <div className={classes.inputRow}>
            <RXRButton onClick={handleResetPassword} disabled={!password}>
              {props.ctaLabel}
            </RXRButton>
          </div>
        </React.Fragment>
      ) : !resetError ? (
        // This wrapper is just give a little space for the spinner so it doesn't change size as much once above controls are loaded
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '320px'}}>
          <SimpleSpinner size={SimpleSpinner.SIZE_LARGE} />
        </div>
      ) : null}
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  inputRow: inputRow,
}))

ChangePasswordInputs.propTypes = {
  ctaLabel: PropTypes.string,
  errorHelperText: PropTypes.string,
  onStateChange: PropTypes.func,
}

export default ChangePasswordInputs
