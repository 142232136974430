export const spaceExtraExtraSmall = 4
export const spaceExtraSmall = 9
export const spaceSmall = 12
export const spaceMedium = 24
export const spaceLarge = 36
export const spaceExtraLarge = 48

// TODO: When we formalize our font sizes, these can be tweaked to match
export const iconSizeSmall = 12
export const iconSizeMedium = 18
export const iconSizeLarge = 24
export const iconSizeExtraLarge = 32

export const iconButtonSizeSmall = 24
export const iconButtonSizeMedium = 34
export const iconButtonSizeLarge = 48
export const iconButtonSizeExtraLarge = 60
