import React from 'react'
import PropTypes from 'prop-types'
import SingleBuildingActionInput from './SingleBuildingActionInput'
import GenericManyInput from '../GenericManyInput'

const initialActionModel = {category: '', action: ''}

function ManyBuildingActionsInput(props) {
  return (
    <GenericManyInput
      onChange={props.onChange}
      value={props.value}
      emptyModel={initialActionModel}
      singleInputComponent={SingleBuildingActionInput}
      error={props.error}
    />
  )
}

ManyBuildingActionsInput.propTypes = {
  onChange: PropTypes.func.isRequired, // returns the array of Action objects
  value: PropTypes.array.isRequired, // an array of BuildingActionModels
  error: PropTypes.bool,
}

export default ManyBuildingActionsInput
