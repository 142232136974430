import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {spaceSmall} from '../../assets/styles/spacing'
import {rxrTealColor, rxrBlueColor} from '../../assets/styles/color'
import StaffProfileImage from '../Messages/StaffProfileImage'
import {useSelector} from 'react-redux'

const useStyles = makeStyles({
  profilePictureEditor: {
    display: 'flex',
    alignItems: 'center',
  },
  profileImage: {
    width: '135px',
    height: '135px',
    border: `2px solid ${rxrTealColor}`,
  },
  uploadText: {
    marginLeft: spaceSmall,
    marginTop: spaceSmall,
    cursor: 'pointer',
    color: rxrBlueColor,
    textDecoration: 'underline',
  },
})

function useProfilePictureEditor({stagedImage, handleImageChange, handleClick, fileInputRef}) {
  const classes = useStyles()
  const authedStaffModel = useSelector(state => state.Staff.authedStaffModel)

  return (
    <div className={classes.profilePictureEditor}>
      <StaffProfileImage staffId={authedStaffModel.id} className={classes.profileImage} stagedImage={stagedImage} />
      <div className={classes.uploadText} onClick={handleClick}>
        Upload photo
      </div>
      <input type="file" hidden accept="image/*" onChange={handleImageChange} ref={fileInputRef} />
    </div>
  )
}

export default useProfilePictureEditor
