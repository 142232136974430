import moment from 'moment'
import {useSelector} from 'react-redux'
import VendorServiceDiscount from '../../lib/VendorServiceDiscount'
import {calculateTaxAndTotal} from '../../Utils/money'
import {
  VENDOR_SERVICE_CANCELLED,
  VENDOR_SERVICE_DECLINED,
  VENDOR_SERVICE_VENDOR_NO_SHOW,
  VENDOR_SERVICE_COMPLETED,
  VENDOR_SERVICE_COMPLETED_PAID,
} from './VendorServiceConstants'

export function getCleaningSlotTime(startAt, endAt) {
  let slotTiming = ''
  if (startAt) {
    slotTiming = `${moment(startAt).format('h:mma')} - ${moment(endAt).format('h:mma')}`
  } else {
    console.error(`Invalid cleaning slot startAt: ${startAt} endAt ${endAt}`)
    slotTiming = 'N/A'
  }

  return slotTiming
}

// -------------------------------------------------------------------
// This is copied from /amplify/backend/function/rexVendors/src/vendorsCloudBridge
const FREQUENCY_REGEX = /^([a-z]+)(_[0-9])*(:[0-9]+)*$/

/**
 * @param {string} frequency
 * @param {Date?} lastOccurredAt
 * @returns {[string, number, Array<number>]}
 */
export function getFrequencyQualities(frequency, lastOccurredAt) {
  if (typeof frequency !== 'string') {
    return ['', 0, []]
  }

  // if they pass a string, we need to convert it to a date object first
  if (typeof lastOccurredAt === 'string') {
    lastOccurredAt = new Date(lastOccurredAt)
  }

  let match = frequency.match(FREQUENCY_REGEX)

  /** @type {string} */
  const base = match[1]

  /** @type {number} -- by default, we assume 1 when omitted (i.e., weekly === weekly_1 === 1) */
  const magnitude = match[2] ? parseInt(match[2].substr(1)) : 1
  /** @type {Array<number>} */
  const weekDays = match[3]
    ? match[3]
        .substr(1)
        .split('')
        .map(n => parseInt(n))
    : lastOccurredAt
    ? [lastOccurredAt.getDay()]
    : []

  // make sure we're sorted 0->6
  weekDays.sort()

  return [base, magnitude, weekDays]
}
// -------------------------------------------------------------------

export const EXTRA_NOTES_DIVIDER = '\n:----------:\n'

/**
 * @param {{taxRate:string}}building
 * @param {number} basePrice
 * @param {Array<{cost: number}>} addOns
 * @param {Array<{amount: number, discountType: string}>} discounts
 * @returns {{taxRate: number, preTax: number, tax: number, total: number, priceWithAddOns: number, priceAfterDiscounts: {
 *   startingPrice: number,
 *   discountContributions: Object<string, number>,
 *   finalCost: number
 * }}}
 */
export function calculateTaxAndTotalForVendorAppointment(building, basePrice, addOns, discounts) {
  const priceWithAddOns = basePrice + (Array.isArray(addOns) ? addOns.reduce((agr, a) => agr + a.cost, 0) : 0)
  const priceAfterDiscounts = VendorServiceDiscount.applyManyDiscounts(discounts || [], priceWithAddOns)
  return {
    ...calculateTaxAndTotal(priceAfterDiscounts.finalCost, building),
    priceWithAddons: priceWithAddOns,
    priceAfterDiscounts: priceAfterDiscounts,
  }
}

/**
 * @param {string} status
 * @return {boolean}
 */
export function statusIsPast(status) {
  return [
    VENDOR_SERVICE_COMPLETED,
    VENDOR_SERVICE_COMPLETED_PAID,
    VENDOR_SERVICE_CANCELLED,
    VENDOR_SERVICE_DECLINED,
    VENDOR_SERVICE_VENDOR_NO_SHOW,
  ].includes(status)
}
