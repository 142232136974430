import React from 'react'
import SortableTable, {ColumnConfig} from '../SortableTable'
import PropTypes from 'prop-types'
import moment from 'moment'
import Routes from '../../constants/RouteConstants'
import {withRouter} from 'react-router-dom'
import ResidentStatusTag from '../UITags/ResidentStatusTag'
import {spaceSmall} from '../../assets/styles/spacing'
import {makeStyles} from '@material-ui/core/styles'

const ResidentsTable = function(props) {
  const classes = useStyles()
  const handleClickResident = resident => {
    props.history.push(Routes.constructPath(Routes.PROFILE_VIEW_SINGLE, {residentId: resident.id}))
  }

  const columns = [
    new ColumnConfig({
      title: 'Unit',
      renderPrimitive: resident => resident.occupancy.unit.number,
      comparator: ColumnConfig.simpleComparatorGenerator('occupancy.unit.number'),
    }),

    new ColumnConfig({
      title: 'Resident name',
      renderPrimitive: resident => resident.displayName,
      render: (r, col) => {
        return (
          <div>
            <span>{col.renderPrimitive(r)}</span>
            <ResidentStatusTag residentId={r.id} className={classes.statusTag} />
          </div>
        )
      },
      comparator: ColumnConfig.simpleComparatorGenerator('displayName'),
      isDefaultSort: true,
    }),

    new ColumnConfig({
      title: 'Leaseholder',
      renderPrimitive: resident => {
        if (resident.referenceId === resident.secondaryReferenceId) {
          return 'True'
        } else {
          return 'False'
        }
      },
      comparator: (a, b) => {
        let aIsLeaseHolder = a.referenceId === a.secondaryReferenceId
        let bIsLeaseHolder = b.referenceId === b.secondaryReferenceId
        if (aIsLeaseHolder < bIsLeaseHolder) {
          return -1
        } else if (aIsLeaseHolder === bIsLeaseHolder) {
          return 0
        } else {
          return 1
        }
      },
    }),

    new ColumnConfig({
      title: 'T-code',
      renderPrimitive: resident => resident.referenceId,
      comparator: ColumnConfig.simpleComparatorGenerator('referenceId'),
    }),

    new ColumnConfig({
      title: 'Auto pay',
      renderPrimitive: resident => {
        if (resident.autoPay.items.some(element => element.active)) return 'Yes'
        return 'No'
      },
      comparator: (a, b) => {
        let aHasAutoPay = 'No'
        let bHasAutoPay = 'No'
        if (a.autoPay.items.some(element => element.active)) aHasAutoPay = 'Yes'
        if (b.autoPay.items.some(element => element.active)) bHasAutoPay = 'Yes'
        if (aHasAutoPay < bHasAutoPay) return -1
        if (aHasAutoPay === bHasAutoPay) return 0
        return 1
      },
    }),

    new ColumnConfig({
      title: 'App user',
      renderPrimitive: resident => {
        if (!!resident.dateClaimed) {
          return 'Yes'
        } else {
          return 'No'
        }
      },
      comparator: (a, b) => {
        let aIsAppUser = 'No'
        let bIsAppUser = 'No'
        if (!!a.dateClaimed) aIsAppUser = 'Yes'
        if (!!b.dateClaimed) bIsAppUser = 'Yes'
        if (aIsAppUser < bIsAppUser) {
          return -1
        } else if (aIsAppUser === bIsAppUser) {
          return 0
        } else {
          return 1
        }
      },
    }),

    new ColumnConfig({
      title: 'Tenure',
      renderPrimitive: resident => {
        let tenureInMonths = moment().diff(moment(resident.moveInDate), 'months')
        let tenureInYears = Math.floor(tenureInMonths / 12)
        let remainderInMonths = tenureInMonths % 12
        return `${tenureInYears} yrs ${remainderInMonths} mos`
      },
      comparator: (a, b) => {
        let tenureInMonthsA = moment().diff(moment(a.moveInDate), 'months')
        let tenureInYearsA = Math.floor(tenureInMonthsA / 12)
        let remainderInMonthsA = tenureInMonthsA % 12
        let tenureA = `${tenureInYearsA} yrs ${remainderInMonthsA} mos`
        let tenureInMonthsB = moment().diff(moment(b.moveInDate), 'months')
        let tenureInYearsB = Math.floor(tenureInMonthsB / 12)
        let remainderInMonthsB = tenureInMonthsB % 12
        let tenureB = `${tenureInYearsB} yrs ${remainderInMonthsB} mos`
        if (tenureA < tenureB) {
          return -1
        } else if (tenureA === tenureB) {
          return 0
        } else {
          return 1
        }
      },
    }),

    new ColumnConfig({
      title: 'Lease expiration',
      renderPrimitive: resident => {
        if (resident.occupancy.leaseToDate) {
          return moment(resident.occupancy.leaseToDate).format('YYYY-MM-DD')
        } else {
          return '--'
        }
      },
      comparator: (a, b) => {
        let aLeaseExpiration = moment(a.occupancy.leaseToDate).format('YYYY-MM-DD')
        let bLeaseExpiration = moment(b.occupancy.leaseToDate).format('YYYY-MM-DD')
        if (aLeaseExpiration < bLeaseExpiration) {
          return -1
        } else if (aLeaseExpiration === bLeaseExpiration) {
          return 0
        } else {
          return 1
        }
      },
    }),
  ]

  return <SortableTable data={props.residents} columns={columns} downloadFileName={`Residents`} onClickRow={handleClickResident} />
}

const useStyles = makeStyles(theme => ({
  statusTag: {
    marginLeft: spaceSmall,
    verticalAlign: '2px',
  },
}))

ResidentsTable.propTypes = {
  residents: PropTypes.array.isRequired,
}

export default withRouter(ResidentsTable)
