import React from 'react'
import * as Sentry from '@sentry/react'

class AbstractKYRScreen extends React.Component {
  constructor(props) {
    super(props)

    // if there was frozen state passed to this component, we want to load from it
    this.state = {
      ...props.navState,
    }

    this.extractFreezableState = this.extractFreezableState.bind(this)

    if (props._visitRecord) {
      // we override the freezeState function on the passed visit record to extract this specific component's state
      props._visitRecord.setExtractFreezableStateFunction = this.extractFreezableState
    }
  }

  /**
   * @return {object}
   */
  extractFreezableState() {
    // by default we take nothing, but can optionally return a subset of/entire state via override
    return {}
  }
}
export default AbstractKYRScreen
