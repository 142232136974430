import React from 'react'
import PropTypes from 'prop-types'
import {
  INSIGHT_CATEGORY_LIFE_EVENT,
  INSIGHT_CATEGORY_OCCASION,
  INSIGHT_CATEGORY_OTHER,
  INSIGHT_CATEGORY_ISSUE,
  INSIGHT_CATEGORY_INSIGHT,
} from './AssistantConstants'
import {makeStyles} from '@material-ui/core'
import GenericFilterMenu from '../GenericFilterMenu'
import RXRButton from '../RXRButton'
import {rxrBlackColor} from '../../assets/styles/color'

const useStyles = makeStyles({
  filterButton: {
    fontSize: 12,
    fontWeight: 700,
    letterSpacing: 0.8,
    color: rxrBlackColor,
    textTransform: 'uppercase',
  },
})

const filterLabels = {
  HIGH_PRIORITY: 'High priority',
  [INSIGHT_CATEGORY_INSIGHT]: 'Insight',
  [INSIGHT_CATEGORY_ISSUE]: 'Issue',
  [INSIGHT_CATEGORY_LIFE_EVENT]: 'Life event',
  [INSIGHT_CATEGORY_OTHER]: 'Other',
  [INSIGHT_CATEGORY_OCCASION]: 'Occasion',
}

export const ALL_FILTERS = Object.keys(filterLabels)

const InsightsFilter = ({onChange}) => {
  const classes = useStyles()

  return (
    <GenericFilterMenu filterLabelMap={filterLabels} onChange={onChange}>
      <RXRButton onClick={() => {}} type={RXRButton.TYPE_TEXT} className={classes.filterButton}>
        filter
      </RXRButton>
    </GenericFilterMenu>
  )
}

InsightsFilter.propTypes = {
  selectedFilters: PropTypes.array,
  onChange: PropTypes.func,
}

export default InsightsFilter
