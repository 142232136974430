import React from 'react'
import PropTypes from 'prop-types'
import ProfileImage from '../ProfileImage'
import {useSelector} from 'react-redux'

function StaffProfileImage(props) {
  const staff = useSelector(state => state.Staff.staffLookup[props.staffId])

  return (
    <ProfileImage
      colorId={props.staffId}
      displayName={staff && staff.displayName}
      className={props.className}
      s3Object={props.stagedImage ? props.stagedImage : staff && staff.profileImage}
    />
  )
}

StaffProfileImage.propTypes = {
  staffId: PropTypes.string,
  className: PropTypes.string, // styles to pass the container (such as, height, width, borders, etc
  stagedImage: PropTypes.object,
}

export default StaffProfileImage
