/*
There's a good amount of duplicated code between this component and the MultipleAmenitiesCalendar.js component
I'm not sure how best to centralize the logic... will need to give it more thought.

In the meantime, if you find a bug in here please confirm it's not also present on the MultipleAmenitiesCalendar component
 */

import React, {useEffect, useRef, useState} from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import Calendar from '../Calendar/Calendar'
import SimpleSpinner from '../SimpleSpinner'
import {makeStyles} from '@material-ui/core/styles'
import RichTooltip from '../RichTooltip'
import {useDispatch, useSelector} from 'react-redux'
import AmenityConstants from './AmenitiesConstants'
import {loadAmenityReservationsForBuilding} from '../../actions/amenitiesActions'
import {getAmenityDetails} from '../../lib/queries'
import useResidentLookup from '../hooks/useResidentLookup'
import {Colors, Spacing, Typography} from '../../assets/styles'
import AmenitiesConstants from './AmenitiesConstants'
import {convertGeneralAvailabilityIntoClosureEvents, splitAndFlattenClosuresByDay} from './amenityCalendarHelpers'
import AmenityReservationEditor from './AmenityReservationEditor'
import {useHistory} from 'react-router-dom'
import useDoOnceTimer from '../hooks/useDoOnceTimer'
import {v4 as uuid} from 'uuid'
import CompactAmenityReservationEditor from './CompactAmenityReservationEditor'
import {useLocation} from 'react-router-dom'
import {onboardingElevatorAmenityFilter} from '../../Utils/amenityUtils'
import Routes from '../../constants/RouteConstants'

const DUMMY_EVENT_ID = uuid()

function AmenityWeekCalendar(props) {
  const classes = useStyles()
  const [isLoadingDetails, setIsLoadingDetails] = useState(false)
  // closure events are closure records from Dynamo
  const [closureEvents, setClosureEvents] = useState([])
  // general closures are times the amenity is closed due to non-existent GeneralAvailabilities
  const [generalClosureEvents, setGeneralClosureEvents] = useState([])
  const [events, setEvents] = useState([])
  const [startAndEndTimes, setStartAndEndTimes] = useState({start: 0, end: 1440})
  const [anchorRef, setAnchorRef] = useState(null)
  const [focusedEventId, setFocusedEventId] = useState(null)
  const [crudReservationDetails, setCrudReservationDetails] = useState(null)
  const dispatch = useDispatch()
  const amenityReservationsLookup = useSelector(state => state.Amenities.amenityReservationsLookup)
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const {getResident} = useResidentLookup()
  const history = useHistory()
  const recentlyClosed = useRef(false)
  const [dummyEvent, setDummyEvent] = useState(null)
  const {setTimer} = useDoOnceTimer()
  const currentLocation = useLocation()
  const isElevatorAmenity = currentLocation.pathname.includes(Routes.ELEVATOR_CALENDAR_VIEW)
  const startOfWeek = moment(props.date).startOf('week')
  const endOfWeek = moment(props.date).endOf('week')
  // columns are always the days of the week starting with Sunday ("Sun")
  const columns = [...new Array(7)].map((e, i) =>
    moment(startOfWeek)
      .add(i, 'days')
      .format('ddd'),
  )

  /**
   * @param {{startAt: Date|string, endAt: Date|string}} r
   * @return {{column: string, start: number, end: number}}
   */
  const getColumnStartAndEndTimes = r => {
    const startOfDay = moment(r.startAt).startOf('day')
    return {
      column: moment(r.startAt).format('ddd'),
      start: moment(r.startAt).diff(startOfDay, 'minutes'),
      end: moment(r.endAt).diff(startOfDay, 'minutes'),
    }
  }

  const loadAmenityDetails = async () => {
    const [details] = await Promise.all([
      getAmenityDetails(props.amenityId),
      loadAmenityReservationsForBuilding(dispatch, activeBuildingId),
    ])

    // we determine the start/end time of the entire grid based on the largest combination of start/end times from general availability
    const se = details.generalAvailability.reduce(
      (agr, a) => {
        agr.start = Math.min(a.startTime, agr.start)
        agr.end = Math.max(a.endTime, agr.end)
        return agr
      },
      {start: 1440, end: 0}, // start and end are set at opposite extremes so they'll reduce correctly
    )

    // group our closures by day of the week
    const generalClosuresByDay = AmenityConstants.daysInAWeek.map(() => [])
    // these are already sorted from the query response, but we do assume NO overlaps
    details.generalAvailability.forEach(av => {
      generalClosuresByDay[av.dayOfWeek].push(av)
    })

    const generalClosures = []
    // build the general closure events one day of the week at a time
    generalClosuresByDay.forEach((gAs, dow) => {
      // NOTE: it does not matter that these general closures are not rebuilt when the date input is changed
      // because the calendar component only cares about start times relative to midnight (regardless of day)
      const startOfDay = moment(startOfWeek)
        .add(dow, 'days')
        .startOf('day')

      generalClosures.push(...convertGeneralAvailabilityIntoClosureEvents(props.amenityId, se.start, se.end, startOfDay, gAs))
    })

    setGeneralClosureEvents(
      generalClosures.map(c => ({
        id: c.id,
        ...getColumnStartAndEndTimes(c),
        content: `CLOSED`,
        isDisabled: true,
      })),
    )

    setStartAndEndTimes(se)
    // split any closure spanning multiple days into multiple closures, one per day
    const allClosures = splitAndFlattenClosuresByDay(details.closures)
    setClosureEvents(allClosures)
  }

  useEffect(() => {
    setEvents(
      // our events comprise all reservations in this time period
      Object.values(amenityReservationsLookup)
        .filter(
          r =>
            r.amenityId === props.amenityId &&
            r.startAt >= startOfWeek.toISOString() &&
            r.endAt <= endOfWeek.toISOString() &&
            ![AmenitiesConstants.STATE_CANCELLED, AmenitiesConstants.STATE_DECLINED, AmenitiesConstants.STATE_TIMEDOUT].includes(
              r.reservationState,
            ),
        )
        .map(r => {
          let startTimeAndColumns
          if (crudReservationDetails && crudReservationDetails._internalId && r.id === crudReservationDetails._internalId) {
            startTimeAndColumns = getColumnStartAndEndTimes(crudReservationDetails)
          } else {
            startTimeAndColumns = getColumnStartAndEndTimes(r)
          }
          const res = getResident(r.residentId)
          const isPending = r.reservationState === AmenityConstants.STATE_REQUESTED
          return {
            id: r.id,
            ...startTimeAndColumns,
            content: (
              <div>
                {isPending && <div className={classes.pendingTag}>Pending Approval</div>}
                {r.staffId ? r.title : `${res.displayName}, ${res.occupancy.unit.number}`}
              </div>
            ),
            color: isPending ? Colors.rxrOrangeColor : Colors.rxrTealColor,
          }
        })
        .concat(
          // along with all created closures in this time period
          closureEvents
            .filter(c => c.startAt >= startOfWeek.toISOString() && c.endAt <= endOfWeek.toISOString())
            .map(c => ({
              id: c.id,
              ...getColumnStartAndEndTimes(c),
              content: `Closed for ${c.label}`,
              isDisabled: true,
            })),
        ),
    )
  }, [amenityReservationsLookup, props.date, closureEvents, crudReservationDetails])

  // when amenity id or date changes, we re-request the usage details
  useEffect(() => {
    if (!props.amenityId) {
      return
    }

    setIsLoadingDetails(true)
    setEvents([])
    loadAmenityDetails()
      .catch(err => window.alert(err.message))
      .finally(() => setIsLoadingDetails(false))
  }, [props.amenityId])

  const handleChangeEvent = ev => {
    // assume they were just trying to click off the panel
    if (recentlyClosed.current) {
      return
    }

    const startAt = moment(startOfWeek)
      .add(columns.indexOf(ev.column), 'days')
      .startOf('day')
      .add(ev.start, 'minutes')
      .toDate()
    const endAt = moment(startAt)
      .add(ev.end - ev.start, 'minutes')
      .toDate()

    // this _internalId is used to update the event objects column/size while in an edit state
    setCrudReservationDetails({startAt: startAt, endAt: endAt, _internalId: ev.id})
    setFocusedEventId(ev.id)
  }

  const handleClickEvent = ev => {
    // assume they were just trying to click off the panel
    if (recentlyClosed.current) {
      return
    }

    setFocusedEventId(ev.id)
  }

  const handleCreateEvent = ev => {
    // assume they were just trying to click off the panel
    if (recentlyClosed.current) {
      return
    }

    const startAt = moment(startOfWeek)
      .add(columns.indexOf(ev.column), 'days')
      .startOf('day')
      .add(ev.start, 'minutes')
      .toDate()
    const endAt = moment(startAt)
      .add(ev.end - ev.start, 'minutes')
      .toDate()

    setCrudReservationDetails({startAt: startAt, endAt: endAt, amenityId: props.amenityId})
    setDummyEvent({...ev, isDisabled: true, color: Colors.rxrGreenColor, content: 'New Appointment', id: DUMMY_EVENT_ID})
    setFocusedEventId(DUMMY_EVENT_ID)
  }

  const handleUpdateForm = newData => {
    // only change our box if the new form has a start and end time, otherwise we just keep previous settings
    if (!(newData && newData.startAt && newData.endAt)) {
      return
    }

    if (focusedEventId === DUMMY_EVENT_ID) {
      setDummyEvent({...dummyEvent, ...getColumnStartAndEndTimes(newData)})
    } else {
      // update our reservation event accordingly
      const e = events.find(e => e.id === focusedEventId)
      if (e) {
        Object.assign(e, getColumnStartAndEndTimes(newData))
        setEvents([...events])
      }
    }
  }

  const handleCloseMenu = () => {
    // this is used to let them click-off onto an empty square without re-opening another create dialog
    recentlyClosed.current = true
    setTimer(
      'recently-closed-timer',
      () => {
        recentlyClosed.current = false
        // if we unset the dummy event too soon the tooltip anchors briefly to the frame and it looks bad
        // don't believe me? move this outside the timer and watch.
        setDummyEvent(null)
      },
      600,
    )

    // we call this so that any changes made that were not saved can get reverted
    // note: we don't need to check for DUMMY_EVENT because handleUpdateForm can handle undefined
    handleUpdateForm(amenityReservationsLookup[focusedEventId])

    // TODO: I can't get this discard dialog working :/
    // history.push(Routes.constructPath(Routes.AMENITIES_CALENDAR_VIEW_INDIVIDUAL_AMENITY, {amenityId: props.amenityId}))
    setCrudReservationDetails(null)
    setAnchorRef(null)
    setFocusedEventId(null)
  }

  const isCurrentWeek = moment() > startOfWeek && moment() < endOfWeek
  const finalEventsArray = events.concat(generalClosureEvents)
  if (dummyEvent) {
    finalEventsArray.push(dummyEvent)
  }

  return (
    <div className={classes.container}>
      {isLoadingDetails ? (
        <div className={classes.loading}>
          <SimpleSpinner size={SimpleSpinner.SIZE_LARGE} />
        </div>
      ) : (
        <Calendar
          columns={columns}
          events={finalEventsArray}
          onChangeEvent={handleChangeEvent}
          onClickEvent={handleClickEvent}
          onCreateEvent={handleCreateEvent}
          highlightedColumn={isCurrentWeek ? moment().format('ddd') : null}
          startTime={startAndEndTimes.start}
          endTime={startAndEndTimes.end}
          focusedEventId={focusedEventId}
          onRenderFocusedEvent={a => setAnchorRef(a)}
        />
      )}
      <RichTooltip open={!!(focusedEventId && anchorRef)} anchorEl={anchorRef} onClose={handleCloseMenu} placement={'right'}>
        {isElevatorAmenity ? (
          <CompactAmenityReservationEditor
            onComplete={handleCloseMenu}
            initialValues={crudReservationDetails}
            onUpdateForm={handleUpdateForm}
            amenitiesFilter={onboardingElevatorAmenityFilter}
            amenityReservationId={focusedEventId !== DUMMY_EVENT_ID ? focusedEventId : undefined}
          />
        ) : (
          <AmenityReservationEditor
            amenityReservationId={focusedEventId !== DUMMY_EVENT_ID ? focusedEventId : undefined}
            initialValues={crudReservationDetails}
            onComplete={handleCloseMenu}
            onUpdateForm={handleUpdateForm}
            amenitiesFilter={props.amenitiesFilter}
          />
        )}
      </RichTooltip>
    </div>
  )
}

export const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  loading: {
    textAlign: 'center',
  },
  pendingTag: {
    borderRadius: 12,
    backgroundColor: Colors.rxrWhiteColor,
    color: Colors.rxrBlackDarkerColor,
    textTransform: 'uppercase',
    fontSize: Typography.fontSizeExtraSmall,
    display: 'block',
    textAlign: 'center',
    width: '100%',
    marginBottom: Spacing.spaceExtraSmall,
  },
}))

AmenityWeekCalendar.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]), // start date of the week
  amenityId: PropTypes.string.isRequired,
}

export default AmenityWeekCalendar
