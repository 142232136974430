import {
  PERMISSION_GROUP_SUPER_ADMIN,
  PERMISSION_GROUP_LIGHTLY_RESTRICTED,
  PERMISSION_GROUP_HIGHLY_RESTRICTED,
  PERMISSION_GROUP_NOT_RESTRICTED,
  PERMISSION_GROUP_MODERATELY_RESTRICTED,
  PERMISSION_GROUP_FORMER_USER,
} from '../../constants/ModelConstants'
import {
  PERMISSION_ANNOUNCEMENTS_READ,
  PERMISSION_ANNOUNCEMENTS_WRITE,
  PERMISSION_MESSAGES_READ,
  PERMISSION_MESSAGES_WRITE,
  PERMISSION_SOCIAL_EVENTS_READ,
  PERMISSION_SOCIAL_EVENTS_WRITE,
  PERMISSION_RESIDENT_PROFILES_READ,
  PERMISSION_RESIDENT_PROFILES_WRITE,
  PERMISSION_SENSITIVE_INFORMATION_READ,
  PERMISSION_SETTINGS_CONTROL,
  PERMISSION_HOUSEKEEPING_READ,
  PERMISSION_HOUSEKEEPING_WRITE,
} from '../../constants/AppConstants'

export const CATEGORY_USER_TYPE = {
  [PERMISSION_GROUP_SUPER_ADMIN]: 'Super Admin',
  [PERMISSION_GROUP_NOT_RESTRICTED]: 'Staff - Non Restricted',
  [PERMISSION_GROUP_LIGHTLY_RESTRICTED]: 'Staff - Lightly Restricted',
  [PERMISSION_GROUP_MODERATELY_RESTRICTED]: 'Staff - Moderately Restricted',
  [PERMISSION_GROUP_HIGHLY_RESTRICTED]: 'Staff - Highly Restricted',
  [PERMISSION_GROUP_FORMER_USER]: 'Former User',
}

export const PERMISSION_TO_LABEL_MAP = {
  [PERMISSION_ANNOUNCEMENTS_READ]: 'Announcements Read',
  [PERMISSION_ANNOUNCEMENTS_WRITE]: 'Announcements Write',
  [PERMISSION_HOUSEKEEPING_READ]: 'Services Read',
  [PERMISSION_HOUSEKEEPING_WRITE]: 'Services Write',
  [PERMISSION_MESSAGES_READ]: 'Messages Read',
  [PERMISSION_MESSAGES_WRITE]: 'Messages Write',
  [PERMISSION_RESIDENT_PROFILES_READ]: 'Resident Profiles Read',
  [PERMISSION_RESIDENT_PROFILES_WRITE]: 'Resident Profiles Write',
  [PERMISSION_SENSITIVE_INFORMATION_READ]: 'Sensitive Information Read',
  [PERMISSION_SETTINGS_CONTROL]: 'Settings Control',
  [PERMISSION_SOCIAL_EVENTS_READ]: 'Social Events Read',
  [PERMISSION_SOCIAL_EVENTS_WRITE]: 'Social Events Write',
}

export default {
  PAGE_TITLE: 'Users',
  BTN_ADD: 'Create new user',
  BTN_POST: 'Submit',
  BTN_DISABLE_USER: 'Disable user',
  BTN_CANCEL: 'Cancel',
  ENTER_SEARCH_KEYWORDS_CONSTANT: 'Search by name',
  ALL_FIELDS_REQUIRED: '* All starred fields are required.',
}
