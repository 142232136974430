import React, {useState, useEffect, useRef} from 'react'
import GenericServicesPage from './GenericServicesPage'
import {VendorServiceTypeCleaning} from './ServicesConstants'
import Routes from '../../constants/RouteConstants'

function CleaningsPage() {
  return (
    <GenericServicesPage
      serviceType={VendorServiceTypeCleaning}
      title={'Cleaning'}
      createNewCTA={'Book a cleaning'}
      baseRoute={Routes.CLEANING}
      createNewRoute={Routes.CLEANING_CREATE}
      viewSingleRoute={Routes.CLEANING_VIEW_SINGLE}
    />
  )
}

export default CleaningsPage
