import React, {useState} from 'react'
import PropTypes from 'prop-types'
import SelectInput from '../SelectInput'
import {BUILDING_ACTION_TYPE_LABELS, BUILDING_ACTION_TYPE_TO_CATEGORY, CATEGORY_LABELS} from './AssistantConstants'
import {convertKeyLabelMapIntoSelectOptions} from '../../Utils/objectUtil'
import {spaceMedium} from '../../assets/styles/spacing'
import {makeStyles} from '@material-ui/core/styles'

export const CATEGORIES_OPTIONS = convertKeyLabelMapIntoSelectOptions(CATEGORY_LABELS)
export const BUILDING_ACTION_OPTIONS = convertKeyLabelMapIntoSelectOptions(BUILDING_ACTION_TYPE_LABELS, true)

export default function SingleBuildingActionInput(props) {
  const classes = useStyles()
  const handleCategoryChange = (cat, obj) => {
    let newObj = {...obj, category: cat}
    props.onChange(newObj)
  }

  const handleActionChange = (action, obj) => {
    let newObj = {...obj, action: action}
    props.onChange(newObj)
  }

  let actions = BUILDING_ACTION_OPTIONS.filter(a => props.value.category === BUILDING_ACTION_TYPE_TO_CATEGORY[a.value]).sort((a, b) =>
    a.label.localeCompare(b.label),
  )

  return (
    <React.Fragment>
      <SelectInput
        className={classes.gutter}
        onChange={val => {
          handleCategoryChange(val, props.value)
        }}
        isRequired={props.isRequired}
        label={'Category'}
        options={CATEGORIES_OPTIONS}
        value={props.value.category}
        error={props.error}
      />
      <SelectInput
        onChange={val => {
          handleActionChange(val, props.value)
        }}
        disabled={!props.value.category}
        isRequired={props.isRequired}
        label={'Action'}
        options={actions}
        value={props.value.action}
        error={props.error}
      />
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  gutter: {
    marginRight: spaceMedium,
  },
}))

/**
 * @param {string|*} action
 * @returns {{action: string, category: string}}
 */
SingleBuildingActionInput.GetObjectFromAction = action => {
  if (typeof action === 'object') {
    return action
  }
  return {
    action: action,
    category: BUILDING_ACTION_TYPE_TO_CATEGORY[action],
  }
}

SingleBuildingActionInput.propTypes = {
  onChange: PropTypes.func.isRequired, // returns the array of GeneralAvaiability objects
  value: PropTypes.any.isRequired, // an array of GeneralActionModal with `rowId as unique identifire`, `category`, `action`)
  error: PropTypes.bool,
}
