import ActionTypes from './types'

/**
 * @param {function} dispatch
 * @param {string} screenName
 * @param {*?} routeParams
 */
export function navigateToScreen(dispatch, screenName, routeParams) {
  dispatch({
    type: ActionTypes.KYR_WIDGET_NAVIGATE_TO_SCREEN,
    screen: screenName,
    params: routeParams,
  })
}

/**
 * @param {function} dispatch
 */
export function navigateBack(dispatch) {
  dispatch({
    type: ActionTypes.KYR_WIDGET_NAVIGATE_BACK,
  })
}

export function setIsWidgetOpen(dispatch, isWidgetOpen) {
  dispatch({
    type: ActionTypes.KYR_SET_IS_WIDGET_OPEN,
    isWidgetOpen: isWidgetOpen,
  })
}
