import React from 'react'
import SubHeader from '../SubHeader'
import KYRGenericCardComponent from '../KYRGenericCardComponent'
import Timeline from '../Timeline'
import {KYRLoaderScreen} from '../../../assets/styles/layout'
import AbstractKYRScreen from './AbstractKYRScreen'
import {withStyles} from '@material-ui/core'
import KYRFilter from '../KYRFilter'
import {FILTER_COPY} from '../KYRConstants'
import Loader from '../../Loader'
import {getResidentEventsByResident} from '../../../lib/queries'
import KYRInsights from '../KYRInsights'
import {MAP_PRIMARY_RECORD_TO_FILTER_LABEL} from '../KYRConstants'

const styles = theme => ({
  loader: {
    ...KYRLoaderScreen,
  },
  root: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: 465,
  },
  timelineContainer: {
    marginTop: 12,
  },
})

class TimelineComponent extends AbstractKYRScreen {
  state = {
    // start off with all selected
    filterState: FILTER_COPY,
    isEventDataLoading: true,
    residentEventsData: [],
  }

  componentDidMount() {
    getResidentEventsByResident(this.props.navParams.residentId).then(residentEventsData => {
      this.setState({...this.state, residentEventsData: residentEventsData, isEventDataLoading: false})
    })
  }

  onfilterStateChange = updatedfilterState => {
    this.setState({...this.state, filterState: updatedfilterState})
  }

  render() {
    const filteredAndSortedEvents =
      this.state.filterState.length > 0
        ? this.state.residentEventsData.filter(event =>
            this.state.filterState.includes(MAP_PRIMARY_RECORD_TO_FILTER_LABEL[event.primaryRecord]),
          )
        : []

    filteredAndSortedEvents.sort((a, b) => {
      // sort descending
      return a.createdAt === b.createdAt ? 0 : a.createdAt < b.createdAt ? 1 : -1
    })

    const KYRFilterButton = () => <KYRFilter onChange={this.onfilterStateChange} selectedFilters={this.state.filterState} />

    return (
      <KYRGenericCardComponent residentId={this.props.navParams.residentId}>
        {this.state.isEventDataLoading ? (
          <Loader className={this.props.classes.loader} />
        ) : (
          <div className={this.props.classes.root}>
            <KYRInsights residentId={this.props.navParams.residentId} />
            <SubHeader title={'TIMELINE'} RightIconComponent={KYRFilterButton} />
            <div className={this.props.classes.timelineContainer}>
              <Timeline
                isEventDataLoading={this.state.isEventDataLoading}
                filterStateParent={this.state.filterState}
                residentEventsData={filteredAndSortedEvents}
                navigationType={'kyrWidget'}
              />
            </div>
          </div>
        )}
      </KYRGenericCardComponent>
    )
  }
}

export default withStyles(styles)(TimelineComponent)
