import React from 'react'
import SortableTable, {ColumnConfig} from '../SortableTable'
import PropTypes from 'prop-types'
import HighlightText from '../HighlightText'
import useResidentLookup from '../hooks/useResidentLookup'
import moment from 'moment'
import Routes from '../../constants/RouteConstants'
import {useHistory} from 'react-router-dom'
import RXRIcon from '../RXRIcon'

function ServiceRequestsTable(props) {
  const {getResident} = useResidentLookup()
  const history = useHistory()

  const columns = [
    new ColumnConfig({
      title: 'Resident',
      renderPrimitive: r => getResident(r.serviceRequestResidentId).displayName,
      render: (r, col) => <HighlightText search={props.filterTerm}>{col.renderPrimitive(r)}</HighlightText>,
      comparator: ColumnConfig.residentNameComparatorGenerator(getResident, null, 'serviceRequestResidentId'),
    }),
    new ColumnConfig({
      title: 'Unit',
      renderPrimitive: r => getResident(r.serviceRequestResidentId).occupancy.unit.number,
      render: (r, col) => <HighlightText search={props.filterTerm}>{col.renderPrimitive(r)}</HighlightText>,
      comparator: ColumnConfig.residentUnitComparatorGenerator(getResident, null, 'serviceRequestResidentId'),
    }),
    new ColumnConfig({
      title: 'Has PTE',
      renderPrimitive: r => (r.hasPermissionToEnter ? 'Yes' : 'No'),
      render: g => (g.hasPermissionToEnter ? <RXRIcon icon={RXRIcon.CHECK} /> : ''),
      comparator: (a, b) => b.hasPermissionToEnter - a.hasPermissionToEnter,
    }),
    new ColumnConfig({
      title: 'Category',
      renderPrimitive: r => r.category,
      render: (r, col) => <HighlightText search={props.filterTerm}>{col.renderPrimitive(r)}</HighlightText>,
      comparator: ColumnConfig.simpleComparatorGenerator('category'),
    }),
    new ColumnConfig({
      title: 'Work Order ID',
      renderPrimitive: r => r.yardiServiceRequestId,
      render: (r, col) => <HighlightText search={props.filterTerm}>{col.renderPrimitive(r)}</HighlightText>,
      comparator: ColumnConfig.simpleComparatorGenerator('yardiServiceRequestId', undefined, parseInt), // service ids are always numeric
    }),
    new ColumnConfig({
      title: 'Status',
      renderPrimitive: r => r.currentStatus,
      render: (r, col) => <HighlightText search={props.filterTerm}>{col.renderPrimitive(r)}</HighlightText>,
      comparator: ColumnConfig.simpleComparatorGenerator('currentStatus'),
    }),
    new ColumnConfig({
      title: 'Created at',
      renderPrimitive: r => moment(r.createdAt).format('MMMM DD'),
      comparator: (a, b) => moment(a.createdAt) - moment(b.createdAt),
      isDefaultSort: true,
      sortingDirection: 'desc',
    }),
  ]

  return (
    <SortableTable
      columns={columns}
      data={props.requests}
      downloadFileName={'Service Requests'}
      rowHighlightCondition={r => r.id === props.focusedRequestId}
      onClickRow={r => history.push(Routes.constructPath(Routes.SERVICE_REQUESTS_VIEW, {serviceRequestId: r.id}))}
    />
  )
}

ServiceRequestsTable.propTypes = {
  requests: PropTypes.array.isRequired,
  focusedRequestId: PropTypes.string,
  filterTerm: PropTypes.string,
}

export default ServiceRequestsTable
